import React from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  // Link,
  // Typography,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core";
// import clsx from "clsx";
// Icons
import CheckIcon from "@material-ui/icons/Check";
// Local
import sentImg from "../assets/images/sent.png";
import {
  Field,
  // FormSection,
  PhoneField,
  SimpleDialog,
  RadioButtonsField,
  InputPropsFor,
  useBasicDialog,
} from "../components";
import { Form, useFormik, useBoolean } from "../lib";
import { Appraisals } from "../services";
import { useMobile } from "../themes";
import {
  // NewAppraisalDefaults,
  NewAppraisalDialog,
} from "../dialogs/NewAppraisalDialog";

const useStyles = makeStyles(
  theme => ({
    title: { margin: "0 auto" },
    content: {
      paddingBottom: 64,
      "& h6": {
        marginTop: 0,
        fontSize: 16,
        fontWeight: 500,
      },
    },
    tabBox: {
      marginLeft: -24,
      marginRight: -24,
      "& .MuiTabs-indicator": {
        backgroundColor: "#4E50FF",
      },
    },
    check: { color: "#00D289" },
    prefillActions: {
      backgroundColor: "white",
      position: "sticky",
      bottom: 0,
      padding: 24,
      zIndex: theme.zIndex.appBar,
      // [theme.breakpoints.down("sm")]: {
      //   padding: 8,
      // },
    },
    actions: {
      backgroundColor: "white",
      position: "sticky",
      bottom: 0,
      zIndex: theme.zIndex.appBar,

      display: "flex",
      paddingBottom: 32,
      paddingTop: 32,
      [theme.breakpoints.down("sm")]: {
        display: "block",
        textAlign: "right",
      },
    },
    actionInput: {
      marginRight: 24,
    },
    hidden: {
      visibility: "hidden",
    },
    actionButton: {
      marginLeft: "auto",
      minHeight: 56,
      minWidth: 116,
      [theme.breakpoints.down("sm")]: {
        marginTop: 32,
      },
    },
    cancel: {
      backgroundColor: "#EFF1FB",
      color: "#141932",
      // marginRight: "auto",
    },
  }),
  {
    classNamePrefix: "InviteCustomerDialog",
  },
);

/** @type {Appraisals.ShareValues & { url?:string; }} */
const defaultValues = {
  typeCode: "tradein",
  shareType: "url",
  email: "",
  phone: "",
  prefillValues: undefined,
  url: "",
};
/** @type {{id:Appraisals.TypeCode,title:string}[]} */
const appraisalTypes = [
  { id: "tradein", title: "Buying center form" },
  { id: "showroom_tradein", title: "Showroom Trade in form" },
  { id: "lease", title: "Lease return form" },
];

const sentAlertContent = (
  <Box color="#141932" textAlign="center" fontSize="16px" fontWeight="400">
    <img src={sentImg} alt="" />
    <Box pt="24px" pb="8px" fontSize="24px" fontWeight="600">
      Sent!
    </Box>
    The form link has been sent to the customer.
  </Box>
);

const InviteCustomerDialog = React.memo(
  /**
   * @param {{ onClose:Function }} param0
   */
  function InviteCustomerDialog({ onClose }) {
    // #region Hooks
    const classes = useStyles();
    const isMobile = useMobile();

    const [busy, setBusy] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(true);
    const { alertAsync, basicDialog } = useBasicDialog();

    const frm = useFormik({
      enableReinitialize: true,
      initialValues: defaultValues,
      validateOnChange: false,
      // validationSchema,
      async onSubmit(values, helpers) {
        setBusy(true);
        try {
          // console.log("SHARE", values);
          const { shareType, url } = values;
          if (shareType === "url") {
            // Get or Copy URL
            if (!url) {
              const { shareUrl } = await Appraisals.shareNew(values);
              helpers.setFieldValue("url", shareUrl);
            } else if (window.navigator?.clipboard?.writeText) {
              window.navigator.clipboard.writeText(url);
            }
            return;
          }
          // Send URL to customer.
          await Appraisals.shareNew(values);
          setDialogOpen(false);
          requestAnimationFrame(async () => {
            await alertAsync({
              content: sentAlertContent,
              title: "",
            });
            onClose();
          });
        } catch (err) {
          console.log("" + err);
          alertAsync("There was an error. Please try again.");
        } finally {
          setBusy(false);
        }
      },
    });

    const {
      getFieldHelpers,
      values,
      values: { typeCode, prefillValues, shareType },
    } = frm;

    const onCopyUrl = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (window.navigator?.clipboard?.writeText) {
          window.navigator.clipboard.writeText(values.url);
        }
      },
      [values.url],
    );

    const onChangeVia = React.useCallback(
      (e, value) => {
        const shareTypeFld = getFieldHelpers("shareType");
        shareTypeFld.setValue(value);
      },
      [getFieldHelpers],
    );

    // #region Prefill
    const [prefill, startPrefill, stopPrefill] = useBoolean();
    const onClosePrefill = React.useCallback(
      /**
       * @type {import("@material-ui/core").ModalProps['onClose']}
       */
      (e, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        stopPrefill();
      },
      /* eslint-disable-next-line */
      [],
    );
    const onSavePrefill = React.useCallback(
      async prefillValues => {
        // console.log("PREFILL!", prefillValues);
        const prefillValuesFld = getFieldHelpers("prefillValues");
        prefillValuesFld.setValue(prefillValues);
        const urlFld = getFieldHelpers("url");
        urlFld.setValue("");
        if (prefillValues.email && !values.email) {
          const emailFld = getFieldHelpers("email");
          emailFld.setValue(prefillValues.email);
        }
        if (prefillValues.phone && !values.phone) {
          const phoneFld = getFieldHelpers("phone");
          phoneFld.setValue(prefillValues.phone);
        }
        onClosePrefill();
      },
      [values.email, values.phone, onClosePrefill, getFieldHelpers],
    );
    const renderPrefillActions = React.useCallback(
      ({ loading }) => {
        return (
          <DialogActions className={classes.prefillActions}>
            <Button
              className={classes.cancel}
              variant="contained"
              color="primary"
              // size="small"
              disabled={loading}
              onClick={stopPrefill}
            >
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              // size="small"
              disabled={loading}
            >
              Continue
            </Button>
          </DialogActions>
        );
      },
      [classes, stopPrefill],
    );
    // #endregion

    React.useEffect(() => {
      const urlFld = getFieldHelpers("url");
      urlFld.setValue("");
    }, [typeCode, getFieldHelpers]);

    // #endregion

    return (
      <>
        <SimpleDialog
          className={classes.root}
          fullScreenMobile
          maxWidth="sm"
          onClose={onClose}
          open={dialogOpen}
          title={<div className={classes.title}>Share customer form</div>}
        >
          <Form form={frm}>
            <DialogContent className={classes.content}>
              <div className={classes.tabBox}>
                <Tabs
                  value={shareType}
                  variant="fullWidth"
                  onChange={onChangeVia}
                >
                  <Tab label="URL" value="url" />
                  <Tab label="Email" value="email" />
                  <Tab label="SMS" value="sms" />
                </Tabs>
                <Divider />
              </div>
              <Box pt="32px">
                <RadioButtonsField name="typeCode" items={appraisalTypes} />
              </Box>
              <Box alignItems="center" display="flex" pt="32px">
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  onClick={startPrefill}
                  disabled={busy}
                >
                  Prefill form
                </Button>
                <Box
                  color="rgba(0,0,0,0.4)"
                  alignItems="center"
                  display="flex"
                  ml="12px"
                >
                  {prefillValues ? (
                    <>
                      <CheckIcon className={classes.check} />
                      &nbsp;&nbsp;Form filled
                    </>
                  ) : (
                    <>Form not filled</>
                  )}
                </Box>
              </Box>
              {shareType === "url" && (
                <div className={classes.actions}>
                  <Field
                    name="url"
                    // placeholder="URL"
                    className={`${classes.actionInput}${
                      !values.url ? " " + classes.hidden : ""
                    }`}
                    fullWidth
                    InputProps={InputPropsFor.readOnly}
                  />
                  <Button
                    className={classes.actionButton}
                    color="secondary"
                    variant="contained"
                    onClick={isMobile && values.url ? onCopyUrl : undefined}
                    type={isMobile && values.url ? undefined : "submit"}
                    disabled={busy}
                  >
                    {values.url ? "Copy" : "Get URL"}
                  </Button>
                </div>
              )}
              {shareType === "email" && (
                <div className={classes.actions}>
                  <Field
                    name="email"
                    placeholder="Customer email address"
                    className={classes.actionInput}
                    fullWidth
                    required
                  />
                  <Button
                    className={classes.actionButton}
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={busy}
                  >
                    Send
                  </Button>
                </div>
              )}
              {shareType === "sms" && (
                <div className={classes.actions}>
                  <PhoneField
                    name="phone"
                    placeholder="Customer phone number"
                    className={classes.actionInput}
                    fullWidth
                    required
                  />
                  <Button
                    className={classes.actionButton}
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={busy}
                  >
                    Send
                  </Button>
                </div>
              )}
            </DialogContent>
          </Form>
        </SimpleDialog>
        {prefill && (
          <NewAppraisalDialog
            defaults={prefillValues}
            onClose={onClosePrefill}
            onSubmit={onSavePrefill}
            renderActions={renderPrefillActions}
            titlePrefix="Prefill form: "
            typeCode={frm.values.typeCode}
            validation="minimal"
          />
        )}
        {basicDialog}
      </>
    );
  },
);

export const InviteCustomerButton = React.memo(
  /**
   *
   */
  function InviteCustomerButton({ children, className }) {
    const [editing, setEditing] = React.useState(false);
    const onClick = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (e && e.preventDefault) e.preventDefault();
        setEditing(true);
      },
      [],
    );
    const onClose = React.useCallback(
      /**
       * @type {import("@material-ui/core").ModalProps['onClose']}
       */
      (e, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        setEditing(false);
      },
      [],
    );

    return (
      <>
        <Button
          className={className}
          onClick={onClick}
          title="Send an invitation to your customer."
        >
          {children || "Share customer form"}
        </Button>
        {editing && <InviteCustomerDialog onClose={onClose} />}
      </>
    );
  },
);
