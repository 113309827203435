import React from "react";
import { InputAdornment, IconButton, MenuItem, Menu } from "@material-ui/core";
// Local

import AddIcon from "@material-ui/icons/Add";

export const CustomMenuVars = React.memo(function CustomMenuVars({
  usableVars,
  name,
  frm,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <InputAdornment position="end">
        <IconButton
          onClick={e => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <AddIcon />
        </IconButton>
      </InputAdornment>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {usableVars?.map((v, index) => {
          return (
            <MenuItem
              key={index}
              onClick={data => {
                frm.setFieldValue(name, frm.values[name] + `{{${v.label}}}`);
                handleClose();
              }}
            >
              {`{{${v.label}}}`}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
});
