import axios from "axios";
// import type { AxiosError } from "axios";
// Local
// import { timeoutAsync } from "../utils";
import { carfaxAuth } from "./carfaxAuth";

const graphUrl = "https://connect.carfax.com/v1/graphql";

export interface Carfax4PillarsOptions {
  vin: string;
}

export interface Carfax4PillarsData {
  data: {
    dealerReport: {
      fourPillars: {
        accident: {
          /** e.g. `"https://partnerstatic.carfax.com/img/vhr/ico_alert.svg"` */
          iconUrl: string;
          /** e.g. `"Branded Title"` */
          iconText: string;
          /** e.g. `1`-`4` display position. */
          position: number;
          hasAccidents: boolean;
        };
        owner: {
          /** e.g. `"https://partnerstatic.carfax.com/img/vhr/ico_owner3.svg"` */
          iconUrl: string;
          /** e.g. `"3+ Owners"` */
          iconText: string;
          /** e.g. `1`-`4` display position. */
          position: number;
          isOneOwner: boolean;
        };
        useType: {
          /** e.g. `"https://partnerstatic.carfax.com/img/vhr/ico_personal.svg"` */
          iconUrl: string;
          /** e.g. `"Personal Use"` */
          iconText: string;
          /** e.g. `1`-`4` display position. */
          position: number;
          isPersonalUse: boolean;
        };
        serviceRecord: {
          /** e.g. `"https://partnerstatic.carfax.com/img/vhr/ico_service.svg"` */
          iconUrl: string;
          /** e.g. `"Service History"` */
          iconText: string;
          /** e.g. `1`-`4` display position. */
          position: number;
          /** e.g. 15 */
          recordCount: number;
        };
      };
    };
  };
}

export interface CarfaxPillar {
  name: "accident" | "owner" | "useType" | "serviceRecord";
  /** e.g. `"https://partnerstatic.carfax.com/img/vhr/ico_alert.svg"` */
  iconUrl: string;
  /** e.g. `"Branded Title"` */
  iconText: string;
  /** e.g. `1`-`4` display position. */
  position: number;
  hasAccidents: boolean;
  isOneOwner: boolean;
  isPersonalUse: boolean;
  recordCount: number;
}

export interface CarfaxReportOptions {
  vin: string;
}

export interface CarfaxReportLinkData {
  data: {
    dealerReport: {
      carfaxLink: {
        url: string;
      };
    };
  };
}

export interface CarfaxSnapshotKeyOptions {
  vin: string;
}

export interface CarfaxSnapshotKeyData {
  data: {
    dealerReport: {
      snapshotKey: string;
    };
  };
}

function runQuery<T = any>(queryBody: string) {
  const query = `query {${queryBody}
}`;
  const headers = {
    Authorization: `Bearer ${carfaxAuth.getAccessToken()}`,
    "Content-Type": "application/json",
  };
  // console.log("HEADERS", headers);
  return axios.post<T>(graphUrl, { query }, { headers });
}

const query4p = `fourPillars {
      accident {
          iconUrl
          iconText
          position
          hasAccidents
      }
      owner {
          iconUrl
          iconText
          position
          isOneOwner
      }
      useType {
          iconUrl
          iconText
          position
          isPersonalUse
      }
      serviceRecord {
          iconUrl
          iconText
          position
          recordCount
      }
    }`;

function sort4PillarsToArray(
  fourPillars: Carfax4PillarsData["data"]["dealerReport"]["fourPillars"],
) {
  const pillars: CarfaxPillar[] = [];
  if (!fourPillars) {
    return pillars;
  }
  for (const key in fourPillars) {
    const pillar = fourPillars[key];
    if (pillar) {
      pillars.push({
        name: key,
        ...pillar,
      });
    }
  }
  return pillars.sort(it => it.position);
}

export const carfax = {
  auth: carfaxAuth,

  /** See https://developer.carfax.com/docs/connect/dealer#four-pillars */
  async get4Pillars({ vin }: Carfax4PillarsOptions) {
    const query = `
  dealerReport(vin: "${vin}") {
    ${query4p}
  }`;
    const res = await runQuery<Carfax4PillarsData>(query);
    return sort4PillarsToArray(res.data?.data?.dealerReport?.fourPillars);
  },

  async getReportLink({ vin }: CarfaxReportOptions) {
    const query = `
  dealerReport(vin: "${vin}") {
    carfaxLink {
      url
    }
  }`;
    const res = await runQuery<CarfaxReportLinkData>(query);
    return res.data?.data?.dealerReport?.carfaxLink;
  },
  async gotoReport(options: CarfaxReportOptions) {
    // Give the developer a chance to open DevTools if necessary...
    // await timeoutAsync(5000);

    if (carfaxAuth.gotoConnectRequired()) {
      // Now going to the Carfax auth page. We'll come back to the current page
      // after we've connected.
      return;
    }
    let carfaxLink;
    try {
      carfaxLink = await carfax.getReportLink(options);
    } catch (err) {
      const status = err?.response?.status;
      if (status > 400 && status < 500) {
        const refreshed = await carfaxAuth.refreshToken();
        if (!refreshed) {
          // We should now be logged out, so send them to the login page.
          carfaxAuth.gotoConnectRequired();
          return "Error: Could not refresh access token.";
        } else {
          carfaxLink = await carfax.getReportLink(options);
        }
      } else {
        return err;
      }
    }
    // Go to the Carfax report page.
    window.location.replace(carfaxLink?.url ?? "/");
  },

  /** See https://developer.carfax.com/docs/connect/dealer#dealer-snapshot */
  async getSnapshotKey({ vin }: CarfaxSnapshotKeyOptions) {
    const query = `
  dealerReport(vin: "${vin}") {
    snapshotKey
  }`;
    const res = await runQuery<CarfaxSnapshotKeyData>(query);
    return res.data?.data?.dealerReport?.snapshotKey;
  },
  /** Get both snapshot and 4 pillars data all at once. */
  async getSnap4P({ vin }: CarfaxSnapshotKeyOptions & Carfax4PillarsOptions) {
    const query = `
  dealerReport(vin: "${vin}") {
    snapshotKey
    ${query4p}
  }`;
    try {
      const res = await runQuery<Carfax4PillarsData & CarfaxSnapshotKeyData>(
        query,
      );
      const { snapshotKey, fourPillars } = res.data?.data?.dealerReport;
      return {
        snapshotKey,
        fourPillars: sort4PillarsToArray(fourPillars),
      };
    } catch (err) {
      const status = err?.response?.status;
      if (status > 400 && status < 500) {
        const refreshed = await carfaxAuth.refreshToken();
        if (!refreshed) {
          return false;
        } else {
          return carfax.getSnap4P({ vin });
        }
      }
      return undefined;
    }
  },

  refreshDocumentSnapshot() {
    const refreshSnapshotEvent = new Event("refreshCarfaxSnapshotData");
    document.dispatchEvent(refreshSnapshotEvent);
  },
};
