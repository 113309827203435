import { authClient } from "../state/auth/actions";

/** Set appraisal status to `grounded`. */
export async function grounded(id: number) {
  await authClient.post(`/appraisals/${id}/grounded`, {});
}
/** Set appraisal status to `pickedUp`. */
export async function pickedUp(id: number) {
  await authClient.post(`/appraisals/${id}/picked-up`, {});
}
/** Set appraisal status to `returned`. */
export async function returned(
  id: number,
  values: ContactInfo & { turninDate: Date | string | null },
) {
  await authClient.post(`/appraisals/${id}/returned`, values);
}

export async function setLeaseReturnLocation(id: number, values: ContactInfo) {
  await authClient.post(`/appraisals/${id}/location`, values);
}

export async function shareNew(values: ShareValues) {
  const { data } = await authClient.post<ShareResult>(
    `/appraisals/share-invite`,
    values,
  );
  return data;
}

export async function startNew(values: NewValues) {
  const { data } = await authClient.post<NewResult>(
    `/appraisals/start`,
    values,
  );
  return data;
}

// #region Types
export interface ContactInfo {
  address?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

export interface NewResult {
  id: number;
  inspectionUrl: string;
}

export interface NewValues {
  typeCode?: TypeCode;

  plate?: string;
  plateState?: string;
  vin: string;
  trim: string;
  vehicle: VehicleFromVin;
  vehicles: VehicleFromVin[];
  mileage: number;
  ownerStatusId: number | null;

  // Bank details

  financeBank?: string;
  financeBankNum?: string;
  maturityDate?: string | null;

  // Customer details

  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;

  // Customer address

  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;

  // User preferences

  sendInspect: SendUserInspect;
}

export type PrefillValues = Partial<Omit<NewValues, "sendInspect">>;

export type SendUserInspect = "email" | "phone" | "email_phone";

export interface ShareResult {
  shareUrl?: string;
}

export type ShareType = "email" | "sms" | "url";

export interface ShareValues {
  typeCode: TypeCode;
  shareType: ShareType;

  email?: string;
  phone?: string;
  prefillValues?: PrefillValues | null;
}
export type TypeIdCode = "lease" | "tradein";

/** Code for the type AND source type of a new appraisal. */
export type TypeCode = TypeIdCode | "showroom_tradein" | "service_tradein";

export function typeIdCode(typeCode: TypeCode): TypeIdCode {
  return typeCode === "showroom_tradein" || typeCode === "service_tradein"
    ? "tradein"
    : typeCode;
}

// #endregion
