import React from "react";
import clsx from "clsx";
import {
  //
  Button,
  ButtonGroup,
  FormControl,
  // FormControlLabel,
  FormHelperText,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
// Local
import { useField } from "../../lib";

const useStyles = makeStyles(
  theme => ({
    root: {
      marginBottom: 16,
    },
    buttons({ buttonWidth, separated }) {
      /** @type {React.CSSProperties} */
      const style = {
        minHeight: 40,
        // display: "block",
      };
      if (buttonWidth) {
        style["& button"] = {
          width: buttonWidth,
        };
      }
      if (separated) {
        // style["& button"] = {
        //   ...style["& button"],
        //   borderRadius: 8,
        // };
        style["& button:not(:last-child)"] = {
          // marginLeft: 5,
          // marginRight: 5,
          marginRight: 10,
        };
      }
      return style;
    },
    label: {
      paddingBottom: 8,
    },
    selected: ({
      selectedColor = "#1581FF",
      // separated,
      // selectedColor = separated ? "#b7b7b7" : "#1581FF",
    }) => ({
      backgroundColor: selectedColor,
      borderColor: selectedColor,
      color: "#fff",
      "&:hover": {
        backgroundColor: selectedColor,
        borderColor: selectedColor,
      },
    }),
  }),
  {
    classNamePrefix: "ButtonGroupField",
  },
);

const yesNoItems = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const ButtonGroupField = React.memo(
  /**
   * @typedef {import("@material-ui/core").FormControlProps} FormControlProps
   * @typedef {import("@material-ui/core").ButtonGroupProps} ButtonGroupProps
   *
   * @typedef {object} ButtonGroupFieldItem
   * @property {string} label
   * @property {any} value
   *
   * @typedef {object} ButtonGroupFieldProps
   * @property {Record<string,string>} [classFrom] Object to get `className`
   * from, for the given field `name`.
   * @property {ButtonGroupFieldItem[]} items
   * @property {React.CSSProperties} [buttonStyle]
   * @property {number|string} [buttonWidth] Exact width of each button.
   * @property {string} [label] Label for the group of buttons.
   * @property {string} name Name of the form field.
   * @property {boolean} [separated] True if the buttons should be separated.
   * @property {string} [selectedColor] Background color of selected button.
   * @property {boolean} [yesNo] True to show yes/no items (true/false).
   *
   * @param {FormControlProps & ButtonGroupFieldProps} props
   */
  function ButtonGroupField(props) {
    let {
      buttonStyle,
      buttonWidth: _buttonWidth,
      children,
      classFrom,
      className,
      error,
      helperText,
      label,
      name,
      selectedColor: _selectedColor,
      separated,
      yesNo,
      id = name,
      items = yesNo ? yesNoItems : [],
      ...rest
    } = props;
    const classes = useStyles(props);
    const [fld, meta, helpers] = useField(name);
    error = Boolean(meta.error) || error;
    helperText = meta.error ? meta.error : helperText;

    const onClick = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      e => {
        const idx = parseInt(e.currentTarget.dataset.idx);
        const { value } = items[idx];
        helpers.setValue(value);
      },
      [items, helpers],
    );

    const GroupContainer = separated ? "div" : ButtonGroup;

    return (
      <FormControl
        className={clsx(classes.root, className || classFrom?.[name])}
        error={error}
        {...rest}
      >
        {label && (
          <FormLabel error={error} className={classes.label} htmlFor={id}>
            {label}
          </FormLabel>
        )}
        <GroupContainer id={id} className={classes.buttons}>
          {items.map((item, i) => (
            <Button
              key={i}
              className={clsx(item.value === fld.value && classes.selected)}
              data-idx={i}
              onClick={onClick}
              variant={separated ? "outlined" : undefined}
              style={buttonStyle}
            >
              {item.label}
            </Button>
          ))}
        </GroupContainer>
        {children}
        {helperText && (
          <FormHelperText id={`${id}-helper-text`} error={error}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);
