import React from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
// Icons
import ClearIcon from "@material-ui/icons/Clear";
// Local
import { selectAllTarget, useField } from "../../lib";

const styles = {
  clearIcon: {
    fontSize: "1.25rem",
  },
};

export const Field = React.memo(
  /**
   * A Material-UI `TextField` bound to a `formik` field.
   *
   * @typedef {import("@material-ui/core").TextFieldProps} TextFieldProps
   * @typedef {object} FieldProps
   * @property {boolean} [allowClear]
   * @property {Record<string,string>} [classFrom] Object to get `className`
   * from, for the given field `name`.
   *
   * @param {FieldProps & TextFieldProps} props
   */
  function Field(props) {
    const {
      name,
      allowClear,
      classFrom,
      className,
      error,
      helperText,
      value: _value,
      InputProps,
      ...rest
    } = props;
    const [fld, meta, helpers] = useField(name);

    const { allInputProps, baseClassNames } = React.useMemo(() => {
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      function onClickClear(e) {
        helpers.setValue("");
      }
      const allInputProps = !allowClear
        ? InputProps
        : {
            ...InputProps,
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton
                    onClick={onClickClear}
                    size="small"
                    tabIndex={-1}
                    className="show-phover"
                  >
                    <ClearIcon style={styles.clearIcon} />
                  </IconButton>
                </InputAdornment>
                {InputProps?.endAdornment}
              </>
            ),
          };
      const baseClassNames = allowClear ? "phover " : "";
      return {
        allInputProps,
        baseClassNames,
      };
    }, [allowClear, helpers, InputProps]);

    return (
      <TextField
        name={name}
        value={fld.value ?? ""}
        className={baseClassNames + (className || classFrom?.[name])}
        onChange={fld.onChange}
        onBlur={fld.onBlur}
        onFocus={selectAllTarget}
        error={(meta.touched && Boolean(meta.error)) || error}
        helperText={(meta.touched && meta.error) || helperText}
        InputProps={allInputProps}
        {...rest}
      />
    );
  },
);
