import React from "react";
import clsx from "clsx";
import {
  //
  FormControl,
  // FormControlLabel,
  FormHelperText,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// Local
import { useField } from "../../lib";

const yesNoItems = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const useStyles = makeStyles(
  theme => ({
    label: {
      paddingBottom: 8,
    },
    group: props => ({
      width: "100%",
      "& .MuiToggleButtonGroup-groupedHorizontal": {
        flex: 1,
        // "&:not(:last-child)": {
        //   // borderTopRightRadius: 0,
        //   // borderBottomRightRadius: 0,
        // },
        "&:not(:first-child):not(.Mui-selected)": {
          borderLeft: "2px solid rgba(0, 0, 0, 0.12)",
          // borderTopLeftRadius: 0,
          // borderBottomLeftRadius: 0,
        },
      },
      ...(!props.separated
        ? undefined
        : {
            "& .MuiToggleButtonGroup-groupedHorizontal:not(:last-child)": {
              marginRight: 5,
            },
            "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
              marginLeft: 5,
            },
          }),
    }),
    button: ({ selectedColor = "blue" }) => ({
      border: "2px solid rgba(0, 0, 0, 0.12)",
      "&.Mui-selected": {
        borderRadius: 0,
        border: `2px solid ${selectedColor}`,
        borderLeft: `2px solid ${selectedColor}`,
        backgroundColor: "transparent",
      },
    }),
  }),
  {
    classNamePrefix: "ToggleButtonsField",
  },
);

export const ToggleButtonsField = React.memo(
  /**
   * @typedef {import("@material-ui/core").FormControlProps} FormControlProps
   * @typedef {import("@material-ui/lab").ToggleButtonGroupProps} ToggleButtonGroupProps
   *
   * @typedef {object} ToggleButtonsFieldItem
   * @property {string} label
   * @property {any} value
   *
   * @typedef {object} ToggleButtonsFieldProps
   * @property {Record<string,string>} [classFrom] Object to get `className`
   * from, for the given field `name`.
   * @property {ToggleButtonsFieldItem[]} items
   * @property {string} [label] Label for the group of buttons.
   * @property {string} name Name of the form field.
   * @property {boolean} [separated] True if the buttons should be separated.
   * @property {string} [selectedColor] Background color of selected button.
   * @property {boolean} [yesNo] True to show yes/no items (true/false).
   *
   * @param {FormControlProps & ToggleButtonsFieldProps} props
   */
  function ToggleButtonsField(props) {
    let {
      children,
      classFrom,
      className,
      error,
      helperText,
      label,
      name,
      selectedColor: _selectedColor,
      separated: _separated,
      yesNo,
      id = name,
      items = yesNo ? yesNoItems : [],
      ...rest
    } = props;
    const classes = useStyles(props);
    const [fld, meta, helpers] = useField(name);
    error = Boolean(meta.error) || error;
    helperText = meta.error ? meta.error : helperText;

    const onChange = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      (e, value) => {
        helpers.setValue(value);
      },
      [helpers],
    );

    return (
      <FormControl
        className={clsx(classes.root, className || classFrom?.[name])}
        error={error}
        {...rest}
      >
        {label && (
          <FormLabel error={error} className={classes.label} htmlFor={id}>
            {label}
          </FormLabel>
        )}
        <ToggleButtonGroup
          id={id}
          className={classes.group}
          value={fld.value}
          exclusive
          onChange={onChange}
        >
          {children}
          {items.map((it, i) => (
            <ToggleButton key={i} className={classes.button} value={it.value}>
              {it.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {helperText && (
          <FormHelperText id={`${id}-helper-text`} error={error}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);
