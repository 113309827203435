import React from "react";

const baseStyle = {
  borderRadius: "100%",
  backgroundColor: "#000",
  display: "inline-block",
  // marginRight: 8,
};

export const Dot = React.memo(
  /**
   * @param {{color:string,size:number,style?:React.CSSProperties}} param0
   */
  function Dot({ color, size = 8, style }) {
    return (
      <span
        style={{
          ...baseStyle,
          backgroundColor: color,
          height: size,
          width: size,
          ...style,
        }}
      />
    );
  },
);
