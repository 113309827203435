import { formatFirstLastName, formatFirstLastNameInitials } from "../../lib";
import { authClient } from "../auth/actions";

export interface UserNotificationSettingsData {
  notifications: { id: number; name: string }[];
  settings: DealerUserNotificationSetting[];
}

export const userApi = {
  // #region CURRENT user
  async getCurrentUser() {
    const { data } = await authClient.get<DealerUser>(`/user`);
    return formatDealerUser(data);
  },
  async getCurrentUserNotificationSettings() {
    const { data } = await authClient.get<UserNotificationSettingsData>(
      `/user/notification-settings`,
    );
    return data;
  },
  async saveCurrentUser(values: any) {
    const { data } = await authClient.put<DealerUser>(`/user`, values);
    return data;
  },
  async saveCurrentUserNotificationSetting(
    setting: DealerUserNotificationSetting,
  ) {
    const { data } = await authClient.put<DealerUserNotificationSetting>(
      `/user/notification-setting`,
      setting,
    );
    return data;
  },
  async saveCurrentUserNotificationSettings(
    changes: DealerUserNotificationSetting[],
  ) {
    const { data } = await authClient.put<DealerUserNotificationSetting[]>(
      `/user/notification-settings`,
      { changes },
    );
    return data;
  },
  // #endregion

  async getUser(id: number) {
    const { data } = await authClient.get<DealerUser>(`/users/${id}`);
    return formatDealerUser(data);
  },
  async getUserRoles() {
    const { data } = await authClient.get(`/users/roles`);
    return data;
  },
  async getUsers() {
    const { data } = await authClient.get<DealerUser[]>(`/users`);
    return data.map(formatDealerUser);
  },
  async getUsersForAppraisal() {
    const { data } = await authClient.get<DealerUser[]>(`/users/appraisal`);
    data.forEach(formatDealerUser);
    return data;
  },
  async getUsersForDeal() {
    const { data } = await authClient.get<DealerUser[]>(`/users/deal`);
    data.forEach(formatDealerUser);
    return data;
  },
  async removeUser(id: number) {
    await authClient.delete(`/users/${id}`);
  },
  async saveUser(id: number, values: any) {
    if (id > 0) {
      const { data } = await authClient.put<DealerUser>(`/users/${id}`, values);
      return data;
    }
    const { data } = await authClient.post<DealerUser>(`/users`, values);
    return data;
  },
  async checkUserNotificationLogs() {
    const { data } = await authClient.post<{
      unreadCount: number;
      enabled: boolean;
    }>(`notifications_log/check`, {});
    return data;
  },
  async getCurrentUserNotificationLogs(dealerId?: number) {
    const params = !dealerId ? undefined : { filters: { dealerId } };
    const { data } = await authClient.get(`notifications_log`, { params });
    return data;
  },
  async updateLogsAsRead() {
    const { data } = await authClient.put(`notifications_log/update-logs`);
    return data;
  },

  // #region Notifications

  async connectPusher() {
    const { data } = await authClient.get<{
      instanceId: string;
      uid: string;
    }>(`/user/connect-pusher`);
    return data;
  },
  async generatePusherToken() {
    const { data } = await authClient.get<{ token: string }>(
      `/user/generate-pusher-token`,
    );
    return data;
  },
  async sendTestNotification() {
    const { data } = await authClient.post<{ message: string }>(
      `/user/send-test-notification`,
      {},
    );
    return data;
  },

  // #endregion

  // #region Stripo

  // Not available to dealers at this time...

  // async connectStripo() {
  //   const { data } = await authClient.get<{
  //     token: string;
  //   }>(`/user/connect-stripo`);
  //   return data;
  // },

  // #endregion
};

export function formatDealerUser(du: DealerUser) {
  du.name = formatFirstLastName(du);
  du.initials = formatFirstLastNameInitials(du);
  if (typeof du.roles === "string") {
    du.roles = JSON.parse(du.roles);
  } else if (!du.roles) {
    du.roles = [];
  }
  for (const role of du.roles) {
    const parts = role.name.split(" ");
    role.abbrev = parts.map(p => p.substr(0, 1).toUpperCase()).join("");
  }
  return du;
}
