import React from "react";

export const TurnynLogo = React.memo(
  /**
   * @param {React.SVGProps<SVGSVGElement> & {title?: string}} param0
   */
  function TurnynLogo({
    height = "40px",
    width = "145px",
    viewBox = "0 0 145 40",
    fill = "#ffffff",
    title = "Turnyn",
    ...props
  }) {
    return (
      <svg
        //
        width={width}
        height={height}
        viewBox={viewBox}
        {...props}
      >
        <title>{title}</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-120.000000, -32.000000)"
            fill={fill}
            fillRule="nonzero"
          >
            <g transform="translate(120.000000, 32.000000)">
              <path d="M114.216809,5.51772105 L106.300426,24.0542979 L98.4148936,5.51772105 L92.3248936,5.51772105 L103.24,30.6533193 L102.814255,31.6419367 C102.413838,32.6642292 101.779887,33.5783979 100.963191,34.3112038 C100.231211,34.8637044 99.3307695,35.14535 98.4148936,35.1082766 C97.8377895,35.1094198 97.2637668,35.0240288 96.7119149,34.8549434 L93.4787234,38.8464862 C93.896012,39.0527796 94.3268708,39.2303192 94.7682979,39.3778681 C95.9605575,39.7841367 97.211034,39.9928414 98.4704255,39.995754 C100.489436,40.0561016 102.475292,39.4718442 104.140851,38.3274621 C105.699858,37.223506 107.026454,35.3080597 108.120638,32.5811233 L119.844043,5.5238999 L114.216809,5.51772105 Z"></path>
              <path d="M16.7151064,25.3086063 C15.7736787,26.0190629 14.6167658,26.383423 13.4387234,26.3404757 C12.4824296,26.4054784 11.5446264,26.054154 10.8657447,25.3765737 C10.2247644,24.5955625 9.90681897,23.5983796 9.97723404,22.5899083 L9.97723404,10.6770683 L16.6719149,10.6770683 L16.6719149,5.98731433 L9.97723404,5.98731433 L9.97723404,0.265690935 L4.12170213,0.265690935 L4.12170213,5.98731433 L0,5.98731433 L0,10.6770683 L4.12170213,10.6770683 L4.12170213,22.7320221 C4.12170213,25.4836739 4.87035461,27.5783071 6.36765957,29.0159216 C7.86496454,30.4535362 9.98751773,31.1723812 12.7353191,31.1723812 C13.7785141,31.1750565 14.816955,31.0315508 15.8204255,30.7460022 C16.7314118,30.5109016 17.5842033,30.0902186 18.3255319,29.5102304 L16.7151064,25.3086063 Z"></path>
              <path d="M145,30.9190102 L145,16.6149517 C145,12.9570672 144.039504,10.1971769 142.118511,8.33528073 C140.471601,6.81229227 138.371223,5.87241797 136.139574,5.6598348 C134.704954,5.50844017 133.260205,5.47743787 131.820426,5.56715192 C130.61503,5.61808871 129.422783,5.83856844 128.278723,6.22211097 C126.982629,6.66841276 125.797794,7.38916162 124.804894,8.33528073 C122.883901,10.1971769 121.923404,12.9570672 121.923404,16.6149517 L121.923404,30.9190102 L127.778936,30.9190102 L127.778936,17.4120245 C127.778936,15.1629199 128.28695,13.4822702 129.302979,12.3700756 C131.636056,10.1535121 135.293519,10.1535121 137.626596,12.3700756 C138.638511,13.4822702 139.144468,15.1629199 139.144468,17.4120245 L139.144468,30.9190102 L145,30.9190102 Z"></path>
              <path d="M54.7914894,9.49072735 L54.7914894,5.78341198 L49.2382979,5.78341198 L49.2382979,30.8448639 L55.0876596,30.8448639 L55.0876596,18.6972272 C55.0876596,16.1968489 55.727305,14.2814027 57.0065957,12.9508884 C58.29,11.6224337 60.0917021,10.9612958 62.35,10.9612958 C62.8056582,10.9653709 63.2600057,11.0108693 63.7074468,11.0972307 L63.7074468,5.53617154 C59.45,5.5238999 56.4780142,6.84205648 54.7914894,9.49072735 Z"></path>
              <path d="M47.4242553,7.6926794 L44.3391489,7.6926794 L44.3391489,20.106007 C44.3391489,23.7638915 43.3786525,26.5217223 41.4576596,28.3794992 C39.8110789,29.903967 37.7108863,30.8459287 35.4787234,31.061124 C34.0439205,31.2102863 32.5991857,31.2392215 31.1595745,31.147628 C29.9624584,31.0993385 28.7782739,30.8808935 27.6425532,30.4988478 C26.3425538,30.0609207 25.1560016,29.3391276 24.1687234,28.385678 C22.2477305,26.5237819 21.287234,23.7638915 21.287234,20.106007 L21.287234,5.78341198 L27.142766,5.78341198 L27.142766,19.3089342 C27.142766,21.5580389 27.6487234,23.2386885 28.6606383,24.3508831 C29.7550363,25.4641884 31.2665921,26.065173 32.8255319,26.0068173 C34.3835531,26.0704183 35.8952537,25.4684844 36.9842553,24.3508831 C38.0043972,23.2386885 38.5124113,21.5580389 38.5082979,19.3089342 L38.5082979,7.6926794 L34.9357447,7.6926794 L41.1738298,0 L47.4242553,7.6926794 Z"></path>
              <path d="M94.0957447,30.752181 L87.8268085,38.4386816 L81.5825532,30.752181 L84.7602128,30.752181 L84.7602128,17.4120245 C84.7602128,15.156741 84.2542553,13.4822702 83.2361702,12.3700756 C80.9051626,10.154302 77.2497311,10.154302 74.9187234,12.3700756 C73.9006383,13.4822702 73.3946809,15.156741 73.3946809,17.4120245 L73.3946809,30.9190102 L67.5638298,30.9190102 L67.5638298,16.6149517 C67.5638298,12.9570672 68.5243262,10.1992365 70.4453191,8.34145959 C71.4363167,7.39140225 72.6215924,6.66827742 73.9191489,6.22211097 C75.0553091,5.8408364 76.2390914,5.62038596 77.4361702,5.56715192 C78.875888,5.47447984 80.3208873,5.5054875 81.7553191,5.6598348 C83.9860212,5.87365975 86.0846716,6.81590206 87.7280851,8.34145959 C89.649078,10.2033558 90.6095745,12.9611865 90.6095745,16.6149517 L90.6095745,30.752181 L94.0957447,30.752181 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    );
  },
);
