import { makeStyles } from "@material-ui/core";
// Local
// import illustration from "../../assets/images/login_bottom.svg";

export const useStyles = makeStyles(
  theme => ({
    container: {
      display: "flex",
      height: "100vh",
      maxWidth: "100vw",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    panel: {
      backgroundColor: "#192740",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flex: 1,
      padding: theme.spacing(6),
      width: "calc(100vw - 520px)",
      [theme.breakpoints.down("xs")]: {
        backgroundColor: "#ffffff",
        width: "100%",
        flexDirection: "row",
        paddingBottom: theme.spacing(1),
      },
    },
    icon: {
      paddingBottom: 32,
      textAlign: "center",
    },
    content: {
      width: 520,
      maxWidth: "100%",
      padding: theme.spacing(6),
      alignSelf: "center",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  }),
  {
    classNamePrefix: "AuthLayout",
  },
);
