import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core";
export const CustomInfoIcon = React.memo(
  /**
   * @property {any} [IconProps]
   * @property {string} [title]
   */
  function CustomInfoIcon({ IconProps, title }) {
    const [open, setOpen] = React.useState(false);
    const handleTooltipClose = () => {
      setOpen(false);
    };

    const handleTooltipOpen = () => {
      setOpen(true);
    };

    const useStyles = makeStyles(theme => ({
      title: {
        fontSize: 16,
      },
      tooltip: {
        width: "200px",
      },
    }));
    const classes = useStyles();

    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div className={classes.title} title={title}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableTouchListener
            title={title}
            placement={"top"}
            classes={{ tooltip: classes.tooltip }}
          >
            <InfoIcon
              onClick={handleTooltipOpen}
              fontSize={"inherit"}
              {...IconProps}
            />
          </Tooltip>
        </div>
      </ClickAwayListener>
    );
  },
);
