import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: 16,
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    imgBox: {
      paddingRight: 24,
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "column",
      },
    },
    titleCar: {
      color: "#141932",
      fontSize: 24,
      fontWeight: 600,
      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
        marginRight: -32,
      },
    },
    labelText: {
      fontSize: 14,
      fontWeight: 500,
      color: "#8D98C2",
    },
    labelValue: {
      fontSize: 16,
      color: "#141932",
      fontWeight: 400,
      textAlign: "right",
    },
    stageBtn: {
      "& button": {
        maxHeight: "40px",
        fontSize: 14,
      },
    },
    showMore: {
      color: "#4E50FF",
      padding: "24px 0 8px 0",
    },
    basicDetails: {
      borderLeft: "1px solid #DADFF6",
      borderRight: "1px solid #DADFF6",
      color: "#526B8A",
      display: "flex",
      flexDirection: "column",
      fontSize: "16px",
      fontWeight: "500",
      minHeight: "176px",
      flex: "1 0 300px",
      padding: "12px 24px 8px 24px",
      [theme.breakpoints.down("xs")]: {
        borderLeft: "none",
        borderRight: "none",
        flex: "unset",
        minHeight: "unset",
        padding: "12px 0 24px 0",
        width: "100%",
      },
    },
    selectInput: {
      marginLeft: 8,
      // marginRight: 24,
      "& .MuiSelect-root": {
        color: "#8D98C2",
        fontSize: 14,
        // fontFamily: "Lato",
        paddingBottom: 6,
      },
    },
    selectOwnerStatus: {
      "& .MuiSelect-root": {
        color: "#141932",
        fontSize: 16,
        letterSpacing: 0,
        paddingBottom: 6,
      },
    },
    contactDetails: {
      display: "flex",
      flexDirection: "column",

      color: "#526B8A",
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: "21px",
      minHeight: 200,
      // minWidth: 360,
      paddingLeft: 24,
      // paddingRight: 24,
      flexGrow: 1,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        minHeight: "unset",
        width: "100%",
      },
    },
    contactHead: {
      alignItems: "center",
      display: "flex",
      "& .MuiIconButton-sizeSmall": {
        color: "#8D98C2",
      },
      marginBottom: -8,
    },
    emailLink: {
      "& .MuiLink-root": {
        color: "#141932",
        fontWeight: 400,
        fontSize: 16,
      },
    },
    actionsButton: {
      backgroundColor: "#EFF1FB",
      borderRadius: 3,
      height: 40,
      marginLeft: "auto",
      width: 40,
    },
    inspector: {
      // border: "1px solid #DADFF6",
      backgroundColor: "ghostwhite",
      borderRadius: "3px",
      color: "#8D98C2",
      fontSize: "14px",
      paddingLeft: "4px",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "4px",
      },
    },
    bankRow: {
      cursor: "pointer",
      display: "flex",
      paddingBottom: 4,
      "& > label": {
        width: 60,
      },
      "& > div": {
        flex: 1,
        textAlign: "right",
      },
    },
    error: {
      color: "#C90A2D",
    },
    /** @type {React.CSSProperties} */
    transfer: {
      backgroundColor: "#e4f7eb",
      borderRadius: 3,
      padding: "2px 6px",
      cursor: "default",
      maxWidth: 242,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    transferred: {
      backgroundColor: "#f7bced",
      color: "#141932",
    },
    deleted: {
      backgroundColor: "#e91257",
      borderRadius: 3,
      color: "#ffffff",
      cursor: "default",
      padding: "2px 6px",
    },
  }),
  {
    classNamePrefix: "AppraisalDetailsHeader",
  },
);
