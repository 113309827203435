import React from "react";
import {
  //
  // Box,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core";
// Icons
import DriveEtaIcon from "@material-ui/icons/DriveEta";
// Local
import {
  // appraisalActions,
  appraisalSelectors,
  appraisalStageDefaultByType,
  appraisalStagesByType,
  // prefActions,
  // prefSelectors,
  // useAction,
  useSelector,
} from "../../../state";
import { useMobile } from "../../../themes";
import { AppraisalsListGroup } from "./AppraisalsListGroup";

function createGroup() {
  return {
    count: 0,
    /** @type {Appraisal[]} */
    rows: [],
  };
}

const emptyGroup = createGroup();

const useStyles = makeStyles(
  theme => ({
    root: ({ isArchived }) =>
      isArchived
        ? {
            paddingTop: 40,
            [theme.breakpoints.down("xs")]: {
              paddingTop: 8,
            },
          }
        : {
            paddingTop: 16,
            [theme.breakpoints.down("xs")]: {
              paddingTop: 8,
            },
          },
    controls: {
      marginLeft: -8,
      paddingBottom: 40,
      [theme.breakpoints.down("xs")]: {
        paddingBottom: 8,
      },
    },
    tabs: {
      [theme.breakpoints.down("xs")]: {
        margin: "0 -12px",
      },
      "&.assess": {
        "& .Mui-selected": {
          color: "#00C2FF",
          "& .count-badge": {
            backgroundColor: "#00C2FF",
            color: "#FFFFFF",
          },
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "#00C2FF",
        },
      },
      "&.payoff": {
        "& .Mui-selected": {
          color: "#FF3B6E",
          "& .count-badge": {
            backgroundColor: "#FF3B6E",
            color: "#FFFFFF",
          },
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "#FF3B6E",
        },
      },
      "&.payoff2": {
        "& .Mui-selected": {
          color: "#FF3B6E",
          "& .count-badge": {
            backgroundColor: "#FF3B6E",
            color: "#FFFFFF",
          },
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "#FF3B6E",
        },
      },
      "&.offered": {
        "& .Mui-selected": {
          color: "#FDBE30",
          "& .count-badge": {
            backgroundColor: "#FDBE30",
            color: "#FFFFFF",
          },
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "#FDBE30",
        },
      },
      "&.accepted": {
        "& .Mui-selected": {
          color: "#00D289",
          "& .count-badge": {
            backgroundColor: "#00D289",
            color: "#FFFFFF",
          },
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "#00D289",
        },
      },
      "&.buying": {
        "& .Mui-selected": {
          color: "#00D289",
          "& .count-badge": {
            backgroundColor: "#00D289",
            color: "#FFFFFF",
          },
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "#00D289",
        },
      },
      "&.grounding": {
        "& .Mui-selected": {
          color: "#FDBE30",
          "& .count-badge": {
            backgroundColor: "#FDBE30",
            color: "#FFFFFF",
          },
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "#FDBE30",
        },
      },
      "&.inspect": {
        "& .Mui-selected": {
          border: "2px solid #401AFF",
          // color: "#401AFF",
          "& .count-badge": {
            backgroundColor: "#401AFF",
            color: "#FFFFFF",
          },
          "& .MuiTab-wrapper": {
            color: "#401AFF",
          },
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "#401AFF",
        },
      },
      "&.ground_return, &.grounded, &.turnedin": {
        "& .Mui-selected": {
          color: "#4E50FF",
          "& .count-badge": {
            backgroundColor: "#4E50FF",
            color: "#FFFFFF",
          },
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "#4E50FF",
        },
      },
    },
    tab: {
      paddingRight: 16,
      "& .count-badge": {
        backgroundColor: "#ECEFF5",
        borderRadius: 3,
        marginLeft: 4,
        padding: "1px 6px",
      },
    },
    inspectTab: {
      border: "2px solid #ECEFF5",
      borderRadius: 3,
      marginLeft: "auto",
      paddingLeft: 16,
      paddingRight: 16,
    },
    tbl: {
      // margin: 0,
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        margin: "0 -16px",
        width: "unset",
      },
      // "& td": {
      //   padding: 0,
      // },
    },
  }),
  {
    classNamePrefix: "AppraisalsList",
  },
);

export const AppraisalsList = React.memo(
  /**
   * @param {{isArchived?:boolean,isService?:boolean;isShowroom?:boolean}} props
   */
  function AppraisalsList(props) {
    const {
      isArchived,
      // isLeaseReturns,
      isLeaseReturnsMgmt,
      isService,
      isShowroom,
      hideTabs,
      onTabChanged,
    } = props;
    const classes = useStyles(props);
    const isMobile = useMobile();
    const rows = useSelector(appraisalSelectors.listItems);
    const { appraisalTypeId: typeId = 1 } = useSelector(
      appraisalSelectors.filter,
    );
    const stages = isLeaseReturnsMgmt
      ? appraisalStagesByType.leaseReturnsMgmt
      : appraisalStagesByType[typeId];
    // Use the given tab prop unless it's invalid.
    /** @type {import("../../../state").AppraisalStageId} */
    // let tab = props.tab && stages.find(it => it.id === props.tab) ? ...
    let tab = props.tab
      ? props.tab
      : isLeaseReturnsMgmt
      ? appraisalStageDefaultByType.leaseReturnsMgmt
      : appraisalStageDefaultByType[typeId];

    /** @type {Record<string, ReturnType<createGroup>>} */
    const groups = React.useMemo(
      () =>
        rows.reduce((groups, row) => {
          let grp = groups[row.stage];
          if (!grp) {
            grp = groups[row.stage] = createGroup();
          }
          grp.count += 1;
          grp.rows.push(row);
          return groups;
        }, {}),
      [rows],
    );

    const currentGroup = groups[tab] ?? emptyGroup;

    return (
      <div className={classes.root}>
        <div className={classes.controls}>
          {!isArchived && !hideTabs && !isService && !isShowroom && (
            <Tabs
              className={`${classes.tabs} ${tab}`}
              value={tab}
              variant="scrollable"
              onChange={onTabChanged}
            >
              {stages.map(stage => {
                const inspect = stage.id === "inspect";
                const grp = groups[stage.id] ?? emptyGroup;
                return (
                  <Tab
                    key={stage.id}
                    value={stage.id}
                    className={
                      inspect
                        ? `${classes.tab} ${classes.inspectTab}`
                        : classes.tab
                    }
                    label={
                      inspect ? (
                        <>
                          <DriveEtaIcon color="inherit" />
                          <div>{stage.title}</div>
                          <div className="count-badge">{grp.count}</div>
                        </>
                      ) : (
                        <>
                          <div>{stage.title}</div>
                          <div className="count-badge">{grp.count}</div>
                        </>
                      )
                    }
                  />
                );
              })}
            </Tabs>
          )}
        </div>
        <table className={classes.tbl} cellSpacing="0" cellPadding="0">
          {isArchived || hideTabs || isService || isShowroom ? (
            <AppraisalsListGroup
              isMobile={isMobile}
              isService={isService}
              isShowroom={isShowroom}
              rows={rows}
            />
          ) : (
            <AppraisalsListGroup
              isLeaseReturnsMgmt={isLeaseReturnsMgmt}
              isMobile={isMobile}
              rows={currentGroup.rows}
              tab={tab}
            />
          )}
        </table>
      </div>
    );
  },
);
