import React from "react";
import {
  Box,
  // makeStyles,
} from "@material-ui/core";
// Local
import { useLocation } from "../../../lib";
import {
  appraisalActions,
  isArchivedPath,
  isLeaseReturnsPath,
  isRejectedPath,
  useAction,
} from "../../../state";
import { FabLogo } from "../components/FabLogo";
import { AppraisalSearchbar } from "../list/AppraisalSearchbar";
import { AppraisalsBoard } from "./AppraisalsBoard";
import { AppraisalsListHeader } from "../list/AppraisalsListHeader";

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       minHeight: "100vh",
//     },
//   }),
//   {
//     classNamePrefix: "AppraisalsBoardPage",
//   },
// );

export const AppraisalsBoardPage = React.memo(
  /**
   *
   */
  function AppraisalsBoardPage() {
    // const classes = useStyles();

    // const dealer = useDealer();
    // const user = useSelector(authSelectors.user);

    const loadList = useAction(appraisalActions.loadList);
    const { query, pathname } = useLocation();

    const isArchived = isArchivedPath(pathname);
    const isLeaseReturns = isArchived ? false : isLeaseReturnsPath(pathname);
    const isRejected =
      isArchived || isLeaseReturns ? false : isRejectedPath(pathname);
    const isTradeins = !isArchived && !isLeaseReturns && !isRejected;

    React.useEffect(() => {
      const { filter, sort, order } = query;
      const filterObj = filter
        ? JSON.parse(filter)
        : {
            filterId: 30,
          };
      if (isArchived) {
        filterObj.archived = 1;
      } else {
        filterObj.appraisalTypeId = isLeaseReturns ? 2 : 1;
      }
      if (isRejected) {
        filterObj.appraisalStatusId = 3;
      } else {
        delete filterObj.appraisalStatusId;
      }
      loadList({
        filter: filterObj,
        sort,
        order,
      });
    }, [query, isArchived, isLeaseReturns, isRejected, loadList]);

    return (
      <Box>
        <AppraisalsListHeader
          isArchived={isArchived}
          isLeaseReturns={isLeaseReturns}
          isRejected={isRejected}
          isTradeins={isTradeins}
        />
        <AppraisalSearchbar />
        <AppraisalsBoard />
        <FabLogo isLeaseReturns={isLeaseReturns} />
      </Box>
    );
  },
);
