import React from "react";
import { MenuItem } from "@material-ui/core";
// Local
import { InputPropsFor } from "../inputs/shared";
import { Field } from "./Field";

const defaultEmptyText = <>&nbsp;</>;

/** @type {import("@material-ui/core").InputLabelProps} */
const shrinkInputLabelProps = {
  shrink: true,
};

/** @type {import("@material-ui/core").SelectProps} */
const displayEmptySelectProps = {
  displayEmpty: true,
};

export const SelectField = React.memo(
  /**
   * @typedef {import("./Field").FieldProps} FieldProps
   * @typedef {import("./Field").TextFieldProps} TextFieldProps
   *
   * @typedef {object} SelectFieldProps
   * @property {boolean} [disableUnderline]
   * @property {boolean} [displayEmpty] `true` to show the empty value.
   * @property {boolean} [emptyEnabled] `true` to allow picking a the empty item.
   * @property {string | boolean} [emptyText] `<>&nbsp;</>` if `emptyEnabled` else
   * `undefined`. Or set `true` to display default empty text of `<>&nbsp;</>`.
   * @property {string} [emptyValue] `""`
   * @property {string} [itemKey] Item key property. `"id"`
   * @property {string} [itemText] Item text property. `"title"`
   * @property {string} [itemValue] Item value property. `"id"`
   * @property {MenuItemProps} [itemProps] Menu item props to pass to each item.
   * @property {{id:string|number,title:string,value?:string|number}[]} [items]
   * The items list to derive menu items from.
   * @property {boolean} [shrinkLabel] True to shrink the label by default.
   *
   * @param {SelectFieldProps & FieldProps & TextFieldProps} props
   */
  function SelectField(props) {
    let {
      children,
      disableUnderline,
      emptyEnabled,
      emptyText = emptyEnabled ? defaultEmptyText : undefined,
      displayEmpty = emptyText !== undefined,
      emptyValue = "",
      itemKey = "id",
      itemText = "title",
      itemValue = itemKey,
      itemProps,
      items = [],
      value: _value,
      shrinkLabel,

      InputProps = disableUnderline
        ? InputPropsFor.disableUnderline
        : undefined,
      InputLabelProps = shrinkLabel ? shrinkInputLabelProps : undefined,
      SelectProps = displayEmpty ? displayEmptySelectProps : undefined,

      ...fieldProps
    } = props;

    if (emptyText === true) {
      emptyText = defaultEmptyText;
    }
    return (
      <Field
        select
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        SelectProps={SelectProps}
        {...fieldProps}
      >
        {displayEmpty && (
          <MenuItem {...itemProps} disabled={!emptyEnabled} value={emptyValue}>
            {emptyText}
          </MenuItem>
        )}
        {children}
        {items.map(it => (
          <MenuItem {...itemProps} key={it[itemKey]} value={it[itemValue]}>
            {it[itemText]}
          </MenuItem>
        ))}
      </Field>
    );
  },
);
