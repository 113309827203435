import React from "react";
import {
  //
  // Box,
  Button,
  // IconButton,
  makeStyles,
} from "@material-ui/core";
// import EditIcon from "@material-ui/icons/Edit";
// Local
import { AutocompleteField } from "../../../components";
import {
  //
  Form,
  useFormik,
  // yup,
} from "../../../lib";
import { appraisalActions, userApi, useAction } from "../../../state";

const useStyles = makeStyles(
  theme => ({
    root: {
      alignContent: "center",
      display: "flex",
      flex: 1,
      minWidth: 300,
      "& > button": {
        marginLeft: 6,
        padding: 4,
      },
    },
    field: {
      flex: 1,
    },
  }),
  {
    classNamePrefix: "AssignedUsersEditor",
  },
);

const DefaultAutocompleteUsersProps = {
  TextFieldProps: {
    autoFocus: true,
  },
};

export const AssignedUsersEditor = React.memo(
  /**
   * @typedef {import("@material-ui/lab").AutocompleteProps} AutocompleteProps
   * @typedef {import("../components").AutocompleteFieldProps} AutocompleteFieldProps
   * @typedef {object} AssignedUsersEditorProps
   * @property {AutocompleteProps & AutocompleteFieldProps} [AutocompleteUsersProps]
   * @property {string} [className]
   * @property {(state:boolean)=>void} [edit]
   * @property {Appraisal} record
   *
   * @param {AssignedUsersEditorProps} props
   */
  function AssignedUsersEditor({
    AutocompleteUsersProps = DefaultAutocompleteUsersProps,
    className = "",
    // onChange,
    // onClose,
    edit,
    record: row,
  }) {
    const classes = useStyles();
    const saveUsers = useAction(appraisalActions.saveUsers);
    const onClose = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        edit(false);
      },
      [edit],
    );
    const frm = useFormik({
      initialValues: { users: row.users },
      validateOnBlur: false,
      validateOnChange: false,
      // validationSchema,
      async onSubmit(values, helpers) {
        if (frm.dirty) {
          await saveUsers(row.id, values.users);
        }
        onClose();
      },
    });
    const onKeyUp = React.useCallback(
      /** @param {React.KeyboardEvent<HTMLElement>} e */
      e => {
        if (e.key === "Escape") {
          onClose();
        }
      },
      [onClose],
    );

    return (
      <Form
        className={`${classes.root} ${className}`}
        form={frm}
        onKeyUp={onKeyUp}
      >
        <AutocompleteField
          multiple
          itemsList={row.users}
          className={classes.field}
          getOptions={userApi.getUsersForAppraisal}
          name="users"
          {...AutocompleteUsersProps}
        />
        <Button type="submit" variant="contained" size="small">
          OK
        </Button>
        <Button variant="contained" size="small" onClick={onClose}>
          Cancel
        </Button>
      </Form>
    );
  },
);

// export const AssignedUsersField = React.memo(
//   /**
//    * @param {{record:Appraisal}} param0
//    */
//   function AssignedUsersField({ record }) {
//     const [editing, setEditing] = React.useState(false);
//     const onClick = React.useCallback(
//       /** @param {React.SyntheticEvent<HTMLElement>} e */
//       e => {
//         if (e && e.preventDefault) e.preventDefault();
//         setEditing(true);
//       },
//       [],
//     );
//     const onClose = React.useCallback(
//       /** @param {React.SyntheticEvent<HTMLElement>} e */
//       e => {
//         setEditing(false);
//       },
//       [],
//     );

//     return editing ? (
//       <AssignedUsersEditor
//         recordId={record.id}
//         users={record.users ?? []}
//         onClose={onClose}
//       />
//     ) : (
//       <>
//         {record.users?.map((it, i, all) => (
//           <React.Fragment key={it.id}>
//             <Box
//               component="span"
//               // color="rgba(0, 0, 0, .54)"
//             >
//               {it.name}
//             </Box>
//             <span>
//               &nbsp;&nbsp;
//               {i < all.length - 1 && <>&middot;&nbsp;&nbsp;</>}
//             </span>
//           </React.Fragment>
//         ))}
//         <IconButton onClick={onClick} size="small">
//           <EditIcon />
//         </IconButton>
//       </>
//     );
//   },
// );
