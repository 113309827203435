import { IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import { Dot } from "../../../components";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import { Navigation, useLocation } from "../../../lib";
import { isDuplicatePath, useDealer } from "../../../state";
import { Link } from "react-router-dom";

const useStyles = makeStyles(
  theme => ({
    text: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: 500,
      [theme.breakpoints.up("sm")]: {
        textDecoration: "none",
      },
    },
    headerContainer: {
      "& > a": {
        fontSize: 12,
      },
      paddingLeft: 10,
      [theme.breakpoints.up("sm")]: {
        width: 115,
        padding: "12px 0",
      },
    },
  }),
  {
    classNamePrefix: "dupeButton",
  },
);
interface Prop {
  row: Appraisal;
  header?: boolean;
}
export const DuplicateSubButton = ({ row, header = false }: Prop) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const isDupePage = isDuplicatePath(pathname);
  const dealer = useDealer();
  const onClickDuplicate = React.useCallback(
    (e: React.SyntheticEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      Navigation.go(`/${dealer.slug}/appraisals/duplicate/${row.vin}`);
    },
    [dealer.slug, row.vin],
  );

  return (
    <>
      {row.vinCount && !isDupePage && (
        <>
          {header ? (
            <div className={classes.headerContainer}>
              <Link
                to={`/${dealer.slug}/appraisals/duplicate/${row.vin}`}
                className={classes.text}
              >
                {`Found ${row.vinCount} duplicate submissions`}
              </Link>
            </div>
          ) : (
            <>
              &nbsp;&nbsp;
              <Dot color="#8D98C2" size={6} />
              &nbsp;&nbsp;
              <IconButton
                onClick={onClickDuplicate}
                title={`${row.vinCount} duplicate submissions found`}
                size="small"
                className={classes.text}
              >
                {row.vinCount}&nbsp;
                <LibraryAddIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </>
      )}
    </>
  );
};
