import React from "react";
import { Button, DialogContent, makeStyles } from "@material-ui/core";
// Local
import {
  CancelSaveDialogActions,
  Field,
  SimpleDialog,
  useBasicDialog,
} from "../../../components";
import { Form, formatDateTime, useFormik } from "../../../lib";
import { dealerApi } from "../../../state";
import { useMobile } from "../../../themes";
import { CustomMenuVars } from "./CustomMenuVars";
const useStyles = makeStyles(
  theme => ({
    root: {
      [theme.breakpoints.up("sm")]: {
        "& .MuiFormControl-root": {
          marginTop: 32,
        },
      },
    },
    formItem: {
      padding: 8,
    },
    infoTable: {
      borderCollapse: "collapse",
      fontWeight: 200,
      "& .info-title": {
        fontWeight: 400,
      },
      "& tr": {
        borderBottom: "1px solid lightgrey",
      },
    },
    changed: {
      paddingTop: 12,
      [theme.breakpoints.down("xs")]: {
        // Added vertical space for mobile keyboard.
        paddingBottom: 36,
      },
    },
  }),
  {
    classNamePrefix: "TemplateEditor",
  },
);

export const TemplateEditor = React.memo(
  /**
   *
   */
  function TemplateEditor({ item, onClose }) {
    const classes = useStyles();
    // const dealer = useDealer();
    const isMobile = useMobile();
    const { basicDialog, confirmAsync } = useBasicDialog();

    /** @type {[DealerUser]} */
    //
    const [initialValues, setInitialValues] = React.useState({});
    const [formFields, setFormFields] = React.useState();
    const [usableVars, setUsableVars] = React.useState();
    const [isCustom, setIsCustom] = React.useState(false);
    const [isEnabled, setIsEnabled] = React.useState(false);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      // validateOnBlur: false,
      validateOnChange: false,
      async onSubmit(values, helpers) {
        await dealerApi.saveTemplate(
          item?.id ?? 0,
          values,
          isCustom,
          isEnabled,
        );
        setInitialValues(values);
        onClose();
      },
    });

    const onClickDelete = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      async e => {
        if (!(await confirmAsync("Are you sure?"))) {
          return;
        }

        await dealerApi.removeTemplate(item.id);
        onClose();
      },
      [item, confirmAsync, onClose],
    );

    React.useEffect(() => {
      const state = { mounted: true };
      (async function onDealerChanged() {
        // const roles = await  dealerApi.getUserRoles();
        const values = item?.id
          ? await dealerApi.getTemplate(item.id)
          : undefined;
        if (!state.mounted) return;
        // setRoles(roles);
        if (values) {
          const parsedValues = JSON.parse(values.info);
          setIsCustom(!!values.options);
          setIsEnabled(!!values.enabled);

          const optionsValues = values.options
            ? JSON.parse(values.options)
            : {};

          const initialVal = {};
          for (const section of parsedValues.sections) {
            initialVal[section.var] =
              optionsValues[section.var] || section.default;
          }
          setInitialValues(initialVal);
          setUsableVars(parsedValues.usable_vars);
          setFormFields(parsedValues.sections);
        }
      })();
      return () => {
        state.mounted = false;
      };
    }, [item]);

    const onPreview = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      async e => {
        await dealerApi.previewTemplate(item.externalId, frm.values);
      },
      [item, frm.values],
    );

    if (!formFields) return;
    if (!usableVars) return;

    return (
      <SimpleDialog
        maxWidth="md"
        className={classes.root}
        onClose={onClose}
        open={true}
        title={item.name}
        fullScreen={isMobile}
      >
        <Form form={frm}>
          <DialogContent>
            {formFields.map((data, index) => {
              return (
                <div key={data.var} className={classes.formItem}>
                  <Field
                    key={index}
                    name={data.var}
                    label={data.label}
                    helperText={`"${
                      data.default || data.label + " -This is not editable"
                    }"`}
                    fullWidth
                    multiline
                    disabled={data.readonly || !data.default}
                    InputProps={
                      data.readonly || !data.default
                        ? undefined
                        : {
                            endAdornment: (
                              <CustomMenuVars
                                usableVars={usableVars}
                                name={data.var}
                                frm={frm}
                              />
                            ),
                          }
                    }
                  />
                  {data.after_text && (
                    <Field
                      name={data.after_text}
                      label={data.after_text}
                      helperText={
                        "Uneditable after text at the end of the above line."
                      }
                      multiline
                      disabled
                      fullWidth
                    />
                  )}
                </div>
              );
            })}

            {item?.updatedAt && (
              <div className={classes.changed}>
                Last change: {formatDateTime(item.updatedAt)}
              </div>
            )}
          </DialogContent>

          <CancelSaveDialogActions
            disabled={frm.isSubmitting}
            saveDisabled={!frm.dirty}
            onCancel={onClose}
            onDelete={onClickDelete}
            deleteLabel="Reset to default"
            // saveLabel="Save and test"
          >
            <Button
              variant="contained"
              color="secondary"
              // size="small"
              onClick={onPreview}
              style={styles.preview}
            >
              Preview
            </Button>
          </CancelSaveDialogActions>
        </Form>
        {basicDialog}
      </SimpleDialog>
    );
  },
);

const styles = {
  /** @type {React.CSSProperties} */
  preview: {
    backgroundColor: "#8D98C2",
    color: "#141932",
    marginRight: "auto",
  },
};

// /** @param {{id:number,name:string}} option */
// function renderRoleOption(option) {
//   return (
//     <React.Fragment>
//       <div style={styles.roleName}>{option.name}</div>
//       &nbsp;&nbsp;
//       <small>TODO: Describe role #{option.id}...</small>
//     </React.Fragment>
//   );
// }
