import React from "react";
// import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import {
  //
  IconButton,
  // Typography,
  makeStyles,
} from "@material-ui/core";
// Icons
// import PublishIcon from "@material-ui/icons/Publish";
// import DeleteIcon from "@material-ui/icons/Delete";
// import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PublishIcon from "@material-ui/icons/Publish";

const useStyles = makeStyles(
  theme => ({
    root: {
      cursor: "pointer",
      "& strong": {
        fontSize: 16,
        fontWeight: "500",
        color: "#8D98C2",
      },
    },
    hidden: {
      visibility: "hidden",
    },
  }),
  {
    classNamePrefix: "InlineFileInput",
  },
);

export const InlineFileInput = React.memo(
  /**
   * A `react-dropzone` file input.
   *
   * @typedef {import("react-dropzone").DropzoneOptions} DropzoneOptions
   *
   * @typedef {object} InlineFileInputProps
   * @property {string} name Name of the form field.
   * @property {Record<string,string>} [classFrom] Object to get `className`
   * from, for the given field `name`.
   * @property {string} [className]
   * @property {boolean} [disabled]
   * @property {React.ReactNode} [error] Error message (string or component)
   * @property {string} [label] Label for the input.
   * @property {number} [maxSize] Maximum file size (in bytes) `0`
   * @property {number} [minSize] Minimum file size (in bytes) `0`
   * @property {DropzoneOptions["onDrop"]} onChange
   * @property {string} [url] URL of the previously uploaded file to display.
   * @property {File} [value] Current value of the input.
   *
   * @param {InlineFileInputProps} props
   */
  function InlineFileInput(props) {
    const {
      //
      name,
      // classFrom,
      // className,
      disabled,
      // error,
      label,
      maxSize,
      minSize,
      onChange,
      url,
      value,
      ...rest
    } = props;
    const classes = useStyles();

    const { getRootProps, getInputProps } = useDropzone({
      onDrop: onChange,
      maxFiles: 1,
      multiple: false,
      accept: "image/*, application/pdf",
      disabled,
      maxSize,
      minSize,
      noDrag: true,
    });

    return (
      <div className={classes.root} {...rest}>
        <div {...getRootProps()}>
          <input name={name} aria-label={label} {...getInputProps()} />
          <div className={!!value || !!url ? classes.hidden : undefined}>
            <IconButton title={"Click to upload"}>
              <PublishIcon color="disabled" />
            </IconButton>
          </div>
        </div>
      </div>
    );
  },
);
