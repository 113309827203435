import React from "react";
import {
  Box,
  Button,
  DialogContent,
  // Link,
  // Typography,
  makeStyles,
} from "@material-ui/core";
// Local
import {
  CancelSaveDialogActions,
  ComboField,
  DollarField,
  SimpleDialog,
} from "../../../components";
import {
  //
  Form,
  formatDateTime,
  formatDecimal,
  useFormik,
  yup,
} from "../../../lib";
import { useAction, appraisalActions } from "../../../state";

const useStyles = makeStyles(
  theme => ({
    // root: {
    //   "& .MuiDialog-paper": {
    //     width: 400,
    //     minHeight: 321,
    //   },
    //   "& .MuiDialogContent-root": {
    //     paddingTop: 24,
    //     paddingRight: 24,
    //     paddingLeft: 24,
    //     paddingBottom: 8,
    //   },
    //   "& .MuiFormControl-root": {
    //     marginTop: 32,
    //   },
    //   "& .MuiDialogActions-root": {
    //     paddingBottom: 24,
    //     paddingLeft: 24,
    //     paddingRight: 24,
    //   },
    // },
    changed: {
      paddingTop: 12,
      [theme.breakpoints.down("xs")]: {
        // Added vertical space for mobile keyboard.
        paddingBottom: 36,
      },
    },
  }),
  {
    classNamePrefix: "AppraisalBidEditDialog",
  },
);

const defaultValues = {
  bidder: null,
  amount: "0.00",
};

const validationSchema = yup.object({
  bidder: yup.object().required("Bidder is required"),
  amount: yup.string().required("Amount is required"),
});

const AppraisalBidEditDialog = React.memo(
  /**
   * @typedef {object} SaveValueType
   * @property {number} amount
   * @property {number} bidderId
   * @property {string} bidderName
   * @property {number} id Id of the item being edited.
   *
   * @param {{item:AppraisalBid}} param0
   */
  function AppraisalBidEditDialog({ item, onChange, onClose }) {
    const classes = useStyles();

    const getBidders = useAction(appraisalActions.getBidders);
    const [busy, setBusy] = React.useState(false);

    const initialValues = React.useMemo(() => {
      return !item
        ? defaultValues
        : {
            bidder: { id: item.bidderId, name: item.bidderName },
            amount: formatDecimal(item.amount),
          };
    }, [item]);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      validationSchema,
      async onSubmit(values) {
        if (frm.dirty) {
          console.log("SAVING...");
          setBusy(true);
          try {
            console.log("SAVING VALUES", values);
            onChange({
              amount: values.amount,
              bidderId: values.bidder?.id,
              bidderName: values.bidder?.name,
              id: item?.id,
            });
          } catch (err) {
            console.log("" + err);
            window.alert("There was an error. Please try again.");
          } finally {
            setBusy(false);
          }
        }
        onClose();
      },
    });

    return (
      <SimpleDialog
        className={classes.root}
        onClose={onClose}
        open={true}
        title={`${!item ? "Add" : "Edit"} bid`}
      >
        <Form form={frm}>
          <DialogContent>
            <ComboField
              autoFocus
              fullWidth
              getOptions={getBidders}
              label="Bidder"
              name="bidder"
              optionsLabelKey="name"
            />
            <Box mt="32px">
              <DollarField name="amount" label="Amount" fullWidth />
            </Box>
            {item?.updatedAt && (
              <div className={classes.changed}>
                Last change: {formatDateTime(item.updatedAt)}
              </div>
            )}
          </DialogContent>
          <CancelSaveDialogActions disabled={busy} onCancel={onClose} />
        </Form>
      </SimpleDialog>
    );
  },
);

export const AppraisalBidEditButton = React.memo(
  /**
   * @typedef {import("@material-ui/core").ButtonProps} ButtonProps
   * @typedef {object} AppraisalBidEditButtonProps
   * @property {AppraisalBid} [item]
   * @property {number} appraisalId
   * @property {(values:SaveValueType)=>void} onChange
   *
   * @param {AppraisalBidEditButtonProps & ButtonProps} param0
   */
  function AppraisalBidEditButton({
    appraisalId,
    item,
    onChange,
    onClick,
    ...buttonProps
  }) {
    const [editing, setEditing] = React.useState(false);
    const onClickHandler = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (e && e.preventDefault) e.preventDefault();
        setEditing(true);
      },
      [],
    );
    const onClose = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        setEditing(false);
      },
      [],
    );

    return (
      <>
        <Button onClick={onClickHandler} {...buttonProps} />
        {editing && (
          <AppraisalBidEditDialog
            appraisalId={appraisalId}
            item={item}
            onChange={onChange}
            onClose={onClose}
          />
        )}
      </>
    );
  },
);
