import React from "react";
import { Redirect, Route } from "react-router-dom";

export const RouteGuard = React.memo(
  /**
   * A `Route` that redirects after checking a condition.
   *
   * @param {RouteGuardProps} props
   */
  function RouteGuard(props) {
    const { check: securityCheck, ...security } = React.useContext(
      RouteSecurityContext,
    );
    const { children, check = securityCheck, render, ...rest } = props;
    const redirect = rest.redirect || security.redirect;
    return (
      <Route
        {...rest}
        render={renderProps => {
          const result = check({
            // Include default `auth,redirect` from `security`.
            ...security,
            // Include all props passed to RouteGuard, which are probably mostly
            // already in `renderProps` as well...
            ...props,
            // Include `auth,location,path,redirect,roles` from `rest`.
            ...renderProps,
          });
          // console.log("RESULT", result);
          if (result === true) {
            return children || render(renderProps);
          }
          // NOTE: The result should be a URL (string), a LocationDescriptor,
          // undefined or some other falsey value.
          return <Redirect to={result || redirect} />;
        }}
      />
    );
  },
);

/** @type {React.Context<RouteSecurityContextType>} */
export const RouteSecurityContext = React.createContext(undefined);

export const RouteSecurityProvider = React.memo(
  /**
   * @param {RouteSecurityContextType} param0
   */
  function RouteSecurityProvider({ children, ...context }) {
    return (
      <RouteSecurityContext.Provider value={context}>
        {children}
      </RouteSecurityContext.Provider>
    );
  },
);
