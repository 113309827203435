import type { AxiosRequestConfig } from "axios";
// Local
import { ROLES } from "../../config";
import { FileUploader, Navigation } from "../../lib";
import { authClient } from "../auth/actions";
import { authSelectors } from "../auth/selectors";
import { formatDealerUser } from "../users/actions";
import { AppThunk } from "../types";
import { AppraisalActivityItem, appraisals } from "./state";

const { actions } = appraisals;

export const appraisalActions = {
  ...actions,

  archiveItem(id: number): AppThunk {
    return async (dispatch, getState) => {
      const userId = getState().auth.userId;
      await authClient.delete(`/appraisals/${id}`);
      dispatch(
        actions.updateShowing({
          appraisal: { deletedAt: new Date().toISOString(), deletedBy: userId },
        }),
      );
    };
  },

  unarchiveItem(id: number): AppThunk {
    return async dispatch => {
      await authClient.post(`/appraisals/${id}/unarchive`, {});
      dispatch(
        actions.updateShowing({
          appraisal: { deletedAt: null, deletedBy: null },
        }),
      );
    };
  },

  createDocument(
    id: number,
    typeName: AppraisalDocumentType,
    file: File,
  ): AppThunk<Promise<CreateDocumentData>> {
    return async dispatch => {
      const { data } = await authClient.post<CreateDocumentData>(
        `/appraisals/${id}/documents`,
        {
          name: file.name,
          size: file.size,
          type: file.type,
          typeName,
        },
      );
      return data;
    };
  },
  getActivity(id: number): AppThunk {
    return async (dispatch, getDispatch) => {
      const { data } = await authClient.get<AppraisalActivityItem[]>(
        `/appraisals/${id}/activity`,
      );
      dispatch(
        actions.setShowing({
          activity: data,
        }),
      );
    };
  },
  getApiResultData(
    id: number,
    name:
      | "blackbookValResult"
      | "mmrValResult"
      | "jdpValResult"
      | "galvesValResult"
      | "paveInspectResult",
    resultId: number,
  ): AppThunk {
    return async (dispatch, getDispatch) => {
      if (!resultId) {
        return;
      }
      const { data } = await authClient.get(
        `/appraisals/${id}/api-results/${resultId}`,
      );
      dispatch(
        actions.setShowing({
          [name]: data,
        }),
      );
    };
  },
  getApiResultInfo(
    id: number,
    name:
      | "blackbookValResult"
      | "mmrValResult"
      | "jdpValResult"
      | "galvesValResult"
      | "paveInspectResult",
    resultId: number,
  ): AppThunk {
    return async (dispatch, getDispatch) => {
      if (!resultId) {
        return;
      }
      const { data } = await authClient.get(
        `/appraisals/${id}/api-results/${resultId}/info`,
      );
      dispatch(
        actions.setShowing({
          [name]: data, // contains: createdAt, selection, data
        }),
      );
    };
  },
  getBidders(name?: string): AppThunk<Promise<DealerBidder[]>> {
    return async dispatch => {
      let config: AxiosRequestConfig;
      if (name) {
        config = {
          params: {
            filter: `{"name":"${name}"}`,
          },
        };
      }
      const { data } = await authClient.get<DealerBidder[]>(
        `/dealer_bidders`,
        config,
      );
      return data;
    };
  },
  rerunBlackBookVal(
    id: number,
    options: { trim: string; range: string },
  ): AppThunk {
    return async (dispatch, getDispatch) => {
      const { data } = await authClient.post(
        `/appraisals/${id}/rerun-blackbook-valuation`,
        options,
      );
      dispatch(
        actions.updateShowing({
          appraisal: data.values,
        }),
      );
      dispatch(
        actions.setShowing({
          blackbookValResult: data, // contains: createdAt, selection, data
        }),
      );
    };
  },
  rerunGalvesVal(
    id: number,
    options: { trim: string; range: string },
  ): AppThunk {
    return async (dispatch, getDispatch) => {
      const { data } = await authClient.post(
        `/appraisals/${id}/rerun-galves-valuation`,
        options,
      );
      dispatch(
        actions.updateShowing({
          appraisal: data.values,
        }),
      );
      dispatch(
        actions.setShowing({
          galvesValResult: data, // contains: createdAt, selection, data
        }),
      );
    };
  },
  rerunJdpVal(id: number, options: { trim: string; range: string }): AppThunk {
    return async (dispatch, getDispatch) => {
      const { data } = await authClient.post(
        `/appraisals/${id}/rerun-jdp-valuation`,
        options,
      );
      dispatch(
        actions.updateShowing({
          appraisal: data.values,
        }),
      );
      dispatch(
        actions.setShowing({
          jdpValResult: data, // contains: createdAt, selection, data
        }),
      );
    };
  },
  rerunMmrVal(
    id: number,
    options: { trim: string; range: string; grade: string; color: string },
  ): AppThunk {
    return async (dispatch, getDispatch) => {
      const { data } = await authClient.post(
        `/appraisals/${id}/rerun-mmr-valuation`,
        options,
      );
      dispatch(
        actions.updateShowing({
          appraisal: data.values,
        }),
      );
      dispatch(
        actions.setShowing({
          mmrValResult: data, // contains: createdAt, selection, data
        }),
      );
    };
  },
  getBanks(): AppThunk<Promise<DealerBank[]>> {
    return async dispatch => {
      const { data } = await authClient.get<DealerBank[]>(`/dealer_banks`);
      return data;
    };
  },
  getCampaigns(): AppThunk<Promise<DealerCampaign[]>> {
    return async dispatch => {
      const { data } = await authClient.get<DealerCampaign[]>(
        `/dealer_campaigns`,
      );
      return data;
    };
  },
  getDealerUsers(): AppThunk<Promise<DealerUser[]>> {
    return async dispatch => {
      // NOTE: dealerId is sent with the auth header already...
      const { data } = await authClient.get<DealerUser[]>(`/dealer_users`);
      return data;
    };
  },
  getDealTypes(): AppThunk<Promise<AppraisalDealType[]>> {
    return async dispatch => {
      const { data } = await authClient.get<AppraisalDealType[]>(
        `/appraisal_deal_types`,
      );
      return data;
    };
  },
  getById(id: number): AppThunk<Promise<Appraisal>> {
    return async dispatch => {
      return getAppraisal(id);
    };
  },
  getDocuments(id: number): AppThunk {
    return async (dispatch, getDispatch) => {
      const { data } = await authClient.get(`/appraisals/${id}/documents`);
      dispatch(
        actions.setShowing({
          docs: data,
        }),
      );
    };
  },
  getList(): AppThunk<Promise<Appraisal[]>> {
    return async (dispatch, getState) => {
      const {
        filter,
        // list: { page },
        // perPage,
        sort,
        order,
      } = getState().appraisals;
      // TODO: getState of filters, paging and sort options for API call.
      const res = await authClient.get<Appraisal[]>(`/appraisals`, {
        params: {
          filter: JSON.stringify(filter ?? {}),
          range: [],
          // range: [
          //   // start index:
          //   (page - 1) * perPage,
          //   // end index:
          //   page * perPage - 1,
          // ],
          sort: sort ? [sort, order] : ["id", "DESC"],
        },
      });
      let total = res.headers["x-total-count"];
      if (total) {
        total = parseInt(total);
      } else {
        total = res.data.length;
      }

      const items = res.data;
      formatAppraisalItems(items);
      dispatch(actions.setList({ items, total }));
      dispatch(
        actions.setShowing({
          appraisal: undefined,
          blackbookValResult: undefined,
          mmrValResult: undefined,
          jdpValResult: undefined,
          galvesValResult: undefined,
          paveInspectResult: undefined,
          docs: undefined,
          messages: [],
        }),
      );
      return res.data;
    };
  },
  // getListStats(): AppThunk {
  //   return async (dispatch, getState) => {
  //     const {
  //       filter,
  //       list: { page },
  //       perPage,
  //       sort,
  //     } = getState().appraisals;
  //     const { data } = await authClient.get(`/appraisals/list_stats`, {
  //       params: {
  //         filter,
  //         range: [
  //           // start index:
  //           (page - 1) * perPage,
  //           // end index:
  //           page * perPage - 1,
  //         ],
  //         sort,
  //       },
  //     });
  //     dispatch(actions.setListStats(data));
  //     return data;
  //   };
  // },
  getMessages(id: number): AppThunk {
    return async dispatch => {
      const { data: messages } = await authClient.get(
        `/appraisals/${id}/messages`,
      );
      dispatch(
        actions.setShowing({
          messages,
        }),
      );
    };
  },
  getRecordToShow(id: number, refresh?: boolean, assign?: boolean): AppThunk {
    // TODO: This action is garbage. It does 2 different functions. Fix this!
    return async (dispatch, getState) => {
      const data = await getAppraisal(id);
      const state = getState();
      if (
        assign &&
        !data.users &&
        (authSelectors.allowWriteAppraisal(state) ||
          state.auth.roles?.includes(ROLES.SERVICE_TECH))
      ) {
        const data2 = await assignFirstUser(id);
        data.users = data2.users;
        formatAppraisalItems(data);
      }
      if (refresh) {
        dispatch(
          actions.setShowing({
            appraisal: data,
          }),
        );
      } else {
        dispatch(
          actions.setShowing({
            appraisal: data,
            blackbookValResult: undefined,
            mmrValResult: undefined,
            jdpValResult: undefined,
            galvesValResult: undefined,
            paveInspectResult: undefined,
            docs: undefined,
            messages: [],
          }),
        );
      }
    };
  },
  getVinDetails(vin: string): AppThunk<Promise<VinDetails>> {
    return async (dispatch, getState) => {
      const { data } = await authClient.get<VinDetails>(
        `appraisals/vin/${vin}`,
      );
      return data;
    };
  },
  getVinFromPlate(
    values: VinFromPlateValues,
  ): AppThunk<Promise<VinFromPlateData>> {
    return async (dispatch, getState) => {
      const { data } = await authClient.get<VinFromPlateData>(
        `appraisals/plate/${values.state}/${values.plate}`,
      );
      return data;
    };
  },
  loadDashboard({
    filter,
    type,
  }: {
    filter: Record<"from" | "to", "string">;
    type: "lease-returns" | "tradeins";
  }): AppThunk {
    return async dispatch => {
      const res = await authClient.get<Appraisal[]>(`/dashboards/${type}`, {
        params: filter,
      });
      if (type === "lease-returns") {
        dispatch(actions.setDashboardLeaseReturns(res.data));
      } else {
        dispatch(actions.setDashboardTradeins(res.data));
      }
    };
  },
  getDealerStats(filters: Record<string, string>) {
    return async () => {
      const { data } = await authClient.get(`/dealer_stats`, {
        params: { filters },
      });
      return data;
    };
  },
  loadList(params: {
    filter: Record<string, any>;
    sort: string;
    order: "ASC" | "DESC";
  }): AppThunk {
    return async dispatch => {
      dispatch(actions.applyQueryParams(params));
      // await Promise.all([
      //   dispatch(appraisalActions.getListStats()),
      //   dispatch(appraisalActions.getList()),
      // ]);
      await dispatch(appraisalActions.getList());
    };
  },
  save(id: number, values: Partial<Appraisal>): AppThunk {
    return async (dispatch, getState) => {
      await authClient.put(`/appraisals/${id}`, values);
      dispatch(
        actions.updateShowing({
          appraisal: { ...values },
        }),
      );
    };
  },
  saveContact(id: number, values: Partial<Appraisal>): AppThunk {
    return async dispatch => {
      const { data } = await authClient.post<Appraisal>(
        `/appraisals/${id}/contact`,
        values,
      );
      const update: Partial<Appraisal> = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        vendorName: data.vendorName,
      };
      dispatch(
        actions.updateShowing({
          appraisal: update,
        }),
      );
    };
  },
  saveDealType(id: number, dealTypeId?: number): AppThunk {
    return async dispatch => {
      await authClient.post(`/appraisals/${id}/deal_type`, {
        dealTypeId,
      });
    };
  },
  saveEmail(id: number, values: { email: string }): AppThunk {
    return async (dispatch, getState) => {
      await authClient.post(`/appraisals/${id}/email`, values);
      dispatch(
        actions.updateShowing({
          appraisal: {
            email: values.email,
          },
        }),
      );
    };
  },
  saveFinanceBank(id: number, values: { financeBank: string }): AppThunk {
    return async (dispatch, getState) => {
      const { data } = await authClient.post<Partial<Appraisal>>(
        `/appraisals/${id}/finance_bank`,
        values,
      );
      dispatch(
        actions.updateShowing({
          appraisal: data,
        }),
      );
    };
  },
  saveRecon(
    id: number,
    values: {
      reconBodyCost: number;
      reconMechCost: number;
      reconOtherCost: number;
      reconditioningCost: number;
    },
  ): AppThunk {
    return async (dispatch, getState) => {
      await authClient.post(`/appraisals/${id}/recon`, values);
    };
  },
  saveAffiliate(id: number, affiliateId?: number): AppThunk {
    return async dispatch => {
      await authClient.post(`/appraisals/${id}/affiliate`, {
        affiliateId,
      });
      dispatch(
        actions.updateShowing({
          appraisal: {
            affiliateId,
          },
        }),
      );
    };
  },
  saveSellStrategy(id: number, sellStrategyStatusId?: number): AppThunk {
    return async dispatch => {
      await authClient.post(`/appraisals/${id}/sell_strat`, {
        sellStrategyStatusId,
      });
      dispatch(
        actions.updateShowing({
          appraisal: {
            sellStrategyStatusId,
          },
        }),
      );
    };
  },
  saveUsers(id: number, users: DealerUser[]): AppThunk<Promise<any>> {
    return async dispatch => {
      const { data } = await authClient.put(`/appraisals/${id}/users`, {
        userIds: users.map(it => it.id),
      });
      dispatch(
        actions.updateShowing({
          appraisal: {
            users,
          },
        }),
      );
      return data;
    };
  },
  saveOwnerStatus(id: number, ownerStatusId?: number): AppThunk {
    return async dispatch => {
      await authClient.post(`/appraisals/${id}/owner-status`, {
        ownerStatusId,
      });
      const values: Partial<Appraisal> = {
        ownerStatusId,
      };
      dispatch(
        actions.updateShowing({
          appraisal: values,
        }),
      );
    };
  },
  saveStatus(
    id: number,
    values: { appraisalStatusId?: number; notify?: boolean },
    optionsUpdateShowing = true,
  ): AppThunk {
    return async dispatch => {
      // const notify =
      //   appraisalStatusId === 3 && window.confirm("Send rejection email now?");
      await authClient.post(`/appraisals/${id}/status`, values);
      if (!optionsUpdateShowing) {
        return;
      }
      const update: Partial<Appraisal> = {
        appraisalStatusId: values.appraisalStatusId,
      };
      if (values.appraisalStatusId === null) {
        update.sellStrategyStatusId = undefined;
      }
      dispatch(
        actions.updateShowing({
          appraisal: update,
        }),
      );
    };
  },
  saveWorksheet(id: number, values): AppThunk {
    return async (dispatch, getState) => {
      await authClient.post(`/appraisals/${id}/worksheet`, values);
      await dispatch(appraisalActions.getRecordToShow(id, true));
    };
  },
  sendToCrm(id: number): AppThunk {
    return async dispatch => {
      await authClient.post(`/appraisals/${id}/send-crm`);
    };
  },
  sendToVauto(id: number): AppThunk {
    return async dispatch => {
      const { data } = await authClient.post(
        `/appraisals/${id}/send-to-vauto`,
        {},
      );
      dispatch(
        actions.updateShowing({
          appraisal: data,
        }),
      );
    };
  },
  makeOffer(
    id: number,
    values: {
      amount: number;
      amountMin: number;
      amountMax: number;
      customerProfit: number;
      includeDamages?: boolean;
      printPdf?: boolean;
      sendPdf?: boolean;
      showCustomerEquity: boolean;
      template: number; // 0: Exact, 1: Ranged, 2: Scheduled.
      textPdf?: string;
      type: "carOffer" | "auction" | "retail";
    },
  ): AppThunk {
    return async (dispatch, getState) => {
      const res = await authClient.post(`/appraisals/${id}/offer`, values);
      await dispatch(appraisalActions.getRecordToShow(id, true));
      if (values.printPdf) {
        window.open(res.data.pdfUrl, "_blank")?.focus();
      }
    };
  },
  buy(
    id: number,
    values: {
      amount: number;
      notify?: boolean;
      type: "carOffer" | "auction" | "retail";
    },
  ): AppThunk<Promise<{ ok: boolean }>> {
    return async (dispatch, getState) => {
      const result = await authClient.post(`/appraisals/${id}/buy`, values);
      await dispatch(appraisalActions.getRecordToShow(id, true));
      return result.data;
    };
  },
  ground(
    id: number,
    values: {
      notify?: boolean;
    },
  ): AppThunk<Promise<{ ok: boolean; id: number; message?: string }>> {
    return async (dispatch, getState) => {
      const result = await authClient.post(`/appraisals/${id}/ground`, values);
      await dispatch(appraisalActions.getRecordToShow(id, true));
      return result.data;
    };
  },
  reject(
    id: number,
    options: {
      notify?: boolean;
    } = {},
  ): AppThunk {
    return appraisalActions.saveStatus(id, {
      appraisalStatusId: 3,
      notify: options.notify ?? false,
    });
  },
  search({ filter, sort, order, pathname, ...queryParams }): AppThunk {
    return async (dispatch, getState) => {
      const state = getState();
      const {
        filter: curFilter,
        sort: curSort,
        order: curOrder,
        // perPage: curPerPage,
        // list: { page: curPage },
      } = state.appraisals;
      const query = {
        filter: JSON.stringify(filter ?? curFilter ?? {}),
        sort: sort ?? curSort,
        order: order ?? curOrder,
        ...queryParams,
      };
      // NOTE: The pathname should already contain the dealer slug...
      Navigation.go(pathname, {
        query,
      });
      dispatch(
        actions.changeState({
          filter: filter ?? curFilter,
          sort: query.sort,
          order: query.order,
        }),
      );
    };
  },
  globalSearch(
    filter: string,
    range: number[],
  ): AppThunk<
    Promise<{
      data: Appraisal[];
      count: number;
    }>
  > {
    return async () => {
      const { data, headers } = await authClient.get(`/global_search`, {
        params: { filter: { q: filter }, range },
      });
      let total = headers["x-total-count"];
      if (!total) {
        const { data: innerData } = data;
        total = innerData.length;
      }
      return { data, count: total };
    };
  },
  sendResponse(
    id: number,
    template: "unknown1" | "unknown2",
    values: any,
  ): AppThunk<Promise<any>> {
    return async dispatch => {
      await authClient.post(`/appraisals/${id}/respond/${template}`, values);
    };
  },
  uploadDocument(
    id: number,
    typeName: AppraisalDocumentType,
    file: File,
  ): AppThunk<Promise<CreateDocumentData>> {
    return async dispatch => {
      const doc = await dispatch(
        appraisalActions.createDocument(id, typeName, file),
      );
      // const res =
      await FileUploader.upload(file, doc.uploadUrl);
      // console.log("FILEUPLOAD", res);
      return doc;
    };
  },
  reopen(
    id: number,
    values: {
      notify?: boolean;
    } = {},
  ): AppThunk {
    return async (dispatch, getState) => {
      await authClient.post(`/appraisals/${id}/reopen`, values);
      await dispatch(appraisalActions.getRecordToShow(id, true));
    };
  },
  undoStatus(id: number): AppThunk {
    return async (dispatch, getState) => {
      await authClient.post(`/appraisals/${id}/undo-status`, {});
      await dispatch(appraisalActions.getRecordToShow(id, true));
    };
  },
  generateOdmStmt(id: number): AppThunk {
    return async (dispatch, getState) => {
      const { data } = await authClient.post(`/appraisals/${id}/odm-stmt`, {});
      await dispatch(appraisalActions.getDocuments(id));
      const url = data?.url ?? "";
      if (url) {
        window.open(url, "_blank")?.focus();
      }
    };
  },
  transferItem(id: number, data: { toDealerId: number }): AppThunk {
    return async (dispatch, getState) => {
      const userId = getState().auth.userId;
      await authClient.post(`/appraisals/${id}/transfer`, data);
      const at = new Date().toISOString();
      dispatch(
        actions.updateShowing({
          appraisal: {
            trfBy: userId,
            trfAt: at,
            deletedAt: at,
            deletedBy: userId,
          },
        }),
      );
    };
  },
  moveArea(id: number, toArea: string): AppThunk {
    return async (dispatch, getState) => {
      const { data: changed } = await authClient.post(
        `/appraisals/${id}/move-area`,
        {
          toArea,
        },
      );
      const at = new Date().toISOString();
      dispatch(
        actions.updateShowing({
          appraisal: {
            updatedAt: at,
            ...changed,
          },
        }),
      );
    };
  },
};

async function assignFirstUser(id: number): Promise<Partial<Appraisal>> {
  const { data } = await authClient.post<Partial<Appraisal>>(
    `/appraisals/${id}/first_user`,
  );
  return data;
}

function formatAppraisalItems(appraisalOrAppraisals) {
  (Array.isArray(appraisalOrAppraisals)
    ? appraisalOrAppraisals
    : [appraisalOrAppraisals]
  ).forEach((it: Appraisal) => {
    const { users, originator } = it;
    it.users = users?.map(formatDealerUser);
    if (originator) {
      it.originator = formatDealerUser(originator);
    }
  });
}

async function getAppraisal(id: number) {
  const { data } = await authClient.get<Appraisal>(`/appraisals/${id}`);
  formatAppraisalItems(data);
  return data;
}

export function isArchivedPath(pathname: string) {
  return pathname.endsWith("/appraisals/archived");
}

export function isDuplicatePath(pathname: string) {
  return pathname.includes(`/appraisals/duplicate`);
}

export function isDealerStatsPath(pathname: string) {
  return pathname.endsWith(`/dealer-stats`);
}

export function isRejectedPath(pathname: string) {
  return pathname.endsWith("/appraisals/rejected");
}

export function isLeaseReturnsPath(pathname = "") {
  return (
    isLeaseReturnsMgmtPath(pathname) ||
    pathname.endsWith("/appraisals/returns") ||
    pathname.endsWith("/appraisals/board/returns") ||
    pathname.endsWith("/appraisals/dashboard/returns")
  );
}

export function isLeaseReturnsMgmtPath(pathname = "") {
  return pathname.endsWith("/appraisals/manage/returns");
}

export function isServicePath(pathname = "") {
  return pathname.endsWith("/appraisals/service");
}

export function isShowroomPath(pathname = "") {
  return pathname.endsWith("/appraisals/showroom");
}

export function appraisalTypeFromPath(pathname: string) {
  // NOTE: It is correct that "/appraisals/rejected" will return 1 (Trade-ins),
  // since only Trade-ins can be rejected...
  return isLeaseReturnsPath(pathname) ? 2 : 1;
}

export function matchAppraisalListPath(pathname: string) {
  return isLeaseReturnsPath(pathname) ||
    pathname.endsWith("/appraisals/rejected") ||
    pathname.endsWith("/appraisals")
    ? true
    : false;
}

export const AppraisalDocumentTypeId = {
  odometerStatement: 38,
  payoff: 39,
  photo: 40,
  leaseReturn: 200,
  groundingReceipt: 201,
  billOfLading: 202,
};
export type AppraisalDocumentType = keyof typeof AppraisalDocumentTypeId;

export enum AppraisalTypeId {
  tradein = 1,
  lease = 2,
}

interface VinDetails {
  vin: string;
  vehicles: VehicleFromVin[];
}

interface VinFromPlateData {
  plate: string;
  state: string;
  vin: string;
}

type VinFromPlateValues = {
  plate: string;
  state: string;
};
