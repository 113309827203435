import React from "react";
import { FormikProvider } from "formik";
import {
  //
  Box,
  Button,
  // Typography,
  // makeStyles,
} from "@material-ui/core";
// Local
import { SelectField } from "../../../components";
import {
  //
  formatAmountUSD,
  formatDateTime,
  // formatThousands,
  useFormik,
  useOnMount,
} from "../../../lib";
import {
  appraisalActions,
  useAction,
  appraisalSelectors,
  useSelector,
} from "../../../state";

const rangeItems = [
  { id: "average", title: "Average" },
  { id: "clean", title: "Clean" },
  { id: "rough", title: "Rough" },
];

const defaultValues = {
  trim: "",
  range: "clean",
};

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       // margin: 0,
//     },
//   }),
//   {
//     classNamePrefix: "AppraisalJdpTab",
//   },
// );

export const AppraisalJdpTab = React.memo(
  /**
   * @param {{ record: Appraisal }} param0
   */
  function AppraisalJdpTab({ closed, record }) {
    // const classes = useStyles();

    const getApiResultInfo = useAction(appraisalActions.getApiResultInfo);
    const rerunJdpVal = useAction(appraisalActions.rerunJdpVal);
    const showing = useSelector(appraisalSelectors.showing);
    /** @type {AppraisalApiResult<JDPValuationResponseData>} */
    const result = React.useMemo(() => showing.jdpValResult ?? {}, [showing]);
    const {
      createdAt,
      // selection, // : { trim, range, grade, color: selectedColor } = {},
      data: { result: [resultData = null] = [] } = {},
    } = result;

    const [busy, setBusy] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const [initialValues, setInitialValues] = React.useState(defaultValues);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      // validationSchema, // They want to let it submit no matter what.
      validateOnBlur: false,
      validateOnChange: false,
      // async onSubmit(values, helpers) {
      //   setBusy(true);
      //   console.log("SUBMIT", values);
      //   setBusy(false);
      // },
    });

    React.useEffect(() => {
      const {
        selection, // : { trim, range } = {},
        // data = null,
      } = result;
      setInitialValues({
        // trim: selection?.trim ?? record.trim,
        range: selection?.range ?? "clean",
      });
      setLoading(false);
    }, [result, record]);

    useOnMount(() => {
      if (!record.jdpValResultId) {
        setLoading(false);
        return;
      }
      getApiResultInfo(record.id, "jdpValResult", record.jdpValResultId);
    });

    const onClickRerun = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        setBusy(true);
        await rerunJdpVal(record.id, {
          ...frm.values,
        });
        setBusy(false);
      },
      [frm.values, record, rerunJdpVal],
    );

    if (loading) {
      return <Box padding="60px">Loading...</Box>;
    }
    return (
      <FormikProvider value={frm}>
        <Box flex={1} padding="20px">
          {/* <Box>
            <SelectField
              disabled={closed || busy}
              name="trim"
              label="Trim"
              items={trimItems}
              fullWidth
            />
          </Box> */}
          <Box
          // pt="22px"
          >
            <SelectField
              disabled={closed || busy}
              name="range"
              label="Range"
              items={rangeItems}
              fullWidth
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pt="22px"
          >
            <Box color="#8D98C2" fontSize="14px">
              {createdAt ? (
                <em>Results from {formatDateTime(createdAt)}</em>
              ) : (
                <>&nbsp;</>
              )}
            </Box>
            <Button
              disabled={closed || busy}
              onClick={onClickRerun}
              variant="contained"
            >
              {createdAt ? "Rerun" : "Run"}
            </Button>
          </Box>
          {!resultData ? (
            <Box padding="60px">No valuation performed on this record.</Box>
          ) : (
            <>
              <JdpValueSection
                data={resultData}
                range={frm.values.range}
                title="Trade in"
                type="trade"
              />
              <JdpValueSection
                data={resultData}
                range={frm.values.range}
                title="Loan"
                type="loan"
              />
              <JdpValueSection
                data={resultData}
                range={frm.values.range}
                title="Retail"
                type="retail"
              />
            </>
          )}
        </Box>
      </FormikProvider>
    );
  },
);

const JdpValueSection = React.memo(
  /**
   * @param {{
   * data:JDPValuationResult,
   * range:string,
   * title:string,
   * type:"trade"|"loan"|"retail"
   * }} param0
   */
  function JdpValueSection({ data, range, title, type }) {
    return (
      <Box>
        <Box
          color="#141932"
          fontSize="16px"
          fontWeight="500"
          letterSpacing="0"
          lineHeight="25px"
        >
          {title}
        </Box>
        {type === "trade" ? (
          <
            // BaseCleanTrade + MileageAdustment = AdjustedCleanTrade
            // BaseRoughTrade + MileageAdustment = AdjustedRoughTrade
            // BaseAverageTrade + MileageAdustment = AdjustedAverageTrade
          >
            <JdpValueItem title="Base" value={data[`base${range}trade`]} />
            <JdpValueItem title="Adjustment" value={data.mileageadjustment} />
            <JdpValueItem title="Total" value={data[`adjusted${range}trade`]} />
          </>
        ) : type === "loan" ? (
          <
            // BaseLoan + MileageAdjustment = AdjustedLoan
          >
            <JdpValueItem title="Base" value={data.baseloan} />
            <JdpValueItem title="Adjustment" value={data.mileageadjustment} />
            <JdpValueItem title="Total" value={data.adjustedloan} />
          </>
        ) : type === "retail" ? (
          <
            // BaseCleanRetail + MileageAdjustment = AdjustedCleanRetail
          >
            <JdpValueItem title="Base" value={data.basecleanretail} />
            <JdpValueItem title="Adjustment" value={data.mileageadjustment} />
            <JdpValueItem title="Total" value={data.adjustedcleanretail} />
          </>
        ) : null}
      </Box>
    );
  },
);

const JdpValueItem = React.memo(
  /**
   *
   */
  function JdpValueItem({ title, value }) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pt="8px"
      >
        <Box
          color="#8D98C2"
          fontSize="14px"
          fontWeight="500"
          lineHeight="21px"
          pl="24px"
        >
          {title}
        </Box>
        <Box color="#141932" fontSize="16px" lineHeight="25px">
          {formatAmountUSD(value)}
        </Box>
      </Box>
    );
  },
);
