import React from "react";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import { Button, Typography, makeStyles } from "@material-ui/core";
// Icons
// import PublishIcon from "@material-ui/icons/Publish";
import DeleteIcon from "@material-ui/icons/Delete";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: 5,
      maxWidth: 600,
    },
    fileInput: {
      backgroundColor: "#ffffff",
      border: "2px dashed #BBC2E1",
      padding: "32px 12px",
      cursor: "pointer",
      "& strong": {
        fontSize: 16,
        fontWeight: "500",
        color: "#8D98C2",
      },
    },
    header: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
    card: {
      // backgroundColor: "#EDEDED",
      // border: "2px dashed #7991AF",
      // borderRadius: 8,
      // padding: "5px 15px 15px 15px",
      cursor: "pointer",
      // minWidth: 253,
      // minHeight: 154,
    },
    icon: {
      color: "#8D98C2",
      fontSize: 14,
      fontWeight: 400,
      paddingTop: 12,
      paddingBottom: 12,
    },
    browseBtn: {
      backgroundColor: "#4E50FF",
      borderRadius: 3,
      width: "170px",
      padding: "8px 12px",
      textAlign: "center",
      margin: "0 auto",
      "& p": {
        color: "#ffffff",
        fontSize: 16,
        fontWeight: 500,
      },
    },
    content: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
    error: {
      color: "darkred",
    },
    footer: {
      display: "flex",
      justifyContent: "center",
      paddingTop: 10,
    },
    hidden: {
      visibility: "hidden",
    },
  }),
  {
    classNamePrefix: "FileInput",
  },
);

export const FileInput = React.memo(
  /**
   * A `react-dropzone` file input.
   *
   * @typedef {import("react-dropzone").DropzoneOptions} DropzoneOptions
   *
   * @typedef {object} FileInputProps
   * @property {string} name Name of the form field.
   * @property {Record<string,string>} [classFrom] Object to get `className`
   * from, for the given field `name`.
   * @property {string} [className]
   * @property {string|false} [confirmRemoveText] Set to `false` to disable
   * confirmation.
   * @property {any} [defaultValue] Value when no file is selected. `[]`
   * @property {boolean} [disabled]
   * @property {boolean} [disableRemove]
   * @property {React.ReactNode} [error] Error message (string or component)
   * @property {string} [helpText] Text to prompt the user. `"Drop a photo..."`
   * @property {string} [label] Label for the input.
   * @property {number} [maxSize] Maximum file size (in bytes) `0`
   * @property {number} [minSize] Minimum file size (in bytes) `0`
   * @property {boolean} [multiple] True to allow multiple inputs.
   * @property {DropzoneOptions["onDrop"]} onChange
   * @property {string} [removeText] Text of the remove button. `"Remove"`
   * @property {boolean} [showPreview] True to show a preview for single files.
   * @property {string} [url] URL of the previously uploaded file to display.
   * @property {File} [value] Current value of the input.
   * @property {accept} [value] File types to accept
   *
   * @param {FileInputProps} props
   */
  function FileInput(props) {
    const {
      //
      name,
      classFrom,
      className,
      confirmRemoveText = "Are you sure?",
      defaultValue = [],
      disabled,
      error,
      // helpText = (
      //   <>
      //     or
      //     <br />
      //     Click to browse
      //   </>
      // ),
      label,
      maxSize,
      minSize,
      multiple,
      onChange,
      removeText = "Remove",
      url,
      value,
      disableRemove: disableRemoveProp,
      showPreview: showPreviewProp = !multiple,
      accept = "image/*, application/pdf",
    } = props;
    const showPreview = multiple ? false : showPreviewProp;
    const disableRemove = multiple ? true : disableRemoveProp;

    const classes = useStyles();

    const onClickRemove = React.useCallback(() => {
      if (confirmRemoveText && !window.confirm(confirmRemoveText)) {
        return;
      }
      onChange(defaultValue);
    }, [onChange, confirmRemoveText, defaultValue]);

    const { getRootProps, getInputProps } = useDropzone({
      onDrop: onChange,
      maxFiles: multiple ? 0 : 1,
      multiple,
      accept,
      disabled,
      maxSize,
      minSize,
    });

    const [isPdf, setIsPdf] = React.useState(false);
    React.useEffect(() => {
      setIsPdf(
        url
          ? url.includes(".pdf")
          : value
          ? value.name.includes(".pdf")
          : false,
      );
    }, [value, url]);

    const style = React.useMemo(() => {
      return !showPreview || (!value && !url) || isPdf
        ? undefined
        : {
            backgroundImage: `url(${value ? URL.createObjectURL(value) : url})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          };
    }, [showPreview, value, url, isPdf]);

    return (
      <div
        className={clsx(
          classes.root,
          className,
          classes.fileInput,
          classFrom?.[name],
        )}
      >
        <div className={classes.header}>
          <Typography>
            <strong>{label}</strong>
          </Typography>
        </div>
        <div
          {...getRootProps({
            className: classes.card,
            style,
          })}
        >
          <input {...getInputProps()} />
          {isPdf ? (
            <div className={`${classes.content} ${classes.icon}`}>
              <PictureAsPdfIcon fontSize="inherit" />
              <Typography>{value.name}</Typography>
            </div>
          ) : (
            <div className={!!value || !!url ? classes.hidden : undefined}>
              <div className={`${classes.content} ${classes.icon}`}>or</div>
              <div
                className={clsx(
                  classes.content,
                  classes.browseBtn,
                  error && classes.error,
                )}
              >
                <Typography>{error || "Click to browse"}</Typography>
              </div>
            </div>
          )}
        </div>
        <div className={classes.footer}>
          {!disableRemove && value && (
            <Button
              onClick={onClickRemove}
              startIcon={<DeleteIcon />}
              variant="text"
            >
              {removeText}
            </Button>
          )}
        </div>
      </div>
    );
  },
);
