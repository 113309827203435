import React from "react";
import {
  // Box,
  // Button,
  DialogContent,
  // Link,
  // Typography,
  makeStyles,
} from "@material-ui/core";
// Local
import {
  CancelSaveDialogActions,
  Field,
  // DollarField,
  SimpleDialog,
  useBasicDialog,
} from "../../../components";
import {
  //
  Form,
  formatDateTime,
  // formatDecimal,
  mapFormDefaults,
  useFormik,
  yup,
} from "../../../lib";
import { dealerApi } from "../../../state";

const useStyles = makeStyles(
  theme => ({
    // root: {
    //   "& .MuiDialog-paper": {
    //     width: 400,
    //     minHeight: 321,
    //   },
    //   "& .MuiDialogContent-root": {
    //     paddingTop: 24,
    //     paddingRight: 24,
    //     paddingLeft: 24,
    //     paddingBottom: 8,
    //   },
    //   "& .MuiFormControl-root": {
    //     marginTop: 32,
    //   },
    //   "& .MuiDialogActions-root": {
    //     paddingBottom: 24,
    //     paddingLeft: 24,
    //     paddingRight: 24,
    //   },
    // },
    changed: {
      paddingTop: 12,
      [theme.breakpoints.down("xs")]: {
        // Added vertical space for mobile keyboard.
        paddingBottom: 36,
      },
    },
  }),
  {
    classNamePrefix: "DealerBankEditor",
  },
);

const defaultValues = {
  name: "",
};

const validationSchema = yup.object({
  name: yup.string().min(3).required("Bank name is required"),
});

export const DealerBankEditor = React.memo(
  /**
   *
   */
  function DealerBankEditor({ item, onClose }) {
    const classes = useStyles();
    // const dealer = useDealer();
    const { basicDialog, confirmAsync } = useBasicDialog();

    /** @type {[DealerBank]} */
    const [initialValues, setInitialValues] = React.useState(defaultValues);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      // validateOnBlur: false,
      validateOnChange: false,
      validationSchema,
      async onSubmit(values, helpers) {
        await dealerApi.saveBank({ id: item.id, ...values });
        setInitialValues(values);
        onClose();
      },
    });

    const onClickDelete = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      async e => {
        // if (!window.confirm("Are you sure?")) {
        //   return;
        // }
        if (
          !(await confirmAsync({
            text: (
              <>
                Are you sure?
                <p>
                  (This bank will be removed from all appraisals that reference
                  it.)
                </p>
              </>
            ),
          }))
        ) {
          return;
        }

        await dealerApi.removeBank(item.id);
        onClose();
      },
      [item, confirmAsync, onClose],
    );

    React.useEffect(() => {
      if (!item || item.id === 0) {
        return;
      }
      const state = { mounted: true };
      (async function onDealerChanged() {
        setInitialValues(defaultValues);
        const values = await dealerApi.getBank(item.id);
        if (!state.mounted) return;
        setInitialValues(mapFormDefaults(defaultValues, values));
      })();
      return () => {
        state.mounted = false;
      };
    }, [item]);

    return (
      <SimpleDialog
        maxWidth="sm"
        onClose={onClose}
        open={true}
        title={`${!item?.id ? "Add" : "Edit"} bank`}
      >
        <Form form={frm}>
          <DialogContent>
            <Field
              name="name"
              label="Bank name"
              helperText="Enter the bank name as it should be displayed."
              autoFocus
              fullWidth
            />
            {item?.updatedAt && (
              <div className={classes.changed}>
                Last change: {formatDateTime(item.updatedAt)}
              </div>
            )}
          </DialogContent>
          <CancelSaveDialogActions
            disabled={frm.isSubmitting}
            saveDisabled={!frm.dirty}
            onCancel={onClose}
            onDelete={onClickDelete}
          />
        </Form>
        {basicDialog}
      </SimpleDialog>
    );
  },
);
