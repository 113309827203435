import React from "react";
import { Box, Typography } from "@material-ui/core";
// Local
import { Navigation, useLocation } from "../../lib";
import { useDefaultDealer } from "../../state";

export const NotFoundPage = React.memo(
  /**
   *
   */
  function NotFoundPage() {
    // TODO: Remove this code used to redirect from old urls to new urls when it
    // is no longer necessary...
    const dealer = useDefaultDealer();
    const location = useLocation();
    const { pathname } = location;
    if (pathname.startsWith("/new") || pathname.startsWith("/appraisals")) {
      Navigation.replace(`/${dealer.slug}${pathname}${location.search}`);
    }

    return (
      <Box>
        <Typography variant="h2">PAGE NOT FOUND</Typography>
      </Box>
    );
  },
);
