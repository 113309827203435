import React from "react";
import {
  //
  Box,
  // Typography,
  //makeStyles,
} from "@material-ui/core";
// Local
import { DocumentGrid, FileInput } from "../../../components";
import { useOnMount } from "../../../lib";
import {
  appraisalActions,
  appraisalSelectors,
  useAction,
  useSelector,
} from "../../../state";

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       // margin: 0,
//     },
//   }),
//   {
//     classNamePrefix: "AppraisalDocumentsTab",
//   },
// );

export const AppraisalDocumentsTab = React.memo(
  /**
   * @param {{ record: Appraisal }} param0
   */
  function AppraisalDocumentsTab({ record }) {
    //const classes = useStyles();

    const { docs } = useSelector(appraisalSelectors.showing);

    const getDocuments = useAction(appraisalActions.getDocuments);
    const uploadDocument = useAction(appraisalActions.uploadDocument);

    const [uploading, setUploading] = React.useState(false);

    const onFileChange = React.useCallback(
      /** @param {File[]} files */
      async (files, rej, e) => {
        if (!files || files.length < 0) {
          return;
        }
        setUploading(true);
        try {
          // const uploaded = [];
          for (let i = 0; i < files.length; i++) {
            // const info =
            await uploadDocument(record.id, "payoff", files[i]);
            // uploaded.push({
            //   uid: info.uid,
            //   fileName: info.name,
            //   // url: URL.createObjectURL(files[i]),
            // });
          }
          getDocuments(record.id);
        } finally {
          setUploading(false);
        }
      },
      [record, getDocuments, uploadDocument],
    );

    useOnMount(() => {
      getDocuments(record.id);
    });

    if (!docs) {
      return <Box padding="60px">Loading...</Box>;
    }
    // TODO: Show "docs.lease_returns" in another section here...
    return (
      <Box padding="24px 12px">
        <Box mb="16px">
          <Box
            color="#141932"
            fontSize="20px"
            fontWeight="600"
            lineHeight="24px"
          >
            Payoff ({docs.payoff.length})
          </Box>
        </Box>
        <DocumentGrid documents={docs.payoff} indexLabelSuffix />
        <FileInput
          name="payoff"
          label="Drag &amp; Drop a photo here"
          onChange={onFileChange}
          disabled={uploading}
          multiple
        />
        <Box paddingTop="40px" paddingBottom="10px">
          <Box
            color="#1F2834"
            fontSize="20px"
            fontWeight="600"
            lineHeight="24px"
          >
            Photos ({docs.photos.length})
          </Box>
        </Box>
        <DocumentGrid documents={docs.photos} indexLabelSuffix />
      </Box>
    );
  },
);
