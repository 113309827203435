import { UserSettingsPage } from "./settings/UserSettingsPage";
import { UserNotificationsTable } from "./notifications/UserNotificationsTable";
import { DealerStatsTable } from "./stats/DealerStatsTable";
import { ROLES } from "../../config";
export const UserPages = {
  profile: {
    path: "/my/settings/profile",
    title: "User Profile",
    view: UserSettingsPage,
  },
  notifications: {
    path: "/my/settings/notifications",
    title: "Notifications",
    view: UserSettingsPage,
  },
  notificationsTable: {
    path: "/my/notifications",
    title: "Notifications",
    view: UserNotificationsTable,
  },
  dealerStatsTable: {
    path: "/my/stats",
    title: "Dealer Stats",
    roles: [ROLES.GROUP_ADMIN],
    view: DealerStatsTable,
  },
};
