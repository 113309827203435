import React from "react";
import {
  //
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
// Local
import { TabPanel } from "../../../components";
import { Navigation, useLocation } from "../../../lib";
import { authSelectors, useSelector } from "../../../state";
import { useMobile } from "../../../themes";
import { UserProfileForm } from "../profile/UserProfileForm";
import { UserNotificationsList } from "../notifications/UserNotificationsList";

const useStyles = makeStyles(
  theme => ({
    root: {
      // margin: 0,
    },
    container: {
      borderTop: "1px solid rgb(0, 0, 0, 0.2)",
      display: "flex",
      margin: "20px -40px 0 -40px",
      padding: "0 40px",
      [theme.breakpoints.down("xs")]: {
        display: "block",
        margin: "8px -16px 0 -16px",
        padding: "0 16px",
      },
    },
    contents: {
      borderLeft: "1px solid rgb(0, 0, 0, 0.2)",
      flex: 1,
      paddingLeft: 16,
      paddingTop: 16,
      minHeight: 800,
      [theme.breakpoints.down("xs")]: {
        borderLeft: "unset",
        paddingLeft: 0,
      },
    },
  }),
  {
    classNamePrefix: "UserSettingsPage",
  },
);
/** Settings area definitions. NOTE: Must also add page path for each one! */
const settingsAreaByName = {
  profile: {
    name: "profile",
    label: "Profile",
    children: <UserProfileForm />,
  },
  notifications: {
    name: "notifications",
    label: "Notifications",
    children: <UserNotificationsList />,
  },
};
const settingsAreas = Object.values(settingsAreaByName);

const disableTransition = {
  // TODO: Figure out why Tabs animation (disabled here) is broken on mobile.
  style: { transition: "none" },
};

export const UserSettingsPage = React.memo(
  /**
   *
   */
  function UserSettingsPage() {
    const classes = useStyles();
    const location = useLocation();
    const isMobile = useMobile();

    /** @type {typeof settingsAreas[number]} */
    const currentArea = React.useMemo(() => {
      // e.g. /my/settings/AREA === 0/1/2/3
      const name = location.pathname.split("/")[3];
      return settingsAreaByName[name];
    }, [location]);
    const user = useSelector(authSelectors.user);
    const isAdmin = useSelector(authSelectors.isAdmin);

    const onChangeSettingsTab = React.useCallback((e, value) => {
      Navigation.replace(`/my/settings/${value}`);
    }, []);

    return (
      <div className={classes.root}>
        <Typography variant="h2" noWrap>
          {user.fullName} &bull; {currentArea.title ?? currentArea.label}
        </Typography>
        <div className={classes.container}>
          <Tabs
            value={currentArea.name}
            onChange={onChangeSettingsTab}
            orientation={isMobile ? "horizontal" : "vertical"}
            variant={isMobile ? "scrollable" : undefined}
            TabIndicatorProps={disableTransition}
          >
            {settingsAreas.map(
              area =>
                (!area.adminOnly || isAdmin) && (
                  <Tab key={area.name} value={area.name} label={area.label} />
                ),
            )}
          </Tabs>
          <div className={classes.contents}>
            {settingsAreas.map(
              area =>
                (!area.adminOnly || isAdmin) && (
                  <TabPanel
                    key={area.name}
                    selected={currentArea.name === area.name}
                  >
                    {area.children}
                  </TabPanel>
                ),
            )}
          </div>
        </div>
      </div>
    );
  },
);
