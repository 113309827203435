import React from "react";
import {
  //
  Button,
  makeStyles,
} from "@material-ui/core";
// Local
import { Field } from "../components";
import {
  //
  Form,
  useFormik,
  // yup,
} from "../lib";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      alignContent: "center",
      minWidth: 400,
      "& > button": {
        margin: 0,
        // padding: 4,
      },
    },
    field: {
      flex: 1,
      minWidth: 300,
    },
  }),
  {
    classNamePrefix: "InlineEmailAddressForm",
  },
);
/**
 * @typedef {import("@material-ui/core").TextFieldProps} TextFieldProps
 * @typedef {import("../components").FieldProps} FieldProps
 * @type {TextFieldProps & FieldProps}
 */
const DefaultFieldProps = {
  autoFocus: true,
};

export const InlineEmailAddressForm = React.memo(
  /**
   * @typedef {object} InlineEmailAddressFormProps
   * @property {TextFieldProps & FieldProps} [FieldProps]
   * @property {string} [className]
   * @property {(values?: {email:string})=>void} [onClose]
   * @property {{email:string}} values
   *
   * @param {InlineEmailAddressFormProps} props
   */
  function InlineEmailAddressForm({
    FieldProps = DefaultFieldProps,
    className = "",
    onClose,
    values: initialValues,
  }) {
    const classes = useStyles();
    const frm = useFormik({
      initialValues,
      validateOnBlur: false,
      validateOnChange: false,
      // validationSchema,
      onSubmit(values, _helpers) {
        if (frm.dirty) {
          onClose(values);
        } else {
          onClose();
        }
      },
    });
    const onClickCancel = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (onClose) {
          onClose();
        }
      },
      [onClose],
    );

    return (
      <Form className={`${classes.root} ${className}`} form={frm}>
        <Field
          className={classes.field}
          name="email"
          type="email"
          fullWidth
          {...FieldProps}
        />
        <Button type="submit" variant="text" size="small">
          OK
        </Button>
        <Button variant="text" size="small" onClick={onClickCancel}>
          Cancel
        </Button>
      </Form>
    );
  },
);
