import React from "react";
import {
  Typography,
  TableBody,
  Box,
  Table,
  TableRow,
  TableCell,
  TableHead,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  required: {
    fontWeight: "bold",
  },
  table: {
    "& > .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root,& > .MuiTableHead-root > .MuiTableRow-root .MuiTableCell-root ":
      {
        paddingTop: "4px",
        paddingBottom: "4px",
      },
    "& > .MuiTableHead-root ": {
      position: "sticky",
      top: 0,
      "& > .MuiTableRow-root": {
        backgroundColor: "#FFFFFF",
        borderTop: "hidden",
        "& > .MuiTableCell-root": {
          fontSize: "16px",
        },
      },
    },
  },
  scrollable: {
    height: "375px",
    overflow: "scroll",
  },
}));

export function CampaignEmailTable() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.scrollable}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} className={classes.required}>
                Column names MUST follow the standard provided below
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Column</TableCell>
              <TableCell>Row</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.required}>vin*</TableCell>
              <TableCell>XXXXXXXXXXX</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.required}>email*</TableCell>
              <TableCell>test@example.com</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>first_name</TableCell>
              <TableCell>John</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>last_name</TableCell>
              <TableCell>Doe</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>phone_number</TableCell>
              <TableCell>555555555</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>mileage</TableCell>
              <TableCell>20000</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>finance_bank</TableCell>
              <TableCell>Chase</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>finance_bank_num</TableCell>
              <TableCell>1111</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>year</TableCell>
              <TableCell>2018</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>make</TableCell>
              <TableCell>Honda</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>model</TableCell>
              <TableCell>Odyssey</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>trim</TableCell>
              <TableCell>Minivan Elite</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>maturity_date</TableCell>
              <TableCell>2023-02-24</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Typography style={{ textAlign: "center" }}>
        Please refer to a template example before uploading
      </Typography>
      <ul>
        <li>
          <a href="/examples/example.csv">CSV</a>
        </li>
        <li>
          <a href="/examples/example.xlsx">XLSX</a>
        </li>
      </ul>
    </>
  );
}
