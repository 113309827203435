import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  // makeStyles,
} from "@material-ui/core";
// Local
import { emptyHandler, useField } from "../../lib";

// export const useStyles = makeStyles(
//   theme => ({
//     group: {
//       flexDirection: "row",
//     },
//   }),
//   {
//     classNamePrefix: "CheckboxArrayField",
//   },
// );

export const CheckboxArrayField = React.memo(
  /**
   * A Material-UI `FormControl` and `Checkbox` bound to a `formik` field.
   *
   * @typedef {import("@material-ui/core").FormControlProps} FormControlProps
   * @typedef {object} CheckboxArrayFieldProps
   * @property {Record<string,string>} [classFrom] Object to get `className`
   * from, for the given field `name`.
   * @property {boolean} [error] True if error.
   * @property {string} [helperText] Message to show beneath the control.
   * @property {{label:string,value:any}[]} items
   * @property {number|string} itemWidth
   * @property {string} name
   * @property {string} [label]
   * @property {string} [labelField] Item field to use as checkbox label. ("name")
   * @property {(e:React.SyntheticEvent<HTMLInputElement>,item:any,value:any[])=>any} [onChangeItem]
   * Handler for when an item changes. If it returns anything other than
   * `undefined`, the value is set to that.
   * @property {string} [valueField] Item field to use as checkbox value. ("id")
   * @property {boolean} [vertical] True to orient checkboxes vertically.
   *
   * @param {CheckboxArrayFieldProps & FormControlProps} props
   */
  function CheckboxArrayField(props) {
    let {
      name,
      classFrom,
      className,
      error,
      helperText,
      items,
      itemWidth,
      label,
      labelField = "name",
      onChangeItem = emptyHandler,
      vertical,
      value: _value,
      valueField = "id",
      ...rest
    } = props;
    // const classes = useStyles(props);
    const [fld, meta, helpers] = useField(name);
    error = Boolean(meta.error) || error;
    helperText = meta.error ? meta.error : helperText;
    const itemStyle = itemWidth ? { width: itemWidth } : undefined;

    const onChange = React.useCallback(
      item => {
        const itemValue = item[valueField];
        return (
          /** @param {React.SyntheticEvent<HTMLInputElement>} e */
          e => {
            const fieldValue = fld.value || [];
            const valueToSet = onChangeItem(e, item, fieldValue);
            if (valueToSet !== undefined) {
              if (valueToSet !== fieldValue) {
                helpers.setValue(valueToSet);
              }
              return;
            }
            if (e.target.checked) {
              // Add
              if (!fieldValue.includes(itemValue)) {
                helpers.setValue([...fieldValue, itemValue]);
              }
            } else {
              // Remove
              if (fieldValue.includes(itemValue)) {
                helpers.setValue(fieldValue.filter(v => v !== itemValue));
              }
            }
          }
        );
      },
      [fld, helpers, onChangeItem, valueField],
    );
    const fieldValue = fld.value || [];
    return (
      <FormControl
        name={name}
        component="fieldset"
        error={error}
        className={className || classFrom?.[name]}
        {...rest}
      >
        <FormLabel component="legend" focused={false}>
          {label}
        </FormLabel>
        <FormHelperText id={`${name}-helper-text`} error={error}>
          {helperText}
        </FormHelperText>
        <FormGroup row={!vertical}>
          {items.map((it, i) => (
            <FormControlLabel
              key={i}
              style={itemStyle}
              control={
                <Checkbox
                  name={`${name}-${i}`}
                  checked={fieldValue.includes(it[valueField])}
                  onChange={onChange(it)}
                />
              }
              label={it[labelField]}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  },
);
