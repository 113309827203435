import React from "react";
import {
  //
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  withStyles,
} from "@material-ui/core";
// Icons
import BadIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import GoodIcon from "@material-ui/icons/CheckCircle";
import { CustomInfoIcon } from "../components/CustomInfoIcon";
import VeryGoodIcon from "@material-ui/icons/OfflinePin";
import RefreshIcon from "@material-ui/icons/Refresh";
// Local
import {
  //
  CopyableText,
  DollarField,
  Field,
  InputPropsFor,
} from "../../../components";
import {
  //
  Form,
  formatAmountUSD,
  formatDateTime,
  formatDecimal,
  isEmpty,
  useAsyncEffect,
} from "../../../lib";
import {
  AppraisalStatusId,
  appraisalActions,
  authSelectors,
  useAction,
  // appraisalSelectors,
  useSelector,
  dealerApi,
} from "../../../state";
import { useMobile } from "../../../themes";
import { AppraisalBiddersBox } from "./AppraisalBiddersBox";
import { AppraisalWorksheetSidebar } from "./AppraisalWorksheetSidebar";
import { AppraisalOfferDialog } from "./AppraisalOfferDialog";
import {
  getProfitStatus,
  getProfitStatusRange,
  useWorksheet,
} from "./useWorksheet";
import { useStyles, useOfferBoxStyles } from "./AppraisalWorksheetTab.styles";
const profitColors = {
  equal: "#FDBE30",
  less: "#F62527",
  more: "#00D289",
};

/**
 * @param {Appraisal} row
 * @param {"auctionValuation" | "retailValuation"} [valuationField]
 */
function valuationTypeTag(row, valuationField = "auctionValuation") {
  const val = row[valuationField];
  if (!val) {
    return "";
  }
  if (valuationField === "retailValuation") {
    return " (JDP)";
  }
  const type = row.auctionValType;
  const match =
    type === "jdp"
      ? "JDP"
      : type === "mmr"
      ? "MMR"
      : type === "galves"
      ? "Galves"
      : "";
  if (!match) {
    return "";
  }
  return ` (${match})`;
}

export const AppraisalWorksheetTab = React.memo(
  /**
   * @param {{record:Appraisal}} param0
   */
  function AppraisalWorksheetTab({ record: row }) {
    // #region Hooks and State

    const classes = useStyles();
    const isMobile = useMobile();
    const [feeBox, setFeeBox] = React.useState("0");

    const buy = useAction(appraisalActions.buy);
    const ground = useAction(appraisalActions.ground);
    const allowWrite = useSelector(authSelectors.allowWriteAppraisal);
    const allowBids = useSelector(authSelectors.isAdminOrAcqManager);

    const {
      //
      closed,
      defaultEquity,
      frm,
      // initialValues,
      isLeaseReturn,
      profits,
      proposals,
      // resetFieldValues,
      // saveWorksheet,
      valuations,
    } = useWorksheet({
      allowBids,
      allowWrite,
      record: row,
    });

    const {
      dirty,
      resetForm,
      setErrors,
      setFieldValue,
      setTouched,
      submitForm,
      validateForm,
      values,
    } = frm;

    /** @type {[OfferType|"", (value:OfferType)=>void]} */
    const [offering, setOffering] = React.useState("");
    const onCloseOffer = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        setOffering(false);
      },
      [],
    );
    const onClickMakeOffer = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        const type = e.currentTarget.dataset.type;
        const errors = await validateForm();
        if (!isEmpty(errors)) {
          setTouched(
            Object.keys(errors).reduce(
              (touched, key) => ({
                ...touched,
                [key]: true,
              }),
              {},
            ),
          );
          setErrors(errors);
          return;
        }
        await submitForm();
        setOffering(type);
      },
      [validateForm, setTouched, setErrors, submitForm],
    );

    const onClickBuy = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        const type = e.currentTarget.dataset.type;
        const missingEmail = !row.email;
        if (
          !window.confirm(
            "Are you sure?" +
              (missingEmail
                ? "\n\nNOTE: Customer email is missing. No notification will be sent."
                : ""),
          )
        ) {
          return;
        }
        const errors = await validateForm();
        if (!isEmpty(errors)) {
          setTouched(
            Object.keys(errors).reduce(
              (touched, key) => ({
                ...touched,
                [key]: true,
              }),
              {},
            ),
          );
          setErrors(errors);
          return;
        }
        const notify = missingEmail
          ? false
          : window.confirm("Send Odometer statement to customer?");
        await submitForm();
        // const notify = window.confirm(`Send email now to ${row.email}?`);
        await buy(row.id, {
          amount: parseFloat(values[`${type}Proposal`]),
          notify,
          type,
        });
        if (notify) {
          window.alert(`Email with odometer statement sent to ${row.email}`);
        }
      },
      [row, values, buy, validateForm, setTouched, setErrors, submitForm],
    );

    const onClickGround = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        // const type = e.currentTarget.dataset.type;
        const missingEmail = !row.email;
        if (
          !window.confirm(
            "Are you sure?" +
              (missingEmail
                ? "\n\nNOTE: Customer email is missing. No notification will be sent."
                : ""),
          )
        ) {
          return;
        }
        const notify = missingEmail
          ? false
          : window.confirm(`Send email now to ${row.email.trim()}?`);
        const errors = await frm.validateForm();
        if (!isEmpty(errors)) {
          frm.setTouched(
            Object.keys(errors).reduce(
              (touched, key) => ({
                ...touched,
                [key]: true,
              }),
              {},
            ),
          );
          frm.setErrors(errors);
          return;
        }
        await frm.submitForm();
        // const notify = window.confirm(`Send email now to ${row.email}?`);
        await ground(row.id, {
          notify,
        });
        // window.alert(`Email sent to ${row.email}`);
      },
      [frm, row, ground],
    );

    const onClickReset = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        resetForm();
      },
      [resetForm],
    );

    // #endregion

    // console.log("DIRTY", frm.dirty);
    const defaultProfitColor =
      profitColors[getProfitStatusRange(defaultEquity, 1000, 5000)];
    const [profitColor, setProfitColor] = React.useState(defaultProfitColor);
    useAsyncEffect(
      async state => {
        // 40117 ==- HighEquity
        const setting = await dealerApi.getSetting(40117);
        if (!state.mounted) return;
        setProfitColor(
          profitColors[
            getProfitStatusRange(defaultEquity, 1000, parseInt(setting.value))
          ],
        );
      },
      [defaultEquity],
    );

    return (
      <>
        <Form className={classes.root} promptOnExit form={frm}>
          <div className={classes.main}>
            <div className={classes.setup}>
              <div className={classes.boxContainer}>
                <div className={classes.icon}>
                  <CustomInfoIcon
                    title={`Valuation calculations can be found below`}
                    IconProps={{ color: "primary" }}
                  />
                </div>
                <TextField
                  className={classes.label}
                  label={`Default valuation ${valuationTypeTag(row)}`}
                  InputProps={InputPropsFor.readOnly}
                  value={formatAmountUSD(row.auctionValuation)}
                  variant="outlined"
                />

                <DollarField
                  name="adjustedPayoffAmount"
                  label={`Payoff`}
                  variant="outlined"
                  disabled={closed}
                  error={frm.values.adjustedPayoffAmount <= 0}
                />
              </div>
              <div className={classes.boxContainer}>
                <div className={classes.feesAndCostBox}>
                  <div>Total fees and costs</div>
                  <div className={classes.feeText}>
                    <CopyableText iconColor="#000" text={formatDecimal(feeBox)}>
                      {formatAmountUSD(feeBox)}
                    </CopyableText>
                    <div className={classes.equityNote}>
                      This is before calculating costs like reconditioning,
                      transportation...
                    </div>
                  </div>
                </div>
                <div
                  className={classes.equityBox}
                  style={{ backgroundColor: profitColor, color: "#FFF" }}
                >
                  <div style={{ display: "flex" }}>
                    Equity &nbsp;{" "}
                    <CustomInfoIcon
                      title={
                        "Valuation - payoff - total fees and costs = equity"
                      }
                    />
                  </div>
                  <div className={classes.equityText}>
                    <CopyableText
                      iconColor="#B5C1FF"
                      text={formatDecimal(defaultEquity)}
                    >
                      {formatAmountUSD(defaultEquity)}
                    </CopyableText>
                    <div className={classes.equityNote}>
                      This is before calculating costs like reconditioning,
                      transportation...
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.offers}>
              <OfferBox
                offerType="auction"
                record={row}
                customerProfitAmount={profits.auctionCustomerProfit}
                profitAmount={profits.auctionTotalProfit}
                profitObjective={values.profitObjective}
                suggestedAmount={proposals.auction}
                title="Wholesale"
                titleAdd={valuationTypeTag(row)}
                onClickMakeOffer={onClickMakeOffer}
                onClickBuy={onClickBuy}
                proposalFieldName="auctionProposal"
                setFieldValue={setFieldValue}
                valuationFieldName="auctionValuation"
                disabled={closed}
              />
              <OfferBox
                offerType="retail"
                record={row}
                customerProfitAmount={profits.retailCustomerProfit}
                profitAmount={profits.retailTotalProfit}
                profitObjective={values.profitObjective}
                suggestedAmount={proposals.retail}
                title="Retail"
                titleAdd={valuationTypeTag(row, "retailValuation")}
                onClickMakeOffer={onClickMakeOffer}
                onClickBuy={onClickBuy}
                proposalFieldName="retailProposal"
                setFieldValue={setFieldValue}
                valuationFieldName="retailValuation"
                disabled={closed}
              />
            </div>
            <Box
              borderRadius="3"
              boxShadow="0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC"
              p="24px"
              pb="40px"
              mb="40px"
            >
              <Box
                color="#141932"
                fontSize="20px"
                fontWeight="600"
                lineHeight="22px"
                pb="16px"
              >
                Notes
              </Box>
              <Field
                name="packageNotes"
                fullWidth
                multiline
                minRows={2}
                maxRows={4}
              />
            </Box>
            {allowBids && (
              <AppraisalBiddersBox
                auctionProposal={values.auctionProposal}
                auctionFee={values.auctionFee}
                bids={values.bids}
                pickupFee={values.pickupFee}
                reconditioningCost={values.reconditioningCost}
                wholesalePack={values.wholesalePack}
                row={row}
                setFieldValue={setFieldValue}
              />
            )}
            {isMobile && (
              <AppraisalWorksheetSidebar
                closed={closed}
                record={row}
                setFeeBox={setFeeBox}
              />
            )}
            <div className={classes.actions}>
              {isLeaseReturn && (
                <WorksheetButton
                  size="large"
                  variant="contained"
                  onClick={onClickGround}
                  disabled={closed}
                >
                  Ground
                </WorksheetButton>
              )}
              <WorksheetButton
                onClick={onClickReset}
                size="large"
                variant="contained"
                disabled={frm.isSubmitting || !dirty}
              >
                Reset worksheet
              </WorksheetButton>
              {!dirty ? (
                <div className={classes.savedStatus}>
                  <CheckIcon color="inherit" />
                  <div>Worksheet saved</div>
                </div>
              ) : (
                <WorksheetButton
                  size="large"
                  variant="contained"
                  type="submit"
                  disabled={frm.isSubmitting}
                >
                  Save worksheet
                </WorksheetButton>
              )}
            </div>
          </div>
          {!isMobile && (
            <AppraisalWorksheetSidebar
              closed={closed}
              record={row}
              setFeeBox={setFeeBox}
            />
          )}
        </Form>
        {offering && (
          <AppraisalOfferDialog
            align="right"
            offerType={offering}
            onClose={onCloseOffer}
            profits={profits}
            proposals={proposals}
            record={row}
            valuations={valuations}
            worksheetValues={values}
          />
        )}
      </>
    );
  },
);

/** @type {import("@material-ui/core").InputProps} */
const offerInputProps = {
  style: {
    color: "#1F2834",
    fontSize: 20,
    fontWeight: "bold",
    padding: 8,
  },
};

const sellStrategyId = {
  carOffer: 1,
  auction: 2,
  repair: 3,
  retail: 4,
};

const profitIcons = {
  equal: GoodIcon,
  less: BadIcon,
  more: VeryGoodIcon,
};

const styles = {
  makeOfferButton: {
    padding: "10px 24px",
  },
  resetOfferButton: {
    padding: 0,
  },
  separator: {
    backgroundColor: "#ACBBCD",
    border: "none",
    height: 1,
    marginLeft: -16,
    marginRight: -16,
  },
  separator2: {
    backgroundColor: "#ACBBCD",
    border: "none",
    height: 1,
    marginTop: 0,
    // marginLeft: -16,
    // marginRight: -16,
  },
};

const OfferBox = React.memo(
  /**
   * @typedef {"carOffer"|"auction"|"retail"} OfferType
   *
   * @typedef {object} OfferBoxProps
   * @property {number} customerProfitAmount
   * @property {OfferType} offerType
   * @property {()=>void} onClickMakeOffer
   * @property {number} profitAmount
   * @property {number} profitObjective
   * @property {string} proposalFieldName
   * @property {Appraisal} record
   * @property {(field:keyof typeof defaultValues,value:any,shouldValidate?:boolean)=>Promise<any>} setFieldValue
   * @property {number} suggestedAmount
   * @property {string} title
   * @property {string} titleAdd
   * @property {string} valuationFieldName
   * @property {string} valuationLabel
   * @property {boolean} disabled
   *
   * @param {OfferBoxProps} param0
   */
  function OfferBox({
    customerProfitAmount = 0,
    offerType,
    onClickBuy,
    onClickMakeOffer,
    profitAmount = 0,
    profitObjective = 0,
    proposalFieldName,
    record: row,
    setFieldValue,
    suggestedAmount,
    title,
    titleAdd,
    valuationFieldName,
    valuationLabel = "Valuation",
    disabled = false,
  }) {
    const classes = useOfferBoxStyles();

    const profitLabel = getProfitStatus(profitAmount, profitObjective);
    const ProfitStatusIcon = profitIcons[profitLabel];
    const profitColor = profitColors[profitLabel];

    const {
      //
      appraisalStatusId,
      appraisalTypeId,
      offerAmount,
      offerSent,
      ownerStatusId,
      sellStrategyStatusId,
    } = row;

    const isClosed = !!appraisalStatusId;
    const isBuying = appraisalStatusId === AppraisalStatusId.buying;
    const isLeaseReturn = appraisalTypeId === 2;
    const isOfferType = sellStrategyStatusId === sellStrategyId[offerType];

    const isBuyingLease = isOfferType && isBuying && isLeaseReturn;
    const sentThisOffer = isOfferType && offerSent;

    const resetProposalAmount = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        setFieldValue(proposalFieldName, suggestedAmount);
      },
      [suggestedAmount, proposalFieldName, setFieldValue],
    );

    const suggestedAmountUSD = React.useMemo(
      () => formatAmountUSD(suggestedAmount),
      [suggestedAmount],
    );

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          {title}
          <span className={classes.titleAdd}>{titleAdd}</span>
        </div>
        <div className={classes.iconContainer}>
          <div className={classes.valuationIcon}>
            <CustomInfoIcon
              title={`Valuation calculations can be found below`}
              IconProps={{ color: "primary" }}
            />
          </div>

          <DollarField
            className={classes.label}
            label={valuationLabel}
            name={valuationFieldName}
            fullWidth
            inputProps={offerInputProps}
            disabled={disabled}
          />
        </div>
        <div className={classes.iconContainer}>
          <div className={classes.offerIcon}>
            <CustomInfoIcon
              title={
                `suggested offer of ${suggestedAmountUSD} =\n` +
                (offerType === "auction"
                  ? `(Valuation - Auction fee - Wholesale pack -` +
                    `\nTransport fee - Reconditioning)`
                  : `(Valuation - Transport fee - Reconditioning)`)
              }
              IconProps={{
                color: "primary",
              }}
            />
          </div>
          <DollarField
            label={isLeaseReturn ? "Our cost" : "Our offer"}
            name={proposalFieldName}
            fullWidth
            inputProps={offerInputProps}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="inherit"
                    onClick={resetProposalAmount}
                    style={styles.resetOfferButton}
                    title={
                      `Reset to suggested offer of ${suggestedAmountUSD} =\n` +
                      (offerType === "auction"
                        ? `(Valuation - Auction fee - Wholesale pack -` +
                          `\nTransport fee - Reconditioning)`
                        : `(Valuation - Transport fee - Reconditioning)`)
                    }
                    disabled={disabled}
                  >
                    <RefreshIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            disabled={disabled}
          />
        </div>
        <Box mx="-8px" pb="17px">
          <hr style={styles.separator} />
        </Box>
        <Box
          color="#141932"
          fontSize="16px"
          fontWeight="500"
          letterSpacing="0"
          pb="8px"
          textAlign="center"
        >
          Our profit
        </Box>
        <Box
          alignItems="center"
          fontSize="32px"
          color={profitColor}
          display="flex"
          justifyContent="center"
        >
          <ProfitStatusIcon color="inherit" fontSize="inherit" />
          <span>&nbsp;</span>
          <CopyableText
            color="#141932"
            fontSize="24px"
            fontWeight="600"
            marginRight="-24px"
            text={formatDecimal(profitAmount)}
          >
            {formatAmountUSD(profitAmount)}
          </CopyableText>
        </Box>
        <div>&nbsp;{/* Placeholder for "advice feature" text... */}</div>
        <Box
          alignItems="flex-start"
          display="flex"
          flex={1}
          flexDirection="column"
          justifyContent="space-between"
          pt="16px"
        >
          {isBuyingLease || (isClosed && sentThisOffer) ? (
            <Box
              alignItems="center"
              color="#526B8A"
              display="flex"
              border="1px solid #526B8A"
              height="41px"
              justifyContent="center"
              width="100%"
            >
              <CheckIcon color="inherit" />
              &nbsp;{isLeaseReturn ? "Buying" : "Offer sent"}
            </Box>
          ) : !isLeaseReturn ? (
            <Button
              size="large"
              variant="contained"
              data-type={offerType}
              onClick={onClickMakeOffer}
              fullWidth
              disabled={disabled}
              title={
                sentThisOffer
                  ? `Offer of ${formatAmountUSD(
                      offerAmount,
                    )} sent ${formatDateTime(offerSent)}`
                  : undefined
              }
              style={
                sentThisOffer
                  ? {
                      backgroundColor: "#ffffff",
                      border: "1px solid #526B8A",
                      color: "#526B8A",
                    }
                  : undefined
              }
            >
              {sentThisOffer ? "RESEND" : "MAKE"} OFFER
            </Button>
          ) : (
            <Button
              size="large"
              variant="contained"
              data-type={offerType}
              onClick={onClickBuy}
              fullWidth
              disabled={disabled}
              title={
                sentThisOffer
                  ? `Offer of ${formatAmountUSD(
                      offerAmount,
                    )} sent ${formatDateTime(offerSent)}`
                  : undefined
              }
              style={
                sentThisOffer
                  ? {
                      backgroundColor: "#ffffff",
                      border: "1px solid #526B8A",
                      color: "#526B8A",
                    }
                  : undefined
              }
            >
              {sentThisOffer ? "RE-" : null}BUY
            </Button>
          )}
          {!isLeaseReturn && (
            <Box
              color="#526B8A"
              fontSize="12px"
              fontWeight="500"
              pt="12px"
              textAlign="center"
              width="100%"
            >
              {ownerStatusId === 3 ? "Customer profit " : "Customer equity "}
              <CopyableText
                marginRight="-24px"
                text={formatDecimal(customerProfitAmount)}
                title={`Valuation - Proposal`}
              >
                {formatAmountUSD(customerProfitAmount)}
              </CopyableText>
            </Box>
          )}
        </Box>
      </div>
    );
  },
);

const WorksheetButton = withStyles(theme => ({
  root: {
    backgroundColor: "#EFF1FB",
    color: "#1F2834",
    fontSize: 16,
    height: "40px",
    margin: "0 16px 16px 0",
    width: 176,
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 16px 0",
      width: "100%",
    },
  },
  disabled: {
    backgroundColor: "lightgrey",
    color: "grey",
  },
}))(Button);

// #region Types
/**
 * @typedef {typeof defaultValues} WorksheetValues
 */
/**
 * @typedef {object} WorksheetValuations
 * @property {number} carOfferProjectedValuation
 * @property {number} auctionProjectedValuation
 * @property {number} retailProjectedValuation
 * @property {number} carOfferProjectedEquity
 * @property {number} auctionProjectedEquity
 * @property {number} retailProjectedEquity
 */
/**
 * @typedef {object} WorksheetProposals
 * @property {number} carOffer
 * @property {number} auction
 * @property {number} retail
 */
/**
 * @typedef {object} WorksheetProfits
 * @property {number} carOfferTotalProfit
 * @property {number} auctionTotalProfit
 * @property {number} retailTotalProfit
 * @property {number} carOfferCustomerProfit
 * @property {number} auctionCustomerProfit
 * @property {number} retailCustomerProfit
 * @property {string} carOfferProfitStatus
 * @property {string} auctionProfitStatus
 * @property {string} retailProfitStatus
 */

// #endregion

// #region Notes

/* The following was the FIRST offer box before it was removed.
<OfferBox
  offerType="carOffer"
  record={row}
  customerProfitAmount={profits.carOfferCustomerProfit}
  profitAmount={profits.carOfferTotalProfit}
  profitObjective={values.profitObjective}
  suggestedAmount={proposals.carOffer}
  title="Wholesale"
  onClickMakeOffer={onClickMakeOffer}
  onClickBuy={onClickBuy}
  proposalFieldName="carOfferProposal"
  setFieldValue={setFieldValue}
  valuationFieldName="carOfferAmount"
  valuationLabel="Bid"
  disabled={closed}
/>
*/

// #endregion
