import React from "react";
import {
  //
  Badge,
  // Box,
  // Button,
  // Card,
  // CardActions,
  // CardContent,
  // Chip,
  // Container,
  // IconButton,
  // InputLabel,
  // Link,
  Tab,
  Tabs,
  // Toolbar,
  // Typography,
} from "@material-ui/core";
// Icons
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import EditIcon from "@material-ui/icons/Edit";
// import BankNotFoundIcon from "@material-ui/icons/Warning";
// Local
import {
  // Dot,
  // PageLink,
  // PageLinkButton,
  // CopyableText,
  // SelectInput,
  TabPanel,
} from "../../../components";
import {
  //
  // Form,
  Navigation,
  formatAmountUSD,
  formatDateTime,
  // useFormik,
  useLocation,
} from "../../../lib";
import {
  appraisalActions,
  appraisalSelectors,
  authSelectors,
  useAction,
  useSelector,
} from "../../../state";
import { AppraisalDetailsHeader } from "./AppraisalDetailsHeader";
import { AppraisalWorksheetTab } from "./AppraisalWorksheetTab";
import { AppraisalPAVEInspectTab } from "./AppraisalPAVEInspectTab";
import { AppraisalDocumentsTab } from "./AppraisalDocumentsTab";
import { AppraisalActivityTab } from "./AppraisalActivityTab";
import { useStyles } from "./ShowAppraisalPage.styles";

/** @type {import("@material-ui/core").BadgeOrigin} */
const autoOfferBadgeAnchorOrigin = {
  horizontal: "right",
  vertical: "top",
};

/**
 * @param {Appraisal} row
 */
function getAutoOfferBadgeProps(row) {
  // Return `badgeContent: 0` to cause Badge to not display...
  /** @type {import("@material-ui/core").BadgeProps} */
  const props = {
    badgeContent: 0,
  };
  // if (process.env.NODE_ENV === "development") {
  //   row = {
  //     offerAmount: 22500,
  //     offerSent: "2022-08-07 13:05:00",
  //   };
  // }
  if (!row.offerAmount || row.offeredBy) {
    return props;
  }
  const usdFormat = { decimalCount: 0 };
  let amounts = formatAmountUSD(row.offerAmount, usdFormat);
  if (row.offerAmountMax) {
    amounts += " - " + formatAmountUSD(row.offerAmountMax, usdFormat);
  }
  props.badgeContent = "Est. " + amounts;
  props.title = `Automated estimate of ${amounts} sent ${formatDateTime(
    row.offerSent,
  )}.`;
  return props;
}

export const ShowAppraisalPage = React.memo(
  /**
   *
   */
  function ShowAppraisalPage() {
    const classes = useStyles();
    const location = useLocation();
    const allowReadVals = useSelector(authSelectors.allowReadVals);

    const {
      params: { id },
      query: { tab: detailTab = allowReadVals ? "worksheet" : "activity" },
    } = location;

    const getRecordToShow = useAction(appraisalActions.getRecordToShow);
    const { appraisal } = useSelector(appraisalSelectors.showing);
    const [row, setRow] = React.useState(appraisal);
    React.useEffect(() => {
      setRow(appraisal);
    }, [appraisal]);

    const onChangeDetailTab = React.useCallback(
      (e, value) => {
        const query = {
          ...location.query,
          tab: value,
        };
        Navigation.replace(location.pathname, {
          query,
          // hash: value === "details" ? location.hash : "",
        });
      },
      [location],
    );

    React.useEffect(() => {
      setRow(undefined);
      getRecordToShow(id, false, true);
    }, [getRecordToShow, id]);

    if (!row) {
      return "Loading...";
    }
    return (
      <div className={classes.root}>
        <AppraisalDetailsHeader record={row} />
        <div className={classes.divider} />
        <Badge
          className="offerBadge"
          color="error"
          anchorOrigin={autoOfferBadgeAnchorOrigin}
          {...getAutoOfferBadgeProps(row)}
        >
          <Tabs
            variant="scrollable"
            value={detailTab}
            onChange={onChangeDetailTab}
            className={classes.detailTabs}
          >
            {allowReadVals && (
              <Tab value="worksheet" label="Vehicle Assessment" />
            )}
            <Tab value="inspection" label="Inspection" />
            <Tab value="documents" label="Documents" />
            {/* <Tab value="messages" label="Messages" /> */}
            <Tab value="activity" label="Activity" />
          </Tabs>
        </Badge>
        <div className={classes.divider} />
        <div className={classes.container}>
          {allowReadVals && (
            <TabPanel selected={detailTab === "worksheet"}>
              <AppraisalWorksheetTab record={row} />
            </TabPanel>
          )}
          <TabPanel selected={detailTab === "inspection"}>
            <AppraisalPAVEInspectTab record={row} />
          </TabPanel>
          <TabPanel selected={detailTab === "documents"}>
            <AppraisalDocumentsTab record={row} />
          </TabPanel>
          {/* <TabPanel selected={detailTab === "messages"}>
              <Box padding="60px" paddingBottom="600px">
                {"// TODO: Show Messages"}
              </Box>
            </TabPanel> */}
          <TabPanel selected={detailTab === "activity"}>
            <AppraisalActivityTab record={row} />
          </TabPanel>
        </div>
      </div>
    );
  },
);
