import React from "react";
import {
  //
  Box,
  // InputLabel,
  Link,
  // Typography,
  // makeStyles,
} from "@material-ui/core";
// Local
import { CopyableText } from "../../../components";
import { useMobile } from "../../../themes";
// import {
//   //
//   // formatAmountUSD,
//   // formatThousands,
//   useOnMount,
// } from "../../../lib";
// import {
//   appraisalActions,
//   useAction,
//   appraisalSelectors,
//   useSelector,
// } from "../../../state";

export const AppraisalPAVEInspectTab = React.memo(
  /**
   * @param {{ record: Appraisal }} param0
   */
  function AppraisalPAVEInspectTab({ record }) {
    // const classes = useStyles();
    const isMobile = useMobile();

    // const getApiResult = useAction(appraisalActions.getApiResult);
    // const showing = useSelector(appraisalSelectors.showing);
    // /** @type {PAVESessionResultsData} */
    // const pave = showing.paveInspectResult;

    // useOnMount(() => {
    //   if (!record.paveInspectResultId) {
    //     return;
    //   }
    //   getApiResult(record.id, "paveInspectResult", record.paveInspectResultId);
    // });

    const {
      // Session key from when PAVE inspection was initiated.
      paveInspectSessionKey,
      // Id of PAVE inspection result record in appraisal_api_results.
      paveInspectResultId,
    } = record;
    // console.log("PAVE", { paveInspectSessionKey, paveInspectResultId });

    const paveReportUrl = `https://paveinspect.com/park/${paveInspectSessionKey}`;
    const paveInspectUrl = `https://dashboard.paveapi.com/valet/${paveInspectSessionKey}`;

    return (
      <>
        <Box
          alignItems="baseline"
          p={isMobile ? "24px 0 8px 0" : "24px 24px 8px 12px"}
          display="flex"
          // justifyContent="space-between"
          position="relative"
          zIndex={1000}
          bgcolor="#fff"
        >
          <>
            <CopyableText
              text={paveInspectUrl}
              color="#8D98C2"
              fontSize="14px"
              fontWeight="500"
              pr="8px"
              lineHeight="21px"
            >
              <Link
                href={paveInspectUrl}
                target="_blank"
                rel="noopener noreferrer"
                color="secondary"
              >
                Customer Capture Link
              </Link>
            </CopyableText>
            {paveInspectResultId && (
              <CopyableText
                text={paveReportUrl}
                color="#8D98C2"
                fontSize="14px"
                fontWeight="500"
                pr="8px"
                ml="16px"
                lineHeight="21px"
              >
                <Link
                  href={paveReportUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="secondary"
                >
                  Condition Report Link
                </Link>
              </CopyableText>
            )}
          </>
          &nbsp;
        </Box>
        {!paveInspectResultId ? (
          "Inspection results are not currently available."
        ) : (
          <Box margin={isMobile ? "0 -16px" : undefined}>
            <Box
              component="iframe"
              src={paveReportUrl}
              title="PAVE"
              border="none"
              width="100%"
              // height=8000
              height="100vh"
              position="relative"
              zIndex="0"
              top="-60"
            />
          </Box>
        )}
      </>
    );
  },
);
