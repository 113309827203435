import React from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  // makeStyles,
} from "@material-ui/core";
// Icons
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       // margin: 0,
//     },
//   }),
//   {
//     classNamePrefix: "SplitButton",
//   },
// );

export const SplitButton = React.memo(
  /**
   * See https://v4.mui.com/components/button-group/#split-button
   *
   * @param {{
   * dropdownClassname?:string;
   *  onClick:(e, index)=>void;
   *  options:string[];
   *  renderLabel?:(label:string)=>React.ReactNode;
   * } & Omit<import("@material-ui/core").ButtonProps, "onClick">} param0
   */
  function SplitButton({
    dropdownClassname,
    onClick,
    options,
    renderLabel,
    ...buttonProps
  }) {
    // const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const onButtonClick = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        onClick(e, selectedIndex);
      },
      [selectedIndex, onClick],
    );

    const { onMenuItemClick, toggleMenu, closeMenu } = React.useMemo(() => {
      return {
        onMenuItemClick(e, index) {
          setSelectedIndex(index);
          setOpen(false);
        },
        toggleMenu() {
          setOpen(prevOpen => !prevOpen);
        },
        closeMenu(e) {
          if (anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
          }
          setOpen(false);
        },
      };
    }, []);

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button {...buttonProps} onClick={onButtonClick}>
              {renderLabel
                ? renderLabel(options[selectedIndex])
                : options[selectedIndex]}
            </Button>
            <Button
              className={dropdownClassname}
              color={buttonProps.color}
              disabled={buttonProps.disabled}
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={toggleMenu}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-end"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={closeMenu}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          disabled={index === 2}
                          selected={index === selectedIndex}
                          onClick={event => onMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    );
  },
);
