// import { createSelector } from "@reduxjs/toolkit";
// Local
import { AppState } from "../types";

export const dealerSelectors = {
  dealers(state: AppState) {
    return state.auth.dealers ?? [];
  },
  defaultDealer(state: AppState) {
    return state.auth.dealers?.[0];
  },
  documents(state: AppState) {
    return state.dealer.documents;
  },
};
