import React from "react";
import QRCode from "qrcode";

export const QRCodeImage = React.memo(
  /**
   * Shows a QRCode image from the given `text` value.
   * @param {React.ImgHTMLAttributes<HTMLImageElement> & {text:string}} props
   */
  function QRCodeImage(props) {
    const { text, title = "QR Code", ...rest } = props;

    const [qrcode, setQrcode] = React.useState(undefined);
    React.useEffect(() => {
      const state = { mounted: true };
      QRCode.toDataURL(text).then(qrcode => {
        if (!state.mounted) return;
        setQrcode(qrcode);
      });
      return () => {
        state.mounted = false;
      };
    }, [text]);

    if (!text) {
      return null;
    }

    return <img title={title} alt={title} src={qrcode} {...rest} />;
  },
);
