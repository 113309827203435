import React from "react";
import { Box, IconButton } from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
// Local
import { useMobile } from "../../themes";

export const CopyableText = React.memo(
  /**
   * @typedef {import("@material-ui/core").BoxProps} BoxProps
   *
   * @typedef CopyableTextProps
   * @property {React.ReactNode} [children]
   * @property {boolean} [dense] True to apply dense horizontal margins.
   * @property {React.ComponentType} [icon] Copy icon to show. Defaults to
   * `FileCopyOutlined`.
   * @property {string} [iconColor]
   * @property {React.ReactNode} [otherButtons]
   * @property {string} [tip] Tooltip text to show over the copy button.
   * Defaults to `"Copy to clipboard"`.
   * @property {string} [text] Text to copy, if not using children.
   *
   * @param {CopyableTextProps & BoxProps} param0
   */
  function CopyableText({
    className,
    children,
    dense,
    icon,
    iconColor,
    text,
    tip,
    otherButtons,
    ...boxProps
  }) {
    const isMobile = useMobile();
    const [showButton, setShowButton] = React.useState(false);
    const show = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        setShowButton(true);
      },
      [],
    );
    const hide = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        setShowButton(false);
      },
      [],
    );
    return (
      <Box
        display="inline-flex"
        alignItems="center"
        className={className}
        onMouseEnter={show}
        onMouseLeave={hide}
        {...boxProps}
      >
        <Box flex={1}>{children}</Box>
        {!isMobile && (
          <CopyTextButton
            dense={dense}
            icon={icon}
            iconColor={iconColor}
            tip={tip}
            text={text ?? children}
            visible={showButton}
            otherButtons={otherButtons}
            hide={hide}
          />
        )}
      </Box>
    );
  },
);

export const CopyTextButton = React.memo(
  /**
   * @typedef CopyTextButtonProps
   * @property {string} [className]
   * @property {boolean} [dense] True to apply dense horizontal margins.
   * @property {()=>void} hide
   * @property {React.ComponentType} [icon] Copy icon to show. Defaults to
   * `FileCopyOutlined`.
   * @property {string} [iconColor]
   * @property {React.ReactNode} [otherButtons]
   * @property {string} [tip] Tooltip text to show over the copy button.
   * Defaults to `"Copy to clipboard"`.
   * @property {string} [text] Text to copy.
   * @property {boolean} [visible] True if the button should be visible.
   *
   * @param {CopyTextButtonProps} param0
   */
  function CopyTextButton({
    className,
    dense,
    hide,
    icon: IconComponent = FileCopyOutlinedIcon,
    iconColor,
    otherButtons,
    tip = "Copy to clipboard",
    text,
    visible,
  }) {
    const onClickCopy = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (window.navigator?.clipboard?.writeText) {
          window.navigator.clipboard.writeText(text);
        }
      },
      [text],
    );
    return (
      <Box
        mx={!dense ? undefined : "-3px"}
        visibility={visible ? undefined : "hidden"}
        onClick={hide}
        color={iconColor ? iconColor : undefined}
      >
        <IconButton
          className={className}
          title={tip}
          onClick={onClickCopy}
          size="small"
          color={iconColor ? "inherit" : undefined}
        >
          <IconComponent fontSize="small" />
        </IconButton>
        {otherButtons}
      </Box>
    );
  },
);
