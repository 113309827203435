import React from "react";
import {
  //
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
// Local
import {
  Form,
  Navigation,
  RouterLink,
  useFormik,
  useLocation,
  yup,
} from "../../lib";
import { authActions, useAction } from "../../state";
import { useStyles } from "./Auth.styles";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const LoginPage = React.memo(
  /**
   *
   */
  function LoginPage() {
    const classes = useStyles();
    const login = useAction(authActions.login);
    const location = useLocation();

    const [error, setError] = React.useState("");
    const frm = useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema,
      async onSubmit(values) {
        try {
          await login(values);
        } catch (ex) {
          setError("Invalid username or password. Please try again.");
          return;
        }
        Navigation.replace(location.query.after || "/");
      },
    });

    return (
      <div className={classes.root}>
        <Box mb="8px">
          <Typography variant="h1">Welcome</Typography>
        </Box>
        <Typography variant="subtitle1">
          {error ? (
            <span className={classes.errorText}>{error}</span>
          ) : (
            "Sign in to your account"
          )}
        </Typography>
        <Form form={frm} className={classes.form}>
          <TextField
            autoFocus
            fullWidth
            id="email"
            name="email"
            label="Username"
            autoComplete="username"
            value={frm.values.email}
            onChange={frm.handleChange}
            error={frm.touched.email && Boolean(frm.errors.email)}
            helperText={frm.touched.email && frm.errors.email}
          />
          <Box pt="32px" pb="40px">
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              value={frm.values.password}
              onChange={frm.handleChange}
              error={frm.touched.password && Boolean(frm.errors.password)}
              helperText={frm.touched.password && frm.errors.password}
            />
          </Box>
          <Box mb="16px">
            <Button
              variant="contained"
              type="submit"
              disabled={frm.isSubmitting}
              fullWidth
              color="secondary"
            >
              Sign in
            </Button>
          </Box>
          <Grid container justifyContent="center" mt="16px">
            <Link
              component={RouterLink}
              to={`/auth/forgot-password?email=${encodeURIComponent(
                frm.values.email,
              )}`}
              underline="none"
              variant="body2"
              color="secondary"
              className={classes.authLink}
            >
              Forgot password?
            </Link>
          </Grid>
        </Form>
      </div>
    );
  },
);
