import React from "react";
import { Link } from "@material-ui/core";
// Local
import { NavLink } from "../../lib";

/** See https://stackoverflow.com/a/52061351/16387 */
const disabledStyle = {
  pointerEvents: "none",
};

export const PageNavLink = React.forwardRef(
  /**
   * @typedef {import("@material-ui/core").LinkProps} LinkProps
   * @typedef {import("react-router-dom").NavLinkProps} RouterNavLinkProps
   * @typedef {object} PageNavLinkProps
   * @property {boolean} [disabled]
   * @property {PageDefinition} [page]
   *
   * @param {LinkProps & RouterNavLinkProps & PageNavLinkProps} param0
   */
  function PageNavLink(
    { children, className, disabled, page, style, to, ...rest },
    ref,
  ) {
    if (page) {
      to = page.path;
    }
    if (disabled) {
      style = {
        ...style,
        ...disabledStyle,
      };
    }
    return (
      <Link
        className={className}
        component={NavLink}
        disabled={disabled}
        ref={ref}
        style={style}
        to={to}
        activeClassName="active"
        exact
        underline="none"
        {...rest}
      >
        {children}
      </Link>
    );
  },
);
