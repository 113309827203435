import React, { Suspense } from "react";
import {
  Box,
  Button,
  DialogContent,
  InputAdornment,
  makeStyles,
  Dialog,
  DialogContentText,
} from "@material-ui/core";
// import clsx from "clsx";
// Local
import {
  AutocompleteField,
  CancelSaveDialogActions,
  DateField,
  Field,
  NumberField,
  PhoneField,
  RadioButtonsField,
  SelectField,
  SelectStateField,
  SimpleDialog,
} from "../components";
// import { APPRAISAL_APP_URL } from "../config";
// Local
import vinPng from "../assets/images/vin.png";

import {
  //
  Form,
  Navigation,
  handleKeys,
  useFormik,
  yup,
} from "../lib";
import { Appraisals } from "../services";
import {
  appraisalActions,
  // authSelectors,
  useAction,
  useDealer,
  // useSelector,
} from "../state";
import { useMobile } from "../themes";
const VideoDecode = React.lazy(() => import("./VideoDecode"));

const useStyles = makeStyles(
  theme => ({
    content: {
      // Cause scrollbars to always be present.
      minHeight: "100%",

      "& h6": {
        marginTop: 0,
        fontSize: 16,
        fontWeight: 500,
      },
    },
    flexRight: {
      marginLeft: 8,
    },
    flexRightLg: {
      marginLeft: 8,
      [theme.breakpoints.down("sm")]: {
        marginLeft: "unset",
        marginTop: 16,
      },
    },
    selectState: {
      maxWidth: 140,
    },
    selectStateLg: {
      maxWidth: 140,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "unset",
      },
    },
    ownerStatusId: {
      "& .MuiFormLabel-root": {
        color: "#141932",
        fontSize: 14,
        fontWeight: 600,
      },
      "& .MuiFormControlLabel-label": {
        fontSize: 14,
      },
    },
    zip: {
      maxWidth: 140,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "unset",
      },
    },
    sendInspect: {
      marginRight: "auto",
      minWidth: 180,
    },
  }),
  {
    classNamePrefix: "NewAppraisalDialog",
  },
);

/** @type {Record<Appraisals.TypeIdCode,string>} */
const buttonLabel = {
  tradein: "Start showroom trade-in",
  lease: "Start showroom lease return",
};

/** @type {Appraisals.NewValues} */
const defaultValues = {
  plate: "",
  plateState: "",
  vin: "",
  trim: "",
  vehicle: {
    year: 0,
    make: "",
    model: "",
    trim: "",
    subSeries: "",
  },
  vehicles: [],
  mileage: "",
  ownerStatusId: null,
  // Bank details
  financeBank: "",
  financeBankNum: "",
  maturityDate: null,
  // Customer details
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  // Customer address
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",

  // User preferences

  sendInspect: "email",
};

/** @type {Record<Appraisals.TypeIdCode,string>} */
const dialogTitle = {
  tradein: "Trade in",
  lease: "Lease return",
};

const validationSchema = {
  full: yup.object({
    vin: yup
      .string()
      .required(
        "Please enter a VIN, or use License Plate + State to find one.",
      ),
    trim: yup.string().required("A trim is required."),
    email: yup.string().email(),
    mileage: yup.number().required("Mileage is required."),
    ownerStatusId: yup.number().nullable(),
  }),
  minimal: yup.object({
    email: yup.string().email(),
    mileage: yup.number(),
    ownerStatusId: yup.number().nullable(),
  }),
};

const ownerStatusItems = [
  { id: 1, title: "Paid off" },
  { id: 2, title: "Finance" },
  { id: 3, title: "Lease" },
];

const sendInspectItems = [
  { id: "email", title: "email" },
  { id: "phone", title: "phone" },
  { id: "email_phone", title: "email & phone" },
];

const sendInspectItemsNone = [
  ...sendInspectItems,
  { id: "none", title: "none" },
];
/** @param {import("@material-ui/core").BoxProps} props */
function Section(props) {
  return <Box py="8px" {...props} />;
}
/** @param {import("@material-ui/core").BoxProps} props */
function SectionLabel(props) {
  return (
    <Box
      color="#141932"
      fontSize="14px"
      fontWeight="600"
      pb="16px"
      {...props}
    />
  );
}

export {
  defaultValues as NewAppraisalDefaults,
  dialogTitle as NewAppraisalDialogTitle,
};

export const NewAppraisalDialog = React.memo(
  /**
   * @param {{
   * customerNoContact?: boolean;
   * defaults?: Appraisals.NewValues;
   * onClose:Function;
   * onSubmit:(values:Appraisals.NewValues) => Promise<void>;
   * renderActions?:(props:{loading:boolean;})=>React.ReactNode;
   * title?: string;
   * titlePrefix?: string;
   * typeCode:Appraisals.TypeCode;
   * // typeIdCode?: Appraisals.TypeIdCode;
   * validation:"full"|"minimal";
   * isService?:boolean;
   * }} param0
   */
  function NewAppraisalDialog({
    defaults = defaultValues,
    isService,
    onClose,
    onSubmit,
    renderActions,
    typeCode,
    typeIdCode = Appraisals.typeIdCode(typeCode),
    customerNoContact = isService || typeCode === "showroom_tradein",
    titlePrefix = "New ",
    title = titlePrefix +
      (isService ? "Service Appraisal" : dialogTitle[typeIdCode]),
    validation = "full",
  }) {
    const classes = useStyles();
    const isMobile = useMobile();

    const [busy, setBusy] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState(defaults);
    const [vehicleInfo, setVehicleInfo] = React.useState({ text: "" });
    const [openDialog, setOpenDialog] = React.useState(false);
    const isLandscape = () =>
        window.matchMedia("(orientation:landscape)").matches,
      [orientation, setOrientation] = React.useState(
        isLandscape() ? "landscape" : "portrait",
      ),
      onWindowResize = () => {
        clearTimeout(window.resizeLag);
        window.resizeLag = setTimeout(() => {
          delete window.resizeLag;
          setOrientation(isLandscape() ? "landscape" : "portrait");
        }, 200);
      };

    React.useEffect(
      () => (
        // eslint-disable-next-line no-sequences
        onWindowResize(),
        window.addEventListener("resize", onWindowResize),
        () => window.removeEventListener("resize", onWindowResize)
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );
    const getBanks = useAction(appraisalActions.getBanks);
    const getVinDetails = useAction(appraisalActions.getVinDetails);
    const getVinFromPlate = useAction(appraisalActions.getVinFromPlate);

    const fullValidation = validation === "full";

    React.useEffect(() => {
      const ls = window.localStorage;
      const prefs = ls.getItem("newappr");
      if (!prefs) {
        return;
      }
      const values = JSON.parse(prefs);
      setInitialValues({
        ...defaultValues,
        ...defaults,
        ...values,
      });
    }, [defaults]);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: validationSchema[validation],
      async onSubmit(values, helpers) {
        setBusy(true);
        try {
          // console.log("SUBMIT", values);
          let prefs;
          if (values.plateState) {
            prefs = { ...prefs, plateState: values.plateState };
          }
          if (values.sendInspect) {
            prefs = { ...prefs, sendInspect: values.sendInspect };
          }
          if (prefs) {
            window.localStorage.setItem("newappr", JSON.stringify(prefs));
          }
          await onSubmit(values);
        } catch (err) {
          console.log("" + err);
          window.alert("There was an error. Please try again.");
          setBusy(false);
        }
      },
    });
    const { isSubmitting, getFieldHelpers, values } = frm;
    const loading = busy || isSubmitting;
    const { vehicles } = values;

    const { decodeVin } = React.useMemo(() => {
      function decodeVin(vin) {
        // console.log("CHANGED VIN", vin);
        const trimFld = getFieldHelpers("trim");
        const vehiclesFld = getFieldHelpers("vehicles");
        trimFld.setValue("");
        if (!vin || vin.length < 11) {
          return;
        }
        setVehicleInfo({ text: "Searching for vehicle..." });
        getVinDetails(vin)
          .then(result => {
            const { vehicles = [] } = result;
            // console.log("CHANGED VEHICLES", vehicles);
            vehiclesFld.setValue(vehicles);
            trimFld.setValue(vehicles[0]?.trim ?? "");
            // setFieldValue("vehicle", vehicles[0] ?? defaultValues.vehicle);
            if (vehicles.length < 1) {
              setVehicleInfo({
                err: true,
                text: "Vehicle not found.",
              });
            }
          })
          .catch(() => {
            setVehicleInfo({
              err: true,
              text: "Error finding vehicle for this VIN.",
            });
          });
      }
      return {
        decodeVin,
      };
    }, [getVinDetails, getFieldHelpers]);

    const onClickFindVin = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      e => {
        const plate = values.plate.trim();
        const plateState = values.plateState.trim();
        // console.log("CHANGED PST", plate, plateState);
        if (!plate || !plateState || plate.length < 4) {
          return;
        }
        const vinFld = getFieldHelpers("vin");
        vinFld.setValue("");
        setVehicleInfo({ text: "Searching for VIN..." });
        getVinFromPlate({
          plate,
          state: plateState,
        })
          .then(result => {
            const vin = result.vin ?? "";
            vinFld.setValue(vin);
            if (!vin) {
              setVehicleInfo({ err: true, text: "Vehicle not found." });
            } else {
              decodeVin(vin);
            }
          })
          .catch(() => {
            setVehicleInfo({ err: true, text: "Vehicle not found." });
          });
      },
      [
        values.plate,
        values.plateState,
        decodeVin,
        getVinFromPlate,
        getFieldHelpers,
      ],
    );

    const onClickDecodeVin = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      e => {
        decodeVin(values.vin);
      },
      [values.vin, decodeVin],
    );

    const { onEnterFindVin, onEnterDecodeVin } = React.useMemo(() => {
      return {
        onEnterFindVin(e) {
          handleKeys(e, onClickFindVin, "Enter");
        },
        onEnterDecodeVin(e) {
          handleKeys(e, onClickDecodeVin, "Enter");
        },
      };
    }, [onClickFindVin, onClickDecodeVin]);

    // When trim changes, update vehicle.
    React.useEffect(() => {
      const trim = values.trim;
      /** @type {VehicleFromVin | undefined} */
      const vehicle = vehicles.find(it => it.trim === trim);
      const vehicleFld = getFieldHelpers("vehicle");
      if (vehicle) {
        vehicleFld.setValue(vehicle ?? defaultValues.vehicle);
        setVehicleInfo({
          text: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
        });
      } else {
        vehicleFld.setValue(vehicle);
        setVehicleInfo({
          err: !!trim,
          text: trim ? `Vehicle not found.` : "",
        });
      }
    }, [values.trim, vehicles, getFieldHelpers]);

    const scrollIntoView = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLInputElement>} e */
      e => {
        const el = e.currentTarget;
        if (!el) return;
        el.scrollIntoView({ block: "nearest" });
      },
      [],
    );

    return (
      <SimpleDialog
        className={classes.root}
        fullScreenMobile
        maxWidth="sm"
        onClose={onClose}
        open={true}
        stretchY
        title={title}
      >
        <Form form={frm}>
          <DialogContent className={classes.content}>
            <Box color="rgba(0, 0, 0, 0.58)" mt="-8px" pb="8px">
              <em>
                Click FIND or press {isMobile ? "Go" : "ENTER"} on keyboard
                after entering license plate.
              </em>
            </Box>
            <Section display="flex">
              <SelectStateField
                name="plateState"
                label="State"
                allowEmpty
                autoFocus
                fullWidth
                disabled={loading}
                className={classes.selectState}
              />
              <Field
                name="plate"
                label="License plate"
                allowClear
                fullWidth
                disabled={loading}
                className={classes.flexRight}
                onKeyDown={onEnterFindVin}
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="m0" position="end">
                      <Button
                        color="secondary"
                        tabIndex="-1"
                        title={
                          "Find license plate and decode VIN at once." +
                          " (Click or press ENTER.)"
                        }
                        onClick={onClickFindVin}
                      >
                        FIND
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Section>
            <Section>
              <Field
                name="vin"
                label="VIN"
                allowClear
                fullWidth
                required={fullValidation}
                disabled={loading}
                onKeyDown={onEnterDecodeVin}
                InputProps={{
                  endAdornment: (
                    <>
                      {isMobile && (
                        <InputAdornment className="m0" position="end">
                          <Button
                            color="secondary"
                            tabIndex="-1"
                            title="Scan with camera to get VIN and Trim."
                            onClick={() => {
                              setOpenDialog(true);
                            }}
                          >
                            SCAN
                          </Button>
                        </InputAdornment>
                      )}
                      <InputAdornment className="m0" position="end">
                        <Button
                          color="secondary"
                          tabIndex="-1"
                          title={
                            "Decode the VIN and get a list of Trims." +
                            " (Click or press ENTER.)"
                          }
                          onClick={onClickDecodeVin}
                        >
                          DECODE
                        </Button>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            </Section>
            <Box
              fontSize="18px"
              fontWeight="600"
              color={vehicleInfo.err ? "#cc0000" : undefined}
              pt="24px"
              pb="32px"
            >
              {vehicleInfo.text || <>Enter a license plate or VIN.</>}
            </Box>
            <Section display={isMobile ? undefined : "flex"}>
              <SelectField
                name="trim"
                label="Trim"
                className={classes.flexLeft}
                items={vehicles}
                fullWidth
                itemKey="trim"
                itemText="trim"
                required={fullValidation}
                disabled={loading}
              />
              <NumberField
                name="mileage"
                label="Exact mileage"
                className={classes.flexRightLg}
                allowClear
                fullWidth
                required={fullValidation}
                disabled={loading}
              />
            </Section>
            {typeIdCode !== "lease" && (
              <Box pt="32px">
                <RadioButtonsField
                  name="ownerStatusId"
                  label="Who owns the vehicle?"
                  className={classes.ownerStatusId}
                  items={ownerStatusItems}
                  format={parseInt}
                  fullWidth
                  horizontal
                  disabled={loading}
                />
              </Box>
            )}
            {(values.ownerStatusId > 1 || typeIdCode === "lease") && (
              <Box pt="26px">
                <SectionLabel>Bank details</SectionLabel>
                <Section>
                  <AutocompleteField
                    id="finance-bank"
                    getOptions={getBanks}
                    label="Bank name"
                    name="financeBank"
                    optionsValueKey="name"
                    freeSolo
                    fullWidth
                    disabled={loading}
                    onFocus={scrollIntoView}
                  />
                </Section>
                <Section display={isMobile ? undefined : "flex"}>
                  <Field
                    name="financeBankNum"
                    label="Last 4 of SSN or Acct #"
                    className={classes.flexLeft}
                    allowClear
                    fullWidth
                    disabled={loading}
                    onFocus={scrollIntoView}
                  />
                  <DateField
                    name="maturityDate"
                    label="Maturity Date"
                    className={classes.flexRightLg}
                    fullWidth
                    disabled={loading}
                    onFocus={scrollIntoView}
                  />
                </Section>
              </Box>
            )}
            <Box pt="24px">
              <SectionLabel pb="8px">Customer info</SectionLabel>
              {customerNoContact && (
                <Box
                  color="rgba(0, 0, 0, 0.58)"
                  fontSize="12px"
                  fontWeight="400"
                  pb="16px"
                >
                  NOTE: Enter actual customer info, they{" "}
                  <em>
                    <u>WILL NOT</u>
                  </em>{" "}
                  be contacted automatically.
                </Box>
              )}
              <Section display={isMobile ? undefined : "flex"}>
                <Field
                  name="firstName"
                  label="First name"
                  allowClear
                  fullWidth
                  disabled={loading}
                  onFocus={scrollIntoView}
                />
                <Field
                  name="lastName"
                  label="Last name"
                  className={classes.flexRightLg}
                  allowClear
                  fullWidth
                  disabled={loading}
                  onFocus={scrollIntoView}
                />
              </Section>
              <Section display={isMobile ? undefined : "flex"}>
                <Field
                  name="email"
                  label="Email"
                  allowClear
                  fullWidth
                  type="email"
                  disabled={loading}
                  onFocus={scrollIntoView}
                />
                <PhoneField
                  name="phone"
                  label="Phone Number"
                  className={classes.flexRightLg}
                  allowClear
                  fullWidth
                  disabled={loading}
                  onFocus={scrollIntoView}
                />
              </Section>
            </Box>
            {typeIdCode === "lease" && (
              <Box pt="24px">
                <SectionLabel>Customer address</SectionLabel>
                <Section>
                  <Field
                    name="address1"
                    label="Address 1"
                    allowClear
                    fullWidth
                    disabled={loading}
                    onFocus={scrollIntoView}
                  />
                </Section>
                <Section>
                  <Field
                    name="address2"
                    label="Address 2"
                    allowClear
                    fullWidth
                    disabled={loading}
                    onFocus={scrollIntoView}
                  />
                </Section>
                <Section display={isMobile ? undefined : "flex"}>
                  <Field
                    name="city"
                    label="City"
                    allowClear
                    fullWidth
                    disabled={loading}
                    onFocus={scrollIntoView}
                  />
                  <SelectStateField
                    name="state"
                    label="State"
                    className={`${classes.selectStateLg} ${classes.flexRightLg}`}
                    disabled={loading}
                    onFocus={scrollIntoView}
                  />
                  <Field
                    name="zip"
                    label="Zip"
                    className={`${classes.zip} ${classes.flexRightLg}`}
                    allowClear
                    pattern="[0-9]*"
                    inputMode="numeric"
                    disabled={loading}
                    onFocus={scrollIntoView}
                  />
                </Section>
              </Box>
            )}
          </DialogContent>
          {renderActions ? (
            renderActions({ loading })
          ) : (
            <CancelSaveDialogActions
              disabled={loading}
              // onCancel={onClose}
              saveLabel="Start"
              topBorder
            >
              <SelectField
                name="sendInspect"
                label="Send inspection to my"
                className={classes.sendInspect}
                items={isService ? sendInspectItemsNone : sendInspectItems}
                // variant="standard"
                // disableUnderline
                disabled={loading}
              />
            </CancelSaveDialogActions>
          )}
        </Form>

        <Dialog
          onClose={() => setOpenDialog(false)}
          aria-labelledby="simple-dialog-title"
          open={openDialog}
          fullWidth={true}
        >
          <DialogContent>
            <Suspense fallback={<div>Opening camera...</div>}>
              {orientation === "portrait" && (
                <>
                  <DialogContentText>
                    Scan your VIN barcode or QR code. Usually located in the
                    drivers side door.
                  </DialogContentText>
                  <img src={vinPng} alt="" style={{ minWidth: "100%" }} />
                </>
              )}

              <VideoDecode
                setOpenDialog={setOpenDialog}
                frm={frm}
                decodeVin={decodeVin}
              />
            </Suspense>
          </DialogContent>
        </Dialog>
      </SimpleDialog>
    );
  },
);

export const NewAppraisalButton = React.memo(
  /**
   * @param {{children:React.ReactNode,className:string,type:Appraisals.TypeIdCode,isService?:boolean}} param0
   */
  function NewAppraisalButton({
    children,
    className,
    isService,
    type: typeIdCode,
  }) {
    if (isService) {
      typeIdCode = "tradein";
    }
    const isMobile = useMobile();

    const dealer = useDealer();

    const [editing, setEditing] = React.useState(false);
    const onClick = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (e && e.preventDefault) e.preventDefault();
        setEditing(true);
      },
      [],
    );
    const onClose = React.useCallback(
      /**
       * @type {import("@material-ui/core").ModalProps['onClose']}
       */
      (e, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        setEditing(false);
      },
      [],
    );

    const onSubmit = React.useCallback(
      async values => {
        const res = await Appraisals.startNew({
          typeCode: typeIdCode,
          service: !!isService,
          ...values,
        });

        if (isMobile) {
          window.location.href = res.inspectionUrl;
        } else {
          requestAnimationFrame(() => {
            // Navigate after onClose completes...
            Navigation.go(`/${dealer.slug}/appraisals/${res.id}`);
          });
        }
        onClose();
      },
      [isMobile, isService, dealer, typeIdCode, onClose],
    );

    return (
      <>
        <Button className={className} onClick={onClick}>
          {children ?? buttonLabel[typeIdCode]}
        </Button>
        {editing && (
          <NewAppraisalDialog
            customerNoContact
            isService={isService}
            onClose={onClose}
            onSubmit={onSubmit}
            // typeCode={isService ? "service_tradein" : typeIdCode}
            typeCode={typeIdCode}
          />
        )}
      </>
    );
  },
);
