import { NewServiceAppraisalPage } from "./NewServiceAppraisalPage";

export const AnonPages = {
  /** @type {PageDefinition} */
  newServiceAppraisal: {
    anon: true,
    path: "/:dslug/open/service",
    pathExact: true,
    view: NewServiceAppraisalPage,
  },
};
