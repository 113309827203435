import React from "react";
import {
  Box,
  // Button,
  DialogContent,
  // Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
// Local
import {
  CancelSaveDialogActions,
  CheckboxField,
  Field,
  // DollarField,
  NumberField,
  SelectField,
  SimpleDialog,
  useBasicDialog,
} from "../../../components";
import {
  //
  Form,
  formatDateTime,
  // formatDecimal,
  mapFormDefaults,
  useFormik,
  yup,
} from "../../../lib";
import { dealerApi } from "../../../state";

const useStyles = makeStyles(
  theme => ({
    // root: {
    //   "& .MuiDialog-paper": {
    //     width: 800,
    //     minHeight: 600,
    //   },
    //   "& .MuiDialogContent-root": {
    //     paddingTop: 24,
    //     paddingRight: 24,
    //     paddingLeft: 24,
    //     paddingBottom: 8,
    //   },
    //   "& .MuiFormControl-root": {
    //     marginTop: 24,
    //   },
    //   "& .MuiDialogActions-root": {
    //     paddingBottom: 24,
    //     paddingLeft: 24,
    //     paddingRight: 24,
    //   },
    //   [theme.breakpoints.down("xs")]: {
    //     "& .MuiDialog-paper": {
    //       minWidth: "100vw",
    //       width: "100vw",
    //     },
    //     "& .MuiDialogContent-root": {
    //       paddingTop: 8,
    //       paddingRight: 8,
    //       paddingLeft: 8,
    //       paddingBottom: 2,
    //     },
    //     "& .MuiDialogActions-root": {
    //       paddingBottom: 8,
    //       paddingLeft: 8,
    //       paddingRight: 8,
    //     },
    //   },
    // },
    autoOffers: {
      display: "flex",
    },
    note: {
      color: "rgba(0, 0, 0, 0.6)",
      paddingTop: 12,
      paddingBottom: 12,
      // marginBottom: -8,
    },
    changed: {
      paddingTop: 32,
      [theme.breakpoints.down("xs")]: {
        // Added vertical space for mobile keyboard.
        paddingBottom: 36,
      },
    },
  }),
  {
    classNamePrefix: "DealerCampaignEditor",
  },
);

const defaultValues = {
  name: "",
  typeId: null,
  rules: {
    appraisalsToCRM: false,
  },
};

const validationSchema = yup.object({
  name: yup.string().min(3).required("Campaign name is required"),
  typeId: yup.number().nullable(),
});

const valuationTypes = [
  { id: "galves", name: "Galves" },
  { id: "mmr", name: "Manheim (MMR)" },
  { id: "jdp", name: "JD Powers" },
];

export const DealerCampaignEditor = React.memo(
  /**
   *
   */
  function DealerCampaignEditor({ item, onClose }) {
    const classes = useStyles();
    // const dealer = useDealer();
    const { basicDialog, confirmAsync } = useBasicDialog();

    /** @type {[DealerCampaign]} */
    const [initialValues, setInitialValues] = React.useState(defaultValues);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      // validateOnBlur: false,
      validateOnChange: false,
      validationSchema,
      async onSubmit(values, helpers) {
        // console.log("SAVING...", values);
        const row = { id: item.id, ...values };
        if (row.rules && row.rules.autoOffers?.length > 0) {
          const autoOffer = row.rules.autoOffers[0];
          if (!autoOffer.p1) {
            delete row.rules.autoOffers;
          } else {
            autoOffer.on = "appsubmit";
          }
        }
        // console.log("-", row);
        await dealerApi.saveCampaign(row);
        setInitialValues(values);
        onClose();
      },
    });

    const onClickDelete = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      async e => {
        // if (!window.confirm("Are you sure?")) {
        //   return;
        // }
        if (!(await confirmAsync("Are you sure?"))) {
          return;
        }

        await dealerApi.removeCampaign(item.id);
        onClose();
      },
      [item, confirmAsync, onClose],
    );

    React.useEffect(() => {
      if (!item || item.id === 0) {
        return;
      }
      const state = { mounted: true };
      (async function onDealerChanged() {
        setInitialValues(defaultValues);
        const values = await dealerApi.getCampaign(item.id);
        if (!state.mounted) return;
        setInitialValues(mapFormDefaults(defaultValues, values));
      })();
      return () => {
        state.mounted = false;
      };
    }, [item]);

    const { values } = frm;
    const autoOfferDisabled = !values.rules?.autoOffers?.[0]?.enabled;
    const isNew = !item || item.id === 0;

    return (
      <SimpleDialog
        maxWidth="sm"
        onClose={onClose}
        open={true}
        title={`${!item?.id ? "Add" : "Edit"} campaign`}
      >
        <Form form={frm}>
          <DialogContent>
            <Field
              name="name"
              label="Campaign name"
              autoFocus
              fullWidth
              disabled={frm.isSubmitting}
            />
            <Box mt="24px">
              <SelectField
                emptyEnabled
                name="typeId"
                label="Type"
                fullWidth
                items={dealerApi.campaignTypes}
                itemText="name"
                disabled={frm.isSubmitting}
                shrinkLabel={!!frm.values.typeId}
                variant="outlined"
                helperText={
                  isNew ? undefined : (
                    <Box component="span" color="rgb(198, 40, 40)">
                      NOTE: Changing Type or{" "}
                      {values.rules?.autoOffers?.[0]?.enabled
                        ? "disabling"
                        : "enabling"}{" "}
                      Automatic offers will cause links and QR Codes to change,
                      requiring an update to your marketing materials.
                    </Box>
                  )
                }
              />
            </Box>
            <CheckboxField
              name="rules.appraisalsToCRM"
              label="Add new Appraisals to CRM"
              fullWidth
            />

            <CheckboxField
              name="rules.autoOffers[0].enabled"
              label="Send automatic offer when appraisal is submitted"
              fullWidth
            />
            <div className={classes.autoOffers}>
              <NumberField
                name="rules.autoOffers[0].p1"
                label="Offer %"
                // defaultValue={90}
                // helperText="Enter a percentage from 1 - 100."
                margin="dense"
                disabled={autoOfferDisabled}
              />
              <SelectField
                name="rules.autoOffers[0].p1v"
                label="Valuation"
                items={valuationTypes}
                itemText="name"
                // defaultValue="mmr"
                // helperText="Choose a valuation type for the Offer %."
                margin="dense"
                fullWidth
                disabled={autoOfferDisabled}
              />
            </div>
            <Typography
              component="div"
              variant="body2"
              className={classes.note}
            >
              Optionally, also enter an Upper % to send a <em>ranged offer</em>
              ...
            </Typography>
            <div className={classes.autoOffers}>
              <NumberField
                name="rules.autoOffers[0].p2"
                label="Upper %"
                // defaultValue={0}
                // helperText="Enter a percentage from 1 - 100."
                margin="dense"
                disabled={autoOfferDisabled}
              />
              <SelectField
                name="rules.autoOffers[0].p2v"
                label="Upper valuation"
                items={valuationTypes}
                itemText="name"
                displayEmpty
                // helperText="Choose a valuation type for the Upper %."
                margin="dense"
                fullWidth
                disabled={autoOfferDisabled}
              />
            </div>

            {item?.updatedAt && (
              <div className={classes.changed}>
                Last change: {formatDateTime(item.updatedAt)}
              </div>
            )}
          </DialogContent>
          <CancelSaveDialogActions
            disabled={frm.isSubmitting}
            saveDisabled={!frm.dirty}
            onCancel={onClose}
            onDelete={onClickDelete}
          />
        </Form>
        {basicDialog}
      </SimpleDialog>
    );
  },
);
