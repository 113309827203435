import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    root: {
      alignItems: "flex-start",
      // borderTop: "1px solid #DADFF6",
      display: "flex",
      minHeight: 2048,
      maxWidth: 1310,
      paddingBottom: 120,
      [theme.breakpoints.down("xs")]: {
        display: "block",
        minHeight: "unset",
      },
    },
    main: {
      position: "sticky",
      top: -400,
      padding: "24px 48px 0 20px",
      [theme.breakpoints.down("xs")]: {
        position: "unset",
        top: "unset",
        padding: "24px 0 0 0",
      },
    },
    setup: {
      alignItems: "flex-end",
      display: "flex",
      flexWrap: "wrap",
      gap: 16,
      justifyContent: "space-between",
      paddingTop: 8,
      paddingBottom: 40,
      "& .MuiFormControl-root": {
        marginBottom: 0,
      },
      flex: 1,
      [theme.breakpoints.down("xs")]: {
        "& .MuiFormLabel-root": {
          fontSize: 12,
          fontWeight: 500,
        },
        "& > div:nth-child(1)": {
          flex: 1,
          padding: "0 8px 16px 0",
        },
        "& > div:nth-child(2)": {
          flex: 1,
          padding: "0 0 16px 8px",
        },
      },
    },
    equityText: {
      color: "#FFF",
      fontSize: 20,
      fontWeight: 600,
      paddingTop: 4,
    },
    equityBox: {
      // Child 3 is a filled block, padding doesn't separate from siblings.
      backgroundColor: "#192740",
      borderRadius: 3,
      color: "#BBC2E1",
      flex: 1,
      fontSize: 12,
      height: 70,
      padding: "6px 0 0 16px",
      minWidth: "175px",
      [theme.breakpoints.down("xs")]: {
        flex: 2,
        flexBasis: "100%",
      },
    },
    feeText: {
      color: "#000",
      fontSize: 20,
      fontWeight: 600,
      paddingTop: 4,
    },
    feesAndCostBox: {
      // Child 3 is a filled block, padding doesn't separate from siblings.
      backgroundColor: "#ECEFF5",
      borderRadius: 3,
      color: "#8D98C2",
      flex: 1,
      fontSize: 12,
      height: 70,
      padding: "6px 0 0 16px",
      minWidth: "175px",
      [theme.breakpoints.down("xs")]: {
        flex: 2,
        flexBasis: "100%",
      },
    },
    boxContainer: {
      width: "365px",
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      gap: 8,
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    icon: {
      position: "absolute",
      top: "-20%",
      left: "160px",
      zIndex: "9999",
      backgroundColor: "#fff",
    },
    equityNote: {
      display: "none",
      // [theme.breakpoints.down("xs")]: {
      //   backgroundColor: "rgba(0,0,0,0.72)",
      //   display: "block",
      //   fontSize: 12,
      //   color: "#FFFFFF",
      //   margin: "-6px 0 0 34%",
      //   width: 213,
      //   letterSpacing: 0,
      // },
    },
    offers: {
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "space-between",
      margin: "0 -12px",
      minWidth: 774,
      padding: "0 12px 40px 12px",
      [theme.breakpoints.down("xs")]: {
        display: "block",
        minWidth: "unset",
        padding: "0 24px 12px 24px",
        margin: "0 -24px",
      },
    },
    actions: {
      display: "flex",
      paddingTop: 40,
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    savedStatus: {
      alignItems: "center",
      color: "#00D289",
      display: "flex",
      fontSize: 14,
      height: 48,
      justifyContent: "center",
      paddingBottom: 8,
      width: 150,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    label: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  }),
  {
    classNamePrefix: "AppraisalWorksheetTab",
  },
);

export const useOfferBoxStyles = makeStyles(
  theme => ({
    root: {
      boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
      display: "flex",
      flexDirection: "column",
      height: 438,
      padding: "16px 32px",
      // width: 397,
      flex: 1,
      "&:nth-child(1)": {
        marginRight: 20,
      },
      "&:nth-child(2)": {
        marginLeft: 20,
      },
      [theme.breakpoints.down("xs")]: {
        width: "unset",
        "&:nth-child(1)": {
          marginBottom: 24,
          marginRight: "unset",
        },
        "&:nth-child(2)": {
          marginBottom: 24,
          marginLeft: "unset",
        },
      },
    },
    title: {
      color: "#1F2834",
      fontSize: 20,
      fontWeight: "bold",
      paddingBottom: 24,
    },
    titleAdd: {
      // color: "rgba(0,0,0,0.4)",
      color: "#8D98C2",
      fontWeight: 400,
    },
    iconContainer: {
      position: "relative",
      paddingBottom: 24,
      "& > div:first-child": {
        backgroundColor: "#fff",
        position: "absolute",
        zIndex: "9999",
      },
    },
    valuationIcon: {
      top: "-17%",
      left: "26%",
    },
    offerIcon: {
      top: "-22%",
      left: "24%",
    },
  }),
  {
    classNamePrefix: "OfferBox",
  },
);
