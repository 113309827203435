import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Local
import type { AppraisalStageId } from "../appraisals/types";

const initialState: PrefState = {
  menu: true,
  appraisals: {
    collapsed: {},
  },
  details: {
    collapsed: {},
  },
};

export const pref = {
  ...createSlice({
    name: "pref",
    initialState,
    reducers: {
      setMenu(state, { payload }: PayloadAction<boolean>) {
        state.menu = payload;
      },
      toggleAppraisalsCollapsed(
        state,
        { payload }: PayloadAction<AppraisalStageId>,
      ) {
        if (!state.appraisals) {
          state.appraisals = {
            collapsed: {},
          };
        }
        state.appraisals.collapsed[payload] =
          !state.appraisals?.collapsed[payload];
      },
      toggleDetailsCollapsed(state, { payload }: PayloadAction<string>) {
        if (!state.details) {
          state.details = {
            collapsed: {},
          };
        }
        state.details.collapsed[payload] = !state.details?.collapsed[payload];
      },
    },
  }),
  persist: true,
};

export interface AppraisalPrefs {
  /** List groups that should be collapsed. */
  collapsed: Partial<Record<AppraisalStageId, boolean>>;
}

export interface PrefState {
  menu: boolean;
  appraisals: AppraisalPrefs;
  details: {
    collapsed: Partial<Record<string, boolean>>;
  };
}
