import React from "react";
import {
  //
  Box,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

// Icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewBoardIcon from "@material-ui/icons/ViewWeek";
import ViewLeaseMgmtIcon from "@material-ui/icons/TurnedIn";
// Local
import { SelectField, PageNavLink } from "../../../components";
import {
  // appraisalActions,
  // appraisalTypeFromPath,
  // appraisalSelectors,
  authSelectors,
  // dealerSelectors,
  // isArchivedPath,
  // isLeaseReturnsPath,
  // isRejectedPath,
  // useAction,
  useDealer,
  useSelector,
} from "../../../state";
import {
  Form,
  useFormik,
  Navigation,
  useLocation,
  isEmpty,
  useDebouncedEffect,
  shallowEqualsObj,
} from "../../../lib";

// import { APPRAISAL_APP_URL } from "../../../config";
// import { FabLogo } from "../components/FabLogo";
// import { AppraisalSearchbar } from "./AppraisalSearchbar";
// import { AppraisalsList } from "./AppraisalsList";

const useStyles = makeStyles(
  theme => ({
    root: {
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: 20,
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
      // [theme.breakpoints.down("xs")]: {
      //   flexDirection: "column",
      // },
    },
    titleArea: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },
    },
    title: {
      [theme.breakpoints.up("md")]: {
        minWidth: 180,
      },
      [theme.breakpoints.down("xs")]: {
        flex: "0 1 100%",
        marginBottom: theme.spacing(2),
        minWidth: 0,
      },
    },
    iconButton: {
      "& .MuiGrid-container": {
        alignItems: "center",
      },
      "& svg": {
        marginRight: 8,
        color: "#BBC2E1",
      },
      "& p": {
        [theme.breakpoints.down("xs")]: {
          fontSize: 12,
        },
      },
      "& .active": {
        "& svg": {
          color: "#4E50FF",
        },
      },
      [theme.breakpoints.down("xs")]: {
        flex: "1 0 25%",
        paddingLeft: 0,
      },
    },
    link: {
      marginLeft: 21,
      transition: "opacity 300ms linear",
      "&:hover": {
        opacity: 0.5,
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    dropdownLabel: {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
    frmGroup: {
      display: "flex",
      alignItems: "flex-end",
      [theme.breakpoints.down("md")]: {
        margin: "12px 0",
        flexDirection: "row-reverse",
        "&>div": {
          padding: 0,
        },
      },
    },
    linkButton: {
      padding: 4,
      marginRight: 2,
      "&>a": {
        textDecoration: "none",
        color: theme.palette.secondary.main,
        fontSize: 16,
        fontWeight: 500,
      },
    },
  }),
  {
    classNamePrefix: "AppraisalsListHeader",
  },
);
const dashAppTypes = [
  { id: "returns", title: "Lease returns" },
  { id: "trade", title: "Trade ins" },
];
const dashSubTypes = [
  { id: "buying", title: "Buying" },
  { id: "inspected", title: "Inspected" },
  { id: "submitted", title: "Submitted" },
];
const dashTradeInSubTypes = [
  ...dashSubTypes,
  { id: "offers-accepted", title: "Offers accepted" },
  { id: "offers-sent", title: "Offers sent" },
];
const dashReturnSubTypes = [
  ...dashSubTypes,
  { id: "grounding", title: "Grounding" },
];
export const AppraisalsListHeader = React.memo(function AppraisalsListHeader({
  isArchived,
  isLeaseReturns,
  isLeaseReturnsMgmt,
  isRejected,
  isTradeins,
  isService,
  isShowroom,
  isDuplicate,
  isDealerStats,
}) {
  const classes = useStyles();
  const isAdminOrFinMgr = useSelector(authSelectors.isAdminOrFinanceManager);
  const isAdminOrSalesAcqMgr = useSelector(
    authSelectors.isAdminOrSalesAcqManager,
  );
  const isGroupAdmin = useSelector(authSelectors.isGroupAdmin);

  const dealer = useDealer();
  const { pathname, params, query } = useLocation();
  const defaultValues = {
    appType: params.appType ?? "trade",
    subType: params.subType ?? "buying",
  };

  const [initialValues, setInitialValues] = React.useState(defaultValues);

  /**
   * When we submit the form, change the location query. and the data will reload
   */
  const frm = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    async onSubmit(values, helpers) {
      Navigation.replace(
        `/${params.dslug}/${values.appType}/${values.subType}/dealer-stats`,
        { query },
      );
    },
  });

  useDebouncedEffect(() => {
    if (shallowEqualsObj(frm.initialValues, frm.values)) {
      return;
    }
    frm.submitForm();
  }, [frm]);

  React.useEffect(() => {
    const filter = !isEmpty(params) ? { ...params } : defaultValues;
    if (!shallowEqualsObj(filter, initialValues)) {
      setInitialValues({ ...filter });
    }
  }, [params, defaultValues, initialValues]);
  return (
    <div className={classes.root}>
      <div className={classes.titleArea}>
        <Typography variant="h2" className={classes.title}>
          {isService
            ? "Service"
            : isShowroom
            ? "Showroom"
            : isArchived
            ? "Archived"
            : isLeaseReturnsMgmt
            ? "Lease returns"
            : isLeaseReturns
            ? "Lease returns"
            : isRejected
            ? "Rejected"
            : isDuplicate
            ? `Duplicate submission "${params.appVin || ""}"`
            : isDealerStats
            ? `${dealer.name}'s Dashboard`
            : "Buying center"}
        </Typography>
        {(isLeaseReturns || isTradeins) && (
          // TODO: active class for different color icons for selected option - cg
          <>
            <Box
              pl="40px"
              color="#1F2834"
              display="flex"
              className={`${classes.iconButton}`}
            >
              <PageNavLink
                to={
                  isLeaseReturns
                    ? `/${dealer.slug}/appraisals/returns`
                    : `/${dealer.slug}/appraisals`
                }
                exact
              >
                <Grid container>
                  <ViewListIcon />
                  <Typography>List</Typography>
                </Grid>
              </PageNavLink>
            </Box>
            <Box
              pl="24px"
              color="#1F2834"
              display="flex"
              className={`${classes.iconButton}`}
            >
              <PageNavLink
                to={
                  isLeaseReturns
                    ? `/${dealer.slug}/appraisals/board/returns`
                    : `/${dealer.slug}/appraisals/board`
                }
              >
                <Grid container>
                  <ViewBoardIcon />
                  <Typography>Board</Typography>
                </Grid>
              </PageNavLink>
            </Box>
            {isAdminOrFinMgr && (
              <Box
                pl="24px"
                color="#1F2834"
                display="flex"
                className={`${classes.iconButton}`}
              >
                <PageNavLink
                  to={
                    isLeaseReturns
                      ? `/${dealer.slug}/appraisals/dashboard/returns`
                      : `/${dealer.slug}/appraisals/dashboard`
                  }
                >
                  <Grid container>
                    <DashboardIcon />
                    <Typography>Dashboard</Typography>
                  </Grid>
                </PageNavLink>
              </Box>
            )}
            {isLeaseReturns && isAdminOrSalesAcqMgr && (
              <Box
                pl="24px"
                color="#1F2834"
                display="flex"
                className={`${classes.iconButton}`}
              >
                <PageNavLink to={`/${dealer.slug}/appraisals/manage/returns`}>
                  <Grid container>
                    <ViewLeaseMgmtIcon />
                    <Typography>Management</Typography>
                  </Grid>
                </PageNavLink>
              </Box>
            )}
          </>
        )}
      </div>
      {!isDealerStats ? (
        <div className={classes.links}>
          <PageNavLink
            to={`/${dealer.slug}/appraisals/rejected`}
            color="secondary"
            className={classes.link}
          >
            Rejected
          </PageNavLink>
          &nbsp;
          <PageNavLink
            to={`/${dealer.slug}/appraisals/archived`}
            color="secondary"
            className={classes.link}
          >
            Archived
          </PageNavLink>
        </div>
      ) : (
        <div>
          <Form form={frm} promptOnExit={false} pathname={pathname}>
            <Box className={classes.frmGroup}>
              {isGroupAdmin && (
                <Box className={classes.linkButton}>
                  <Link to="/my/stats">View all stats</Link>
                </Box>
              )}
              <Box ml={2} mr={2}>
                <Typography className={classes.dropdownLabel}>
                  Appraisal type
                </Typography>
                <SelectField
                  name="appType"
                  items={dashAppTypes}
                  size="small"
                  variant="standard"
                  disableUnderline
                  className={classes.filter}
                />
              </Box>
              <Box ml={2}>
                <Typography className={classes.dropdownLabel}>
                  Submission type
                </Typography>
                <SelectField
                  name="subType"
                  items={
                    initialValues.appType === "trade"
                      ? dashTradeInSubTypes
                      : dashReturnSubTypes
                  }
                  size="small"
                  variant="standard"
                  disableUnderline
                  className={classes.filter}
                />
              </Box>
            </Box>
          </Form>
        </div>
      )}
    </div>
  );
});
