import { makeStyles } from "@material-ui/core";
// Local
// import { mainToolbarHeight } from "./MainToolbar.styles";

export const useStyles = makeStyles(
  theme => ({
    // root: {
    //   maxWidth: "100vw",
    //   overflow: "hidden",
    //   width: "100vw",
    // },
    container: {
      display: "flex",
      justifyContent: "center",
      // padding: theme.spacing(5, 4),
      padding: "40px 40px",
      [theme.breakpoints.down("xs")]: {
        padding: "16px 16px",
      },
    },
    content: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      maxWidth: "100%",
    },
    backdrop: {
      // backgroundColor: "transparent",
      zIndex: theme.zIndex.modal + 100,
    },
  }),
  {
    classNamePrefix: "MainLayout",
  },
);
