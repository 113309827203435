import React from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
// Local
import { Form, Navigation, useFormik, useLocation, yup } from "../../lib";
import { Pages } from "../../pages";
import { authActions, useAction } from "../../state";
import { useStyles } from "./Auth.styles";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export const ForgotPasswordPage = React.memo(
  /**
   *
   */
  function ForgotPasswordPage() {
    const classes = useStyles();
    const recoverPassword = useAction(authActions.recoverPassword);
    const location = useLocation();

    const frm = useFormik({
      initialValues: {
        email: location.query.email || "",
      },
      validationSchema,
      async onSubmit(values) {
        await recoverPassword(values);
        Navigation.replace(Pages.auth.recoverySent, {
          query: {
            email: values.email,
          },
        });
      },
    });

    return (
      <div className={classes.root}>
        <Box mb="8px">
          <Typography variant="h1"> Reset password</Typography>
        </Box>
        <Box mb="8px">
          <Typography variant="subtitle1">
            Don’t worry, happens to the best of us. Enter your email we’ll send
            you a link to reset your password.
          </Typography>
        </Box>
        <Form form={frm} className={classes.form}>
          <TextField
            autoFocus
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={frm.values.email}
            onChange={frm.handleChange}
            error={frm.touched.email && Boolean(frm.errors.email)}
            helperText={frm.touched.email && frm.errors.email}
          />
          <Box pt="40px">
            <Button
              variant="contained"
              type="submit"
              disabled={frm.isSubmitting}
              fullWidth
              color="secondary"
            >
              Email me a reset link
            </Button>
          </Box>
        </Form>
      </div>
    );
  },
);
