import React from "react";
import {
  //
  Button,
  makeStyles,
} from "@material-ui/core";
// Local
import { AutocompleteField } from "../components";
import { selectAllTarget } from "../lib";
import {
  //
  Form,
  useFormik,
  // yup,
} from "../lib";
import { appraisalActions, useAction } from "../state";

const useStyles = makeStyles(
  theme => ({
    root: {
      flex: 1,
      minWidth: 280,
      marginLeft: -24,
      marginRight: -20,
      padding: 8,
    },
    field: {},
    buttons: {
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: 4,
      "& > button": {
        margin: 0,
        marginRight: 12,
        padding: 4,
      },
    },
  }),
  {
    classNamePrefix: "InlineAppraisalBankForm",
  },
);

const DefaultFieldProps = {
  /** @type {import("@material-ui/core").TextFieldProps} */
  TextFieldProps: {
    autoFocus: true,
    label: "Bank",
    onFocus: e => {
      const { target } = e;
      requestAnimationFrame(() => {
        selectAllTarget({ target });
      });
    },
    variant: "standard",
  },
};

export const InlineAppraisalBankForm = React.memo(
  /**
   * @typedef {import("@material-ui/lab").AutocompleteProps} AutocompleteProps
   * @typedef {import("../components").AutocompleteFieldProps} AutocompleteFieldProps
   * @typedef {object} InlineAppraisalBankFormProps
   * @property {AutocompleteProps & AutocompleteFieldProps} [FieldProps]
   * @property {string} [className]
   * @property {(values:{financeBank: string })=>void} [onClose]
   * @property {{ financeBank?: string }} values
   *
   * @param {InlineAppraisalBankFormProps} props
   */
  function InlineAppraisalBankForm({
    FieldProps = DefaultFieldProps,
    className = "",
    onClose,
    values: initialValues,
  }) {
    const classes = useStyles();
    const getBanks = useAction(appraisalActions.getBanks);
    const frm = useFormik({
      initialValues,
      validateOnBlur: false,
      validateOnChange: false,
      // validationSchema,
      async onSubmit(values, helpers) {
        if (frm.dirty) {
          onClose({
            financeBank: values.financeBank,
          });
        } else {
          onClose();
        }
      },
    });
    const onClickCancel = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (onClose) {
          onClose();
        }
      },
      [onClose],
    );
    const onKeyUp = React.useCallback(
      /** @param {React.KeyboardEvent<HTMLElement>} e */
      e => {
        if (e.key === "Escape") {
          onClose();
        }
      },
      [onClose],
    );

    return (
      <Form
        className={`${classes.root} ${className}`}
        form={frm}
        onKeyUp={onKeyUp}
      >
        <AutocompleteField
          className={classes.field}
          id="appraisal-bank"
          getOptions={getBanks}
          name="financeBank"
          optionsValueKey="name"
          freeSolo
          fullWidth
          // autoSelect={false}
          // loadingText={null}
          {...FieldProps}
        />
        <div className={classes.buttons}>
          <Button variant="contained" size="small" onClick={onClickCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" size="small">
            OK
          </Button>
        </div>
      </Form>
    );
  },
);
