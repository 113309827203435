import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Local
import { ListState } from "../types";

const initialState: AppraisalsState = {
  dashboardLeaseReturns: {},
  dashboardTradeins: {},
  filter: {},
  list: {
    items: [],
    // page: 1,
    // pagesTotal: 1,
    total: 0,
  },
  // perPage: 25,
  // range: [0, 24],
  sort: "id",
  order: "DESC",
  showing: {},
  // stats: {},
};

export const appraisals = {
  ...createSlice({
    name: "appraisals",
    initialState,
    reducers: {
      applyQueryParams(state, { payload }: PayloadAction<any>) {
        if (payload.filter) {
          state.filter = payload.filter;
        }
        if (payload.sort) {
          state.sort = payload.sort;
        }
        if (payload.order) {
          state.order = payload.order;
        }
        // state.list.page = payload.page
        //   ? parseInt(payload.page)
        //   : initialState.list.page;
        // state.perPage = payload.perPage
        //   ? JSON.parse(payload.perPage)
        //   : initialState.perPage;
      },
      setDashboardLeaseReturns(state, { payload }: PayloadAction<{}>) {
        state.dashboardLeaseReturns = payload;
      },
      setDashboardTradeins(state, { payload }: PayloadAction<{}>) {
        state.dashboardTradeins = payload;
      },
      setFilter(state, { payload }: PayloadAction<{}>) {
        state.filter = payload;
      },
      setList(
        state,
        { payload }: PayloadAction<Partial<ListState<Appraisal>>>,
      ) {
        Object.assign(state.list, payload);
      },
      // setListStats(state, { payload }: PayloadAction<any>) {
      //   Object.assign(state.stats, payload);
      // },
      // setPerPage(state, { payload }: PayloadAction<number>) {
      //   state.perPage = payload;
      // },
      // setRange(state, { payload }: PayloadAction<[number, number]>) {
      //   state.range = payload;
      // },
      setShowing(
        state,
        { payload }: PayloadAction<Partial<ShowingAppraisalState>>,
      ) {
        Object.assign(state.showing, payload);
      },
      changeState(state, { payload }) {
        Object.keys(payload).forEach(key => (state[key] = payload[key]));
      },
      updateShowing(
        state,
        { payload }: PayloadAction<Partial<ShowingAppraisalState>>,
      ) {
        Object.keys(payload).forEach(key => {
          if (!state.showing[key]) {
            state.showing[key] = payload[key];
          } else {
            Object.assign(state.showing[key], payload[key]);
          }
        });
      },
    },
  }),
  persist: false,
};

export interface AppraisalsState {
  dashboardLeaseReturns: AppraisalDashboardData;
  dashboardTradeins: AppraisalDashboardData;
  /** Set of filter properties. */
  filter: any;
  /** List results object. */
  list: {
    items: Appraisal[];
    total: number;
  };
  // list: ListState<Appraisal>;
  // /** Number of items per page. */
  // perPage: number;
  // /** Search range for simple REST dialect [start, length] */
  // range: [number, number];
  /** Sort for simple REST dialect. */
  sort: string;
  order: "ASC" | "DESC";

  showing: ShowingAppraisalState;

  // stats: {
  //   totalCountsByStatus?: {
  //     new: number;
  //     total: number;
  //     buying: number;
  //     grounding: number;
  //     reject: number;
  //     bought: number;
  //   };
  //   listSums?: Record<string, number>;
  // };
}

export interface ShowingAppraisalState {
  appraisal?: Appraisal;
  blackbookValResult?: any;
  mmrValResult?: AppraisalApiResult<MMRData<MMRValuationItem>>;
  jdpValResult?: AppraisalApiResult<JDPValuationResponseData>;
  galvesValResult?: AppraisalApiResult<GalvesValResult>;
  paveInspectResult?: any;
  // TODO: Add lease_returns: any[] docs.
  docs?: { payoff: any[]; photos: any[] };
  activity?: AppraisalActivityItem[];
  messages?: any[];
}

export interface AppraisalActivityItem {
  act: string;
  det: string;
  ts: string;
}

export interface AppraisalDashboardData {
  totalSubmitted?: number;
  totalInspected?: number;
  totalOfferSent?: number;
  totalOfferAccepted?: number;
  totalBuying?: number;
  totalGrounding?: number;
  totalExpectedProfit?: number;
  totalExpectedProfitAvg?: number;
}
