import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  alert: {
    position: "fixed",
    // right under appbar
    top: 61,
  },
}));
interface Props {
  title: string;
  severity: "error" | "info" | "success" | "warning";
}
export function Toast({ title, severity = "success" }: Props) {
  const classes = useStyles();
  return (
    <>
      {title.trim() !== "" ? (
        <Box display={"flex"} justifyContent={"center"}>
          <Alert
            className={classes.alert}
            severity={severity}
            closeText={"close"}
          >
            {title}
          </Alert>
        </Box>
      ) : null}
    </>
  );
}
