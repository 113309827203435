import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const styles = {
  /** @type {React.CSSProperties} */
  root: {
    display: "flex",
    padding: 2,
    // cursor: "default",
  },
  /** @type {React.CSSProperties} */
  icon: {
    color: "#00D289",
    fontSize: 18,
  },
  /** @type {React.CSSProperties} */
  text: {
    color: "#141932",
    fontSize: 16,
    lineHeight: "17px",
    marginLeft: 4,
  },
};

export const CheckLabel = React.memo(
  /**
   *
   */
  function CheckLabel({ children, ...rest }) {
    return (
      <div style={styles.root} {...rest}>
        <CheckCircleIcon style={styles.icon} />
        <span style={styles.text}>{children}</span>
      </div>
    );
  },
);
