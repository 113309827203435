import React from "react";
import {
  //
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
} from "@material-ui/core";
// Icons
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// Local
import {
  //
  CarfaxSnap4P,
  CopyableText,
  NestedMenuItem,
  SelectInput,
  useBasicDialog,
} from "../../../components";
import {
  //
  // Navigation,
  formatDate,
  formatDateTime,
  formatPhone,
  formatThousands,
  useOnMountAsync,
  formatAmountUSD,
} from "../../../lib";
import {
  appraisalActions,
  authSelectors,
  useAction,
  useDealer,
  useLinkedDealers,
  useSelector,
  dealerApi,
} from "../../../state";
import { InlineAppraisalBankForm } from "../../../forms";
import { useMobile } from "../../../themes";
import { AppraisalStageButton } from "../components/AppraisalStageButton";
import { AssignedUsers } from "../components/AssignedUsers";
import { AssignedUsersEditor } from "../components/AssignedUsersEditor";
import { CampaignAvatar } from "../components/CampaignAvatar";
import { RatingInput } from "../components/RatingInput";
import { EditAppraisalContactButton } from "../edit";
import CarSideImg from "../img/CarSide.png";
import { useStyles } from "./AppraisalDetailsHeader.styles";
import { DuplicateSubButton } from "../components/DuplicateSubButton";
// const carOwnerStatusById = {
//   1: "Paid off",
//   2: "Finance",
//   3: "Lease",
// };

const carOwnerStatusItems = [
  { id: 1, title: "Paid off" },
  { id: 2, title: "Finance" },
  { id: 3, title: "Lease" },
];

const sellStrategyStatusItems = [
  { id: 1, title: "Wholesale" },
  { id: 2, title: "Auction" },
  { id: 3, title: "Repair" },
  { id: 4, title: "Retail" },
];

/** @type {import("@material-ui/core").MenuProps} */
const actionsMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  /** Makes vertical position work https://stackoverflow.com/a/52551100 */
  getContentAnchorEl: null,
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

/** @type {import("@material-ui/core").MenuProps} */
const actionsMenuPropsMobile = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  /** Makes vertical position work https://stackoverflow.com/a/52551100 */
  getContentAnchorEl: null,
  transformOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
};

export const AppraisalDetailsHeader = React.memo(
  /**
   * @typedef {object} AppraisalDetailsHeaderProps
   * @property {Appraisal} record
   *
   * @param {AppraisalDetailsHeaderProps} param0
   */
  function AppraisalDetailsHeader({ record: row }) {
    const isMobile = useMobile();
    const classes = useStyles();
    const { confirmAsync, basicDialog } = useBasicDialog();

    const dealer = useDealer();
    const allowTransfers = useSelector(authSelectors.allowTransfers);
    const linkedDealers = useLinkedDealers(allowTransfers ? dealer.id : 0);

    const allowWrite = useSelector(authSelectors.allowWriteAppraisal);
    const closed =
      !allowWrite ||
      // Any status.
      !!row.appraisalStatusId;

    const [showingMore, showMore] = React.useState(false);
    const toggleShowingMore = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        showMore(showingMore => !showingMore);
      },
      [],
    );

    const [actionsMenuAnchor, setActionsMenuAnchor] = React.useState(null);
    const actionsMenuOpen = Boolean(actionsMenuAnchor);
    const closeActionsMenu = React.useCallback(() => {
      setActionsMenuAnchor(null);
    }, []);
    const onClickActionMenu = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        setActionsMenuAnchor(e.currentTarget);
      },
      [],
    );

    const reject = useAction(appraisalActions.reject);
    const onClickReject = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        closeActionsMenu();
        if (!window.confirm("Are you sure?")) {
          return;
        }
        reject(row.id);
      },
      [row.id, reject, closeActionsMenu],
    );
    const reopen = useAction(appraisalActions.reopen);
    const onClickReopen = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        closeActionsMenu();
        if (!window.confirm("Are you sure?")) {
          return;
        }
        reopen(row.id);
      },
      [row.id, reopen, closeActionsMenu],
    );
    const undoStatus = useAction(appraisalActions.undoStatus);
    const onClickUndoStatus = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        closeActionsMenu();
        if (!window.confirm(`Undo the last status change?`)) {
          return;
        }
        undoStatus(row.id);
      },
      [row.id, undoStatus, closeActionsMenu],
    );

    const saveSellStrategy = useAction(appraisalActions.saveSellStrategy);
    const saveAffiliate = useAction(appraisalActions.saveAffiliate);
    const onChangeDropdown = React.useCallback(
      /**
       *  @param {React.SyntheticEvent<HTMLElement>} e
       *  @param {"sellStrategy"|"affiliate"} type
       */
      (e, type) => {
        const value = parseInt(e.target.value) || null;
        if (type === "sellStrategy") {
          if (!row.profitObjective) {
            if (
              !window.confirm(
                "Worksheet incomplete, expected profit will be incorrect." +
                  "\n\nOK to continue?",
              )
            ) {
              return;
            }
          }
          saveSellStrategy(row.id, value);
        } else {
          saveAffiliate(row.id, value);
        }
      },
      [row, saveSellStrategy, saveAffiliate],
    );

    const saveOwnerStatus = useAction(appraisalActions.saveOwnerStatus);
    const onChangeOwnerStatus = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        saveOwnerStatus(row.id, parseInt(e.target.value));
      },
      [row, saveOwnerStatus],
    );

    const archiveItem = useAction(appraisalActions.archiveItem);
    const onClickArchive = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        closeActionsMenu();
        if (!window.confirm("Archive this record?")) {
          return;
        }
        archiveItem(row.id);
      },
      [row, closeActionsMenu, archiveItem],
    );
    const unarchiveItem = useAction(appraisalActions.unarchiveItem);
    const onClickUnArchive = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        closeActionsMenu();
        if (!window.confirm("Un-archive this record?")) {
          return;
        }
        unarchiveItem(row.id);
      },
      [row, closeActionsMenu, unarchiveItem],
    );

    const transferItem = useAction(appraisalActions.transferItem);
    const onClickTransfer = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLInputElement>} e */
      e => {
        const toDealerId = parseInt(e.currentTarget.value);
        // console.log("TO DEALER", toDealerId, e.currentTarget);
        const dealer = linkedDealers.find(it => it.id === toDealerId);
        closeActionsMenu();
        // Let the actions menu close...
        setTimeout(async () => {
          // NOTE: If changing this to pop a model instead of window.confirm,
          // you can take away the setTimeout...
          if (!window.confirm(`Transfer this record to ${dealer.name}?`)) {
            return;
          }
          await transferItem(row.id, { toDealerId });
          window.alert(
            "Record transferred. Your copy can be found in Archived items.",
          );
        }, 250);
      },
      [row, linkedDealers, closeActionsMenu, transferItem],
    );
    const moveArea = useAction(appraisalActions.moveArea);
    const onClickMoveArea = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLInputElement>} e */
      e => {
        // NOTE: For some reason, this only works with number values!
        // const toArea = e.currentTarget.value;
        // To get a string we have to do this...
        const toArea =
          e.currentTarget?.attributes?.getNamedItem("value")?.value;
        const label =
          toArea === "service"
            ? "Service"
            : toArea === "showroom"
            ? "the Showroom"
            : toArea === "buying"
            ? "the Buying center"
            : "Lease returns";
        closeActionsMenu();
        // Let the actions menu close...
        setTimeout(async () => {
          // NOTE: If changing this to pop a model instead of window.confirm,
          // you can take away the setTimeout...
          if (!window.confirm(`Move this record to ${label}?`)) {
            return;
          }
          await moveArea(row.id, toArea);
        }, 250);
      },
      [row.id, closeActionsMenu, moveArea],
    );

    const generateOdmStmt = useAction(appraisalActions.generateOdmStmt);
    const onClickOdmStmt = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        closeActionsMenu();
        generateOdmStmt(row.id);
      },
      [row.id, closeActionsMenu, generateOdmStmt],
    );

    const saveBank = useAction(appraisalActions.saveFinanceBank);
    const [editingBank, editBank] = React.useState(false);
    const onClickEditBank = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (closed) {
          return;
        }
        editBank(true);
      },
      [closed],
    );
    const onCloseEditBank = React.useCallback(
      /** @param {{email:string}} [values] */
      async values => {
        if (values) {
          await saveBank(row.id, values);
        }
        editBank(false);
      },
      [row.id, saveBank],
    );

    const [editingUsers, editUsers] = React.useState(false);

    const sendToVauto = useAction(appraisalActions.sendToVauto);
    const onClickSendToVauto = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        if (e && e.preventDefault) {
          e.preventDefault();
        }
        if (
          await confirmAsync({
            title: "Create vAuto Appraisal?",
            text: "Create an appraisal in vAuto now?",
          })
        ) {
          await sendToVauto(row.id);
        }
      },
      [row.id, sendToVauto, confirmAsync],
    );

    const [affiliates, setAffiliates] = React.useState([]);
    useOnMountAsync(async state => {
      const items = await dealerApi.getAffiliates();
      if (!state.mounted) return;
      const reduce = items.reduce((a, b) => {
        return [...a, { id: b.id, title: b.name }];
      }, []);
      setAffiliates(reduce);
    }, []);

    return (
      <div className={classes.root}>
        {basicDialog}
        <div className={classes.imgBox}>
          <img
            src={row.thumb ?? CarSideImg}
            alt=""
            width="106px"
            height="60px"
          />
          <DuplicateSubButton row={row} header />
        </div>
        <Box
          // Main Details
          color="#526B8A"
          flex="1 0 auto"
          fontSize="16px"
          fontWeight="500"
          minHeight="176px"
          pr={isMobile ? undefined : "24px"}
        >
          <CopyableText className={classes.titleCar}>
            {row.year} {row.make} {row.model}
          </CopyableText>
          <VehicleDetailsText isMobile={isMobile} row={row} />
          <Box
            color="#8D98C2"
            display="flex"
            fontSize="14px"
            letterSpacing="0"
            pt="8px"
          >
            Submitted on {formatDate(row.createdAt)}
          </Box>
          <Box
            alignItems="center"
            color="#8D98C2"
            display="flex"
            justifyContent="space-between"
            fontSize="14px"
            letterSpacing="0"
            pt="10px"
          >
            <div className="inflex">
              <RatingInput
                color="inherit"
                defaultValue={row.runningStatus ?? 0}
                size="small"
                readOnly
              />
              &nbsp;&nbsp;Inspection grade
            </div>
            {row.trfId && (
              <div
                className={classes.transfer}
                title={`Transferred from ${row.trfDealerName} ${formatDateTime(
                  row.trfDateTime,
                )}`}
              >
                {row.trfDealerName}
              </div>
            )}
            {row.trfAt ? (
              <div
                className={`${classes.transfer} ${classes.transferred}`}
                title={`Transferred on ${formatDateTime(row.trfAt)}`}
              >
                Transferred {formatDate(row.trfAt)}
              </div>
            ) : row.deletedAt ? (
              <div
                className={classes.deleted}
                title={`Archived on ${formatDateTime(row.deletedAt)}`}
              >
                Archived {formatDate(row.deletedAt)}
              </div>
            ) : null}
          </Box>
          {row.estimatedDamage && (
            <Box
              display="flex"
              justifyContent="space-between"
              pt="14px"
              fontSize="14px"
              color="#4E50FF"
            >
              Estimated Damage Cost: {formatAmountUSD(row.estimatedDamage)}
            </Box>
          )}
          <Box
            className={classes.stageBtn}
            display="flex"
            justifyContent="space-between"
            pt="14px"
          >
            {!editingUsers ? (
              <>
                <AppraisalStageButton disabled={closed} record={row} />
                <AssignedUsers
                  disabled={closed}
                  edit={editUsers}
                  record={row}
                />
              </>
            ) : (
              <AssignedUsersEditor record={row} edit={editUsers} />
            )}
          </Box>
          {isMobile && (
            <Button
              className={classes.showMore}
              onClick={toggleShowingMore}
              disableRipple
            >
              Show {showingMore ? "less" : "more"}
            </Button>
          )}
        </Box>
        {(!isMobile || showingMore) && (
          <>
            <div className={classes.basicDetails}>
              <Box display="flex" justifyContent="space-between" pb="8px">
                <div className={classes.labelText}>Color&nbsp;</div>
                <div className={classes.labelValue}>{row.color}</div>
              </Box>
              {!editingBank ? (
                <>
                  <div className={classes.bankRow} onClick={onClickEditBank}>
                    <label className={classes.labelText}>Bank</label>
                    {!row.dealerBankId ? (
                      <div
                        className={classes.error}
                        title="Not found in dealer banks!"
                        tabIndex="0"
                      >
                        {row.financeBank}
                      </div>
                    ) : (
                      <div className={classes.labelValue} tabIndex="0">
                        {row.financeBank}
                      </div>
                    )}
                    <ArrowDropDownIcon
                      color={closed ? "disabled" : undefined}
                    />
                  </div>
                  {row.financeBankNum && (
                    <Box display="flex" justifyContent="space-between">
                      <div className={classes.labelText}>Account</div>
                      <CopyableText className={classes.labelValue}>
                        {row.financeBankNum}
                      </CopyableText>
                    </Box>
                  )}
                  {row.maturityDate && (
                    <Box display="flex" justifyContent="space-between">
                      <div className={classes.labelText}>Matures</div>
                      <CopyableText className={classes.labelValue}>
                        {formatDate(row.maturityDate)}
                      </CopyableText>
                    </Box>
                  )}
                </>
              ) : (
                <InlineAppraisalBankForm
                  onClose={onCloseEditBank}
                  values={row}
                />
              )}
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                pb="4px"
              >
                <Box width="60px" className={classes.labelText}>
                  Type
                </Box>
                <SelectInput
                  items={carOwnerStatusItems}
                  value={row.ownerStatusId || ""}
                  onChange={onChangeOwnerStatus}
                  disableUnderline
                  className={classes.selectOwnerStatus}
                  disabled={closed}
                />
              </Box>
              <Box
                alignItems="center"
                color="#8D98C2"
                display="flex"
                justifyContent="space-between"
                fontSize="14px"
                // lineHeight="21px"
                minWidth={170}
                // marginTop="auto"
                pb="8px"
              >
                <div className={classes.labelText}>Sell strategy</div>
                <SelectInput
                  emptyEnabled
                  items={sellStrategyStatusItems}
                  value={row.sellStrategyStatusId ?? ""}
                  onChange={e => onChangeDropdown(e, "sellStrategy")}
                  disableUnderline
                  className={classes.selectInput}
                  disabled={
                    closed
                    // row.appraisalStatusId !== 1 && row.appraisalStatusId !== 4
                  }
                />
              </Box>
              <Box
                color="#8D98C2"
                display="flex"
                fontSize="14px"
                lineHeight="21px"
                justifyContent="space-between"
                pb="8px"
              >
                <div className={classes.labelText}>Campaign</div>
                <div className={classes.labelValue}>
                  <CampaignAvatar record={row} small />
                  &nbsp;&nbsp;
                  {row.campaign?.name}
                </div>
              </Box>
              {affiliates.length > 0 && (
                <Box
                  color="#8D98C2"
                  display="flex"
                  fontSize="14px"
                  lineHeight="21px"
                  justifyContent="space-between"
                  minWidth={170}
                >
                  <div className={classes.labelText}>Affiliate</div>
                  <SelectInput
                    emptyEnabled
                    items={affiliates}
                    value={row.affiliateId ?? ""}
                    onChange={e => onChangeDropdown(e, "affiliate")}
                    disableUnderline
                    className={classes.selectInput}
                    disabled={closed}
                  />
                </Box>
              )}
            </div>
            <div className={classes.contactDetails}>
              <div className={classes.contactHead}>
                <CopyableText
                  color="#141932"
                  fontSize="20px"
                  fontWeight="600"
                  lineHeight="24px"
                  text={`${row.firstName} ${row.lastName}`}
                >
                  {row.firstName} {row.lastName}
                </CopyableText>
                {allowWrite && <EditAppraisalContactButton recordId={row.id} />}
                <IconButton
                  aria-label="Appraisal actions"
                  aria-controls="menu-appraisal-actions"
                  aria-haspopup="true"
                  onClick={onClickActionMenu}
                  className={classes.actionsButton}
                >
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  id="menu-appraisal-actions"
                  anchorEl={actionsMenuAnchor}
                  open={actionsMenuOpen}
                  onClose={closeActionsMenu}
                  {...(isMobile ? actionsMenuPropsMobile : actionsMenuProps)}
                >
                  <MenuItem onClick={onClickOdmStmt} disabled={!allowWrite}>
                    Generate Odometer
                    <br />
                    Statement
                  </MenuItem>
                  <Divider />
                  {!closed && row.appraisalTypeId === 1 && (
                    <MenuItem onClick={onClickReject}>Reject</MenuItem>
                  )}
                  <NestedMenuItem
                    key="moveArea"
                    align="left"
                    disabled={!allowWrite}
                    label="Move to"
                    parentMenuOpen={actionsMenuOpen}
                  >
                    <MenuItem value="buying" onClick={onClickMoveArea}>
                      Buying center
                    </MenuItem>
                    <MenuItem value="lease" onClick={onClickMoveArea}>
                      Lease return
                    </MenuItem>
                    <MenuItem value="showroom" onClick={onClickMoveArea}>
                      Showroom
                    </MenuItem>
                    <MenuItem value="service" onClick={onClickMoveArea}>
                      Service
                    </MenuItem>
                  </NestedMenuItem>
                  {closed && (
                    <MenuItem
                      disabled={!allowWrite}
                      onClick={onClickReopen}
                      title="Clears the current status."
                    >
                      Reopen
                    </MenuItem>
                  )}
                  {row.appraisalStatusId && (
                    <MenuItem
                      disabled={!allowWrite}
                      onClick={onClickUndoStatus}
                      title="Reset the record back to the last status."
                    >
                      Undo status
                    </MenuItem>
                  )}
                  {((!closed && row.appraisalTypeId === 1) || closed) && (
                    <Divider />
                  )}
                  {row.deletedAt ? (
                    <MenuItem disabled={!allowWrite} onClick={onClickUnArchive}>
                      Un-Archive
                    </MenuItem>
                  ) : (
                    [
                      !allowTransfers || linkedDealers.length < 1 ? null : (
                        <NestedMenuItem
                          key="transfer"
                          align="left"
                          disabled={!allowWrite}
                          label="Transfer"
                          parentMenuOpen={actionsMenuOpen}
                        >
                          {linkedDealers.map(it => (
                            <MenuItem
                              key={it.id}
                              value={it.id}
                              onClick={onClickTransfer}
                            >
                              {it.name}
                            </MenuItem>
                          ))}
                        </NestedMenuItem>
                      ),
                      <Divider key="divider1" />,
                      <MenuItem
                        key="archive"
                        disabled={!allowWrite}
                        onClick={onClickArchive}
                      >
                        Archive
                      </MenuItem>,
                    ]
                  )}
                </Menu>
              </div>
              {row.vendorName && (
                <Box borderBottom="1px solid #344c6b">
                  <CopyableText
                    text={row.vendorName}
                    fontSize="18px"
                    color="#344c6b"
                    title="Dealer or vendor name"
                  >
                    {row.vendorName}
                  </CopyableText>
                </Box>
              )}
              <Box className={classes.emailLink}>
                <CopyableText text={row.email} fontSize="16px">
                  <Link href={`mailto:${row.email}`}>{row.email}</Link>
                </CopyableText>
              </Box>
              {row.phoneNumber && (
                <Box fontSize="14px" color="#8D98C2">
                  <CopyableText text={row.phoneNumber}>
                    <Link href={`tel:${row.phoneNumber}`}>
                      {formatPhone(row.phoneNumber)}
                    </Link>
                  </CopyableText>
                </Box>
              )}
              {row.zip && (
                <Box pt="8px" fontSize="14px" color="#8D98C2">
                  <CopyableText>{row.zip}</CopyableText>
                </Box>
              )}
              {row.altEmail && (
                <>
                  <div className={classes.inspector}>
                    Inspector
                    <Box textAlign="right">
                      <CopyableText
                        text={row.altEmail}
                        fontSize="12px"
                        className={classes.emailLink}
                      >
                        <Link href={`mailto:${row.altEmail}`}>
                          {row.altEmail}
                        </Link>
                      </CopyableText>
                    </Box>
                    {row.altPhone && (
                      <Box textAlign="right">
                        <CopyableText fontSize="12px" color="#8D98C2">
                          <Link href={`tel:${row.altPhone}`}>
                            {formatPhone(row.altPhone)}
                          </Link>
                        </CopyableText>
                      </Box>
                    )}
                  </div>
                </>
              )}
              <Box
                alignItems="flex-end"
                display="flex"
                justifyContent={isMobile ? "space-between" : undefined}
                pt="8px"
                flex="1"
              >
                <Box maxWidth="96px">
                  <CarfaxSnap4P vin={row.vin} />
                </Box>
                <Box
                  color="#4E50FF"
                  fontSize="14px"
                  fontWeight="500"
                  marginLeft="24px"
                  lineHeight="21px"
                >
                  {row.vautoUrl ? (
                    <a
                      href={row.vautoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/images/vauto-logo.svg"
                        alt="vAuto"
                        height="32px"
                      />
                    </a>
                  ) : (
                    <Box
                      component="a"
                      href={`./${row.id}#send-to-vauto`}
                      onClick={onClickSendToVauto}
                      display="flex"
                      flexDirection="column"
                      fontSize="16px"
                      // height="54px"
                      // justifyContent="flex-end"
                    >
                      <img
                        src="/images/vauto-logo.svg"
                        alt="vAuto"
                        height="32px"
                      />
                      <small>
                        <em>Create vAuto appraisal</em>
                      </small>
                    </Box>
                  )}
                </Box>
              </Box>
            </div>
          </>
        )}
      </div>
    );
  },
);

const VehicleDetailsText = React.memo(
  /**
   *
   */
  function VehicleDetailsText({ isMobile, row }) {
    const pipeML = isMobile ? "18px" : "-6px";
    const pipeMR = isMobile ? "18px" : "8px";
    const mileageText = formatThousands(row.inspectedMileage || row.mileage);
    return (
      <Box
        alignItems="baseline"
        color="#141932"
        display={isMobile ? undefined : "flex"}
        fontSize="14px"
        fontWeight="400"
        pt="8px"
        // textAlign="center"
      >
        <CopyableText dense>{row.trim}</CopyableText>
        <Box component="span" ml={pipeML} mr={pipeMR}>
          |
        </Box>
        <CopyableText dense>{row.vin}</CopyableText>
        <Box component="span" ml={pipeML} mr={pipeMR}>
          |
        </Box>
        <CopyableText>{mileageText} miles</CopyableText>
      </Box>
    );
  },
);
