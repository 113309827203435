import React from "react";
import { Box, Typography } from "@material-ui/core";
// Local
// import { useLocation } from "../../lib";
import { useStyles } from "./Auth.styles";

export const RecoverySentPage = React.memo(
  /**
   *
   */
  function RecoverySentPage() {
    const classes = useStyles();
    // const location = useLocation();

    return (
      <div className={classes.root}>
        <Box mb="8px">
          <Typography variant="h1">Email sent</Typography>
        </Box>
        {/* to {location.query.email || "your E-mail"} */}
        <Box mb="8px">
          <Typography variant="subtitle1">
            We’ve sent you an email with a link to reset your password.
          </Typography>
        </Box>
      </div>
    );
  },
);
