import React from "react";
import {
  //
  // Box,
  Button,
  ButtonGroup,
  makeStyles,
} from "@material-ui/core";
// Icons
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
// Local
import { appraisalStagesById } from "../../../state";

const useStyles = makeStyles(
  theme => ({
    root: {
      // margin: 0,
    },
    stageBtnGroup: {
      boxShadow: "none",
      [theme.breakpoints.down("xs")]: {
        maxWidth: 220,
        "& .MuiButton-label": {
          lineHeight: "16px",
        },
      },
      "& button:not(:last-child)": {
        borderRight: "1px solid #fff",
      },
      "& .inspectBtn": {
        color: "#FFF",
        background: "#4E50FF",
      },
      "& .assessBtn": {
        color: "#FFF",
        background: "#00C2FF",
      },
      "& .payoffBtn": {
        color: "#FFF",
        background: "#FF3B6E",
      },
      "& .offeredBtn": {
        color: "#FFF",
        background: "#FDBE30",
      },
      "& .acceptedBtn": {
        color: "#FFF",
        background: "#00D289",
      },
      "& .groundedBtn": {
        color: "#FFF",
        background: "#4E50FF",
      },
      "& .turnedinBtn": {
        color: "#FFF",
        background: "#4E50FF",
      },
    },
  }),
  {
    classNamePrefix: "AppraisalStageButton",
  },
);

export const AppraisalStageButton = React.memo(function AppraisalStageButton({
  disabled,
  record: row,
}) {
  const classes = useStyles();
  const stageTitle = appraisalStagesById[row.stage].title;
  const stageClassName = appraisalStagesById[row.stage].id;

  // const onClick = React.useCallback(
  //   /** @param {React.SyntheticEvent<HTMLElement>} e */
  //   e => {
  //     console.log("TODO: Set new stage?");
  //   },
  //   [],
  // );

  // const onClickNext = React.useCallback(
  //   /** @param {React.SyntheticEvent<HTMLElement>} e */
  //   e => {
  //     console.log("TODO: Go to next stage?");
  //   },
  //   [],
  // );

  return (
    <ButtonGroup
      variant="contained"
      color="primary"
      // ref={anchorRef}
      aria-label="appraisal status"
      size="small"
      className={classes.stageBtnGroup}
      disabled={disabled}
    >
      <Button
        // disabled
        // onClick={onClick}
        size="small"
        className={`${stageClassName}Btn`}
      >
        {stageTitle}
      </Button>
      <Button
        color="primary"
        size="small"
        className={`${stageClassName}Btn`}
        // aria-controls={open ? "split-button-menu" : undefined}
        // aria-expanded={open ? "true" : undefined}
        // aria-label="select merge strategy"
        // aria-haspopup="menu"
        // disabled
        // onClick={onClickNext}
      >
        <ArrowRightIcon />
      </Button>
    </ButtonGroup>
  );
});
