import { CarfaxConnectedPage } from "./CarfaxConnectedPage";
import { CarfaxVinReportPage } from "./CarfaxVinReportPage";

export const CarfaxPages = {
  connected: {
    path: "/carfax/connected",
    // roles: [],
    title: "Carfax Connected",
    view: CarfaxConnectedPage,
  },
  loadVinReport: {
    path: "/carfax/report/:vin",
    // roles: [],
    title: "Carfax Report",
    view: CarfaxVinReportPage,
  },
};
