import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
// Local
import { useField } from "../../lib";

const filter = createFilterOptions();

/**
 * This component currently only saves object values, e.g. the entire selected
 * option object or, a new object `{ [optionsLabelKey]: inputValue }` when
 * adding a new item (via `freeSolo`).
 * Use `AutocompleteField` to save a single option field as the value.
 *
 * @typedef {import("@material-ui/lab").AutocompleteProps} AutocompleteProps
 * @typedef {import("@material-ui/core").TextFieldProps} TextFieldProps
 * @typedef {object} ComboFieldProps
 * @property {boolean} [autoFocus]
 * @property {(value:string)=>Promise<unknown[]>} [getOptions]
 * @property {string} [label]
 * @property {string} name
 * @property {string} [optionsLabelKey] Defaults to `"name"`.
 * @property {TextFieldProps["variant"]} [variant]
 *
 * @param {AutocompleteProps & ComboFieldProps} props
 */
export function ComboField(props) {
  const {
    autoFocus,
    getOptions,
    label,
    name,
    optionsLabelKey = "name",
    value: _value,
    variant,
    ...autocompleteProps
  } = props;

  const [fld, meta, helpers] = useField(name);
  const [options, setOptions] = React.useState([]);

  const filterOptions = React.useCallback(
    (options, params) => {
      const filtered = filter(options, params);

      // Suggest the creation of a new value
      if (params.inputValue !== "") {
        filtered.push({
          inputValue: params.inputValue,
          [optionsLabelKey]: `Add "${params.inputValue}"`,
        });
      }

      return filtered;
    },
    [optionsLabelKey],
  );

  const getOptionLabel = React.useCallback(
    option => {
      let label;
      if (typeof option === "string") {
        // Value selected with enter, right from the input
        label = option;
      } else if (option.inputValue) {
        // Add "xxx" option created dynamically
        label = option.inputValue;
      } else {
        // Regular option
        label = option[optionsLabelKey];
      }
      // console.log("LABEL", {label, option});
      return label;
    },
    [optionsLabelKey],
  );

  const onChange = React.useCallback(
    /**
     * Handler for when the entire Autocomplete changes.
     * @param {React.ChangeEvent<{}>} e
     * @param {any} value
     * @param {"create-option"|"select-option"|"remove-option"|"blur"|"clear"} reason
     * @param {any} details
     */
    (e, value, reason, details) => {
      let newValue;
      if (typeof value === "string") {
        if (reason === "blur") {
          // Mui has a bug where the Autocomplete causes an unnecessary change
          // from an object to string value when the input loses focus.
          // See https://github.com/mui/material-ui/pull/28190
          // Since the fix was not back-ported to MUI v4/labs we ignore it here.
          return;
        }
        newValue = {
          [optionsLabelKey]: value,
        };
      } else if (value && value.inputValue) {
        // Create a new value from the user input
        newValue = {
          [optionsLabelKey]: value.inputValue,
        };
      } else {
        newValue = value;
      }
      // console.log("SET VALUE", { newValue, value, reason, details });
      helpers.setValue(newValue);
    },
    [helpers, optionsLabelKey],
  );

  const onInputChange = React.useCallback(
    /**
     * Handler for when the TextField changes.
     */
    async (e, value, reason) => {
      if (getOptions) {
        const options = await getOptions(value);
        setOptions(options);
      }
    },
    [getOptions],
  );

  const onOpen = React.useCallback(async () => {
    if (getOptions) {
      const options = await getOptions();
      setOptions(options);
    }
  }, [getOptions]);

  // console.log("RENDER", fld.value, options);

  return (
    <Autocomplete
      // Automatically highlight the first matching item on the list.
      autoHighlight
      // Automatically select the highlighted item.
      autoSelect
      clearOnBlur
      filterOptions={filterOptions}
      forcePopupIcon
      freeSolo
      getOptionLabel={getOptionLabel}
      handleHomeEndKeys
      id={autocompleteProps.id ?? `${name}-combo`}
      // loading={!options || options.length < 1}
      onChange={onChange}
      onInputChange={onInputChange}
      onOpen={onOpen}
      options={options}
      renderInput={params => (
        <TextField
          autoFocus={autoFocus}
          name={name}
          label={label}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          variant={variant}
          {...params}
        />
      )}
      renderOption={option => option[optionsLabelKey]}
      // Select all text on input focus.
      selectOnFocus
      // When the value is blank, send `null` to avoid the warning that the
      // entered value doesn't match any of the options.
      value={fld.value || null}
      {...autocompleteProps}
    />
  );
}
