import { MainPages } from "./main";
import { AuthPages } from "./auth";
import { AppraisalPages } from "./appraisals";
import { DealerPages } from "./dealer";
// import { DealsPages } from "./deals";
// import { CustomersPages } from "./customers";
import { CarfaxPages } from "./integrations/carfax";
import { UserPages } from "./user";
import { AnonPages } from "./anon";

export const Pages = {
  appraisals: AppraisalPages,
  auth: AuthPages,
  // customers: CustomersPages,
  dealer: DealerPages,
  // deals: DealsPages,
  user: UserPages,
  main: MainPages,

  anon: AnonPages,

  // Integrations
  carfax: CarfaxPages,
};
export default Pages;

const RolesRequiredByDefault = ["DEALER"];
/**
 * Function to process each page in `Pages`.
 *
 * @param {(
 *   page: PageDefinition,
 *   pageName: string,
 *   areaName: string,
 *   pages: typeof Pages,
 * ) => void} handler
 */
export function eachPage(handler) {
  Object.keys(Pages).forEach(areaName => {
    const area = Pages[areaName];
    Object.keys(area).forEach(pageName => {
      handler(area[pageName], pageName, areaName, Pages);
    });
  });
}

eachPage(page => {
  if (!page.anon && !page.roles) {
    page.roles = RolesRequiredByDefault;
  }
});
