import { appraisals, AppraisalsState } from "./appraisals/state";
import { auth, AuthState } from "./auth/state";
import { dealer, DealerState } from "./dealer/state";
// import { deals, DealsState } from "./deals/state";
import { pref, PrefState } from "./pref/state";

import { AppStateInfo } from "./types";

// Export Actions, Selectors, Types
// export * from "./activity_types/actions";

export * from "./appraisals/actions";
export * from "./appraisals/selectors";
export * from "./appraisals/types";

export * from "./auth/actions";
export * from "./auth/selectors";

export * from "./dealer/actions";
export * from "./dealer/selectors";
export * from "./dealer/utils";

// export * from "./deals/actions";
// export * from "./deals/selectors";

// export * from "./customers/actions";

export * from "./pref/selectors";

export * from "./users/actions";

export const prefActions = pref.actions;

export interface AppState {
  appraisals: AppraisalsState;
  auth: AuthState;
  // deals: DealsState;
  pref: PrefState;
  dealer: DealerState;
}

const states: AppStateInfo[] = [
  // Export States
  auth,
  pref,
  appraisals,
  dealer,
  // deals,
];
export default states;
