import React from "react";
import {
  //
  Grid,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
// Local
import {
  // appraisalActions,
  appraisalSelectors,
  appraisalStagesByType,
  // prefActions,
  // prefSelectors,
  // useAction,
  useSelector,
} from "../../../state";
import { AssignedUsers } from "../components/AssignedUsers";
import { formatAmountUSD } from "../../../lib";

function createGroup() {
  return {
    count: 0,
    /** @type {Appraisal[]} */
    rows: [],
  };
}

const emptyGroup = createGroup();

const useStyles = makeStyles(
  theme => ({
    root: {},
    gridContainer: {
      overflowX: "scroll",
      flexWrap: "nowrap",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    item: {
      boxShadow: "0 2px 12px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
      padding: "12px",
      width: "280px",
      minHeight: "67px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    stageTitle: {
      "&.MuiGrid-item": {
        paddingTop: theme.spacing(5),
        [theme.breakpoints.down("xs")]: {
          paddingTop: theme.spacing(1),
        },
      },
      "&.MuiGrid-item:first-child": {
        [theme.breakpoints.down("xs")]: {
          paddingTop: theme.spacing(5),
        },
      },
      "& .inspectTitleColor": {
        borderColor: "#4E50FF",
      },
      "& .assessTitleColor": {
        borderColor: "#00C2FF",
      },
      "& .payoffTitleColor": {
        borderColor: "#FF3B6E",
      },
      "& .offeredTitleColor": {
        borderColor: "#FDBE30",
      },
      "& .acceptedTitleColor": {
        borderColor: "#00D289",
      },
    },
    carDetailTextTrim: {
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(1),
    },
    carDetailTextVin: { color: theme.palette.text.secondary },
    users: {
      "& .MuiAvatarGroup-avatar": {
        width: "30px",
        height: "30px",
        fontSize: "12px",
      },
    },
  }),
  {
    classNamePrefix: "AppraisalsBoard",
  },
);

export const AppraisalsBoard = React.memo(
  /**
   *
   */
  function AppraisalsBoard(props) {
    const classes = useStyles();
    const rows = useSelector(appraisalSelectors.listItems);
    const { appraisalTypeId: typeId = 1 } = useSelector(
      appraisalSelectors.filter,
    );

    /** @type {Record<string, ReturnType<createGroup>>} */
    const groups = React.useMemo(
      () =>
        rows.reduce((groups, row) => {
          let grp = groups[row.stage];
          if (!grp) {
            grp = groups[row.stage] = createGroup();
          }
          grp.count += 1;
          grp.rows.push(row);
          return groups;
        }, {}),
      [rows],
    );

    return (
      <div className={classes.root}>
        {process.env.NODE_ENV !== "development" ? (
          <Typography variant="h4" align="center">
            Coming soon...
          </Typography>
        ) : (
          <>
            {/* TODO: Implement Board layout to display {rows.length} rows in{" "}
            {appraisalStagesByType[typeId].length} groups... */}
            <Grid container spacing={3} className={classes.gridContainer}>
              {appraisalStagesByType[typeId].map(stage => {
                const grp = groups[stage.id] ?? emptyGroup;
                const totalAmount = grp.rows.reduce(
                  (sum, row) => sum + row.expectedTotalProfit,
                  0,
                );

                return (
                  <React.Fragment key={stage.id}>
                    <Grid item className={classes.stageTitle}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderTop="4px solid"
                        className={clsx(classes.item, `${stage.id}TitleColor`)}
                        mb={2}
                      >
                        <Typography variant="body2">
                          {stage.title} ({grp.count})
                        </Typography>
                        <Box
                          flexBasis="25%"
                          textAlign="right"
                          color="#8D98C2"
                          fontSize="12px"
                          fontWeight="500"
                          ml="10px"
                        >
                          {formatAmountUSD(totalAmount)}
                        </Box>
                      </Box>
                      <Box>
                        {grp.rows.map(row => (
                          <Box key={row.id} className={classes.item} mb={1}>
                            <Box mb={1}>
                              <Typography variant="h5">
                                {row.year} {row.make} {row.model}
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="flex-end"
                            >
                              <Box>
                                <Typography
                                  variant="body2"
                                  className={classes.carDetailTextTrim}
                                >
                                  {row.trim}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className={classes.carDetailTextVin}
                                >
                                  {row.vin}
                                </Typography>
                              </Box>
                              <Box className={classes.users}>
                                {(row.users || row.originator) && (
                                  <AssignedUsers record={row} />
                                )}
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </>
        )}
      </div>
    );
  },
);
