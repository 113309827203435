import React from "react";
import {
  //
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useMobile } from "../../../themes";
import { Link } from "react-router-dom";
// Icons
import SubmittedIcon from "@material-ui/icons/Description";
import InspectionsIcon from "@material-ui/icons/Search";
import SentIcon from "@material-ui/icons/Send";
import AcceptedIcon from "@material-ui/icons/CheckCircle";
import ConvertedIcon from "@material-ui/icons/Autorenew";
import GroundedIcon from "@material-ui/icons/HighlightOff";
// Local
import {
  //
  Dot,
  PageLink,
  SelectField,
} from "../../../components";
import {
  //
  Form,
  Navigation,
  formatAmountUSD,
  formatThousands,
  isEmpty,
  shallowEqualsObj,
  useDebouncedEffect,
  useFormik,
  useLocation,
} from "../../../lib";
import {
  appraisalActions,
  appraisalSelectors,
  isLeaseReturnsPath,
  useAction,
  useDealer,
  useSelector,
  authSelectors,
} from "../../../state";

const dateRanges = [
  { id: "7d", title: "Past week" },
  { id: "30d", title: "Past 30 days" },
  { id: "60d", title: "Past 60 days" },
  { id: "90d", title: "Past 90 days" },
  { id: "183d", title: "Past 6 months" },
  { id: "365d", title: "Past year" },
];
const filterIdMap = [
  { id: "7d", value: 7 },
  { id: "30d", value: 30 },
  { id: "60d", value: 60 },
  { id: "90d", value: 90 },
  { id: "183d", value: 183 },
  { id: "365d", value: 365 },
];

const useStyles = makeStyles(
  theme => ({
    root: {},
    titlebar: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "space-between",
      paddingBottom: 40,
      flexWrap: "wrap",
      "& h2": {
        [theme.breakpoints.down("xs")]: {
          flex: "0 1 100%",
          marginBottom: theme.spacing(2),
        },
      },
    },
    filterContainer: {
      display: "flex",
      alignItems: "flex-start",
      "& div:first-child": {
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 2,
        },
      },
    },
    filter: {
      "& .MuiSelect-root": {
        color: "#8D98C2",
        fontSize: 14,
      },
      "& .MuiSelect-icon": {
        top: "calc(50% - 14px)",
        color: "#8D98C2",
      },
    },
    stats: {
      gap: "20px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    statsBox: {
      display: "block",

      border: "1px solid #BBC2E1",
      borderRadius: "3px",
      height: "140px",
      padding: "16px 16px 14px 16px",
      width: "224px",
      backgroundColor: "#FFFFFF",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        marginBottom: theme.spacing(3),
        width: "100%",
        height: "100%",
        "& > :first-child": {
          marginBottom: theme.spacing(1),
          marginRight: theme.spacing(2),
        },
        "& > :last-child": {
          width: "100%",
        },
      },
    },
    statsAmounts: {
      color: "#141932",
      fontSize: "24px",
      fontWeight: "600",
      letterSpacing: "0",
      paddingTop: "16px",
      [theme.breakpoints.down("xs")]: {
        paddingTop: 0,
      },
    },
    sideTotalItem: {
      borderRadius: "3px",
      boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
      display: "flex",
      height: "108px",
      marginTop: "16px",
      padding: "32px 16px",
      width: "468px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "auto",
      },
    },
    bgImg: {
      position: "fixed",
      bottom: 0,
      right: 0,
      zIndex: -1,
    },
    link: {
      textDecoration: "none",
      transition: "opacity 300ms linear",
      "&:hover": {
        opacity: 0.7,
      },
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
  }),
  {
    classNamePrefix: "AppraisalsDashboardPage",
  },
);

const defaultValues = {
  from: "30d",
};

const fieldHelpText = {
  totalInspected: "Inspected",
  totalOfferSent: "Offers sent",
  totalOfferAccepted: "Offers accepted",
  totalBuying: "Buying",
  totalSubmitted: "Submitted",
  totalGrounding: "Grounding",
};

const topTotalsByType = {
  /** @type {TopTotalBoxProps[]} */
  tradeins: [
    {
      icon: SubmittedIcon,
      value: "totalSubmitted",
      total: undefined,
      label: "Appraisals submitted",
      link: "submitted",
    },
    {
      icon: InspectionsIcon,
      value: "totalInspected",
      total: "totalSubmitted",
      label: "Inspections completed",
      link: "inspected",
    },
    {
      icon: SentIcon,
      value: "totalOfferSent",
      total: "totalSubmitted",
      label: "Offers sent",
      link: "offers-sent",
    },
    {
      icon: AcceptedIcon,
      value: "totalOfferAccepted",
      total: "totalOfferSent",
      label: "Offers accepted",
      link: "offers-accepted",
    },
    {
      icon: ConvertedIcon,
      value: "totalBuying",
      total: "totalSubmitted",
      label: "Total converted",
      link: "buying",
    },
  ],
  /** @type {TopTotalBoxProps[]} */
  returns: [
    {
      icon: SubmittedIcon,
      value: "totalSubmitted",
      total: undefined,
      label: "Submitted",
      link: "submitted",
    },
    {
      icon: InspectionsIcon,
      value: "totalInspected",
      total: "totalSubmitted",
      label: "Inspections completed",
      link: "inspected",
    },
    {
      icon: AcceptedIcon,
      value: "totalBuying",
      total: "totalInspected",
      label: "Buying",
      link: "buying",
    },
    {
      icon: GroundedIcon,
      value: "totalGrounding",
      total: "totalInspected",
      label: "Grounding",
      link: "grounding",
    },
    {
      icon: ConvertedIcon,
      value: "totalBuying",
      total: "totalSubmitted",
      label: "Total converted",
      link: "buying",
    },
  ],
};

const sideTotalsByType = {
  /** @type {SideTotalBoxProps[]} */
  tradeins: [
    {
      color: "#00D289",
      label: "Total expected profit of accepted offers",
      value: "totalExpectedProfit",
    },
    {
      color: "#00C2FF",
      label: "Average expected profit of accepted offers",
      value: "totalExpectedProfitAvg",
    },
  ],
  /** @type {SideTotalBoxProps[]} */
  returns: [
    {
      color: "#00D289",
      label: "Total expected profit of buying",
      value: "totalExpectedProfit",
    },
    {
      color: "#00C2FF",
      label: "Average expected profit of buying",
      value: "totalExpectedProfitAvg",
    },
  ],
};

export const AppraisalsDashboardPage = React.memo(
  /**
   *
   */
  function AppraisalsDashboardPage({ children }) {
    const classes = useStyles();
    const isMobile = useMobile();
    const dealer = useDealer();

    const {
      //
      query,
      pathname,
    } = useLocation();
    const isLeaseReturns = isLeaseReturnsPath(pathname);

    const isGroupAdmin = useSelector(authSelectors.isGroupAdmin);
    const loadDashboard = useAction(appraisalActions.loadDashboard);
    const dashData = useSelector(
      isLeaseReturns
        ? appraisalSelectors.dashboardLeaseReturns
        : appraisalSelectors.dashboardTradeins,
    );
    const [loading, setLoading] = React.useState(true);
    const [initialValues, setInitialValues] = React.useState(defaultValues);
    /**
     * Whenever the location changes, set the form values to reflect the
     * query and fetch the dashboard data from our server.
     */
    React.useEffect(() => {
      setLoading(true);
      const filter = !isEmpty(query) ? { ...query } : defaultValues;
      setInitialValues(filter);
      loadDashboard({
        filter,
        type: isLeaseReturns ? "lease-returns" : "tradeins",
      }).finally(() => {
        setLoading(false);
      });
    }, [query, isLeaseReturns, loadDashboard]);
    /**
     * When we submit the form, just change the location query.
     */
    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      // validationSchema,
      validateOnBlur: false,
      validateOnChange: false,
      async onSubmit(values, helpers) {
        Navigation.replace(Navigation.history.location.pathname, {
          query: values,
        });
      },
    });
    /**
     * Whenever the form values change, submit the form.
     */
    useDebouncedEffect(() => {
      if (shallowEqualsObj(frm.initialValues, frm.values)) {
        // console.log("SKIPPING auto search");
        return;
      }
      // console.log("VALUES CHANGED", frm.values);
      frm.submitForm();
    }, [frm]);

    const topTotalItems = isLeaseReturns
      ? topTotalsByType.returns
      : topTotalsByType.tradeins;

    const sideTotalItems = isLeaseReturns
      ? sideTotalsByType.returns
      : sideTotalsByType.tradeins;
    const filterId = filterIdMap.find(f => f.id === initialValues.from)?.value;

    return (
      <div className={classes.root}>
        <Form className={classes.titlebar} form={frm}>
          <Typography variant="h2">{dealer.name} Dashboard</Typography>
          <Box className={classes.filterContainer}>
            {isGroupAdmin && (
              <Box pt="2px">
                <Link to="/my/stats" className={classes.link}>
                  View stats for all dealers
                </Link>
              </Box>
            )}
            <Box
              color="#8D98C2"
              fontSize="14px"
              fontWeight="500"
              pt="2px"
              pl="24px"
            >
              Date range:{" "}
            </Box>
            <Box pl="8px">
              <SelectField
                // emptyEnabled
                name="from"
                items={dateRanges}
                size="small"
                variant="standard"
                disableUnderline
                disabled={loading}
                className={classes.filter}
              />
            </Box>
          </Box>
        </Form>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          className={classes.stats}
        >
          {topTotalItems.map((it, idx) => (
            <TopTotalBox
              key={it.value + idx}
              icon={it.icon}
              value={dashData[it.value]}
              valueHelp={fieldHelpText[it.value]}
              total={dashData[it.total]}
              totalHelp={fieldHelpText[it.total]}
              label={it.label}
              dealer={dealer}
              link={`${isLeaseReturns ? "returns" : "trade"}/${
                it.link
              }/dealer-stats${
                filterId
                  ? `?${new URLSearchParams({
                      filter: JSON.stringify({ filterId }),
                    }).toString()}`
                  : ""
              }`}
            />
          ))}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box pt="24px">
            {sideTotalItems.map(it => (
              <SideTotalBox
                key={it.value}
                color={it.color}
                label={it.label}
                value={dashData[it.value]}
              />
            ))}
          </Box>
          {isMobile ? (
            ""
          ) : (
            <Box pt="40px" textAlign="right" className={classes.bgImg}>
              <img
                alt="Appraisal dashboard"
                height="540"
                src="/images/AppraisalDashboard.png"
                width="720"
              />
            </Box>
          )}
        </Box>
      </div>
    );
  },
);

const TopTotalBox = React.memo(
  /**
   * @typedef {object} TopTotalBoxProps
   * @property {React.Component} icon
   * @property {string} label
   * @property {number} [total]
   * @property {string} [totalHelp]
   * @property {number} value
   * @property {string} valueHelp
   * @property {Dealer} dealer
   * @property {string} link
   *
   * @param {TopTotalBoxProps} param0
   */

  function TopTotalBox({
    icon: Icon,
    label,
    total,
    totalHelp,
    value = 0,
    valueHelp,
    dealer,
    link,
  }) {
    let text;
    let labelText;
    let tooltipText;
    if (total !== undefined) {
      text = `${formatThousands(value)}/${formatThousands(total)}`;
      const percentage =
        total <= 0
          ? "0"
          : // Using fixed 2 digit decimal...
            ((value / total) * 100).toFixed(2).toString();
      // Using round to next whole number..
      // Math.round((value / total) * 100).toString();
      labelText = label + ` (${percentage}%)`;
      tooltipText = `${valueHelp} / ${totalHelp}`;
    } else {
      text = formatThousands(value);
      labelText = label;
      tooltipText = valueHelp;
    }
    const classes = useStyles();

    return (
      <PageLink className={classes.statsBox} to={`/${dealer.slug}/${link}`}>
        <Box
          bgcolor="#4E50FF"
          color="#FFFFFF"
          display="flex"
          height="40px"
          alignItems="center"
          justifyContent="center"
          width="40px"
          borderRadius="3px"
        >
          <Icon />
        </Box>
        <Box className={classes.statsAmounts} title={tooltipText}>
          {text}
        </Box>
        <Box
          color="#8D98C2"
          fontSize="12px"
          fontWeight="500"
          letterSpacing="0"
          pt="4px"
          whiteSpace="nowrap"
        >
          {labelText}
        </Box>
      </PageLink>
    );
  },
);

const SideTotalBox = React.memo(
  /**
   * @typedef {object} SideTotalBoxProps
   * @property {string} color
   * @property {string} label
   * @property {number} value
   *
   * @param {SideTotalBoxProps} param0
   */
  function SideTotalBox({ color = "#00D289", label, value }) {
    const classes = useStyles();

    return (
      <Box className={classes.sideTotalItem}>
        <Box padding="4px 16px">
          <Dot color={color} size={10} />
        </Box>
        <div>
          <Box
            color="#141932"
            fontSize="24px"
            fontWeight="600"
            letterSpacing="0"
          >
            {formatAmountUSD(value)}
          </Box>
          <Box
            color="#141932"
            fontSize="16px"
            fontWeight="500"
            letterSpacing="0"
            pt="4px"
          >
            {label}
          </Box>
        </div>
      </Box>
    );
  },
);
