import React from "react";
import {
  // Box,
  DialogContent,
  IconButton,
  // Typography,
  // makeStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
// Local
import {
  CancelSaveDialogActions,
  Field,
  FormSection,
  PhoneField,
  SimpleDialog,
} from "../../../components";
import {
  Form,
  useFormik,
  mapFormDefaults,
  useOnMountAsync,
} from "../../../lib";
import { appraisalActions, useAction } from "../../../state";

const defaultValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
};

const styles = {
  /** @type {React.CSSProperties} */
  gridColSpan2: {
    gridColumn: "span 2",
  },
};

const EditAppraisalContactDialog = React.memo(
  /**
   *
   */
  function EditAppraisalContactDialog({ recordId, onChange, onClose }) {
    const getData = useAction(appraisalActions.getById);
    const saveData = useAction(appraisalActions.saveContact);
    const [initialValues, setInitialValues] = React.useState(defaultValues);
    const [busy, setBusy] = React.useState(true);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      // validationSchema,
      async onSubmit(values) {
        if (frm.dirty) {
          setBusy(true);
          try {
            await saveData(recordId, values);
          } finally {
            setBusy(false);
          }
          if (onChange) {
            onChange();
          }
        }
        onClose();
      },
    });

    const { vendorName } = frm.values;
    const noVendor = !vendorName;

    useOnMountAsync(async state => {
      const data = await getData(recordId);
      if (!state.mounted) return;
      const defaults = data.vendorName
        ? {
            ...defaultValues,
            vendorName: "",
          }
        : defaultValues;
      setInitialValues(mapFormDefaults(defaults, data));
      setBusy(false);
    });

    return (
      <SimpleDialog
        maxWidth="md"
        onClose={onClose}
        open={true}
        title="Appraisal Contact"
      >
        <Form form={frm}>
          <DialogContent>
            {!noVendor && (
              <FormSection pt={0} title="Vendor">
                <Field
                  name="vendorName"
                  label="Vendor Name"
                  autoFocus
                  fullWidth
                  style={styles.gridColSpan2}
                />
              </FormSection>
            )}
            <FormSection pt={noVendor ? 0 : undefined} title="Name">
              <Field
                name="firstName"
                label="First Name"
                autoFocus={noVendor}
                fullWidth
              />
              <Field name="lastName" label="Last Name" />
            </FormSection>
            <FormSection title="Contact info">
              <PhoneField name="phoneNumber" label="Phone Number" />
              <Field name="email" label="Email" type="email" />
            </FormSection>
          </DialogContent>
          <CancelSaveDialogActions disabled={busy} onCancel={onClose} />
        </Form>
      </SimpleDialog>
    );
  },
);

export const EditAppraisalContactButton = React.memo(
  /**
   * @param {{recordId:number,onChange?:()=>void}} param0
   */
  function EditAppraisalContactButton({ disabled, recordId, onChange }) {
    const [editing, setEditing] = React.useState(false);
    const onClick = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (e && e.preventDefault) e.preventDefault();
        setEditing(true);
      },
      [],
    );
    const onClose = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        setEditing(false);
      },
      [],
    );

    return (
      <>
        <IconButton
          disabled={disabled}
          onClick={onClick}
          size="small"
          title="Edit contact info"
        >
          <EditIcon />
        </IconButton>
        {editing && (
          <EditAppraisalContactDialog
            recordId={recordId}
            onChange={onChange}
            onClose={onClose}
          />
        )}
      </>
    );
  },
);
