import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    root: {
      alignSelf: "center",
      minWidth: "1167px",
      [theme.breakpoints.down("md")]: {
        minWidth: "unset",
        width: "100%",
        "& .offerBadge > .MuiBadge-badge": {
          marginRight: "20vw",
        },
      },
    },
    divider: {
      borderTop: "1px solid #DADFF6",
      [theme.breakpoints.down("xs")]: {
        marginLeft: -16,
        marginRight: -16,
      },
    },
    container: {
      minHeight: 900,
      // paddingTop: 16, // moved to detailTabs
      minWidth: 1350,
      // borderRight: isWorksheet ? "1px solid #ACBBCD" : undefined,
      // flex: 1,
      // padding: "32px 40px",
      [theme.breakpoints.down("xs")]: {
        minWidth: "unset",
        maxWidth: "100vw",
      },
    },
    detailTabs: {
      paddingTop: 16,
      "& button": {
        textTransform: "capitalize",
        fontSize: 16,
        fontWeight: 500,
        color: "#141932",
        opacity: 1,
        "&.Mui-selected": {
          color: "#4E50FF",
        },
      },
      "& .MuiTabs-indicator": {
        background: "#4E50FF",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: -16,
        paddingRight: 16,
        maxWidth: "100vw",
        "& button": {
          marginRight: 0,
        },
      },
    },
  }),
  {
    classNamePrefix: "ShowAppraisalPage",
  },
);
