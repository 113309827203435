import { ROLES } from "../../config";
import { DealerSettingsPage } from "./settings/DealerSettingsPage";

export const DealerPages = {
  profile: {
    path: "/:dslug/settings/profile",
    roles: [ROLES.DEALER_ADMIN],
    title: "Dealer Profile",
    view: DealerSettingsPage,
  },
  defaults: {
    path: "/:dslug/settings/defaults",
    roles: [ROLES.DEALER_ADMIN],
    title: "Defaults",
    view: DealerSettingsPage,
  },
  affiliates: {
    path: "/:dslug/settings/affiliates",
    roles: [ROLES.DEALER_ADMIN],
    title: "Affiliates",
    view: DealerSettingsPage,
  },
  banks: {
    path: "/:dslug/settings/banks",
    roles: [ROLES.DEALER_ADMIN],
    title: "Banks",
    view: DealerSettingsPage,
  },
  campaigns: {
    path: "/:dslug/settings/campaigns",
    roles: [
      ROLES.DEALER_ADMIN,
      ROLES.ACQ_MANAGER,
      ROLES.FINANCE_MANAGER,
      ROLES.SALES_MANAGER,
    ],
    title: "Campaigns",
    view: DealerSettingsPage,
  },
  users: {
    path: "/:dslug/settings/users",
    roles: [ROLES.DEALER_ADMIN],
    title: "Users",
    view: DealerSettingsPage,
  },
  emails: {
    path: "/:dslug/settings/emails",
    roles: [ROLES.DEALER_ADMIN],
    title: "Emails",
    view: DealerSettingsPage,
  },
  emailTemplates: {
    path: "/:dslug/settings/email-templates",
    roles: [ROLES.DEALER_ADMIN],
    title: "Email templates",
    view: DealerSettingsPage,
  },
};
