import { makeStyles } from "@material-ui/core";
// import pattern from "../../assets/images/login_cars_pattern.svg";

export const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    logo: {
      marginBottom: 32,
    },
    errorText: {
      color: "#cc0000",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      marginTop: 40,
      "& .MuiInputBase-root": {
        height: 48,
      },
    },
    flexJustifySpace: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    },
    forgotPassBox: {
      marginTop: 16,
      "& a": {
        color: "#1581FF",
        "&:hover": {
          opacity: 0.5,
        },
      },
    },
  }),
  {
    classNamePrefix: "AuthPage",
  },
);
