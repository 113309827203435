import React from "react";
import {
  //
  Avatar,
  makeStyles,
} from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

const useStyles = makeStyles(
  theme => ({
    root: {
      flexWrap: "wrap",
    },
    item: {
      color: "#141932",
      backgroundColor: "#EBEBEB",
    },
  }),
  {
    classNamePrefix: "DealerUserRoles",
  },
);

export const DealerUserRoles = React.memo(
  /**
   * @param {{row:DealerUser}} param0
   */
  function DealerUserRoles({ row }) {
    const classes = useStyles();
    const { roles } = row;

    return (
      <AvatarGroup className={classes.root}>
        {roles.map(it => (
          <Avatar
            key={it.id}
            className={`nohover ${classes.item}`}
            title={it.name}
          >
            {it.abbrev}
          </Avatar>
        ))}
      </AvatarGroup>
    );
  },
);
