import React from "react";
import {
  Box,
  Button,
  DialogContent,
  IconButton,
  // Typography,
  makeStyles,
} from "@material-ui/core";
// Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import EditIcon from "@material-ui/icons/Edit";
import exactOfferImg from "../../../assets/images/arrow_in_target.png";
import rangeOfferImg from "../../../assets/images/cards_money.png";
import schedOfferImg from "../../../assets/images/house_front_door.png";
// Local
import {
  // CancelSaveDialogActions,
  CheckboxField,
  DollarField,
  // Field,
  // PhoneField,
  SimpleDialog,
  SplitButton,
} from "../../../components";
import {
  Form,
  titleCase,
  formatDecimal,
  formatThousands,
  formatAmountUSD,
  isEmpty,
  useFormik,
} from "../../../lib";
import {
  //
  appraisalActions,
  useAction,
  useDealer,
} from "../../../state";

const useStyles = makeStyles(
  theme => ({
    root: ({ align }) => ({
      ...(align === "right"
        ? {
            [theme.breakpoints.up("md")]: {
              "& .MuiDialog-paper": {
                marginLeft: 832,
              },
            },
          }
        : undefined),
    }),
    offerTemplates: {
      display: "flex",
      justifyContent: "center",
      "& .MuiButton-root": {
        borderColor: "#BBC2E1",
        borderRadius: 3,
        borderWidth: 1,

        height: 182,
        width: 166,

        marginLeft: 6.5,
        marginRight: 6.5,
      },
      '& .MuiButton-root[data-selected="true"]': {
        borderColor: "#401AFF",
        borderWidth: 2,
      },
    },
    nextBtn: {
      backgroundColor: "#4E50FF",
      borderRadius: 3,
      padding: "10px 32px",
      "&:hover": {
        backgroundColor: "#4E50FF",
      },
    },
    nextBtnDropdown: {
      backgroundColor: "#4E50FF",
      borderRadius: 3,
      "&:hover": {
        backgroundColor: "#4E50FF",
      },
    },
  }),
  {
    classNamePrefix: "AppraisalOfferDialog",
  },
);

const defaultValues = {
  customerProfit: 0,
  includeDamages: false,
  offerAmount: "0.00",
  offerAmountMin: "0.00",
  offerAmountMax: "0.00",
  offerTemplate: 0,
  printPdf: false,
  screenIdx: 0,
  sendPdf: false,
  showCustomerEquity: false,
  textPdf: "",
};

export const AppraisalOfferDialog = React.memo(
  /**
   * @typedef {object} AppraisalOfferDialogProps
   * @property {"right"} [align]
   * @property {"carOffer"|"auction"|"retail"} offerType
   * @property {()=>void} onClose
   * @property {import("./AppraisalWorksheetTab").WorksheetProfits} profits
   * @property {import("./AppraisalWorksheetTab").WorksheetProposals} proposals
   * @property {Appraisal} record
   * @property {import("./AppraisalWorksheetTab").WorksheetValuations} valuations
   * @property {import("./AppraisalWorksheetTab").WorksheetValues} worksheetValues
   *
   * @param {AppraisalOfferDialogProps} param0
   */
  function AppraisalOfferDialog({
    align,
    offerType,
    onClose,
    profits,
    proposals,
    record: row,
    valuations,
    worksheetValues,
  }) {
    const classes = useStyles({ align });
    const dealer = useDealer();
    const makeOffer = useAction(appraisalActions.makeOffer);

    const [initialValues, setInitialValues] = React.useState(defaultValues);
    const [busy, setBusy] = React.useState(false);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      validateOnChange: false,
      // validationSchema,
      async onSubmit(values) {
        setBusy(true);
        try {
          const offer = {
            amount: parseFloat(values.offerAmount),
            amountMin: parseFloat(values.offerAmountMin) || 0,
            amountMax: parseFloat(values.offerAmountMax) || 0,
            customerProfit: values.customerProfit,
            includeDamages: values.includeDamages,
            printPdf: values.printPdf,
            sendPdf: values.sendPdf,
            showCustomerEquity: values.showCustomerEquity,
            template: values.offerTemplate,
            textPdf: values.textPdf,
            type: offerType,
          };
          await makeOffer(row.id, offer);
        } finally {
          setBusy(false);
        }
        onClose();
      },
    });

    React.useEffect(
      () => {
        const { [`${offerType}CustomerProfit`]: customerProfit } = profits;
        const { [`${offerType}Proposal`]: offerAmount, profitObjective } =
          worksheetValues;
        const objective = parseFloat(profitObjective);
        const rangedOfferData = [];
        if (profits.carOfferTotalProfit >= objective) {
          rangedOfferData.push(worksheetValues.carOfferProposal);
        }
        if (profits.auctionTotalProfit >= objective) {
          rangedOfferData.push(worksheetValues.auctionProposal);
        }
        if (profits.retailTotalProfit >= objective) {
          rangedOfferData.push(worksheetValues.retailProposal);
        }
        const initialValues = {
          ...defaultValues,
          customerProfit,
          offerAmount: offerAmount ?? "0.00",
          offerAmountMin: formatDecimal(Math.min(...rangedOfferData)),
          offerAmountMax: formatDecimal(Math.max(...rangedOfferData)),
        };
        setInitialValues(initialValues);
      },
      /* eslint-disable-next-line */
      [worksheetValues],
    );

    const {
      setFieldValue,
      values: { screenIdx, offerTemplate },
      submitForm,
      validateForm,
      setTouched,
      setErrors,
    } = frm;

    const onClickNext = React.useCallback(
      /**
       * @param {React.SyntheticEvent<HTMLElement>} e
       * @param {number} [index] SplitButton index...
       */
      async (e, index) => {
        const printing = index === 1;
        if (printing) {
          setFieldValue("printPdf", true);
        }
        if (offerTemplate === 2 || screenIdx > 0) {
          const errors = await validateForm();
          if (!isEmpty(errors)) {
            setTouched(
              Object.keys(errors).reduce(
                (touched, key) => ({
                  ...touched,
                  [key]: true,
                }),
                {},
              ),
            );
            setErrors(errors);
            return;
          }
          await submitForm();
          return;
        }
        setFieldValue("screenIdx", screenIdx + 1);
      },
      [
        offerTemplate,
        screenIdx,
        setFieldValue,
        submitForm,
        validateForm,
        setTouched,
        setErrors,
      ],
    );

    return (
      <SimpleDialog
        maxWidth="md"
        className={classes.root}
        onClose={onClose}
        open={true}
        // title="Appraisal Contact"
      >
        <Form form={frm}>
          <DialogContent>
            {screenIdx === 0 ? (
              <SelectOfferTemplate
                busy={busy}
                classes={classes}
                dealer={dealer}
                frm={frm}
                record={row}
                onClickNext={onClickNext}
              />
            ) : (
              <MessagePreview
                busy={busy}
                classes={classes}
                dealer={dealer}
                frm={frm}
                record={row}
                onClickNext={onClickNext}
              />
            )}
          </DialogContent>
          {/* <CancelSaveDialogActions disabled={busy} onCancel={onClose} /> */}
        </Form>
      </SimpleDialog>
    );
  },
);

const SelectOfferTemplate = React.memo(
  /**
   * @param {{classes:ReturnType<useStyles>,dealer:Dealer,frm:ReturnType<useFormik>,record:Appraisal}} param0
   */
  function SelectOfferTemplate({
    busy,
    classes,
    frm,
    record: row,
    onClickNext,
  }) {
    const onClickOfferTemplate = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        const idx = parseInt(e.currentTarget.dataset.idx);
        frm.setFieldValue("offerTemplate", idx);
      },
      [frm],
    );

    const { offerTemplate } = frm.values;
    const isSchedOffer = offerTemplate === 2;
    return (
      <>
        <Box
          color="#141932"
          fontSize="20px"
          fontWeight="600"
          letterSpacing="0"
          textAlign="center"
          paddingTop="24px"
          paddingBottom="39px"
        >
          Which type of offer would you like to send?
        </Box>
        <div className={classes.offerTemplates}>
          <Button
            data-idx="0"
            data-selected={offerTemplate === 0 ? "true" : ""}
            disableFocusRipple
            disableElevation
            disableRipple
            variant="outlined"
            onClick={onClickOfferTemplate}
          >
            <div>
              <img src={exactOfferImg} alt="" />
              <Box
                color="#141932"
                fontSize="14px"
                fontWeight="500"
                letterSpacing="0"
                paddingTop="22px"
              >
                Exact offer
              </Box>
            </div>
          </Button>
          <Button
            data-idx="1"
            data-selected={offerTemplate === 1 ? "true" : ""}
            disableFocusRipple
            disableElevation
            disableRipple
            variant="outlined"
            onClick={onClickOfferTemplate}
          >
            <div>
              <img src={rangeOfferImg} alt="" />
              <Box
                color="#141932"
                fontSize="14px"
                fontWeight="500"
                letterSpacing="0"
                paddingTop="22px"
              >
                Ranged offer
              </Box>
            </div>
          </Button>
          <Button
            data-idx="2"
            data-selected={isSchedOffer ? "true" : ""}
            disableFocusRipple
            disableElevation
            disableRipple
            variant="outlined"
            onClick={onClickOfferTemplate}
          >
            <div>
              <img src={schedOfferImg} alt="" />
              <Box
                color="#141932"
                fontSize="14px"
                fontWeight="500"
                letterSpacing="0"
                paddingTop="9px"
              >
                No offer,
                <br />
                Bring to store
              </Box>
            </div>
          </Button>
        </div>
        <Box pt="40px" pb="40px" textAlign="center">
          <Button
            className={classes.nextBtn}
            variant="contained"
            onClick={onClickNext}
            disabled={busy}
          >
            {isSchedOffer ? "Send offer" : "Continue to offer"}
          </Button>
        </Box>
      </>
    );
  },
);

const MessagePreview = React.memo(
  /**
   * @param {{classes:ReturnType<useStyles>,dealer:Dealer,frm:ReturnType<useFormik>,record:Appraisal}} param0
   */
  function MessagePreview({
    busy,
    classes,
    dealer,
    frm,
    record: row,
    onClickNext,
  }) {
    const dealerLogoUrl = dealer.logoUrl;

    const {
      values: {
        customerProfit,
        // sendPdf,
        offerAmount,
        offerTemplate,
        showCustomerEquity,
      },
      setFieldValue,
    } = frm;
    const customerProfitUSD = formatAmountUSD(customerProfit);
    const formattedOfferAmount = formatAmountUSD(offerAmount);
    const isExactOffer = offerTemplate === 0;
    const onClickBack = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        setFieldValue("screenIdx", 0);
      },
      [setFieldValue],
    );
    return (
      <>
        <Box
          color="#141932"
          fontSize="20px"
          fontWeight="600"
          letterSpacing="0"
          lineHeight="30px"
          pb="24px"
        >
          Message preview
        </Box>
        <Box bgcolor="#ECEFF5" p="24px">
          <Box
            bgcolor="#ffffff"
            color="#141932"
            py="24px"
            px="40px"
            textAlign="center"
          >
            <img height="64" src={dealerLogoUrl} alt="Dealer logo" />
            <Box
              fontSize="20px"
              fontWeight="600"
              letterSpacing="0"
              lineHeight="30px"
              pt="22px"
            >
              Hey {titleCase(row.firstName)}, great news! It's here!
              <br />
              Check out your offer
            </Box>
            <Box fontSize="16px" fontWeight="500" letterSpacing="0" pt="24px">
              {row.year} {row.make} {row.model} {row.trim}
            </Box>
            <Box fontSize="14px" fontWeight="500" letterSpacing="0" pt="8px">
              {formatThousands(row.inspectedMileage ?? row.mileage)} miles
            </Box>
            <Box fontSize="14px" fontWeight="500" letterSpacing="0" pt="8px">
              {row.color}
            </Box>
            <Box
              color="#8D98C2"
              fontSize="14px"
              fontWeight="500"
              letterSpacing="0"
              pt="8px"
            >
              {row.vin}
            </Box>
            {isExactOffer ? (
              <>
                <Box
                  fontSize="24px"
                  fontWeight="600"
                  letterSpacing="0"
                  lineHeight="35px"
                  pt="16px"
                >
                  {formattedOfferAmount}
                </Box>
                <CheckboxField
                  name="showCustomerEquity"
                  label="Show customer equity"
                />
                <Box
                  fontSize="12px"
                  fontWeight="500"
                  letterSpacing="0"
                  pb="8px"
                >
                  {showCustomerEquity ? (
                    `Customer profit ${customerProfitUSD}`
                  ) : (
                    <>&nbsp;</>
                  )}
                </Box>
                <CheckboxField name="sendPdf" label="Send a PDF" />
                &nbsp;
                <CheckboxField
                  // disabled={!sendPdf}
                  name="includeDamages"
                  label="Include damages in PDF"
                />
                {/* <div>
                  <PhoneField
                    // disabled={!sendPdf}
                    name="textPdf"
                    label="Send SMS/Text offer to"
                    margin="dense"
                    size="small"
                  />
                </div> */}
              </>
            ) : (
              <Box display="flex" justifyContent="center" pt="24px">
                <Box pr="8px" width="180px">
                  <DollarField name="offerAmountMin" label="Offer low" />
                </Box>
                <Box pl="8px" width="180px">
                  <DollarField name="offerAmountMax" label="Offer high" />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" py="24px" ml="-12px">
          <IconButton title="Go back" onClick={onClickBack}>
            <ArrowBackIcon />
          </IconButton>
          <Box
            color="#141932"
            fontSize="14px"
            fontWeight="500"
            letterSpacing="0"
            lineHeight="21px"
            display="flex"
            alignItems="center"
            whiteSpace="nowrap"
            pl="8px"
          >
            <img src="/images/mmr-logo.jpg" alt="MMR" height="22px" />
            &nbsp; {formatAmountUSD(row.mmrVal)}
          </Box>
          <Box
            color="#141932"
            fontSize="14px"
            fontWeight="500"
            letterSpacing="0"
            lineHeight="21px"
            display="flex"
            alignItems="center"
            whiteSpace="nowrap"
            px="24px"
          >
            <img src="/images/jdp-logo.png" alt="JDP" height="22px" />
            &nbsp; {formatAmountUSD(row.jdpVal)}
          </Box>
          {isExactOffer ? (
            <SplitButton
              onClick={onClickNext}
              options={splitButtonOptions}
              renderLabel={renderButtonLabel}
              className={classes.nextBtn}
              dropdownClassname={classes.nextBtnDropdown}
              variant="contained"
              disabled={busy}
            />
          ) : (
            <Button
              className={classes.nextBtn}
              variant="contained"
              onClick={onClickNext}
              disabled={busy}
            >
              <Box whiteSpace="nowrap">Send offer</Box>
            </Button>
          )}
        </Box>
      </>
    );
  },
);

const splitButtonOptions = ["Send offer", "Print offer"];

function renderButtonLabel(label) {
  return <Box whiteSpace="nowrap">{label}</Box>;
}
