import { proxy, useSnapshot } from "valtio";

export interface SectionDetails {
  id: number;
  title: string;
  internal_name: "mp" | "ai" | "ro" | "he";
}
export interface DraggableState {
  order: SectionDetails[];
  collapse: { id: number; isCollapsed: boolean }[];
}
// TODO : maybe make this a db call
export const defaultOrder = [
  { id: 1, title: "Missing payoff", internal_name: "mp" },
  {
    id: 2,
    title: "Awaiting inspection",
    internal_name: "ai",
  },
  { id: 3, title: "Resend offer", internal_name: "ro" },
  { id: 4, title: "High equity", internal_name: "he" },
] as SectionDetails[];

export const draggableState = proxy<DraggableState>({
  order: JSON.parse(localStorage.getItem("dragOrder")) ?? defaultOrder,
  collapse:
    JSON.parse(localStorage.getItem("dragCollapse")) ??
    defaultOrder.map(de => {
      return { id: de.id, isCollapsed: false };
    }),
});

export const reorder = (startIndex: number, endIndex: number) => {
  const result = draggableState.order?.map(i => i.id);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return draggableState.order.sort(
    (a, b) => result.indexOf(a.id) - result.indexOf(b.id),
  );
};

export function isCollapse(id: number) {
  return draggableState.collapse.find(s => s.id === id).isCollapsed ?? false;
}

export function toggleCollapse(id: number) {
  const find = draggableState.collapse.find(s => s.id === id);
  if (find) {
    return (draggableState.collapse = [
      ...draggableState.collapse.filter(o => o.id !== id),
      { id, isCollapsed: !find.isCollapsed },
    ]);
  }
}

export function useDragState() {
  return useSnapshot(draggableState);
}
