import React from "react";
import { FormikProvider } from "formik";
import {
  //
  Box,
  Button,
  // Typography,
  // makeStyles,
} from "@material-ui/core";
// Local
import { SelectField } from "../../../components";
import {
  //
  formatAmountUSD,
  formatDateTime,
  // formatThousands,
  useFormik,
  useOnMount,
} from "../../../lib";
import {
  appraisalActions,
  useAction,
  appraisalSelectors,
  useSelector,
} from "../../../state";

const colorItems = [
  { id: "", title: "No color" },
  { id: "Beige", title: "Beige" },
  { id: "Black", title: "Black" },
  { id: "Blue", title: "Blue" },
  { id: "Brown", title: "Brown" },
  { id: "Burgundy", title: "Burgundy" },
  { id: "Charcoal", title: "Charcoal" },
  { id: "Gold", title: "Gold" },
  { id: "Gray", title: "Gray" },
  { id: "Green", title: "Green" },
  { id: "Off-white", title: "Off-white" },
  { id: "Orange", title: "Orange" },
  { id: "Purple", title: "Purple" },
  { id: "Red", title: "Red" },
  { id: "Silver", title: "Silver" },
  { id: "Turquoise", title: "Turquoise" },
  { id: "White", title: "White" },
  { id: "Yellow", title: "Yellow" },
];

const defaultValues = {
  trim: "",
  range: "average",
  grade: "",
  color: "",
};

const gradeItems = [
  {
    id: "",
    title: "No grade",
  },
];
for (let i = 10; i < 51; i++) {
  const id = i.toString();
  gradeItems.push({
    id,
    title: id.substr(0, 1) + "." + id.substr(1),
  });
}

const rangeItems = [
  { id: "average", title: "Average" },
  { id: "above", title: "Above" },
  { id: "below", title: "Below" },
];

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       // margin: 0,
//     },
//   }),
//   {
//     classNamePrefix: "AppraisalMmrTab",
//   },
// );

export const AppraisalMmrTab = React.memo(
  /**
   * @param {{ record: Appraisal }} param0
   */
  function AppraisalMmrTab({ closed, record }) {
    // const classes = useStyles();

    const getApiResultInfo = useAction(appraisalActions.getApiResultInfo);
    const rerunMmrVal = useAction(appraisalActions.rerunMmrVal);
    const showing = useSelector(appraisalSelectors.showing);
    /** @type {MMRData} */
    const result = React.useMemo(() => showing?.mmrValResult ?? {}, [showing]);
    const {
      createdAt,
      // selection, // : { trim, range, grade, color: selectedColor } = {},
      data: resultData = null,
    } = result;

    const [busy, setBusy] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [selectedItem, setSelectedItem] = React.useState(null);

    const [trimItems, setTrimItems] = React.useState([
      { id: "", title: "None" },
    ]);

    const [initialValues, setInitialValues] = React.useState(defaultValues);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      // validationSchema, // They want to let it submit no matter what.
      validateOnBlur: false,
      validateOnChange: false,
      // async onSubmit(values, helpers) {
      //   setBusy(true);
      //   console.log("SUBMIT", values);
      //   setBusy(false);
      // },
    });
    const { trim } = frm.values;

    React.useEffect(() => {
      const {
        selection, // : { trim, range, grade, color: selectedColor } = {},
        data = null,
      } = result;
      const items = data?.items ?? [];
      if (items.length === 0) {
        return;
      }
      const trimItems = items.map(it => {
        const trim = it.description?.trim ?? "";
        return { id: trim, title: trim };
      });
      setTrimItems(trimItems);
      setInitialValues({
        trim: selection?.trim ?? record.trim,
        range: selection?.range ?? "average",
        grade: selection?.grade ?? "",
        color: selection?.color ?? "",
      });
      setLoading(false);
    }, [result, record]);

    React.useEffect(() => {
      const items = resultData?.items ?? [];
      if (items.length === 0) {
        return;
      }
      const item = items.find(it => it.description?.trim === trim);
      setSelectedItem(item);
    }, [trim, resultData]);

    useOnMount(() => {
      if (!record.mmrValResultId) {
        setLoading(false);
        return;
      }
      getApiResultInfo(record.id, "mmrValResult", record.mmrValResultId);
    });

    const onClickRerun = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        setBusy(true);
        try {
          await rerunMmrVal(record.id, {
            ...frm.values,
          });
        } catch (err) {
          window.alert("There was an error with MMR. Please try again later.");
        } finally {
          setBusy(false);
        }
      },
      [frm.values, record, rerunMmrVal],
    );

    if (loading) {
      return <Box padding="60px">Loading...</Box>;
    }
    return (
      <FormikProvider value={frm}>
        <Box flex="1" padding="20px">
          <Box>
            <SelectField
              disabled={closed || busy}
              name="trim"
              label="Trim"
              items={trimItems}
              fullWidth
            />
          </Box>
          <Box pt="22px">
            <SelectField
              disabled={closed || busy}
              name="range"
              label="Range"
              items={rangeItems}
              fullWidth
            />
          </Box>
          <Box display="flex" pt="22px">
            <Box flex="1" mr="20px">
              <SelectField
                disabled={closed || busy}
                name="grade"
                label="Grade"
                items={gradeItems}
                fullWidth
              />
            </Box>
            <Box flex="1" ml="20px">
              <SelectField
                disabled={closed || busy}
                name="color"
                label="Color"
                items={colorItems}
                fullWidth
              />
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pt="22px"
          >
            <Box color="#8D98C2" fontSize="14px">
              {createdAt ? (
                <em>Results from {formatDateTime(createdAt)}</em>
              ) : (
                <>&nbsp;</>
              )}
            </Box>
            <Button
              disabled={closed || busy}
              onClick={onClickRerun}
              variant="contained"
            >
              {createdAt ? "Rerun" : "Run"}
            </Button>
          </Box>
          {!selectedItem ? (
            <Box padding="60px">No valuation performed on this record.</Box>
          ) : (
            <>
              <Box pt="8px">
                <ValueItem
                  label="Base"
                  value={selectedItem.wholesale[frm.values.range]}
                />
                <ValueItem
                  label="Adjustment"
                  value={
                    selectedItem.adjustedPricing.wholesale[frm.values.range] -
                    selectedItem.wholesale[frm.values.range]
                  }
                />
                <ValueItem
                  label="Wholesale value"
                  value={
                    selectedItem.adjustedPricing.wholesale[frm.values.range]
                  }
                  isLastItem
                />
              </Box>
            </>
          )}
        </Box>
      </FormikProvider>
    );
  },
);

const ValueItem = React.memo(
  /**
   *
   */
  function ValueItem({ label, value, isLastItem }) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pt="8px"
      >
        <Box color="#8D98C2" fontSize="14px" fontWeight="500" letterSpacing="0">
          {label}
        </Box>
        <Box
          color="#141932"
          fontSize={isLastItem ? "18px" : "16px"}
          letterSpacing="0"
          lineHeight="25px"
          fontWeight={isLastItem ? "700" : undefined}
        >
          {formatAmountUSD(value)}
        </Box>
      </Box>
    );
  },
);
