import React from "react";
import clsx from "clsx";
import { Box, BoxProps, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  theme => ({
    root: {
      // margin: 0,
    },
    titleBox: {
      // margin: 0,
    },
    content: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "repeat(2, 300px)",
      gridGap: "8px 40px",
      paddingRight: 10,
      "& .MuiFormControl-root": {
        width: "100%",
        maxWidth: "100%",
        // "& .MuiInputLabel-root": {
        //   // fontFamily: "Lato",
        // },
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
        "& .MuiFormControl-root": {
          marginTop: 12,
        },
      },
    },
  }),
  {
    classNamePrefix: "FormSection",
  },
);

export interface FormSectionProps extends BoxProps {
  classes?: Partial<Record<"root" | "content", "string">>;
  title?: string;
}

export const FormSection = React.memo(function FormSection({
  children,
  className,
  title,
  ...props
}: FormSectionProps) {
  const classes = useStyles();
  return (
    <Box pt="32px" className={clsx(classes.root, className)} {...props}>
      {title && (
        <Box pb="8px" className={classes.titleBox}>
          <Typography variant="subtitle2">{title}</Typography>
        </Box>
      )}
      <div className={classes.content}>{children}</div>
    </Box>
  );
});
