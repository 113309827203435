import React from "react";
import {
  // Button,
  DialogContent,
  IconButton,
  makeStyles,
} from "@material-ui/core";
// import clsx from "clsx";
// Icons
import PencilIcon from "@material-ui/icons/Create";
// Local
import {
  CancelSaveDialogActions,
  CheckboxField,
  Field,
  PhoneField,
  SimpleDialog,
} from "../components";
import {
  //
  Form,
  mapFormDefaults,
  useFormik,
  yup,
} from "../lib";
import { Appraisals } from "../services";
// import {
//   appraisalActions,
//   // authSelectors,
//   useAction,
//   // useSelector,
// } from "../state";

const useStyles = makeStyles(
  theme => ({
    content: {
      // Cause scrollbars to always be present.
      minHeight: "100%",
      "& h6": {
        marginTop: 0,
        fontSize: 16,
        fontWeight: 500,
      },
    },
    addressFields: {
      paddingTop: 32,
    },
    contactHead: {
      fontSize: 16,
      fontWeight: 500,
      paddingTop: 32,
      paddingBottom: 16,
    },
    contactFields: {
      display: "flex",
      paddingBottom: 32,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        paddingBottom: 0,
      },
    },
    flexRight: {
      marginLeft: 24,
    },
    flexRightLg: {
      marginLeft: 24,
      [theme.breakpoints.down("sm")]: {
        marginLeft: "unset",
        marginTop: 16,
      },
    },
  }),
  {
    classNamePrefix: "LeaseLocationDialog",
  },
);

/** @type {Appraisals.ContactInfo & {agreed:boolean}} */
const defaultValues = {
  // Location details
  agreed: false,
  address: "",
  // Contact details
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

const validationSchema = yup.object({
  agreed: yup.boolean().equals([true], "Agreement required."),
  address: yup.string(),
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup.string().email(),
  phone: yup.string(),
});

export const LeaseLocationDialog = React.memo(
  /**
   * @param {{
   * onClose:Function;
   * onSubmit:(values:any) => Promise<void>;
   * title?: string;
   * }} param0
   */
  function LeaseLocationDialog({
    onClose,
    onSubmit,
    title = "Update location",
    row,
  }) {
    const classes = useStyles();

    const [busy, setBusy] = React.useState(false);

    const frm = useFormik({
      enableReinitialize: false,
      initialValues: mapFormDefaults(
        defaultValues,
        row.lrtnInfo?.location ?? {},
      ),
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: validationSchema,
      async onSubmit(values, helpers) {
        setBusy(true);
        try {
          // console.log("SUBMIT", values);
          await onSubmit(values);
        } catch (err) {
          console.log("" + err);
          window.alert("There was an error. Please try again.");
          setBusy(false);
        }
      },
    });
    const {
      isSubmitting,
      // getFieldHelpers,
      // values,
    } = frm;
    const loading = busy || isSubmitting;
    // const { ... } = values;

    return (
      <SimpleDialog
        className={classes.root}
        fullScreenMobile
        maxWidth="sm"
        onClose={onClose}
        open={true}
        title={title}
      >
        <Form form={frm}>
          <DialogContent className={classes.content}>
            <CheckboxField
              autoFocus
              name="agreed"
              label={
                "I acknowledge that I will contact the bank and " +
                "inform them of the pickup's location change."
              }
            />
            <div className={classes.addressFields}>
              <Field name="address" label="Address" fullWidth />
            </div>
            <div className={classes.contactHead}>Contact</div>
            <div className={classes.contactFields}>
              <Field name="firstName" label="First name" />
              <Field
                name="lastName"
                label="Last name"
                className={classes.flexRightLg}
              />
            </div>
            <div className={classes.contactFields}>
              <Field name="email" label="Email" type="email" />
              <PhoneField
                name="phone"
                label="Phone number"
                className={classes.flexRightLg}
              />
            </div>
          </DialogContent>
          <CancelSaveDialogActions
            disabled={loading}
            onCancel={onClose}
            // saveLabel="Save"
            // topBorder
          />
        </Form>
      </SimpleDialog>
    );
  },
);

export const LeaseLocationButton = React.memo(
  /**
   * @param {{children:React.ReactNode,onChanged:Function,row:Appraisal}} param0
   */
  function LeaseLocationButton({ onChanged, row }) {
    const [editing, setEditing] = React.useState(false);
    const onClick = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (e && e.preventDefault) e.preventDefault();
        setEditing(true);
      },
      [],
    );
    const onClose = React.useCallback(
      /**
       * @type {import("@material-ui/core").ModalProps['onClose']}
       */
      (e, reason) => {
        // if (reason === "backdropClick") {
        //   return;
        // }
        setEditing(false);
      },
      [],
    );

    const onSubmit = React.useCallback(
      /** @param {Appraisals.ContactInfo & {agreed:boolean}} values */
      async values => {
        // console.log("Saving location...", values);
        await Appraisals.setLeaseReturnLocation(row.id, values);
        onClose();
        if (onChanged) {
          onChanged();
        }
      },
      [row.id, onChanged, onClose],
    );

    return (
      <>
        <IconButton size="small" onClick={onClick}>
          <PencilIcon color="disabled" />
        </IconButton>
        {editing && (
          <LeaseLocationDialog
            onClose={onClose}
            onSubmit={onSubmit}
            row={row}
          />
        )}
      </>
    );
  },
);
