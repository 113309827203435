import React from "react";
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
// Icons
import AddIcon from "@material-ui/icons/Add";
// Local
import { QRCodeImage, FileUploadModal, Toast } from "../../../components";
import { formatDateTime } from "../../../lib";

import {
  dealerApi,
  useDealer,
  useSelector,
  authSelectors,
} from "../../../state";
import { useMobile } from "../../../themes";
import { DealerCampaignEditor } from "./DealerCampaignEditor";
import { CampaignAvatar } from "../../appraisals/components/CampaignAvatar";

const useStyles = makeStyles(
  theme => ({
    actions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    list: {
      padding: 16,
      "& td > a": {
        display: "block",
        margin: -16,
        padding: 16,
        height: "100%",
      },
    },
    iconCell: {
      padding: "0 !important",
      paddingLeft: "16px !important",
    },
    noPad: {
      padding: "0 !important",
    },
  }),
  {
    classNamePrefix: "DealerCampaignsList",
  },
);

export const DealerCampaignsList = React.memo(
  /**
   *
   */
  function DealerCampaignsList() {
    const classes = useStyles();
    const dealer = useDealer();
    const isMobile = useMobile();

    /** @type {[DealerCampaign[]]} */
    const [list, setList] = React.useState([]);
    /** @type {[DealerCampaign | null]} */
    const [editing, setEditing] = React.useState(null);
    /** @type {[Boolean | null]} */
    const [sentEmail, setSentEmail] = React.useState("");
    const onClickAdd = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      e => {
        if (e.preventDefault) e.preventDefault();
        setEditing({ id: 0 });
      },
      [],
    );

    const onClickEdit = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLAnchorElement>} e */
      e => {
        if (e) {
          if (e.preventDefault) e.preventDefault();
          const id = parseInt(e.currentTarget.dataset.id);
          const editing = list.find(it => it.id === id);
          setEditing(editing);
        }
      },
      [list],
    );
    const onCloseEditor = React.useCallback(() => {
      setEditing(null);
    }, []);

    React.useEffect(() => {
      if (editing !== null) {
        // Only run at start or when `editing` changes back to null...
        return;
      }
      const state = { mounted: true };
      (async function onDealerChanged() {
        const list = await dealerApi.getCampaigns();
        if (!state.mounted) return;
        setList(list);
      })();
      return () => {
        state.mounted = false;
      };
    }, [dealer, editing]);
    const isAdmin = useSelector(authSelectors.isAdmin);
    return (
      <>
        <div className={classes.actions}>
          <Button
            onClick={onClickAdd}
            startIcon={<AddIcon />}
            variant="contained"
            color="secondary"
            size="small"
          >
            Add Campaign
          </Button>
        </div>
        <Table className={classes.list}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.iconCell}></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Tradein Link</TableCell>
              <TableCell>Lease Return Link</TableCell>
              {!isMobile && (
                <>
                  <TableCell>Updated</TableCell>
                  <TableCell>Created</TableCell>
                </>
              )}
              {isAdmin && <TableCell>Upload Mailing List</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(it => {
              const linkProps = {
                "data-id": it.id,
                href: `/${dealer.slug}/settings/campaigns/${it.id}`,
                underline: "none",
                onClick: onClickEdit,
              };
              return (
                <TableRow key={it.id}>
                  <TableCell className={classes.iconCell}>
                    <Link {...linkProps} className={classes.noPad}>
                      <CampaignAvatar campaign={it} />
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link {...linkProps}>
                      {/* <CampaignAvatar campaign={it} />&nbsp; */}
                      {it.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      className={classes.noPad}
                      href={it.links.tradein}
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      <QRCodeImage
                        text={it.links.tradein}
                        title={
                          "New Trade-in link\n\nRight click to Copy link address or\nSave image for your marketing materials"
                        }
                        width={120}
                      />
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      className={classes.noPad}
                      href={it.links.return}
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      <QRCodeImage
                        text={it.links.return}
                        title={
                          "New Lease return link\n\nRight click to Copy link address or\nSave image for your marketing materials"
                        }
                        width={120}
                      />
                    </Link>
                  </TableCell>
                  {!isMobile && (
                    <>
                      <TableCell>
                        <Link {...linkProps}>
                          {formatDateTime(it.updatedAt)}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link {...linkProps}>
                          {formatDateTime(it.createdAt)}
                        </Link>
                      </TableCell>
                    </>
                  )}
                  {isAdmin && (
                    <TableCell>
                      <FileUploadModal id={it.id} setSentEmail={setSentEmail} />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {editing && (
          <DealerCampaignEditor item={editing} onClose={onCloseEditor} />
        )}
        <Toast
          title={sentEmail}
          severity={
            sentEmail && sentEmail?.includes("Failed") ? "error" : "success"
          }
        />
      </>
    );
  },
);
