import React from "react";
import {
  Box,
  IconButton,
  TextField,
  Popper,
  InputAdornment,
  Grid,
  // Slide,
  ClickAwayListener,
  Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ReplayIcon from "@material-ui/icons/Replay";

//local
import {
  formatDate,
  formatDateTime,
  formatThousands,
  useDebouncedEffect,
  useKeyDown,
} from "../../lib";
import { appraisalActions, useAction, useDealer } from "../../state";
import { useGlobalSearchStyles } from "./MainToolbar.styles.js";
import { Dot, PageLink } from "../../components";
import { RatingInput } from "../../pages/appraisals/components/RatingInput";
import CarSideImg from "../../pages/appraisals/img/CarSide.png";

interface SearchResponse {
  search: string;
  range: number[];
}
interface Props {
  isMobile?: boolean;
  setSearchOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GlobalSearch = ({ isMobile = false, setSearchOpen }: Props) => {
  const classes = useGlobalSearchStyles({ isMobile });
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  /** Normalized search term (trimmed) */
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const [range, setRange] = React.useState([0, 24]);
  const [lastValues, setLastValues] = React.useState<SearchResponse>({
    search,
    range,
  });
  const [values, setValues] = React.useState<{
    data: Appraisal[];
    count: number;
  }>({ data: [], count: 0 });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const globalSearch = useAction(appraisalActions.globalSearch);
  const dealer = useDealer();
  const ref = React.useRef<HTMLElement | null>(null);

  const onChangeSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearching(true);
      const search = e.target?.value ?? "";
      setSearch(search);
      setSearchTerm(search.trim());
    },
    [],
  );

  const togglePopper = React.useCallback(() => {
    setOpen(open => !open);
  }, []);
  useKeyDown("Ctrl+Slash", togglePopper);

  const doSearch = React.useCallback(
    async (isRangeChange?: boolean) => {
      const check = await globalSearch(searchTerm, range);
      if (isRangeChange) {
        setValues({
          data: [...values.data, ...check.data],
          count: check.count,
        });
      } else {
        setValues({
          data: check.data,
          count: check.count,
        });
        if (range.toString() !== "0,24") setRange([0, 24]);
      }
      setSearching(false);
    },
    [globalSearch, searchTerm, values.data, range],
  );

  useDebouncedEffect(() => {
    if (
      (lastValues.search !== searchTerm &&
        searchTerm &&
        searchTerm.length > 2) ||
      lastValues.range !== range
    ) {
      doSearch(lastValues.range !== range && range.toString() !== "0,24");
      setLastValues({ search: searchTerm, range });
    }
  }, [doSearch, searchTerm, range, lastValues.search, lastValues.range]);

  React.useEffect(() => {
    setAnchorEl(open && ref?.current ? ref.current : null);
    if (setSearchOpen) setSearchOpen(open);
  }, [ref, open, setSearchOpen]);

  const showMoreButton = React.useCallback(() => {
    setRange(old => [old[0] + 25, old[1] + 25]);
  }, []);

  const { data, count } = values;

  const keyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const getFirst = document.querySelector(
        "[data-index='0']",
      ) as HTMLElement;
      const { key } = e;
      if (key === "Escape") {
        setOpen(false);
        return;
      }

      if (open && count && getFirst) {
        const getFocus = document.activeElement as HTMLElement;
        const idx = parseInt(getFocus.dataset.index);
        if (key === "ArrowDown") {
          const next = document.querySelector(
            `[data-index='${idx + 1}']`,
          ) as HTMLElement;
          if (next) {
            next.focus();
          } else {
            const el = document.querySelector(
              "[data-index='-2']",
            ) as HTMLElement;
            if (el) el.focus();
          }
        } else if (key === "ArrowUp") {
          if (idx < 0) {
            getFirst.focus();
          } else {
            const prev = document.querySelector(
              `[data-index='${idx - 1}']`,
            ) as HTMLElement;
            if (prev) prev.focus();
          }
        }
      }
    },
    [count, open],
  );

  const onClickResult = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement>) => {
      setOpen(false);
    },
    [],
  );
  const onClickClear = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement>) => {
      if (search !== "") {
        setSearch("");
      } else {
        togglePopper();
      }
    },
    [search, togglePopper],
  );
  const onBlur = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement>) => {
      if (!open || search.length < 3) {
        togglePopper();
      }
    },
    [search, open, togglePopper],
  );
  const onClickShowMore = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    e => {
      showMoreButton();
      doSearch(true);
    },
    [showMoreButton, doSearch],
  );
  const onClickAway = React.useCallback(
    (e: React.MouseEvent<Document, MouseEvent>) => {
      if (ref.current !== e.target) {
        // If they click anywhere BUT the search box, close.
        setOpen(false);
      }
    },
    [],
  );

  return (
    <>
      <Box className={classes.searchFieldContainer}>
        {open ? (
          <TextField
            autoFocus
            onChange={onChangeSearch}
            onKeyDown={keyDown}
            className={classes.globalSearchTextField}
            value={search}
            variant="standard"
            onBlur={onBlur}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start" className={classes.menuButton}>
                  {/* <Slide
                    direction="left"
                    in={open}
                    mountOnEnter
                    unmountOnExit
                    timeout={350}
                  > */}
                  <SearchIcon />
                  {/* </Slide> */}
                </InputAdornment>
              ),
              endAdornment: !search ? undefined : (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear textfield"
                    onClick={onClickClear}
                    className={classes.menuButton}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputRef={ref}
            inputProps={{
              "data-index": -1,
            }}
          />
        ) : (
          <IconButton
            color="inherit"
            aria-label="Global Search"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            className={classes.menuButton}
            onClick={togglePopper}
            title="Search (Ctrl+/)"
          >
            <SearchIcon />
          </IconButton>
        )}
      </Box>
      {open && searchTerm.length > 2 && anchorEl ? (
        <ClickAwayListener onClickAway={onClickAway}>
          <Popper
            open={open}
            className={classes.popper}
            placement="bottom"
            anchorEl={anchorEl}
            id="global-search-popper"
            onKeyDown={keyDown}
          >
            <div className={classes.modalContainer}>
              <div className={classes.row}>
                {data.length ? (
                  data.map((app: Appraisal, idx: number) => {
                    return (
                      <PageLink
                        to={`/${dealer.slug}/appraisals/${app.id}`}
                        className={classes.link}
                        key={idx}
                        style={{
                          //  show more button is fixed ~65px to prevent it from being hidden add to last element
                          paddingBottom:
                            idx === range[1] && count > 24 ? 67 : 12,
                        }}
                        data-index={idx}
                        onClick={onClickResult}
                      >
                        <Grid
                          container
                          direction="column"
                          alignItems="flex-start"
                          className={classes.thumbAndRate}
                        >
                          <div className={classes.photoBox}>
                            {app.appraisalTypeId === 2 ? <ReplayIcon /> : null}
                            <img
                              src={app?.thumb ?? CarSideImg}
                              alt=""
                              className={app?.thumb ? `thumbnail` : ""}
                            />
                          </div>
                          <div>
                            <RatingInput
                              color="inherit"
                              value={app?.runningStatus ?? 0}
                              size="small"
                              readOnly
                            />
                          </div>
                        </Grid>

                        <Grid
                          container
                          className={classes.descriptionContainer}
                        >
                          <h4 className={classes.ymm}>
                            {app.year} {app.make} {app.model}
                          </h4>
                          <div className={classes.carSpecs}>
                            {app.trim}
                            {" | "}
                            {app.vin}
                            {" | "}
                            {formatThousands(
                              app.inspectedMileage || app.mileage,
                            )}{" "}
                            Miles
                          </div>
                          <div
                            className={classes.displayDate}
                            style={{ width: app.deletedAt ? "100%" : "66%" }}
                          >
                            <p
                              title={`Created on ${formatDateTime(
                                app.createdAt,
                              )}`}
                            >
                              Submitted on {formatDate(app.createdAt)}
                            </p>
                            {app.deletedAt ? (
                              <>
                                {!isMobile && (
                                  <Dot
                                    color="#8D98C2"
                                    size={6}
                                    style={{ margin: 8 }}
                                  />
                                )}
                                <p
                                  title={`Archived on ${formatDateTime(
                                    app.deletedAt,
                                  )}`}
                                >
                                  Archived on {formatDate(app.deletedAt)}
                                </p>
                              </>
                            ) : null}
                            {app.firstName || app.lastName ? (
                              <>
                                {(!app.deletedAt || !isMobile) && (
                                  <Dot
                                    color="#8D98C2"
                                    size={6}
                                    style={{ margin: 8 }}
                                  />
                                )}
                                <p>
                                  {app.firstName ?? ""} {app.lastName ?? ""}
                                </p>
                              </>
                            ) : null}
                          </div>
                        </Grid>
                      </PageLink>
                    );
                  })
                ) : searching ? (
                  <div className={classes.row}>
                    <span className={classes.noResults}>SEARCHING...</span>
                  </div>
                ) : (
                  <div className={classes.row}>
                    {search && (
                      <span className={classes.noResults}>
                        NO RESULTS FOUND
                      </span>
                    )}
                  </div>
                )}
              </div>
              {count > range[1] ? (
                <div className={classes.showMore}>
                  <Button
                    onClick={onClickShowMore}
                    variant="outlined"
                    color="secondary"
                    tabIndex={1}
                    data-index={-2}
                  >
                    Show more
                  </Button>
                  <span>{`Showing ${range[1] + 1} of ${count} results`}</span>
                </div>
              ) : null}
            </div>
          </Popper>
        </ClickAwayListener>
      ) : null}
    </>
  );
};
