/**
 * Returns the given `values` with `null` and `undefined` replaced by the
 * matching value in `defaults`.
 * @template T
 * @param {T} defaults
 * @param {T} values
 * @returns {T}
 */
export function mapFormDefaults(defaults, values) {
  const keys = Object.keys(defaults);
  const { length } = keys;
  const newValues = {};
  for (let i = 0; i < length; i++) {
    const key = keys[i];
    const value = values[key];
    const defaultValue = defaults[key];
    if (value === null || value === undefined) {
      // Value missing or null. Replace with default.
      newValues[key] = defaults[key];
    } else if (typeof defaultValue === "boolean") {
      // Value should be a boolean.
      newValues[key] = !!value;
    } else {
      // All other values.
      newValues[key] = value;
    }
  }
  return newValues;
}
