// import * as PusherPushNotifications from "@pusher/push-notifications-web";
// Local
// import { userApi } from "../state";

// let beamsClient: PusherPushNotifications.Client;

/**
 * Pusher wants tokenProvider to look like below. However since we use a users
 * cookie for identification in the backend this may not be feasible.
 * The below implementation is how PusherPushNotifications.TokenProvider type
 * would look `{fetchToken:() => Promise<data:{token}>}`
 *
 * @example
 * ```ts
 * const tokenProvider = new PusherPushNotifications.TokenProvider({
 *   url: "http://localhost:7770/v1/dealer/notifications_log/generate-pusher-token",
 * });
 * ```
 */
// const tokenProvider = {
//   fetchToken: () => userApi.generatePusherToken(),
// };

export const Notifications = {
  async connect() {
    // const { instanceId, uid } = await userApi.connectPusher();
    // beamsClient = new PusherPushNotifications.Client({
    //   instanceId,
    // });
    // // Check if the Beams user matches the user that is currently logged in if not unregister
    // const existingUid = await beamsClient.getUserId();
    // if (existingUid !== uid) {
    //   await beamsClient.stop();
    // }
    // await beamsClient.start();
    // await beamsClient.setUserId(uid, tokenProvider);
  },
  async sendTest() {
    // const data = await userApi.sendTestNotification();
    // console.log("TEST NOTIFICATION", data);
    // return data;
    return {
      message: "Notification not sent! Please contact support",
    };
  },
};
