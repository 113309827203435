import React from "react";
import NumberFormat from "react-number-format";
// import { InputAdornment } from "@material-ui/core";
// Local
import { Field } from "./Field";

function PhoneInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="(###) ###-####"
      mask="_"
    />
  );
}

const PhoneInputProps = {
  inputComponent: PhoneInput,
  // startAdornment: <InputAdornment position="start">+1</InputAdornment>,
};

/**
 * A Material-UI `TextField` with US phone formatting bound to a `formik` field.
 *
 * @typedef {import("@material-ui/core").TextFieldProps} TextFieldProps
 * @typedef {object} PhoneFieldProps
 * @property {boolean} [allowClear]
 * @property {Record<string,string>} [classFrom] Object to get `className`
 * from, for the given field `name`.
 *
 * @param {PhoneFieldProps & TextFieldProps} props
 */
export function PhoneField(props) {
  const { InputProps = PhoneInputProps, ...rest } = props;
  return (
    <Field placeholder="(__) ___-____" {...rest} InputProps={InputProps} />
  );
}
