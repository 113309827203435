import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Local
const initialState: DealerState = {};

export const dealer = {
  ...createSlice({
    name: "dealer",
    initialState,
    reducers: {
      setDocument(state, { payload }: PayloadAction<DealerCampaignDocument>) {
        state.documents = payload;
      },
    },
  }),
  persist: false,
};

export interface DealerCampaignDocument {
  id: number;
  name: string;
  link: string;
  size: number;
  mimeType: string;
  uid: string;
}
export interface DealerState {
  documents?: DealerCampaignDocument;
}
