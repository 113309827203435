import React from "react";
import {
  //
  Box,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
// Local
import { Form, Navigation, useFormik, useLocation, yup } from "../../lib";
import { authActions, useAction } from "../../state";
import { AuthPages } from "./";
import { useStyles } from "./Auth.styles";

const validationSchema = yup.object({
  password1: yup
    .string("Enter a password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password2: yup
    .string("Enter a password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const NewPasswordPage = React.memo(
  /**
   *
   */
  function NewPasswordPage() {
    const classes = useStyles();
    const resetPassword = useAction(authActions.resetPassword);
    const location = useLocation();

    const frm = useFormik({
      initialValues: {
        password1: "",
        password2: "",
      },
      validationSchema,
      async onSubmit(values) {
        await resetPassword({
          ...values,
          newPassword: values.password1,
          token: location.query.token,
        });
        Navigation.replace(AuthPages.login);
      },
    });

    return (
      <div className={classes.root}>
        <Box mb="8px">
          <Typography variant="h1">Change password</Typography>
        </Box>
        <Box mb="8px">
          <Typography variant="subtitle1">
            Your new password must be different than previously used passwords.
          </Typography>
        </Box>
        <Form form={frm} className={classes.form}>
          <TextField
            fullWidth
            id="password1"
            name="password1"
            label="New password"
            type="password"
            autoComplete="new-password"
            value={frm.values.password1}
            onChange={frm.handleChange}
            error={frm.touched.password1 && Boolean(frm.errors.password1)}
            helperText={frm.touched.password1 && frm.errors.password1}
          />
          <Box pt="32px" pb="40px">
            <TextField
              fullWidth
              id="password2"
              name="password2"
              label="Repeat new password"
              type="password"
              autoComplete="reenter-new-password"
              value={frm.values.password2}
              onChange={frm.handleChange}
              error={frm.touched.password2 && Boolean(frm.errors.password2)}
              helperText={frm.touched.password2 && frm.errors.password2}
            />
          </Box>
          <Button
            variant="contained"
            type="submit"
            disabled={frm.isSubmitting}
            fullWidth
            color="secondary"
          >
            Save new password
          </Button>
        </Form>
      </div>
    );
  },
);
