import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import qs from "query-string";
import { KEY_PREFIX } from "redux-persist";
// Local
import { getMainSlug } from "../../lib";
import { REACT_APP_API_URL, REACT_APP_PERSIST_KEY } from "../../config";
import { AppThunk } from "../types";
import { auth, AuthState } from "./state";

const { actions } = auth;

export const authActions = {
  ...actions,
  load(): AppThunk {
    return async (dispatch, getState) => {
      const dealers = getState().auth.dealers;
      if (!dealers) {
        return;
      }
      defaultDealerId = dealers[0].id;
      dealers.forEach(dealer => {
        dealerIdBySlug[dealer.slug] = dealer.id;
      });
    };
  },
  login(values: { email: string; password: string }): AppThunk {
    return async dispatch => {
      const {
        data: { dealers, user },
      } = await apiClient.post(`/auth/login`, values);
      authClient.userId = user.id;
      const authState: AuthState = {
        dealers,
        roles: user.roles,
        userId: user.id,
        userName: user.email,
        user: {
          email: user.email,
          firstName: user.firstName,
          fullName: `${user.firstName} ${user.lastName}`,
          id: user.id,
          lastName: user.lastName,
          uid: user.uid,
        },
      };
      dispatch(actions.setAuthState(authState));
      dispatch(authActions.load());
    };
  },
  logout(): AppThunk {
    return async dispatch => {
      await apiClient.get(`/auth/logout`).catch(err => undefined);
      logout(dispatch);
    };
  },
  recoverPassword(values: { email: string }): AppThunk {
    return async dispatch => {
      await Promise.resolve();
      await axios.put(`/auth/forgot-password`, values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
      await logout(dispatch);
    };
  },
  resetPassword(values: {
    password1: string;
    password2: string;
    token: string;
  }): AppThunk {
    return async dispatch => {
      await Promise.resolve();
      await axios.put(`/auth/reset-password`, values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
      await logout(dispatch);
    };
  },
  confirmAccount(values: {
    email: string;
    newPassword: string;
    token: string;
  }): AppThunk {
    return async dispatch => {
      await axios.put(`/auth/confirm-account`, values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      // dispatch(actions.setAuthState(undefined));
    };
  },
};

let defaultDealerId: number = 0;
const dealerIdBySlug: Record<string, number> = {};

function configDealer(config?: AxiosRequestConfig): AxiosRequestConfig {
  const slug = getMainSlug() ?? "";
  const dealerId = dealerIdBySlug[slug] ?? defaultDealerId;
  return {
    ...config,
    headers: {
      ...config?.headers,
      "x-dealer-id": dealerId || slug,
    },
  };
}

/** Client for making authenticated API calls. */
export const authClient = {
  /** The current user id. */
  userId: 0,

  delete(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.delete(url, configDealer(config)));
  },
  download(url: string, config?: AxiosRequestConfig) {
    return Promise.reject("TODO: Implement apiDownload.");
  },
  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.get<T>(url, configDealer(config)));
  },
  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return handleAuthResponse(
      apiClient.patch<T>(url, data, configDealer(config)),
    );
  },
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return handleAuthResponse(
      apiClient.post<T>(url, data, configDealer(config)),
    );
  },
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return handleAuthResponse(
      apiClient.put<T>(url, data, configDealer(config)),
    );
  },
};

/** Connection used to make authorized, authenticated API calls. */
const apiClient = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    // TESTING:
    // "x-dealer-id": 1001,
  },
  withCredentials: true,
  paramsSerializer: serializeParams,
});

/**
 * @param promise
 */
async function handleAuthResponse<T = any>(
  promise: Promise<AxiosResponse<T>>,
): Promise<AxiosResponse<T>> {
  let error: AxiosError<T> | undefined;
  promise = promise.catch(err => {
    error = err;
    return err.response;
  });
  const res = await promise;
  if (error) {
    if (res) {
      const status = res.status;
      if (status === 401 || status === 403) {
        redirectToLogin(status === 401);
      }
    }
    throw error;
  }
  return res;
}

export function redirectToLogin(addAfter = true) {
  window.location.replace(
    `/auth/login${
      addAfter
        ? "?after=" +
          encodeURIComponent(window.location.pathname + window.location.search)
        : ""
    }`,
  );
}

function logout(dispatch) {
  // NOTE: We could do  window.localStorage.clear(); but other JS might be
  // using localStorage, so just remove the key that our Redux app saves.
  window.localStorage.removeItem(`${KEY_PREFIX}${REACT_APP_PERSIST_KEY}`);
  dispatch(actions.setAuthState(undefined));
}

/**
 * Serializes URL params correctly for `express-openapi-validator`. See:
 * - https://github.com/axios/axios/issues/678#issuecomment-634632500
 * - https://github.com/axios/axios/blob/8a8c534a609cefb10824dec2f6a4b3ca1aa99171/lib/helpers/buildURL.js
 * - https://github.com/axios/axios/blob/59ab559386273a185be18857a12ab0305b753e50/lib/utils.js#L177
 *
 * @param params The query params.
 */
function serializeParams(params: Record<string, any>) {
  if (params instanceof URLSearchParams) {
    return params.toString();
  }
  const formattedParams = {};
  const keys = Object.keys(params);
  const { length } = keys;
  for (let i = 0; i < length; i++) {
    const key = keys[i];
    let value = params[key];
    if (value === null || value === undefined) {
      continue;
    }
    if (Object.prototype.toString.call(value) === "[object Date]") {
      // Format Dates...
      value = value.toISOString();
    } else if (value !== null && typeof value === "object") {
      // Format objects and arrays...
      value = JSON.stringify(value);
    }
    formattedParams[key] = value;
  }
  // URLSearchParams does not handle arrays...
  // return new URLSearchParams(formattedParams).toString();
  return qs.stringify(formattedParams);
}
axios.defaults.paramsSerializer = serializeParams;

// #region Types

/** Return value for API call wrappers. */
type ApiCall<T = any> = AppThunk<Promise<AxiosResponse<T>>>;

// #endregion
