import React from "react";
import { Button, makeStyles } from "@material-ui/core";
// import bytes from "bytes";
// Icons
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
// Local
import { SimpleDialog } from "../feedback/SimpleDialog";
// import { formatDate } from "../../lib";

const useStyles = makeStyles(
  theme => ({
    root: {
      // margin: 0,
      maxWidth: "100%",
      maxHeight: 800,
      marginBottom: "24px",
    },
    container: {
      display: "grid",
      gap: theme.spacing(3),
      gridTemplateColumns: "repeat(auto-fit, 200px);",
      gridTemplateRows: "minmax(0, 200px)",
      // See https://stackoverflow.com/a/43312314
      minHeight: 0,
      minWidth: 0,
    },
  }),
  {
    classNamePrefix: "DocumentGrid",
  },
);

export const DocumentGrid = React.memo(
  /**
   * @typedef {object} DocumentGridProps
   * @property {DocumentInfo[]} documents
   * @property {boolean} [indexLabelSuffix]
   *
   * @param {DocumentGridProps} props
   */
  function DocumentGrid(props) {
    const { documents, indexLabelSuffix } = props;
    const classes = useStyles(props);
    const [showingDoc, showDoc] = React.useState(undefined);
    const closeDoc = React.useCallback(() => {
      showDoc(undefined);
    }, []);

    const onDialogKeyUp = React.useCallback(
      /** @param {React.KeyboardEvent<HTMLElement>} e */
      e => {
        if (e.key === "ArrowRight") {
          const index = showingDoc.index + 1;
          const next = documents[index];
          if (!next) return;
          showDoc({ ...next, index });
        } else if (e.key === "ArrowLeft") {
          const index = showingDoc.index - 1;
          const prev = documents[index];
          if (!prev) return;
          showDoc({ ...prev, index });
        }
      },
      [showingDoc, documents],
    );

    if (!documents || documents.length < 1) return null;

    return (
      <>
        <div className={classes.root}>
          <div className={classes.container}>
            {documents.map((it, i) => {
              return (
                <DocumentGridItem
                  key={it.id ?? i}
                  value={it}
                  labelSuffix={indexLabelSuffix ? ` ${i + 1}` : undefined}
                  index={i}
                  showDoc={showDoc}
                />
              );
            })}
          </div>
        </div>
        {showingDoc && (
          <SimpleDialog
            open={true}
            onClose={closeDoc}
            title={showingDoc.documentTypeName}
            onKeyUp={onDialogKeyUp}
            // TODO: Add carousel buttons on left and right to navigate docs...
          >
            {showingDoc.mimeType === "application/pdf" ? (
              <Button
                href={showingDoc.link}
                target="_blank"
                title={showingDoc.name}
              >
                <PictureAsPdfIcon fontSize="large" />
              </Button>
            ) : (
              <img
                src={showingDoc.link}
                alt={showingDoc.name}
                title={showingDoc.name}
              />
            )}
          </SimpleDialog>
        )}
      </>
    );
  },
);

const useItemStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      // See https://stackoverflow.com/a/43312314
      overflow: "hidden",
      minHeight: 0,
      minWidth: 0,
    },
    content: {
      flex: 1,
      overflow: "hidden",
      wordBreak: "break-all",
      "& > *": {
        height: "100%",
        width: "100%",
        objectFit: "cover",
      },
    },
    infobar: {
      display: "flex",
      justifyContent: "center",
    },
  }),
  {
    classNamePrefix: "DocumentGridItem",
  },
);

const DocumentGridItem = React.memo(
  /**
   * @typedef {object} DocumentGridItemProps
   * @property {DocumentInfo} value
   * @property {string} labelSuffix
   * @property {(doc:DocumentInfo)=>void} showDoc
   *
   * @param {DocumentGridItemProps} props
   */
  function DocumentGridItem(props) {
    const { index, labelSuffix, showDoc, value } = props;
    const classes = useItemStyles(props);

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          {value.mimeType === "application/pdf" ? (
            <Button href={value.link} target="_blank" title={value.name}>
              <PictureAsPdfIcon fontSize="large" />
            </Button>
          ) : (
            <img
              src={value.link}
              alt={value.name}
              title={value.name}
              onClick={() => showDoc({ ...value, index })}
            />
          )}
        </div>
        <div className={classes.infobar}>
          {value.documentTypeName}
          {labelSuffix}
        </div>
      </div>
    );
  },
);
