import React from "react";
import {
  // Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
// Local
import { formatDateTime } from "../../../lib";
import { dealerApi, useDealer } from "../../../state";
import { useMobile } from "../../../themes";
import { DealerSettingEditor } from "./DealerSettingEditor";

const useStyles = makeStyles(
  theme => ({
    actions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    list: {
      padding: 16,
      "& td > a": {
        display: "block",
        margin: -16,
        padding: 16,
        height: "100%",
      },
    },
  }),
  {
    classNamePrefix: "DealerSettingsList",
  },
);

export const DealerSettingsList = React.memo(
  /**
   *
   */
  function DealerSettingsList() {
    const classes = useStyles();
    const dealer = useDealer();
    const isMobile = useMobile();

    /** @type {[DealerSetting[]]} */
    const [list, setList] = React.useState([]);
    /** @type {[DealerSetting | null]} */
    const [editing, setEditing] = React.useState(null);

    const onClickEdit = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLAnchorElement>} e */
      e => {
        if (e) {
          if (e.preventDefault) e.preventDefault();
          const id = parseInt(e.currentTarget.dataset.id);
          const editing = list.find(it => it.id === id);
          setEditing(editing);
        }
      },
      [list],
    );
    const onCloseEditor = React.useCallback(() => {
      setEditing(null);
    }, []);

    React.useEffect(() => {
      if (editing !== null) {
        // Only run at start or when `editing` changes back to null...
        return;
      }
      const state = { mounted: true };
      (async function onDealerChanged() {
        const list = await dealerApi.getSettings();
        if (!state.mounted) return;
        setList(list);
      })();
      return () => {
        state.mounted = false;
      };
    }, [dealer, editing]);

    return (
      <div className={classes.root}>
        <div className={classes.actions}>&nbsp;</div>
        <Table className={classes.list}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
              {!isMobile && (
                <>
                  <TableCell>Description</TableCell>
                  <TableCell>Updated</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(it => {
              const linkProps = {
                "data-id": it.id,
                href: `/${dealer.slug}/settings/defaults/${it.id}`,
                underline: "none",
                onClick: onClickEdit,
              };
              return (
                <TableRow key={it.id}>
                  <TableCell>
                    <Link {...linkProps}>{it.name}</Link>
                  </TableCell>
                  <TableCell>
                    <Link {...linkProps}>
                      {it.type === "boolean"
                        ? it.value === "true"
                          ? "Yes"
                          : "No"
                        : it.type === "number"
                        ? "$" + it.value
                        : it.value}
                    </Link>
                  </TableCell>
                  {!isMobile && (
                    <>
                      <TableCell>
                        <Link {...linkProps}>{it.description}</Link>
                      </TableCell>
                      <TableCell>
                        <Link {...linkProps}>
                          {formatDateTime(it.updatedAt)}
                        </Link>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {editing && (
          <DealerSettingEditor item={editing} onClose={onCloseEditor} />
        )}
      </div>
    );
  },
);
