import React from "react";
import NumberFormat from "react-number-format";
// import { InputAdornment } from "@material-ui/core";
// Local
import { useField } from "../../lib";
import { Field } from "./Field";

// TODO: Save value as numeric when calling onChange({ value ... })

function DollarFormatInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
      fixedDecimalScale
      // NOTE: You can use the InputAdornment or the prefix, not both...
      prefix="$"
      {...other}
    />
  );
}

const DollarFormatInputProps = {
  inputComponent: DollarFormatInput,
  // startAdornment: <InputAdornment position="start">$</InputAdornment>,
};

const keyHandler = {
  ArrowDown(value, step) {
    return value - step;
  },
  ArrowUp(value, step) {
    return value + step;
  },
};

/**
 * A Material-UI `TextField` with USD formatting bound to a `formik` field.
 *
 * @typedef {import("@material-ui/core").TextFieldProps} TextFieldProps
 * @typedef {object} DollarFieldProps
 * @property {Record<string,string>} [classFrom] Object to get `className`
 * from, for the given field `name`.
 * @property {number} [step] Value to increment or decrement with keyboard. `1`
 *
 * @param {DollarFieldProps & TextFieldProps} props
 */
export function DollarField(props) {
  const { InputProps = DollarFormatInputProps, step = 1, ...rest } = props;
  const [fld, _meta, helpers] = useField(props.name);
  const fldValue = fld.value;
  const setValue = helpers.setValue;
  // TODO: Debounce onKeyDown
  const onKeyDown = React.useCallback(
    /** @param {React.KeyboardEvent<HTMLInputElement>} e */
    e => {
      const { key } = e;
      const handler = keyHandler[key];
      if (!handler) {
        return;
      }
      const newValue = handler(parseFloat(fldValue), step);
      setValue(newValue.toString());
    },
    [fldValue, setValue, step],
  );
  return (
    <Field
      placeholder="$___.__"
      onKeyDown={onKeyDown}
      {...rest}
      InputProps={{
        ...DollarFormatInputProps,
        ...InputProps,
      }}
    />
  );
}
