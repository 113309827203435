import React from "react";
// import {
//   //
//   Backdrop,
//   // Box,
//   CircularProgress,
//   IconButton,
// } from "@material-ui/core";
import { useMobile } from "../../themes";
// Local
import { Notifications } from "../../services";
import { prefActions, useAction, useSelector } from "../../state";
import { MainToolbar } from "./MainToolbar";
import { MobileToolbar } from "./MobileToolbar";
import { useStyles } from "./MainLayout.styles";

// CONSIDER: Use <Alert /> inside of <Snackbar> to display different types of
// notifications. See https://v4.mui.com/components/snackbars/#customized-snackbars

function _MainLayout({ children }) {
  const classes = useStyles();
  const isMobile = useMobile();

  const setMenu = useAction(prefActions.setMenu);
  const initialMainMenuOpen = useSelector(state => state.pref.menu);

  const [_mainMenuOpen, setMainMenuOpen] = React.useState(initialMainMenuOpen);
  const toggleMainMenu = React.useCallback(() => {
    setMainMenuOpen(value => {
      value = !value;
      setMenu(value);
      return value;
    });
  }, [setMenu]);

  React.useEffect(() => {
    Notifications.connect();
  }, []);

  return (
    <div className={classes.root}>
      {isMobile ? (
        <MobileToolbar toggleMainMenu={toggleMainMenu} />
      ) : (
        <MainToolbar toggleMainMenu={toggleMainMenu} />
      )}
      <div className={classes.container}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
}

export const MainLayout = React.memo(_MainLayout);
