import React from "react";
import { Popper, Typography } from "@material-ui/core";
// Local
import { states } from "../../assets/data/us_states";
import { AutocompleteField } from "./AutocompleteField";

const items = states.map(state => ({
  id: state.abbr,
  name: state.abbr,
  fullName: state.name,
}));
const itemsWithEmpty = [{ id: "", name: "", fullName: "" }, ...items];
/**
 * Render items so they don't wrap.
 * See https://material-ui.com/components/autocomplete/#virtualization
 */
function renderItem(item) {
  return (
    <Typography noWrap>
      {item.id ? `${item.name} - ${item.fullName}` : <span>&nbsp;</span>}
    </Typography>
  );
}
const popperStyle = { width: "fit-content" };
/**
 * Render popup so it is wider than the TextField.
 * See https://stackoverflow.com/questions/63579345/how-can-i-change-the-width-of-material-ui-autocomplete-popover
 * @param {import("@material-ui/core").PopperProps} props
 */
function StateFieldPopper(props) {
  return <Popper {...props} style={popperStyle} placement="bottom-start" />;
}
/**
 * @typedef {import("@material-ui/core").TextFieldProps} TextFieldProps
 * @typedef {import("@material-ui/lab").AutocompleteProps} AutocompleteProps
 *
 * @param {AutocompleteProps & TextFieldProps & {allowEmpty?:boolean}} param0
 */
export function SelectStateField({
  label = "State",
  name = "state",
  allowEmpty,
  ...props
}) {
  return (
    <AutocompleteField
      name={name}
      label={label}
      itemsList={allowEmpty ? itemsWithEmpty : items}
      renderOption={renderItem}
      PopperComponent={StateFieldPopper}
      // Don't wrap the list item text.
      disableListWrap
      {...props}
    />
  );
}
