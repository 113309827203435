import React from "react";
import { differenceInHours, formatDistance } from "date-fns";
import { FormikProvider } from "formik";
import {
  //
  // Avatar,
  Box,
  Button,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
// Icons
// import LaunchIcon from "@material-ui/icons/Launch";
import ReplayIcon from "@material-ui/icons/Replay";
import SquareIcon from "@material-ui/icons/Stop";
import ScheduleIcon from "@material-ui/icons/Schedule";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import {
//   CollapsedCircleIcon,
//   ExpandedCircleIcon,
// } from "../../../components/img/ExpandCircleIcon";
// import { CarSideIcon } from "../../img/CarSideIcon";
import CarSideImg from "../img/CarSide.png";
// Local
import {
  //
  CarfaxSnap4P,
  DollarField,
  Dot,
  InlineFileInput,
  PageLink,
  useBasicDialog,
} from "../../../components";
import {
  //
  formatAmountUSD,
  formatDate,
  formatDateTime,
  formatPhone,
  formatThousands,
} from "../../../lib";
import {
  authSelectors,
  appraisalActions,
  appraisalStagesById,
  useAction,
  useDealer,
  useSelector,
} from "../../../state";
import { Appraisals } from "../../../services";
import { LeaseLocationButton } from "../../../dialogs/LeaseLocationDialog";
import { LeaseReturnButton } from "../../../dialogs/LeaseReturnDialog";
import { AssignedUsers } from "../components/AssignedUsers";
// import { AppraisalEditor } from "./AppraisalEditor";
import { CampaignAvatar } from "../components/CampaignAvatar";
import { CheckLabel } from "../components/CheckLabel";
import { DealTypeInput } from "../components/DealTypeInput";
// import { InlineReconCostForm } from "./InlineReconCostForm";
import { RatingInput } from "../components/RatingInput";
import { useWorksheet } from "../show/useWorksheet";
import { useStyles } from "./AppraisalsListGroup.styles";
import { DuplicateSubButton } from "../components/DuplicateSubButton";

const paveInspectStatuses = {
  Complete: {
    color: "",
    label: "Complete",
  },
  Started: {
    color: "orange",
    label: "Started",
  },
  Process: {
    color: "green",
    label: "In process",
  },
  Qc_passed: {
    color: "blue",
    label: "QC Passed",
  },
  Idle: {
    color: "red",
    label: "Idle",
  },
};

const styles = {
  /** @type {React.CSSProperties} */
  button: {
    padding: 2,
  },
  flexerr: {
    alignItems: "center",
    color: "#C90A2D",
    display: "flex",
  },
  pb0: {
    paddingBottom: 0,
  },
};

export const AppraisalsListGroup = React.memo(
  /**
   * @typedef {object} AppraisalsListGroupProps
   * @property {boolea} [isLeaseReturnsMgmt]
   * @property {boolean} [isMobile]
   * @property {boolean} [isService]
   * @property {boolean} [isShowroom]
   * @property {Appraisal[]} rows
   * @property {import("../../../state").AppraisalStageId} tab
   *
   * @param {AppraisalsListGroupProps} param0
   */
  function AppraisalsListGroup({
    isLeaseReturnsMgmt,
    isMobile,
    isService,
    isShowroom,
    rows,
    tab,
  }) {
    const classes = useStyles();
    const { confirmAsync, basicDialog } = useBasicDialog();
    const stage = appraisalStagesById[tab] ?? {};

    const getList = useAction(appraisalActions.getList);
    const saveStatus = useAction(appraisalActions.saveStatus);
    const sendToCrm = useAction(appraisalActions.sendToCrm);

    const dealer = useDealer();
    const allowReadVals = useSelector(authSelectors.allowReadVals);

    const [dealTypes, setDealTypes] = React.useState(undefined);
    const getDealTypes = useAction(appraisalActions.getDealTypes);

    const onClickPushToCrm = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        if (!e) return;
        e.preventDefault();
        e.stopPropagation();
        const id = parseInt(e.currentTarget.dataset.id);
        if (!(await confirmAsync("Are you sure?"))) {
          return;
        }
        await sendToCrm(id);
        getList();
      },
      [sendToCrm, getList, confirmAsync],
    );

    const onClickBuying = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        if (!e) return;
        e.preventDefault();
        e.stopPropagation();
        const data = e.currentTarget.dataset;
        const id = parseInt(data.id);
        const undoing = parseInt(data.status) === 1;
        const msg = undoing ? "Undo Buying status?" : "Set status to Buying?";
        const appraisalStatusId = undoing ? null : 1;
        if (!(await confirmAsync(msg))) {
          return;
        }
        await saveStatus(id, { appraisalStatusId, notify: false }, false);
        getList();
      },
      [saveStatus, getList, confirmAsync],
    );
    /** @type {{id:number,type:""|typeof tab,param:string;}} */
    const defaultEditing = { id: 0, type: "" };
    const [editing, setEditing] = React.useState(defaultEditing);
    const onEditRow = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLAnchorElement>} e */
      e => {
        e.preventDefault();
        const ds = e.currentTarget.dataset;
        const id = parseInt(ds.id);
        const type = ds.type;
        const param = ds.param ?? "";
        setEditing({ id, type, param });
      },
      [],
    );
    const onEditRowDone = React.useCallback(() => {
      setEditing({ id: 0, type: "" });
    }, []);

    React.useEffect(() => {
      if (isService || isShowroom) {
        getDealTypes().then(items => setDealTypes(items));
      }
    }, [isService, isShowroom, getDealTypes]);

    // const thisPageUrl = window.location.pathname + window.location.search;
    return (
      <>
        {isLeaseReturnsMgmt && (
          <thead>
            <tr>
              <td className={classes.lrtnColHead} colSpan="2">
                Vehicle info
              </td>
              <td className={classes.lrtnColHead}>Assignee</td>
              <td className={classes.lrtnColHead}>Bank</td>
              <td className={classes.lrtnColHead}>Location</td>
              {tab === "grounded" ? (
                <>
                  <td className={classes.lrtnColHead}>Grounding receipt</td>
                  <td className={classes.lrtnColHead}>Next action</td>
                </>
              ) : tab === "turnedin" ? (
                <>
                  <td className={classes.lrtnColHead}>Grounding receipt</td>
                  <td className={classes.lrtnColHead}>Bill of lading</td>
                  <td className={classes.lrtnColHead}>Status</td>
                </>
              ) : (
                <td className={classes.lrtnColHead}>Next action</td>
              )}
              <td className={classes.lrtnColHead}></td>
            </tr>
          </thead>
        )}
        {rows.length ? (
          rows.map(row => {
            const linkProps =
              // isShowroom ? { to: thisPageUrl, onClick: onClickRow } :
              { to: `/${dealer.slug}/appraisals/${row.id}` };
            return (
              <React.Fragment key={row.id}>
                <tbody className={classes.statusGroupRow} data-id={row.id}>
                  <tr>
                    {isMobile ? (
                      <td>
                        <PageLink {...linkProps} className="bullet">
                          <Box fontSize="16px" color={stage.color}>
                            <SquareIcon fontSize="inherit" color="inherit" />
                          </Box>
                        </PageLink>
                      </td>
                    ) : (
                      <td width="90">
                        <PageLink {...linkProps}>
                          <div className={classes.photoBox}>
                            {row.appraisalTypeId === 2 ? <ReplayIcon /> : null}
                            <img
                              src={row.thumb ?? CarSideImg}
                              alt=""
                              className={row.thumb ? `thumbnail` : ""}
                            />
                          </div>
                          <div className={classes.gradeCol}>
                            <RatingInput
                              color="inherit"
                              defaultValue={row.runningStatus ?? 0}
                              size="small"
                              readOnly
                            />
                          </div>
                        </PageLink>
                      </td>
                    )}
                    <td
                      className={
                        isLeaseReturnsMgmt ? classes.lrtnVehicle : undefined
                      }
                    >
                      <PageLink {...linkProps}>
                        <Grid container alignItems="center">
                          <Typography variant="h4">
                            {row.year} {row.make} {row.model}
                          </Typography>
                          {/* <div className={classes.launcher}>
                            <IconButton
                              data-id={row.id}
                              size="small"
                              title="Open quick view"
                              onClick={onClickEdit}
                            >
                              <LaunchIcon />
                            </IconButton>
                          </div> */}
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          className={classes.carDetailText}
                        >
                          {row.trim}
                          <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
                          <div>{row.vin}</div>
                          <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
                          {isLeaseReturnsMgmt ? (
                            <div
                            // TODO: Where are we supposed to get keys?
                            >
                              1 key
                            </div>
                          ) : (
                            <div>
                              {formatThousands(
                                row.inspectedMileage || row.mileage,
                              )}{" "}
                              Miles
                            </div>
                          )}
                          {row.trfId && (
                            <>
                              <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
                              <div
                                className={classes.transfer}
                                title={`Transferred from ${
                                  row.trfDealerName
                                } ${formatDateTime(row.trfDateTime)}`}
                              >
                                {row.trfDealerName}
                              </div>
                            </>
                          )}
                          {row.trfAt ? (
                            <div
                              className={classes.transferred}
                              title={`Transferred on ${formatDateTime(
                                row.trfAt,
                              )}`}
                            >
                              Transferred {formatDate(row.trfAt)}
                            </div>
                          ) : row.deletedAt ? (
                            <div
                              className={classes.deleted}
                              title={`Archived on ${formatDateTime(
                                row.deletedAt,
                              )}`}
                            >
                              Archived {formatDate(row.deletedAt)}
                            </div>
                          ) : null}
                        </Grid>
                        {isLeaseReturnsMgmt ? (
                          <Grid
                            container
                            alignItems="center"
                            className={classes.detailText}
                            title={`Record #${row.id}`}
                          >
                            <SubmittedOnField row={row} simple />
                            &nbsp;&nbsp;
                            {row.maturityDate && (
                              <>
                                <Dot color="#8D98C2" size={6} />
                                &nbsp;&nbsp;
                                <MaturesOnField row={row} simple />
                                &nbsp;&nbsp;
                              </>
                            )}
                            <DuplicateSubButton row={row} />
                            <Dot color="#8D98C2" size={6} />
                            &nbsp;&nbsp;
                            <div>
                              {row.firstName} {row.lastName}
                            </div>
                          </Grid>
                        ) : (
                          <Grid
                            container
                            alignItems="center"
                            className={classes.detailText}
                            title={`Record #${row.id}`}
                          >
                            <div>Inspection grade</div>
                            &nbsp;&nbsp;
                            <Dot color="#8D98C2" size={6} />
                            &nbsp;&nbsp;
                            <SubmittedOnField row={row} />
                            <DuplicateSubButton row={row} />
                            &nbsp;&nbsp;
                            <Dot color="#8D98C2" size={6} />
                            &nbsp;&nbsp;
                            <div>
                              {row.firstName} {row.lastName}
                            </div>
                            {(isService || isShowroom) && (
                              <>
                                &nbsp;&nbsp;
                                <Dot color="#8D98C2" size={6} />
                                &nbsp;&nbsp;
                                <CarfaxSnap4P noLogo vin={row.vin} />
                              </>
                            )}
                          </Grid>
                        )}
                      </PageLink>
                    </td>
                    {!isMobile && (
                      <>
                        {!isLeaseReturnsMgmt && (
                          <td>
                            <PageLink {...linkProps}>
                              <CampaignAvatar record={row} />
                            </PageLink>
                          </td>
                        )}
                        <td className={classes.users}>
                          {(row.users || row.originator) && (
                            <PageLink {...linkProps}>
                              <AssignedUsers record={row} />
                            </PageLink>
                          )}
                        </td>
                      </>
                    )}
                    {isMobile ? null : isService || isShowroom ? (
                      <ShowroomColumns
                        classes={classes}
                        dealTypes={dealTypes}
                        linkProps={linkProps}
                        row={row}
                        onClickPushToCrm={onClickPushToCrm}
                        onClickBuying={onClickBuying}
                        allowReadVals={allowReadVals}
                      />
                    ) : tab === "assess" ? (
                      editing.id === row.id && editing.type === "assess" ? (
                        <AssessEditorColumns
                          classes={classes}
                          focusOn={editing.param}
                          linkProps={linkProps}
                          row={row}
                          getList={getList}
                          onEditRowDone={onEditRowDone}
                        />
                      ) : (
                        <AssessColumns
                          classes={classes}
                          linkProps={linkProps}
                          row={row}
                          onEditRow={onEditRow}
                        />
                      )
                    ) : tab === "payoff" ? (
                      editing.id === row.id && editing.type === "payoff" ? (
                        <PayoffEditorColumns
                          classes={classes}
                          focusOn={editing.param}
                          linkProps={linkProps}
                          row={row}
                          getList={getList}
                          onEditRowDone={onEditRowDone}
                          allowReadVals={allowReadVals}
                        />
                      ) : (
                        <PayoffColumns
                          classes={classes}
                          linkProps={linkProps}
                          row={row}
                          onEditRow={onEditRow}
                          allowReadVals={allowReadVals}
                        />
                      )
                    ) : tab === "offered" ? (
                      <OfferedColumns
                        classes={classes}
                        linkProps={linkProps}
                        row={row}
                      />
                    ) : tab === "accepted" ? (
                      <AcceptedColumns
                        classes={classes}
                        linkProps={linkProps}
                        row={row}
                      />
                    ) : tab === "buying" ? (
                      <BuyingColumns
                        classes={classes}
                        linkProps={linkProps}
                        row={row}
                      />
                    ) : tab === "grounding" ? (
                      <GroundingColumns
                        classes={classes}
                        linkProps={linkProps}
                        row={row}
                      />
                    ) : tab === "inspect" ? (
                      <InspectColumns
                        classes={classes}
                        linkProps={linkProps}
                        row={row}
                        allowReadVals={allowReadVals}
                      />
                    ) : tab === "ground_return" ? (
                      <GroundReturnColumns
                        classes={classes}
                        linkProps={linkProps}
                        row={row}
                        allowReadVals={allowReadVals}
                        getList={getList}
                        confirmAsync={confirmAsync}
                      />
                    ) : tab === "grounded" ? (
                      <GroundedColumns
                        classes={classes}
                        linkProps={linkProps}
                        row={row}
                        allowReadVals={allowReadVals}
                        getList={getList}
                        confirmAsync={confirmAsync}
                      />
                    ) : tab === "turnedin" ? (
                      <TurnedInColumns
                        classes={classes}
                        linkProps={linkProps}
                        row={row}
                        allowReadVals={allowReadVals}
                        getList={getList}
                        confirmAsync={confirmAsync}
                      />
                    ) : null}
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td
                      colSpan={isMobile ? "2" : "8"}
                      className={classes.rowgap}
                    />
                  </tr>
                </tbody>
              </React.Fragment>
            );
          })
        ) : isShowroom ? (
          <tbody className={classes.noRows}>
            <tr>
              <td>
                <img
                  src="/images/clipboards.svg"
                  alt="clipboards"
                  style={{ width: "145px" }}
                />
                <p>Welcome to the Showroom page</p>
                <p>Get started by creating some appraisals</p>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className={classes.noRows}>
            <tr>
              <td>
                <img
                  src="/images/break-time.svg"
                  alt="break-time"
                  style={{ width: "145px" }}
                />
                <p>Time for a break</p>
                <p>Nothing to do here right now</p>
              </td>
            </tr>
          </tbody>
        )}
        {basicDialog}
      </>
    );
  },
);
/**
 * @param {Appraisal} row
 */
function useLeaseMgmtUpload(row, getList) {
  const uploadDocument = useAction(appraisalActions.uploadDocument);
  const [uploading, setUploading] = React.useState(false);
  const onFileChange = React.useCallback(
    /** @param {File[]} files */
    async (files, rej, e) => {
      // console.log("fileInput", e);
      const name = e.target.name;
      const nameParts = name.split("_");
      /** @type {CreateDocumentData["typeName"]} */
      const typeName = nameParts[0];
      const id = parseInt(nameParts[1]);
      if (!files || files.length < 0) {
        return;
      }
      setUploading(true);
      try {
        // const info =
        await uploadDocument(id, typeName, files[0]);
        // returns {uid, name, size, type, typeName, uploadUrl}
        getList();
      } finally {
        setUploading(false);
      }
    },
    [getList, uploadDocument],
  );
  const groundingReceipt = row.groundingReceipt ? (
    <Button
      href={row.groundingReceipt.url}
      target="_blank"
      title={row.groundingReceipt.name}
    >
      {row.groundingReceipt.name}
    </Button>
  ) : (
    <InlineFileInput
      name={`groundingReceipt_${row.id}`}
      label="Upload receipt"
      onChange={onFileChange}
      disabled={uploading}
    />
  );
  const billOfLading = row.billOfLading ? (
    <Button
      href={row.billOfLading.url}
      target="_blank"
      title={row.billOfLading.name}
    >
      {row.billOfLading.name}
    </Button>
  ) : (
    <InlineFileInput
      name={`billOfLading_${row.id}`}
      label="Upload bill of lading"
      onChange={onFileChange}
      disabled={uploading}
    />
  );
  return {
    billOfLading,
    groundingReceipt,
  };
}

function LeaseReturnLocation({ classes, getList, row }) {
  const contact = row?.lrtnInfo?.location ?? {};
  const phone = formatPhone(contact.phone);
  return (
    <div
      className={classes.lrtnContact}
      title={`${contact.address}
${contact.firstName} ${contact.lastName}
${phone}
${contact.email}`}
    >
      <div className={classes.lrtnInfo}>
        <div className={classes.lrtnAddr}>{contact.address}</div>
        <div className={classes.lrtnOther}>
          {contact.firstName} {contact.lastName}
        </div>
        <div className={classes.lrtnOther}>{phone}</div>
        <div className={classes.lrtnOther}>{contact.email}</div>
        &nbsp;
      </div>
      <div>
        <LeaseLocationButton onChanged={getList} row={row} />
      </div>
    </div>
  );
}

const MaturesOnField = React.memo(
  /**
   * @param {{row:Appraisal,simple?:boolean}} param0
   */
  function MaturesOnField({ row, simple }) {
    const { maturityDate } = row;
    if (!maturityDate) {
      return (
        <div style={styles.flexerr} title={`Maturity date missing`}>
          Maturity unknown
        </div>
      );
    }
    if (simple) {
      return (
        <div title={`Maturity ${formatDateTime(maturityDate)}`}>
          Maturity {formatDate(maturityDate)}
        </div>
      );
    }
    // TODO: Figure out the correct algorithm to use to inform the user here...
    const maturity = new Date(maturityDate);
    const now = new Date();
    if (differenceInHours(now, maturity) < 72) {
      return (
        <div title={`Maturity ${formatDateTime(maturity)}`}>
          Maturity {formatDate(maturity)}
        </div>
      );
    }
    return (
      <div
        style={styles.flexerr}
        title={`Matures in ${formatDistance(now, maturity)}!
Maturity ${formatDateTime(maturity)}`}
      >
        Maturity {formatDate(maturity)}
        &nbsp;
        <ScheduleIcon fontSize="small" />
      </div>
    );
  },
);

const SubmittedOnField = React.memo(
  /**
   * @param {{row:Appraisal,simple?:boolean}} param0
   */
  function SubmittedOnField({ row, simple }) {
    const { createdAt, offerSent } = row;
    if (simple) {
      return (
        <div title={`Submitted ${formatDateTime(createdAt)}`}>
          Submitted on {formatDate(createdAt)}
        </div>
      );
    }
    const created = new Date(createdAt);
    const now = new Date();
    if (offerSent || differenceInHours(now, created) < 24) {
      return (
        <div title={`Submitted ${formatDateTime(created)}`}>
          Submitted on {formatDate(created)}
        </div>
      );
    }
    return (
      <div
        style={styles.flexerr}
        title={`No offer sent in ${formatDistance(created, now)}!
Submitted ${formatDateTime(created)}`}
      >
        Submitted on {formatDate(created)}
        &nbsp;
        <ScheduleIcon fontSize="small" />
      </div>
    );
  },
);

const LeaseBankField = React.memo(
  /**
   * @param {{row:Appraisal}} param0
   */
  function LeaseBankField({ classes, row }) {
    return (
      <div
      // className={classes.bankRow}
      // onClick={onClickEditBank}
      >
        {!row.financeBank ? (
          <div
            // className={classes.error}
            // style={styles.flexerr}
            // className={classes.detailText}
            title="Click to edit bank on the next screen"
            // tabIndex="0"
          >
            &nbsp;{/* Unknown bank */}
          </div>
        ) : !row.dealerBankId ? (
          <div
            // className={classes.error}
            style={styles.flexerr}
            title="Needs to be grounded by another dealer"
            // tabIndex="0"
          >
            Banks don't match
          </div>
        ) : (
          <div
          // className={classes.labelValue}
          // tabIndex="0"
          >
            {row.financeBank}
          </div>
        )}
      </div>
    );
  },
);

const GroundBtn = React.memo(
  /** @param {{row:Appraisal,confirmAsync:ReturnType<useBasicDialog>["confirmAsync"]}} param0 */
  function GroundBtn({ confirmAsync, getList, row }) {
    const onClick = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        if (!(await confirmAsync("Move this vehicle to Grounded?"))) {
          return;
        }
        await Appraisals.grounded(row.id);
        getList();
      },
      [row.id, confirmAsync, getList],
    );
    return (
      <Button color="secondary" onClick={onClick}>
        Ground
      </Button>
    );
  },
);

const PickedUpBtn = React.memo(
  /** @param {{row:Appraisal,confirmAsync:ReturnType<useBasicDialog>["confirmAsync"]}} param0 */
  function PickedUpBtn({ confirmAsync, getList, row }) {
    const onClick = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        if (!(await confirmAsync("Mark vehicle as picked up?"))) {
          return;
        }
        await Appraisals.pickedUp(row.id);
        getList();
      },
      [row.id, confirmAsync, getList],
    );
    return (
      <Button color="secondary" onClick={onClick}>
        Picked up
      </Button>
    );
  },
);

/**
 * @param {{row:Appraisal,allowReadVals:boolean}} param0
 * @returns
 */
function ShowroomColumns({
  classes,
  dealTypes,
  linkProps,
  row,
  onClickPushToCrm,
  onClickBuying,
  allowReadVals,
}) {
  return (
    <>
      <td>
        <div className={classes.nolink}>
          <div className={classes.amountHeader}>Deal type</div>
          <DealTypeInput record={row} items={dealTypes} />
        </div>
      </td>
      {allowReadVals ? (
        <td>
          <PageLink {...linkProps}>
            <div className={classes.amountHeader}>Wholesale valuation</div>
            <div className={classes.amountBox}>
              {formatAmountUSD(row.auctionValuation)}
            </div>
          </PageLink>
        </td>
      ) : (
        <td width="0"></td>
      )}
      <td>
        <div className={classes.nolink}>
          <div className={classes.amountHeader}>CRM</div>
          {row.crmSentAt ? (
            <CheckLabel title={`Sent ${formatDateTime(row.crmSentAt)}`}>
              Sent
            </CheckLabel>
          ) : (
            <Button
              color="secondary"
              data-id={row.id}
              onClick={onClickPushToCrm}
              style={styles.button}
            >
              Push to CRM
            </Button>
          )}
        </div>
      </td>
      <td>
        <div className={classes.nolink}>
          <div className={classes.amountHeader}>Bought</div>
          <Button
            color="secondary"
            data-id={row.id}
            data-status={row.appraisalStatusId}
            onClick={onClickBuying}
            style={styles.button}
          >
            {row.appraisalStatusId === 1 ? (
              <CheckLabel>Buying</CheckLabel>
            ) : (
              "Buying"
            )}
          </Button>
        </div>
      </td>
    </>
  );
}
/** "Recon assessment pending"
 * @param {{row:Appraisal}} param0
 * @returns
 */
function AssessColumns({ classes, linkProps, row, onEditRow }) {
  const paveReportUrl = `https://paveinspect.com/park/${row.paveInspectSessionKey}`;
  return (
    <>
      <td>
        <div className="cell-body">
          <div className={classes.amountHeader}>Inspection</div>
          <div className={classes.amountBox}>
            <Link
              href={paveReportUrl}
              target="_blank"
              rel="noopener noreferrer"
              color="secondary"
            >
              View inspection
            </Link>
          </div>
        </div>
      </td>
      <td width="140">
        <PageLink
          {...linkProps}
          data-id={row.id}
          data-type="assess"
          data-param="body"
          onClick={onEditRow}
        >
          <div className={classes.amountHeader}>Body work</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.reconBodyCost ?? 0)}
          </div>
        </PageLink>
      </td>
      <td width="120">
        <PageLink
          {...linkProps}
          data-id={row.id}
          data-type="assess"
          data-param="mech"
          onClick={onEditRow}
        >
          <div className={classes.amountHeader}>Mechanical</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.reconMechCost ?? 0)}
          </div>
        </PageLink>
      </td>
      <td width="160">
        <PageLink
          {...linkProps}
          data-id={row.id}
          data-type="assess"
          onClick={onEditRow}
        >
          <div className={classes.amountHeader}>Recon amount</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.reconditioningCost)}
          </div>
        </PageLink>
      </td>
    </>
  );
}
/** "Recon assessment pending"
 * @param {{row:Appraisal}} param0
 * @returns
 */
function AssessEditorColumns({
  classes,
  focusOn,
  getList,
  // linkProps,
  row,
  onEditRowDone,
}) {
  const { frm } = useWorksheet({
    allowBids: false,
    allowWrite: true,
    record: row,
  });
  const {
    submitForm,
    values: { reconditioningCost },
  } = frm;
  const onSubmit = React.useCallback(async () => {
    await submitForm().catch(err => {
      console.error("" + err, { ...err });
      window.alert("There was an error. Please try again.");
    });
    onEditRowDone();
    getList();
  }, [getList, onEditRowDone, submitForm]);

  const onKeyDown = React.useCallback(
    /** @param {React.KeyboardEvent<HTMLInputElement>} e */
    e => {
      const { key } = e;
      if (key === "Enter") {
        onSubmit();
      } else if (key === "Escape") {
        onEditRowDone();
      }
      return;
    },
    [onEditRowDone, onSubmit],
  );
  const paveReportUrl = `https://paveinspect.com/park/${row.paveInspectSessionKey}`;

  return (
    <FormikProvider value={frm}>
      <td>
        <div className="cell-body">
          <div className={classes.amountHeader}>Inspection</div>
          <div className={classes.amountBox}>
            <Link
              href={paveReportUrl}
              target="_blank"
              rel="noopener noreferrer"
              color="secondary"
            >
              View inspection
            </Link>
          </div>
        </div>
      </td>
      <td width="140" onKeyDown={onKeyDown}>
        <div className="cell-body" style={styles.pb0}>
          <div className={classes.amountHeader} style={styles.pb0}>
            Body work
          </div>
          <DollarField
            name="reconBodyCost"
            autoFocus={focusOn === "body"}
            // label="Body work"
            fullWidth
            helperText={
              <span title="Use the Escape key on your keyboard to cancel.">
                <strong>ESC</strong> to cancel.
              </span>
            }
            margin="dense"
            size="small"
          />
        </div>
      </td>
      <td width="140" onKeyDown={onKeyDown}>
        <div className="cell-body" style={styles.pb0}>
          <div className={classes.amountHeader} style={styles.pb0}>
            Mechanical
          </div>
          <DollarField
            name="reconMechCost"
            autoFocus={focusOn === "mech"}
            fullWidth
            helperText={
              <span title="Use the ENTER/RETURN key on your keyboard to save.">
                <strong>ENTER</strong> to save.
              </span>
            }
            // label="Mechanical work"
            margin="dense"
            size="small"
          />
        </div>
      </td>
      <td width="140">
        <div className="cell-body">
          <div className={classes.amountHeader}>Recon amount</div>
          <div
            className={classes.amountBox}
            style={{ paddingTop: 16 }}
            title={
              row.reconOtherCost
                ? `Includes ${formatAmountUSD(row.reconOtherCost)} (other).`
                : ""
            }
          >
            &nbsp;=&nbsp;{formatAmountUSD(reconditioningCost)}
          </div>
        </div>
      </td>
    </FormikProvider>
  );
}
/** "Finalize Payoff & Make Offer"
 * @param {{row:Appraisal,allowReadVals:boolean}} param0
 * @returns
 */
function PayoffColumns({ classes, linkProps, row, onEditRow, allowReadVals }) {
  return (
    <>
      <td width="140">
        <PageLink
          {...linkProps}
          data-id={row.id}
          data-type="payoff"
          data-param="payoff"
          onClick={onEditRow}
        >
          <div className={classes.amountHeader}>Payoff</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.adjustedPayoffAmount ?? 0)}
          </div>
        </PageLink>
      </td>
      <td width="140">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Recon amount</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.reconditioningCost)}
          </div>
        </PageLink>
      </td>
      {allowReadVals ? (
        <td width="120">
          <PageLink {...linkProps}>
            <div className={classes.amountHeader}>MMR</div>
            <div className={classes.amountBox}>
              {formatAmountUSD(row.mmrVal ?? 0)}
            </div>
          </PageLink>
        </td>
      ) : (
        <td width="0"></td>
      )}
      <td width="160">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Total equity</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.auctionProjectedEquity)}
          </div>
        </PageLink>
      </td>
    </>
  );
}
/** "Finalize Payoff & Make Offer"
 * @param {{row:Appraisal,allowReadVals:boolean}} param0
 * @returns
 */
function PayoffEditorColumns({
  classes,
  focusOn,
  getList,
  linkProps,
  row,
  onEditRowDone,
  allowReadVals,
}) {
  const { frm } = useWorksheet({
    allowBids: false,
    allowWrite: true,
    record: row,
  });
  const { submitForm } = frm;
  const onSubmit = React.useCallback(async () => {
    await submitForm().catch(err => {
      console.error("" + err, { ...err });
      window.alert("There was an error. Please try again.");
    });
    onEditRowDone();
    getList();
  }, [getList, onEditRowDone, submitForm]);

  const onKeyDown = React.useCallback(
    /** @param {React.KeyboardEvent<HTMLInputElement>} e */
    e => {
      const { key } = e;
      if (key === "Enter") {
        onSubmit();
      } else if (key === "Escape") {
        onEditRowDone();
      }
      return;
    },
    [onEditRowDone, onSubmit],
  );
  return (
    <FormikProvider value={frm}>
      <td width="140" onKeyDown={onKeyDown}>
        <div className="cell-body" style={styles.pb0}>
          <div className={classes.amountHeader} style={styles.pb0}>
            Payoff
          </div>
          <DollarField
            name="adjustedPayoffAmount"
            autoFocus={focusOn === "payoff"}
            // label="Payoff amount"
            fullWidth
            helperText={
              <span title="Use the Enter key to save, Escape to cancel.">
                <strong>ESC</strong> to cancel.
                <br />
                <strong>ENTER</strong> to save.
              </span>
            }
            margin="dense"
            size="small"
          />
        </div>
      </td>
      <td width="140">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Recon amount</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.reconditioningCost)}
          </div>
        </PageLink>
      </td>
      {allowReadVals ? (
        <td width="120">
          <PageLink {...linkProps}>
            <div className={classes.amountHeader}>MMR</div>
            <div className={classes.amountBox}>
              {formatAmountUSD(row.mmrVal ?? 0)}
            </div>
          </PageLink>
        </td>
      ) : (
        <td width="0"></td>
      )}
      <td width="160">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Total equity</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.auctionProjectedEquity)}
          </div>
        </PageLink>
      </td>
    </FormikProvider>
  );
}
/** "Awaiting response" (Trade-ins only)
 * @param {{row:Appraisal}} param0
 * @returns
 */
function OfferedColumns({ classes, linkProps, row }) {
  return (
    <>
      <td width="140">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Recon amount</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.reconditioningCost)}
          </div>
        </PageLink>
      </td>
      <td width="140">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Offer amount</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.offerAmount ?? 0)}
          </div>
        </PageLink>
      </td>
      <td width="160">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Expected profit</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.expectedTotalProfit)}
          </div>
        </PageLink>
      </td>
    </>
  );
}
/** "Offer Accepted" (Trade-ins only)
 * @param {{row:Appraisal}} param0
 * @returns
 */
function AcceptedColumns({ classes, linkProps, row }) {
  return (
    <>
      <td width="140">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Recon amount</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.reconditioningCost)}
          </div>
        </PageLink>
      </td>
      <td width="140">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Offer amount</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.offerAmount ?? 0)}
          </div>
        </PageLink>
      </td>
      <td width="160">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Expected profit</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.expectedTotalProfit)}
          </div>
        </PageLink>
      </td>
    </>
  );
}
/** "Awaiting Inspection"
 * @param {{row:Appraisal,allowReadVals:boolean}} param0
 * @returns
 */
function InspectColumns({ classes, linkProps, row, allowReadVals }) {
  const stat = paveInspectStatuses[row.paveInspectStatus || "Idle"] || {
    label: row.paveInspectStatus,
  };
  return (
    <>
      <td width="220" colSpan="2">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Inspection status</div>
          <div
            className={`${classes.amountBox}${
              stat.color ? " " + stat.color : ""
            }`}
          >
            {stat.label}
          </div>
        </PageLink>
      </td>
      {allowReadVals ? (
        <td width="220">
          <PageLink {...linkProps}>
            <div className={classes.amountHeader}>Wholesale valuation</div>
            <div className={classes.amountBox}>
              {formatAmountUSD(row.auctionValuation)}
            </div>
          </PageLink>
        </td>
      ) : (
        <td width="0"></td>
      )}
    </>
  );
}
/** "Buying" (Lease Returns only)
 * @param {{row:Appraisal}} param0
 * @returns
 */
function BuyingColumns({ classes, linkProps, row }) {
  return (
    <>
      <td width="220" colSpan="2">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Recon amount</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.reconditioningCost)}
          </div>
        </PageLink>
      </td>
      <td width="220">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Expected profit</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.expectedTotalProfit)}
          </div>
        </PageLink>
      </td>
    </>
  );
}
/** "Grounding" (Lease Returns only)
 * @param {{row:Appraisal}} param0
 * @returns
 */
function GroundingColumns({ classes, linkProps, row }) {
  return (
    <>
      <td width="220" colSpan="2">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Recon amount</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.reconditioningCost)}
          </div>
        </PageLink>
      </td>
      <td width="220">
        <PageLink {...linkProps}>
          <div className={classes.amountHeader}>Expected profit</div>
          <div className={classes.amountBox}>
            {formatAmountUSD(row.expectedTotalProfit)}
          </div>
        </PageLink>
      </td>
    </>
  );
}
/** "Not grounded/not returned"
 * @param {{row:Appraisal}} param0
 * @returns
 */
function GroundReturnColumns({
  classes,
  confirmAsync,
  getList,
  linkProps,
  row,
}) {
  return (
    <>
      <td>
        <PageLink {...linkProps}>
          <LeaseBankField classes={classes} row={row} />
        </PageLink>
      </td>
      <td>
        <LeaseReturnLocation classes={classes} getList={getList} row={row} />
      </td>
      <td>
        <div className={classes.nolink}>
          {row.financeBank && !row.dealerBankId ? (
            <LeaseReturnButton
              // confirmAsync={confirmAsync}
              onChanged={getList}
              row={row}
            />
          ) : (
            <GroundBtn
              confirmAsync={confirmAsync}
              getList={getList}
              row={row}
            />
          )}
        </div>
      </td>
      <td>
        <div className={classes.nolink}>
          &nbsp;
          {/* <MoreHorizIcon color="disabled" /> */}
        </div>
      </td>
    </>
  );
}
/** "Grounded"
 * @param {{row:Appraisal}} param0
 * @returns
 */
function GroundedColumns({ classes, confirmAsync, getList, linkProps, row }) {
  const { groundingReceipt } = useLeaseMgmtUpload(row, getList);
  return (
    <>
      <td>
        <PageLink {...linkProps}>
          <LeaseBankField classes={classes} row={row} />
        </PageLink>
      </td>
      <td>
        <LeaseReturnLocation classes={classes} getList={getList} row={row} />
      </td>
      <td width="240">
        <div className={classes.nolink}>{groundingReceipt}</div>
      </td>
      <td width="220">
        <div className={classes.nolink}>
          {row.financeBank && !row.dealerBankId ? (
            <LeaseReturnButton
              // confirmAsync={confirmAsync}
              onChanged={getList}
              row={row}
              returned
            />
          ) : (
            <PickedUpBtn
              confirmAsync={confirmAsync}
              getList={getList}
              row={row}
            />
          )}
        </div>
      </td>
      <td>
        <div className={classes.nolink}>
          &nbsp;
          {/* <MoreHorizIcon color="disabled" /> */}
        </div>
      </td>
    </>
  );
}
/** "Picked up/returned"
 * @param {{row:Appraisal}} param0
 * @returns
 */
function TurnedInColumns({ classes, confirmAsync, getList, linkProps, row }) {
  const { billOfLading, groundingReceipt } = useLeaseMgmtUpload(row, getList);
  const ri = row.lrtnInfo?.returned;
  return (
    <>
      <td>
        <PageLink {...linkProps}>
          <LeaseBankField classes={classes} row={row} />
        </PageLink>
      </td>
      <td>
        <LeaseReturnLocation classes={classes} getList={getList} row={row} />
      </td>
      <td width="240">
        <div className={classes.nolink}>{groundingReceipt}</div>
      </td>
      <td width="240">
        <div className={classes.nolink}>{billOfLading}</div>
      </td>
      <td width="220">
        <div className={classes.nolink}>
          {row.appraisalStatusId === 220 ? (
            <>
              <div className={classes.lrtnGreen}>Picked up</div>
              <div className={classes.lrtnStat}>
                {formatDate(row.turninDate)}
              </div>
            </>
          ) : (
            <div
              title={
                !ri
                  ? undefined
                  : `${ri.address}
${ri.firstName} ${ri.lastName}
${ri.phone}
${ri.email}`
              }
            >
              <div className={classes.lrtnRed}>Returned</div>
              <div className={classes.lrtnStat}>
                {formatDate(row.turninDate)}
              </div>
              <div className={classes.lrtnStat}>
                {ri?.address}
                <br />
                {ri?.firstName} {ri?.lastName}
                &nbsp;&middot;&nbsp;
                {ri?.phone}
                <br />
                {ri?.email}
              </div>
            </div>
          )}
        </div>
      </td>
      <td>
        <div className={classes.nolink}>
          &nbsp;
          {/* <MoreHorizIcon color="disabled" /> */}
        </div>
      </td>
    </>
  );
}
