import React from "react";
import { FormikProvider } from "formik";
// Local
import { history } from "../routing";

const DEFAULT_PROMPT = "You have unsaved changes...";

export const Form = React.memo(
  /**
   * Form element that implements FormikProvider for a `useFormik` component.
   * See:
   * - https://github.com/formium/formik/discussions/2746
   * - https://github.com/formium/formik/blob/formik%402.2.5/packages/formik/src/Form.tsx
   *
   * @typedef {object} FormProps
   * @property {React.ReactNode} children
   * @property {ReturnType<typeof import("formik").useFormik>} form
   * @property {boolean|string} promptOnExit
   * @property {string} pathname
   *
   * @param {FormProps & JSX.IntrinsicElements["form"]} param0
   */
  function Form({
    form: frm,
    children,
    action,
    promptOnExit,
    // pathname,
    ...rest
  }) {
    const initialPathname = React.useMemo(() => history.location.pathname, []);

    React.useEffect(() => {
      if (!promptOnExit) return;
      const message = promptOnExit === true ? DEFAULT_PROMPT : promptOnExit;
      /** See https://github.com/remix-run/history/blob/v4/docs/Blocking.md */
      const unblock = history.block((location, action) => {
        // console.log(
        //   "From",
        //   initialPathname,
        //   "To",
        //   location.pathname,
        //   "Via",
        //   action,
        // );
        return frm.dirty && initialPathname !== location.pathname
          ? message
          : undefined;
      });
      // NOTE: Return the unblock function to let the effect cleanup on unmount.
      return unblock;
    }, [frm.dirty, promptOnExit, initialPathname]);

    return (
      <FormikProvider value={frm}>
        <form
          onReset={frm.handleReset}
          onSubmit={frm.handleSubmit}
          // iOS needs an "action" attribute for nice input: https://stackoverflow.com/a/39485162/406725
          // We default the action to "#" in case the preventDefault fails (just updates the URL hash)
          action={action || "#"}
          {...rest}
        >
          {children}
        </form>
      </FormikProvider>
    );
  },
);
