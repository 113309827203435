export type AppraisalStageId =
  | "inspect"
  | "assess"
  | "payoff"
  | "offered"
  | "accepted"
  | "buying"
  | "grounding"
  // Lease returns mgmt
  | "ground_return"
  | "grounded"
  | "turnedin";

export interface AppraisalStageInfo {
  id: string;
  title: string;
  appraisalTypeIds: number[];
  isDefault?: boolean;
  color: string;
  leaseReturnsMgmt?: true;
}

const appraisalStages: AppraisalStageInfo[] = [
  {
    id: "assess",
    title: "Recon assessment pending",
    appraisalTypeIds: [1, 2],
    isDefault: true,
    color: "#00C2FF",
  },
  {
    id: "payoff",
    title: "Finalize payoff & Make offer",
    appraisalTypeIds: [1],
    color: "#FF3B6E",
  },
  {
    id: "payoff",
    title: "Finalize payoff & Make purchase decision",
    appraisalTypeIds: [2],
    color: "#FF3B6E",
  },
  {
    id: "offered",
    title: "Awaiting response",
    appraisalTypeIds: [1],
    color: "#FDBE30",
  },
  {
    id: "accepted",
    title: "Offer accepted",
    appraisalTypeIds: [1],
    color: "#00D289",
  },
  {
    id: "buying",
    title: "Buying",
    appraisalTypeIds: [2],
    color: "#00D289",
  },
  {
    id: "grounding",
    title: "Grounding",
    appraisalTypeIds: [2],
    color: "#FDBE30",
  },
  {
    id: "inspect",
    title: "Awaiting inspection",
    appraisalTypeIds: [1, 2],
    color: "#401AFF",
  },
  {
    id: "ground_return",
    title: "Not grounded/not returned",
    appraisalTypeIds: [],
    color: "#4E50FF",
    isDefault: true,
    leaseReturnsMgmt: true,
  },
  {
    id: "grounded",
    title: "Grounded",
    appraisalTypeIds: [],
    color: "#4E50FF",
    leaseReturnsMgmt: true,
  },
  {
    id: "turnedin",
    title: "Picked up/returned",
    appraisalTypeIds: [],
    color: "#4E50FF",
    leaseReturnsMgmt: true,
  },
];

export const appraisalStagesByType = {
  1: appraisalStages.filter(it => it.appraisalTypeIds.includes(1)),
  2: appraisalStages.filter(it => it.appraisalTypeIds.includes(2)),
  leaseReturnsMgmt: appraisalStages.filter(it => it.leaseReturnsMgmt),
};

export const appraisalStageDefaultByType = {
  1: appraisalStages.find(it => it.isDefault && it.appraisalTypeIds.includes(1))
    .id,
  2: appraisalStages.find(it => it.isDefault && it.appraisalTypeIds.includes(2))
    .id,
  leaseReturnsMgmt: appraisalStages.find(
    it => it.isDefault && it.leaseReturnsMgmt,
  ).id,
};

export const appraisalStagesById = appraisalStages.reduce((byId, it) => {
  byId[it.id] = it;
  return byId;
}, {} as Record<AppraisalStageId, AppraisalStageInfo>);

export const AppraisalStatusId = {
  buying: 1,
  grounding: 2,
  reject: 3,

  grounded: 200,
  pickedup: 220,
  returned: 230,

  bought: 400,
};
