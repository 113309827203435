import React from "react";
import { FormikProvider } from "formik";
import {
  //
  Box,
  Button,
  // Typography,
  // makeStyles,
} from "@material-ui/core";
// Local
import {
  //
  CheckboxArrayField,
  // CheckboxField,
  SelectField,
} from "../../../components";
import {
  //
  formatAmountUSD,
  formatDateTime,
  // formatThousands,
  useFormik,
  useOnMount,
} from "../../../lib";
import {
  appraisalActions,
  useAction,
  appraisalSelectors,
  useSelector,
} from "../../../state";

const rangeItems = [
  { id: "trade", title: "Trade" },
  { id: "trade_poor", title: "Trade (poor)" },
];
const rangeItemIds = rangeItems.map(it => it.id);

const defaultValues = {
  trim: "",
  range: "trade",
  vacs: [],
};

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       // margin: 0,
//     },
//   }),
//   {
//     classNamePrefix: "AppraisalGalvesTab",
//   },
// );

export const AppraisalGalvesTab = React.memo(
  /**
   * @param {{ record: Appraisal }} param0
   */
  function AppraisalGalvesTab({ closed, record }) {
    // const classes = useStyles();

    const getApiResultInfo = useAction(appraisalActions.getApiResultInfo);
    const rerunValuation = useAction(appraisalActions.rerunGalvesVal);
    const showing = useSelector(appraisalSelectors.showing);
    const {
      //
      adjustments,
      createdAt,
      resultData,
      selection,
      vacs,
      // vacsById,
    } = React.useMemo(() => {
      const {
        createdAt,
        selection, // : { trim, range, grade, color: selectedColor } = {},
        data: {
          adjustments = {
            mileage: 0,
            vacs: 0,
          },
          results: resultData = null,
        } = {},
      } = showing.galvesValResult ?? {};
      const vacs = resultData?.vacs ?? [];
      // const vacsById = vacs.reduce((byId, it) => {
      //   byId[it.id] = it;
      //   return byId;
      // }, {});
      return {
        adjustments,
        createdAt,
        resultData,
        selection,
        vacs,
        // vacsById,
      };
    }, [showing]);

    const [busy, setBusy] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const [initialValues, setInitialValues] = React.useState(defaultValues);

    React.useEffect(() => {
      const range = selection?.range;
      setInitialValues({
        // trim: selection?.trim ?? record.trim,
        range: !rangeItemIds.includes(range) ? defaultValues.range : range,
        vacs: selection?.vacs ?? defaultValues.vacs,
      });
      setLoading(false);
    }, [selection, record]);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      // validationSchema, // They want to let it submit no matter what.
      validateOnBlur: false,
      validateOnChange: false,
      // async onSubmit(values, helpers) {
      //   setBusy(true);
      //   console.log("SUBMIT", values);
      //   setBusy(false);
      // },
    });

    useOnMount(() => {
      if (!record.galvesValResultId) {
        setLoading(false);
        return;
      }
      getApiResultInfo(record.id, "galvesValResult", record.galvesValResultId);
    });

    const onChangeVacs = React.useCallback(
      /**
       * @param {React.SyntheticEvent<HTMLInputElement>} e
       * @param {GalvesVehicleAccessory} item The item that changed.
       * @param {number[]} fieldValue The current `vacs` form field value.
       */
      (e, item, fieldValue) => {
        const itemValue = item.id;
        if (e.target.checked) {
          // Add
          if (!fieldValue.includes(itemValue)) {
            fieldValue = [...fieldValue, itemValue];
            // First remove any excluded items...
            const { mutex } = item;
            if (mutex && mutex.length > 0) {
              fieldValue = fieldValue.filter(v => !mutex.includes(v));
            }
          }
        } else {
          // Remove
          if (fieldValue.includes(itemValue)) {
            fieldValue = fieldValue.filter(v => v !== itemValue);
          }
        }
        return fieldValue;
      },
      [],
    );

    const onClickRerun = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        setBusy(true);
        await rerunValuation(record.id, {
          ...frm.values,
        });
        setBusy(false);
      },
      [frm.values, record, rerunValuation],
    );

    if (loading) {
      return <Box padding="60px">Loading...</Box>;
    }
    return (
      <FormikProvider value={frm}>
        <Box flex={1} padding="20px">
          {/* Galves never returns more than one trim, so there's no point...
          <Box>
            <SelectField
              disabled={closed || busy}
              name="trim"
              label="Trim"
              items={trimItems}
              fullWidth
            />
          </Box> */}
          <Box pb="24px">
            <SelectField
              disabled={closed || busy}
              name="range"
              label="Range"
              items={rangeItems}
              fullWidth
            />
          </Box>
          <Box display="flex" flexDirection="column" pb="24px" maxWidth="360px">
            <CheckboxArrayField
              name="vacs"
              items={vacs}
              labelField="desc"
              onChangeItem={onChangeVacs}
              vertical
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pt="22px"
          >
            <Box color="#8D98C2" fontSize="14px">
              {createdAt ? (
                <em>Results from {formatDateTime(createdAt)}</em>
              ) : (
                <>&nbsp;</>
              )}
            </Box>
            <Button
              disabled={closed || busy}
              onClick={onClickRerun}
              variant="contained"
            >
              {createdAt ? "Rerun" : "Run"}
            </Button>
          </Box>
          {!resultData ? (
            <Box padding="60px">No valuation performed on this record.</Box>
          ) : (
            <>
              <ValueSection
                adjustments={adjustments}
                app={record}
                data={resultData}
                range={frm.values.range}
                title="Trade in"
                vacs={frm.values.vacs}
              />
            </>
          )}
        </Box>
      </FormikProvider>
    );
  },
);

const ValueSection = React.memo(
  /**
   * @param {{adjustments:GalvesValAdjustment,app:Appraisal,data:GalvesVehicleResult,range:string,title:string,vacs:number[]}}
   */
  function ValueSection({ adjustments, app, data, range, title, vacs }) {
    const baseValue = data[range] ?? 0;

    // To calculate in real-time...
    // let value = baseValue ?? 0;
    // let vacsAdj = 0;
    // if (vacs && vacs.length > 0) {
    //   vacs.forEach(vacId => {
    //     const vac = data.vacs.find(it => it.id === vacId);
    //     if (vac) {
    //       const { addded, amt = 0 } = vac;
    //       if (addded === "A") {
    //         vacsAdj += amt;
    //         value += amt;
    //       } else if (addded === "D") {
    //         vacsAdj -= amt;
    //         value -= amt;
    //       }
    //     }
    //   });
    // }
    // value -= adjustments.mileage;

    // To use stored values...
    const vacsAdj = adjustments.vacs;
    const value = app.galvesVal ?? 0;

    return (
      <Box>
        <Box
          color="#141932"
          fontSize="16px"
          fontWeight="500"
          letterSpacing="0"
          lineHeight="25px"
        >
          {title}
        </Box>
        <ValueItem title="Base" value={baseValue} />
        <ValueItem title="Accessories" value={vacsAdj} />
        <ValueItem title="Odometer adjustment" value={adjustments.mileage} />
        <ValueItem title="Total" value={value} />
      </Box>
    );
  },
);

const ValueItem = React.memo(
  /**
   *
   */
  function ValueItem({ title, value }) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pt="8px"
      >
        <Box
          color="#8D98C2"
          fontSize="14px"
          fontWeight="500"
          lineHeight="21px"
          pl="24px"
        >
          {title}
        </Box>
        <Box color="#141932" fontSize="16px" lineHeight="25px">
          {formatAmountUSD(value)}
        </Box>
      </Box>
    );
  },
);
