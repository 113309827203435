import { Box, Typography } from "@material-ui/core";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { subscribe } from "valtio";
import { useMobile } from "../../themes";
import OpportunitiesBox from "./OpportunitiesBox";
import {
  draggableState,
  reorder,
  SectionDetails,
  useDragState,
} from "./DraggableState";

export default function OpportunitiesContainer() {
  const isMobile = useMobile();
  const isTablet = useMobile("md");
  const getListStyle = _isDraggingOver => ({
    width: isMobile || isTablet ? "100%" : "70%",
    height: "100%",
    background: "transparent",
  });

  // help with reordering the result
  // https://codesandbox.io/s/k260nyxq9v?file=/index.js:177-314
  const { order } = useDragState();
  let currentOrder: readonly SectionDetails[] = order;
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    currentOrder = reorder(result.source.index, result.destination.index);
    return currentOrder;
  };
  subscribe(draggableState.order, () => {
    localStorage.setItem("dragOrder", JSON.stringify(currentOrder));
  });

  return (
    <Box m={isMobile || isTablet ? 2 : 6} mt={8}>
      <Box p={1} textAlign={isMobile || isTablet ? "center" : "justify"}>
        <Typography variant="h1">Don't miss these opportunities</Typography>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sections">
          {(provided, snapshot) => (
            <div
              className="sections"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <OpportunitiesBox />
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}
