import React from "react";
import {
  //
  makeStyles,
} from "@material-ui/core";
// Icons
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles(
  theme => ({
    root: {
      // padding: 8,

      "& .ToggleBlock-head": {
        alignItems: "center",
        color: "rgba(0, 0, 0, 0.42)",
        cursor: "pointer",
        display: "flex",
        padding: "8px 12px 8px 0",
        userSelect: "none",
      },
      "& .ToggleBlock-title": {
        flex: 1,
      },
      // "& .ToggleBlock-content": {
      //   // ...
      // },
    },
  }),
  {
    classNamePrefix: "ToggleBlock",
  },
);

const defaultIcons = {
  opened: <ExpandLessIcon color="inherit" fontSize="small" />,
  closed: <ExpandMoreIcon color="inherit" fontSize="small" />,
  info: <InfoIcon color="inherit" fontSize="small" />,
};

export const ToggleBlock = React.memo(
  /**
   * @param {{
   * children:React.ReactNode,
   * className?:string,
   * defaultOpen?:boolean,
   * iconOpened?: React.ReactNode,
   * iconClosed?: React.ReactNode,
   * infoIcon?: boolean,
   * tip?:string,
   * title?:string
   * }} param0
   */
  function ToggleBlock({
    children,
    className,
    defaultOpen,
    infoIcon,
    iconOpened = infoIcon ? defaultIcons.info : defaultIcons.opened,
    iconClosed = infoIcon ? defaultIcons.info : defaultIcons.closed,
    tip,
    title,
  }) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(defaultOpen);
    const { toggleOpen } = React.useMemo(() => {
      return {
        /** @param {React.KeyboardEvent<HTMLElement>} e */
        toggleOpen: e => {
          if (e.key) {
            // console.log("KEY", `"${e.key}"`, e.keyCode);
            if (e.key !== " " && e.key !== "Enter") {
              return;
            }
          }
          setOpen(value => !value);
        },
      };
    }, []);

    return (
      <div className={`${classes.root} ${className}`}>
        <div
          className={"ToggleBlock-head"}
          onClick={toggleOpen}
          onKeyDown={toggleOpen}
          tabIndex="0"
          title={tip}
        >
          <div className={"ToggleBlock-title"}>{title}</div>
          {open ? iconOpened : iconClosed}
        </div>
        {open && <div className={"ToggleBlock-content"}>{children}</div>}
      </div>
    );
  },
);
