import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Local
import { useField } from "../../lib";

/**
 * This component currently only saves a single option field as the value, e.g.
 * `option[optionsValueKey]`.
 * Use `ComboField` to save object values.
 *
 * @typedef {import("@material-ui/lab").AutocompleteProps} AutocompleteProps
 * @typedef {import("@material-ui/core").TextFieldProps} TextFieldProps
 * @typedef {object} AutocompleteFieldProps
 * @property {(value:string)=>Promise<unknown[]>} [getOptions]
 * @property {TextFieldProps} [TextFieldProps]
 * @property {boolean} required
 *
 * @param {AutocompleteProps & AutocompleteFieldProps} props
 */
export function AutocompleteField(props) {
  const {
    label,
    name,
    itemsList,
    getOptions,
    optionsLabelKey = "name",
    optionsValueKey = "id",
    value: _value,
    variant,
    required,
    TextFieldProps,
    autoFocus,
    ...autocompleteProps
  } = props;
  const { freeSolo, multiple } = autocompleteProps;
  const [fld, meta, helpers] = useField(name);

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (itemsList) {
      setItems(itemsList);
    }
  }, [itemsList]);

  const getOptionLabel = React.useCallback(
    option => {
      const item = items.find(it => it[optionsValueKey] === option);
      const label = item
        ? item[optionsLabelKey] ?? ""
        : // NOTE: Sometimes option is NOT the option object, it's the VALUE!!
        // So if it's a string, just return it...
        typeof option === "string"
        ? option
        : (typeof optionsLabelKey === "function"
            ? optionsLabelKey(option)
            : option[optionsLabelKey]) ?? "";
      // console.log("LABEL", label, option, optionsLabelKey);
      return label;
    },
    [items, optionsLabelKey, optionsValueKey],
  );

  const getOptionSelected = React.useCallback(
    (option, value) => {
      // console.log(
      //   "SELECTED?",
      //   option,
      //   value,
      //   option[optionsValueKey] === value ||
      //     option[optionsValueKey] === value[optionsValueKey],
      // );
      return (
        option &&
        (option[optionsValueKey] === value ||
          (value && option[optionsValueKey] === value[optionsValueKey]))
      );
    },
    [optionsValueKey],
  );

  const onChange = React.useCallback(
    /**
     * Handler for when the entire Autocomplete changes.
     * @param {React.ChangeEvent<{}>} e
     * @param {any} value
     * @param {"create-option"|"select-option"|"remove-option"|"blur"|"clear"} reason
     * @param {any} details
     */
    (e, value, reason, details) => {
      // console.log("AutocompletField onChange", { value, reason, details });
      if (multiple) {
        helpers.setValue(value);
        return;
      }
      if (freeSolo && typeof value === "string") {
        helpers.setValue(value);
        return;
      }
      helpers.setValue(value?.[optionsValueKey] ?? "");
    },
    [helpers, optionsValueKey, multiple, freeSolo],
  );

  const onInputChange = React.useCallback(
    /**
     * Handler for when the TextField changes.
     */
    async (e, value, reason) => {
      // console.log("AutocompletField onInputChange", { value, reason });
      if (getOptions) {
        const items = await getOptions(value);
        setItems(items);
      }
    },
    [getOptions],
  );

  const onOpen = React.useCallback(async () => {
    if (getOptions) {
      const items = await getOptions();
      setItems(items);
    }
  }, [getOptions]);

  // console.log("RENDER", fld.value, items);

  return (
    <Autocomplete
      loading={!items || items.length < 1}
      name={name}
      // When the value is blank, send `null` to avoid the warning that the
      // entered value doesn't match any of the options.
      value={fld.value || null}
      onChange={onChange}
      options={items}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onInputChange={onInputChange}
      onOpen={onOpen}
      renderInput={params => (
        <TextField
          name={name}
          label={label}
          autoFocus={autoFocus}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          variant={variant}
          required={required}
          {...TextFieldProps}
          {...params}
        />
      )}
      // Automatically highlight the first matching item on the list.
      autoHighlight
      // Automatically select the highlighted item.
      autoSelect
      {...autocompleteProps}
    />
  );
}
