import { makeStyles } from "@material-ui/core";

export const mainToolbarHeight = 56;

export const useStyles = makeStyles(
  theme => ({
    root: {
      // backgroundColor: "#000",
      zIndex: theme.zIndex.drawer + 1,
      // boxShadow: "none",
      boxShadow: "0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)",
    },
    toolbar: {
      justifyContent: "space-between",
      height: mainToolbarHeight,
    },
    mainTools: {
      color: "white",
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    menuButton: {
      fontSize: 24,
      marginRight: 12,
    },
    headerLink: {
      height: "100%",
      marginLeft: 40,
      color: "#B5C1FF",
      fontSize: 16,
      fontWeight: 500,
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      borderBottom: "2px solid transparent",
      "&:hover": {
        borderBottom: "2px solid #fff",
        color: "#fff",
      },
      "&.active": {
        borderBottom: "2px solid #fff",
        color: "#fff",
      },
    },
    sideTools: {
      alignItems: "center",
      display: "flex",
    },
    inviteLink: {
      color: "#B5C1FF",
      fontSize: 14,
      fontWeight: 400,
      paddingRight: 40,
    },
    dealerName: {
      color: "#B5C1FF",
      fontSize: 16,
      fontWeight: 500,
      // paddingRight: 40,
      // marginRight: 40,
    },
    userMenu: {
      width: 200,
      "& .MuiMenu-list": {
        padding: 0,
      },
      "& .MuiMenuItem-root": {
        padding: "12px 24px",
      },
    },
  }),
  {
    classNamePrefix: "MainToolbar",
  },
);

export const useGlobalSearchStyles = makeStyles(
  theme => ({
    menuButton: {
      padding: 8,
    },
    searchFieldContainer: {
      margin: "0 16px",
      color: "#FFF",
    },
    globalSearchTextField: {
      width: "600px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      borderRadius: "8px",
      backgroundColor: "#FFF",
      border: "hidden",
      "& > .MuiInputBase-root > .MuiInputBase-input": {
        padding: "12px 0",
      },
    },
    popper: {
      backgroundColor: "#fcfcfc",
      marginTop: "52px",
      borderRadius: "4px",
      zIndex: 9999,
      boxShadow: "0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        overflow: "hidden",
      },
    },
    modalContainer: {
      position: "relative",
      height: "94vh",
      width: "600px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      overflowX: "hidden",
      overflowY: "scroll",
    },
    row: {
      padding: 12,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // maxHeight: "600px",
      width: " 100%",
      [theme.breakpoints.down("md")]: {
        padding: 8,
      },
    },
    thumbAndRate: {
      width: "30%",
    },
    link: {
      textDecoration: "none",
      border: "1px solid #DADFF6",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      margin: 6,
      padding: 8,
      borderRadius: "4px",
      outline: "3px transparent",
      "&:focus": {
        outline: "solid",
        backgroundColor: "ghostwhite",
      },
      [theme.breakpoints.down("md")]: {
        height: "150px",
      },
    },
    //year make model
    ymm: {
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        margin: 0,
      },
    },
    carSpecs: {
      fontSize: "14px",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
      width: "100%",
      whiteSpace: "break-spaces",
      fontWeight: 400,
      color: "black",
    },
    displayDate: {
      fontWeight: 500,
      color: theme.palette.primary.main,
      fontSize: "12px",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
      display: "flex",
      width: "100%",
      alignItems: "center",
      paddingLeft: 0,
      flex: 1,
      "&>p": {
        "&:first-child": {
          paddingLeft: 0,
        },
      },
    },
    noResults: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      fontWeight: "bold",
      fontSize: "20px",
    },
    descriptionContainer: {
      height: "100%",
      [theme.breakpoints.down("md")]: {
        padding: 12,
      },
    },
    photoBox: {
      height: 56,
      width: 88,
      boxSizing: "content-box",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: 11,
      "& svg": {
        position: "absolute",
        top: 4,
        left: 0,
        height: 20,
        width: 20,
        color: "white",
        backgroundColor: "#6876FF",
      },
      "& .thumbnail": {
        height: 56,
        width: 88,
        borderRadius: 3,
        objectFit: "cover",
      },
    },
    showMore: {
      display: "flex",
      alignItems: "center",
      position: "fixed",
      bottom: 0,
      background: "#fcfcfc",
      zIndex: 70,
      width: "600px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      justifyContent: "flex-end",
      "&>.MuiButtonBase-root": {
        width: "50%",
      },
      "&>span": {
        width: "25%",
        padding: "12px",
        [theme.breakpoints.down("md")]: {
          fontSize: "10px",
          color: theme.palette.primary.main,
        },
      },
    },
  }),
  {
    classNamePrefix: "GlobalSearchStyles",
  },
);
