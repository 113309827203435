import React from "react";
import { Button, DialogActions, makeStyles } from "@material-ui/core";

export interface CancelSaveDialogActionsProps {
  children?: React.ReactNode;
  deleteLabel?: React.ReactNode;
  disabled: boolean;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  onSave?: React.MouseEventHandler<HTMLButtonElement>;
  saveDisabled?: boolean;
  saveLabel?: React.ReactNode;
  topBorder?: boolean;
}

export const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: "white",
      position: "sticky",
      bottom: 0,
      padding: 24,
      zIndex: theme.zIndex.appBar,
      // [theme.breakpoints.down("sm")]: {
      //   padding: 8,
      // },
    },
  }),
  {
    classNamePrefix: "CancelSaveDialogActions",
  },
);

const styles = {
  topBorder: {
    borderTop: "1px solid lightgrey",
  },
  /** @type {React.CSSProperties} */
  cancel: {
    backgroundColor: "#EFF1FB",
    color: "#141932",
    // marginRight: "auto",
  },
  /** @type {React.CSSProperties} */
  delete: {
    marginRight: "auto",
    borderColor: "#c62828",
    color: "#c62828",
    padding: "10px 26px",
  },
};

export const CancelSaveDialogActions = React.memo(
  function CancelSaveDialogActions({
    children,
    disabled,
    onCancel,
    onDelete,
    onSave,
    saveDisabled,
    saveLabel = "Save",
    deleteLabel = "Delete",
    topBorder,
  }: CancelSaveDialogActionsProps) {
    const classes = useStyles();
    return (
      <DialogActions
        className={classes.root}
        style={topBorder ? styles.topBorder : undefined}
      >
        {children}
        {onDelete && (
          <Button
            variant="outlined"
            // color="secondary"
            // size="small"
            disabled={disabled}
            onClick={onDelete}
            style={styles.delete}
          >
            {deleteLabel}
          </Button>
        )}

        {onCancel && (
          <Button
            variant="contained"
            color="primary"
            // size="small"
            disabled={disabled}
            onClick={onCancel}
            style={styles.cancel}
          >
            Cancel
          </Button>
        )}
        <Button
          type={onSave ? undefined : "submit"}
          variant="contained"
          color="secondary"
          // size="small"
          disabled={saveDisabled || disabled}
          onClick={onSave}
        >
          {saveLabel}
        </Button>
      </DialogActions>
    );
  },
);
