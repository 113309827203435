import React from "react";
import { Box, Typography } from "@material-ui/core";
// Local
import { carfax, Navigation, useOnMount } from "../../lib";
import { authActions, useAction } from "../../state";
import { AuthPages } from "./";
import { useStyles } from "./Auth.styles";

export const LogoutPage = React.memo(
  /**
   *
   */
  function LogoutPage() {
    const classes = useStyles();
    const logout = useAction(authActions.logout);

    useOnMount(() => {
      carfax.auth.logout();
      logout().then(() => {
        Navigation.reload(AuthPages.login);
      });
    });

    return (
      <div className={classes.root}>
        <Box>
          <Typography variant="h1">Logging out...</Typography>
        </Box>
      </div>
    );
  },
);
