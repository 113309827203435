import React from "react";
// Local
import { getMainSlug, useRouterLocation } from "../../lib";
import { authClient } from "../auth/actions";
import { useSelector } from "../utils";
import { dealerSelectors } from "./selectors";

/**
 * Gets a URL to the dealer's ANON service appraisal page.
 * (Not the New Opportunity page.)
 */
export function getDealerServiceAppraisalUrl(
  dealer: Dealer,
  user?: { uid: string },
) {
  const loc = window.location;
  let url = `${loc.protocol}//${loc.host}/${dealer.slug}/open/service`;
  if (user) {
    url += `?duid=${user.uid}`;
  }
  return url;
}

export function useDealer(): Dealer {
  const location = useRouterLocation();
  const dealers = useDealers();
  return React.useMemo(() => {
    const mainSlug = getMainSlug(location.pathname);
    const { length } = dealers;
    if (!length) {
      return undefined;
    }
    for (let i = 0; i < length; i++) {
      const dealer = dealers[i];
      if (dealer.slug === mainSlug) {
        return dealer;
      }
    }
    return undefined;
  }, [dealers, location.pathname]);
}

export function useDealerOrDefault() {
  const defaultDealer = useSelector(dealerSelectors.defaultDealer);
  const dealer = useDealer();
  return dealer ?? defaultDealer;
}

export function useDealers() {
  return useSelector(dealerSelectors.dealers);
}

export function useDefaultDealer() {
  return useSelector(dealerSelectors.defaultDealer);
}

const cached = {
  linkedDealers: {
    from: {} as Record<
      number,
      {
        data: Dealer[];
        time: number;
      }
    >,
    to: {} as Record<
      number,
      {
        data: Dealer[];
        time: number;
      }
    >,
  },
  // users: {} as Record<
  //   number,
  //   {
  //     data: DealerUser[];
  //     time: number;
  //   }
  // >,
};
const maxCacheTime = 15 * 60 * 1000; // Minutes * seconds * milliseconds.
// /**
//  * Gets a cached list of dealership users.
//  * @param dealerId The current dealer id.
//  * @returns An array of dealer users.
//  */
// export function useDealerUsers(dealerId: number) {
//   const [items, setItems] = React.useState(
//     cached.users[dealerId]?.data ?? ([] as DealerUser[]),
//   );
//   React.useEffect(() => {
//     if (dealerId === 0) {
//       return;
//     }
//     const state = { mounted: true };
//     async function load() {
//       let cache = cached.users[dealerId];
//       if (cache && Date.now() - cache.time <= maxCacheTime) {
//         setItems(cache.data);
//         return;
//       }
//       // NOTE: dealerId is sent with the auth header already...
//       const { data } = await authClient.get<DealerUser[]>(`/dealer_users`);
//       // CONSIDER: Format user to add initials here as done in other actions...
//       cache = {
//         data,
//         time: Date.now(),
//       };
//       cached.users[dealerId] = cache;
//       if (!state.mounted) return;
//       setItems(data);
//     }
//     load().catch(err => {
//       // Network errors show up elsewhere.
//     });
//     return () => (state.mounted = false);
//   }, [dealerId]);
//   return items;
// }
/**
 * Gets a cached list of linked dealers.
 * @param dealerId The current dealer id.
 * @param side Pass "from" to get dealers that the current dealer links to.
 * Pass "to" for dealers that link to the current dealer. Defaults to "from".
 * @returns An array of dealers.
 */
export function useLinkedDealers(
  dealerId: number,
  side: "from" | "to" = "from",
) {
  const [dealers, setDealers] = React.useState(
    cached.linkedDealers[side][dealerId]?.data ?? ([] as Dealer[]),
  );
  React.useEffect(() => {
    if (dealerId === 0) {
      return;
    }
    const state = { mounted: true };
    async function load() {
      let cache = cached.linkedDealers[side][dealerId];
      if (cache && Date.now() - cache.time <= maxCacheTime) {
        setDealers(cache.data);
        return;
      }
      const { data } = await authClient.get<Dealer[]>("linked_dealers");
      cache = {
        data,
        time: Date.now(),
      };
      cached.linkedDealers[side][dealerId] = cache;
      if (!state.mounted) return;
      setDealers(data);
    }
    load().catch(err => {
      // Network errors show up elsewhere.
    });
    return () => (state.mounted = false);
  }, [dealerId, side]);
  return dealers;
}
