// import React from "react";
import {
  //
  withStyles,
} from "@material-ui/core";
import MuiRating from "@material-ui/lab/Rating";

export const RatingInput = withStyles({
  iconFilled: {
    stroke: "#FDBE30",
    color: "#FDBE30",
  },
  iconHover: {
    color: "#FDBE30",
  },
  iconEmpty: {
    stroke: "#BBC2E1",
    color: "transparent",
  },
})(MuiRating);
