import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
// Local
import {
  //
  carfax,
  useOnMount,
} from "../../../lib";

const useStyles = makeStyles(
  theme => ({
    root: {
      // margin: 0,
      padding: 20,
    },
  }),
  {
    classNamePrefix: "CarfaxVinReportPage",
  },
);

export const CarfaxVinReportPage = React.memo(
  /**
   *
   */
  function CarfaxVinReportPage({ match = {} }) {
    const classes = useStyles();
    const { params: { vin = "" } = {} } = match;

    const [error, setError] = React.useState(undefined);

    useOnMount(() => {
      // Probably go to the dealer report page, but if we need to login first
      // our Carfax code will send us right back to this page after it has
      // successfully connected with Carfax.
      async function handler() {
        const err = await carfax.gotoReport({ vin });
        if (err) {
          setError("" + err);
        }
      }
      handler();
    });

    return (
      <div className={classes.root}>
        {error ? (
          <Typography>
            There was an error loading the report for VIN {vin}...
            <br />
            Please contact support.
          </Typography>
        ) : (
          <Typography>Loading report for VIN {vin}...</Typography>
        )}
      </div>
    );
  },
);
