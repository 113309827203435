import React from "react";
import {
  //
  IconButton,
} from "@material-ui/core";

export const IconToggleButton = React.memo(
  /**
   * @typedef {import("@material-ui/core").IconButtonProps} IconButtonProps
   * @typedef {object} IconToggleButtonProps
   * @property {(e:React.MouseEvent<HTMLButtonElement, MouseEvent>,value:any)=>void} onChange
   * @property {React.ReactNode} [hoverIcon] ~~Icon to show when hovering.~~
   * **(NOTE: THIS RESOURCE-HEAVY FEATURE IS DISABLED FOR NOW.)**
   * @property {React.ReactNode} [toggleIcon] Icon to show when `value` matches `valueOn`.
   * @property {any} value
   * @property {any} [valueOff] Defaults to `false`. **NOTE: We compare only
   * `valueOn` to `value` to choose the icon and set `valueOff` when toggled.**
   * @property {any} [valueOn] Defaults to `true`.
   *
   * @param {IconToggleButtonProps & IconButtonProps} param0
   */
  function IconToggleButton({
    children,
    // hoverIcon,
    toggleIcon,
    onChange,
    onClick: onClickProp,
    value,
    valueOff = false,
    valueOn = true,
    ...iconButtonProps
  }) {
    const toggled = value === valueOn;

    const onClick = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      e => {
        if (onClickProp) {
          onClickProp(e);
          if (e.defaultPrevented) {
            return;
          }
        }
        if (onChange) {
          onChange(e, toggled ? valueOff : valueOn);
        }
      },
      [onChange, onClickProp, toggled, valueOff, valueOn],
    );

    // #region hoverIcon feature, uncomment if necessary...
    //
    // const [hovering, setHovering] = React.useState(false);
    // const onMouseEnter = React.useCallback(e => {
    //   setHovering(true);
    // }, []);
    // const onMouseLeave = React.useCallback(e => {
    //   setHovering(false);
    // }, []);
    // const hoverProps = hoverIcon ? { onMouseEnter, onMouseLeave } : undefined;
    // const icon = hovering ? hoverIcon : toggled ? toggleIcon : children;
    //
    // #endregion

    const icon = toggled ? toggleIcon : children;

    return (
      <IconButton
        onClick={onClick}
        // {...hoverProps}
        {...iconButtonProps}
      >
        {icon}
      </IconButton>
    );
  },
);
