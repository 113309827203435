import React from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
// Local
import { Form, Navigation, useFormik, useLocation, yup } from "../../lib";
import { authActions, useAction } from "../../state";
// import { AuthPages } from "./";
import { useStyles } from "./Auth.styles";

const validationSchema = yup.object({
  password1: yup
    .string("Enter a password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password2: yup
    .string("Enter a password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const ConfirmAccountPage = React.memo(
  /**
   *
   */
  function ConfirmAccountPage() {
    const classes = useStyles();
    const confirmAccount = useAction(authActions.confirmAccount);
    const login = useAction(authActions.login);
    const location = useLocation();

    const frm = useFormik({
      initialValues: {
        password1: "",
        password2: "",
      },
      validationSchema,
      async onSubmit(values) {
        // TODO: Validate passwords. See mailpake/prime client-app ResetPasswordPage.jsx
        const password = values.password1;
        const { email, token } = location.query;
        await confirmAccount({
          newPassword: password,
          email,
          token,
        });
        // Navigation.replace(AuthPages.login);
        try {
          await login({ email, password });
        } catch (ex) {
          window.alert(
            "There was an error logging in. Please contact support.",
          );
          return;
        }
        Navigation.replace("/");
      },
    });

    return (
      <div className={classes.root}>
        <Box mb="8px">
          <Typography variant="h1"> Confirm Account</Typography>
        </Box>
        <Box mb="8px">
          <Typography variant="subtitle1">
            Enter your new password here.
          </Typography>
        </Box>
        <Form form={frm} className={classes.form}>
          <TextField
            fullWidth
            id="password1"
            name="password1"
            label="New password"
            type="password"
            autoComplete="new-password"
            value={frm.values.password1}
            onChange={frm.handleChange}
            error={frm.touched.password1 && Boolean(frm.errors.password1)}
            helperText={frm.touched.password1 && frm.errors.password1}
          />
          <Box pt="32px" pb="40px">
            <TextField
              fullWidth
              id="password2"
              name="password2"
              label="Repeat new password"
              type="password"
              autoComplete="reenter-new-password"
              value={frm.values.password2}
              onChange={frm.handleChange}
              error={frm.touched.password2 && Boolean(frm.errors.password2)}
              helperText={frm.touched.password2 && frm.errors.password2}
            />
          </Box>
          <Button
            variant="contained"
            type="submit"
            disabled={frm.isSubmitting}
            color="secondary"
          >
            Signup
          </Button>
        </Form>
      </div>
    );
  },
);
