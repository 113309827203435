import React from "react";
import {
  //
  Box,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
// Local
import {
  //
  Field,
  SelectField,
} from "../../../components";
import {
  //
  Form,
  // Navigation,
  // isEmpty,
  shallowEqualsObj,
  useDebouncedEffect,
  useFormik,
  useLocation,
} from "../../../lib";
import {
  appraisalActions,
  authSelectors,
  useAction,
  useDealer,
  useLinkedDealers,
  useSelector,
  isDuplicatePath,
} from "../../../state";
// import { AppraisalPages } from "../../index";

const appraisalFilters = [
  { id: 0, title: "Today" },
  // { id: -1, title: "Yesterday" },
  { id: -2, title: "This week" },
  { id: -3, title: "This month" },
  { id: 7, title: "Past 7 days" },
  { id: 30, title: "Past 30 days" },
  { id: 60, title: "Past 60 days" },
  { id: 90, title: "Past 90 days" },
  { id: -4, title: "Any date" },
];

const appraisalSorts = [
  { id: "id-DESC", title: "Newest" },
  { id: "updatedAt-DESC", title: "Latest update" },
  { id: "runningStatus-DESC", title: "Inspection grade" },
  { id: "id-ASC", title: "Oldest" },
  { id: "reconditioningCost-DESC", title: "Recon amount" },
  { id: "expectedTotalProfit-DESC", title: "Expected profit" },
  { id: "adjustedPayoffAmount-DESC", title: "Payoff" },
  { id: "year-DESC", title: "Year" },
  { id: "make-ASC", title: "Make" },
  { id: "inspectedMileage-ASC", title: "Mileage" },
  { id: "model-ASC", title: "Model" },
];

// const sortItems = [
//   { title: "Id", id: "id" },
//   { title: "VIN", id: "vin" },
//   { title: "Year", id: "year" },
//   { title: "Mileage", id: "mileage" },
// ];

// const sortOrderItems = [
//   { title: "descending", id: "desc" },
//   { title: "ascending", id: "asc" },
// ];

export const useStyles = makeStyles(
  theme => ({
    root: {
      alignItems: "center",
      // borderBottom: "1px solid #E5E7F5",
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: 24,
      // paddingLeft: 32,
    },
    divider: {
      backgroundColor: "#DADFF6",
      margin: "0 -40px",
    },
    inputs: {
      display: "flex",
      alignItems: "center",
      "& .MuiSelect-select": {
        fontSize: 14,
        paddingTop: 0,
        paddingBottom: 0,
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "start",
        width: "100%",
      },
    },
    searchQuery: {
      width: 320,
      marginRight: 20,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginRight: 0,
        marginBottom: theme.spacing(3),
      },
      "& .MuiInputBase-input": {
        padding: "10.5px 14px",
      },
      "& ::placeholder": {
        fontSize: 14,
        color: theme.palette.text.secondary,
        opacity: 1,
      },
    },
    filtersContainer: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        justifyContent: "space-between",
        width: "100%",
      },
    },
    filterContainer: {
      paddingLeft: 8,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
      },
    },
    filters: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.text.secondary,
      paddingLeft: 24,
      [theme.breakpoints.down("xs")]: {
        display: "block",
        paddingLeft: 0,
      },
    },
    filter: {
      "& .MuiSelect-selectMenu": {
        paddingTop: "1.5px",
        color: theme.palette.text.secondary,
      },
      "& .MuiSelect-select": {
        [theme.breakpoints.down("xs")]: {
          fontSize: 12,
        },
      },
      "& .MuiSelect-icon": {
        color: theme.palette.text.secondary,
      },
    },
    // filterDropdown: {
    //   position: "absolute",
    //   right: 0,
    //   padding: "24px 32px",
    //   width: 360,
    //   zIndex: 1000,
    //   "& .MuiFormControl-root": {
    //     width: "100%",
    //     marginBottom: 8,
    //   },
    // },
  }),
  {
    classNamePrefix: "AppraisalSearchbar",
  },
);

export const AppraisalSearchbar = React.memo(
  /**
   *
   */
  function AppraisalSearchbar({
    isLeaseReturns,
    // isLeaseReturnsMgmt,
    isService,
    isShowroom,
    hideFilters,
  }) {
    const classes = useStyles();

    const {
      pathname,
      query,
      query: { tab },
    } = useLocation();
    const getCampaigns = useAction(appraisalActions.getCampaigns);
    const getUsers = useAction(appraisalActions.getDealerUsers);
    const search = useAction(appraisalActions.search);

    const { id: dealerId } = useDealer();
    const allowTransfers = useSelector(authSelectors.allowTransfers);
    const linkedDealers = useLinkedDealers(allowTransfers ? dealerId : 0, "to");
    const [campaigns, setCampaigns] = React.useState([]);
    const [users, setUsers] = React.useState([]);

    // const location = useLocation();
    // /** @type {{filter:any,sort:string,order:"ASC"|"DESC"}} */
    // const query = location.query;
    // const currentFilter = query.filter ? JSON.parse(query.filter) : {};
    const {
      filter: currentFilter,
      sort: currentSort = "id",
      order: currentOrder = "DESC",
    } = useSelector(state => state.appraisals);
    const initialValues = React.useMemo(() => {
      return {
        q: `${currentFilter?.q ?? ""}`,
        filterId:
          JSON.parse(query?.filters || "{}")?.filterId ??
          parseInt(
            currentFilter?.filterId ?? (isService || isShowroom ? "0" : "30"),
          ),
        ...(isLeaseReturns
          ? undefined
          : {
              trfDealerId: parseInt(currentFilter?.trfDealerId) || 0,
              campaignId: parseInt(currentFilter?.campaignId) || 0,
              originatorId: parseInt(currentFilter?.originatorId) || 0,
            }),
        sortBy: `${currentSort}-${currentOrder}`,
      };
    }, [
      query.filters,
      currentFilter,
      currentSort,
      currentOrder,
      isService,
      isShowroom,
      isLeaseReturns,
    ]);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      // validationSchema,
      validateOnBlur: false,
      validateOnChange: false,
      async onSubmit(values, helpers) {
        const filter = {
          filterId: values.filterId,
          q: values.q,
        };
        if (values.campaignId) {
          filter.campaignId = values.campaignId;
        }
        if (values.originatorId) {
          filter.originatorId = values.originatorId;
        }
        if (values.trfDealerId) {
          filter.trfDealerId = values.trfDealerId;
        }
        const [sort, order] = values.sortBy.split("-");
        // console.log("SORT", values.sortBy, sort, order);
        // console.log("SEARCHING");
        search({
          filter,
          sort,
          order,
          pathname,
          tab,
        });
      },
    });

    // const { setValues } = frm;
    // const clearFilters = React.useCallback(
    //   /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
    //   e => {
    //     setValues({
    //       q: "",
    //       inspection: "",
    //     });
    //   },
    //   [setValues],
    // );
    const isDuplicate = isDuplicatePath(pathname);
    useDebouncedEffect(() => {
      if (shallowEqualsObj(frm.initialValues, frm.values)) {
        // console.log("SKIPPING auto search");
        return;
      }
      // console.log("VALUES CHANGED", frm.values);
      frm.submitForm();
    }, [frm]);

    React.useEffect(() => {
      const state = { mounted: true };
      async function load() {
        const campaigns = initialValues?.campaignId ? await getCampaigns() : [];
        const users = initialValues?.originatorId ? await getUsers() : [];
        if (!state.mounted) return;
        setCampaigns(campaigns);
        setUsers(users);
      }
      load();
      return () => (state.mounted = false);
    }, [initialValues, getCampaigns, getUsers]);

    const loadCampaigns = React.useCallback(() => {
      getCampaigns().then(items => {
        setCampaigns(items);
      });
    }, [getCampaigns]);

    const loadUsers = React.useCallback(() => {
      getUsers().then(items => {
        setUsers(items);
      });
    }, [getUsers]);
    const displayMoreFilterOptions =
      !hideFilters && !isService && !isShowroom && !isLeaseReturns;
    return (
      <>
        <Form className={classes.root} form={frm}>
          <div className={classes.inputs}>
            <Field
              name="q"
              placeholder={`Search ${
                isLeaseReturns ? "lease returns" : "trade ins"
              }...`}
              fullWidth
              size="small"
              className={classes.searchQuery}
              title="VIN  | year | make | model | trim | name | email"
            />
            <Box className={classes.filtersContainer}>
              {!isDuplicate && (
                <Box className={classes.filters}>
                  <Typography variant="body2">Filter by:</Typography>
                  <Box className={classes.filterContainer}>
                    <SelectField
                      name="filterId"
                      items={appraisalFilters}
                      size="small"
                      variant="standard"
                      disableUnderline
                      className={classes.filter}
                    />
                  </Box>
                </Box>
              )}
              <Box className={classes.filters}>
                <Typography variant="body2">Sort by:</Typography>
                <Box className={classes.filterContainer}>
                  {/* TODO: add in chevron - cg */}
                  <SelectField
                    name="sortBy"
                    items={appraisalSorts}
                    size="small"
                    variant="standard"
                    disableUnderline
                    className={classes.filter}
                  />
                </Box>
              </Box>
              {displayMoreFilterOptions && (
                <Box className={classes.filters}>
                  <Typography variant="body2">Campaign:</Typography>
                  <Box className={classes.filterContainer}>
                    <SelectField
                      name="campaignId"
                      emptyEnabled
                      emptyValue={0}
                      items={campaigns}
                      itemText="name"
                      size="small"
                      variant="standard"
                      disableUnderline
                      className={classes.filter}
                      SelectProps={
                        campaigns.length
                          ? undefined
                          : {
                              displayEmpty: true,
                              onOpen: loadCampaigns,
                            }
                      }
                    />
                  </Box>
                </Box>
              )}
              {displayMoreFilterOptions && (
                <Box className={classes.filters}>
                  <Typography variant="body2">Originator:</Typography>
                  <Box className={classes.filterContainer}>
                    <SelectField
                      name="originatorId"
                      emptyEnabled
                      emptyValue={0}
                      items={users}
                      itemText="name"
                      size="small"
                      variant="standard"
                      disableUnderline
                      className={classes.filter}
                      SelectProps={
                        users.length
                          ? undefined
                          : {
                              displayEmpty: true,
                              onOpen: loadUsers,
                            }
                      }
                    />
                  </Box>
                </Box>
              )}
              {displayMoreFilterOptions &&
                allowTransfers &&
                linkedDealers.length > 0 && (
                  <Box className={classes.filters}>
                    <Typography variant="body2">Transfers:</Typography>
                    <Box className={classes.filterContainer}>
                      <SelectField
                        name="trfDealerId"
                        emptyEnabled
                        emptyValue={0}
                        items={linkedDealers}
                        itemText="name"
                        size="small"
                        variant="standard"
                        disableUnderline
                        className={classes.filter}
                      />
                    </Box>
                  </Box>
                )}
            </Box>
          </div>
          <div className={classes.buttons}></div>
        </Form>
        <Divider className={classes.divider} />
      </>
    );
  },
);
