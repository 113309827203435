// import { createSelector } from "@reduxjs/toolkit";
// Local
import { AppState } from "../types";

export const prefSelectors = {
  //
  appraisalsCollapsed: (state: AppState) =>
    state.pref.appraisals?.collapsed ?? {},
  detailsCollapsed: (state: AppState) => state.pref.details?.collapsed ?? {},
};
