import { Box } from "@material-ui/core";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import OpportunitiesContent from "./OpportunitiesContent";
import { useDragState } from "./DraggableState";

export default function OpportunitiesBox() {
  const { order } = useDragState();
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: 8,
    margin: `0 0 4px 0`,
    // change background colour if dragging
    background: "#FFF",
    boxShadow: isDragging ? "0 2px 12px 0 #DADFF6, 0 2px 40px 0 #F9FAFC" : "",
    borderRadius: "4px",

    ...draggableStyle,
  });

  return (
    <Box>
      {order.map((item, idx) => {
        return (
          <Draggable key={item.id} draggableId={item.internal_name} index={idx}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style,
                )}
              >
                <OpportunitiesContent item={item} />
              </div>
            )}
          </Draggable>
        );
      })}
    </Box>
  );
}
