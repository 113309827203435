import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles(theme => ({
  tableContainer: {
    width: "100%",
    boxShadow: "0 2px 12px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
    borderRadius: 4,
    overflowY: "hidden",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
    height: "100%",
    gap: "32px",
    paddingBottom: 80,
  },
  pageContainer: {
    margin: 12,
    "& > div > h2:first-child": {
      margin: "40px 12px",
      [theme.breakpoints.down("md")]: {
        margin: 0,
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      "& > div ": {
        alignSelf: "flex-start",
      },
      gap: 12,
    },
  },
  statCardContainerHorizontal: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: 20,
    [theme.breakpoints.down("md")]: {
      justifyContent: "start",
      gap: 12,
    },
  },
  statCardContainerVertical: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      justifyContent: "start",
      gap: 12,
    },
  },
  verticalColumn: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    height: 160,
  },
  verticalStatCard: {
    width: 620,
    borderRadius: 3,
    height: 80,
    padding: 8,
    flex: 1,
    boxShadow: "0 2px 12px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "max-content",
      "& > div > div": {
        flexDirection: "column",
      },
    },
  },
  imgContainer: {
    display: "inline-flex",
    width: 638,
    [theme.breakpoints.down("md")]: {
      width: 389,
    },
  },
  dashImg: {
    width: 638,
    height: 470,
    [theme.breakpoints.down("md")]: {
      width: 389,
      height: 231,
    },
  },
  statCard: {
    width: 224,
    height: 172,
    border: "1px solid #BBC2E1",
    borderRadius: 3,
    padding: 8,
    flex: 1,
    [theme.breakpoints.down("md")]: {
      flex: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: 150,
      height: 150,
    },
  },
  icon: {
    padding: 4,
    marginLeft: 12,
    height: 40,
    width: 40,
    borderRadius: 3,
    backgroundColor: theme.palette.secondary.main,
    color: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardValue: {
    marginLeft: 12,
    fontSize: 24,
    fontWeight: 600,
  },
  verticalCardDescription: {
    marginLeft: 12,
    fontSize: 16,
    fontWeight: 600,
    alignSelf: "center",
  },
  cardDescription: {
    color: theme.palette.text.secondary,
    marginLeft: 12,
    fontSize: 12,
    fontWeight: 500,
  },
  filterContainer: {
    justifyContent: "flex-end",
    alignItems: "center",
    display: "flex",
  },
  filter: {
    "& .MuiSelect-root": {
      color: "#8D98C2",
      fontSize: 14,
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 14px)",
      color: "#8D98C2",
    },
  },
  title: {
    margin: "20px 0",
  },
  link: {
    width: "100%",
    height: "100%",
    color: theme.palette.primary.dark,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    textDecoration: "none",
  },
  noRows: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",

    "& > p ": {
      textAlign: "center",
      fontSize: "18px",
    },
    "& > p:nth-child(3)": {
      textAlign: "center",
      fontSize: "20px",
      color: "#8D98C2",
    },
  },
}));
