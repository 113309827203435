import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    root: {
      padding: 24,
      borderRadius: 3,
      boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
      [theme.breakpoints.down("xs")]: {
        borderRadius: "unset",
        boxShadow: "unset",
        borderTop: "1px solid #DADFF6",
        padding: "24px 4px 0 4px",
        margin: "0 -16px",
      },
    },
    titlebar: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 24,
      },
    },
    title: {
      color: "#141932",
      fontSize: 20,
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "22px",
      "& .MuiSvgIcon-root": {
        color: "#8D98C2",
        fontSize: 14,
        marginLeft: 4,
      },
    },
    toggler: {
      // color: "#BBC2E1",
      color: "rgba(0, 0, 0, 0.54)",
      "& .MuiIconButton-root": {
        marginTop: -12,
        marginBottom: -12,
      },
      [theme.breakpoints.down("xs")]: {
        "& .MuiIconButton-root": {
          marginRight: 12,
        },
      },
    },
    list: {
      // tableLayout: "fixed",
      width: "100%",
    },
    // List headers
    colhead: {
      "& .MuiSvgIcon-root": {
        color: "#8D98C2",
        fontSize: 14,
        marginLeft: 4,
      },
    },
    // List columns
    pref: {
      color: "#BBC2E1",
      width: 50,
      "&:hover": {
        color: "#FDBE30",
      },
      [theme.breakpoints.down("xs")]: {
        width: 0,
      },
    },
    prefSelected: {
      color: "#FDBE30",
    },
    bidder: {
      // width: "100%",
      // maxWidth: "100%",
      textAlign: "center",
      userSelect: "none",
      [theme.breakpoints.down("xs")]: {
        "& > .MuiButton-root": {
          padding: "4px 4px 4px 2px",
          display: "inline-block",
          textAlign: "left",
        },
      },
    },
    amount: {
      // width: 240,
      width: 140,
      [theme.breakpoints.down("xs")]: {
        width: 0,
        "& > .MuiButton-root": {
          padding: 0,
          // display: "inline-block",
          // textAlign: "left",
        },
      },
    },
    eq: {
      width: 25,
      textAlign: "center",
    },
    profit: {
      width: 130,
      paddingLeft: 8,
      [theme.breakpoints.down("xs")]: {
        width: 0,
        "& > .MuiButton-root": {
          padding: 0,
          // display: "inline-block",
          // textAlign: "left",
        },
      },
    },
    date: {
      fontSize: 12,
      width: 130,
    },
    del: {
      color: "#BBC2E1",
      width: 50,
      textAlign: "right",
      "&:hover": {
        color: "#141932",
      },
      [theme.breakpoints.down("xs")]: {
        width: 0,
      },
    },
    add: {
      color: "#00B374",
      padding: "24px 0 0 2px",
      [theme.breakpoints.down("xs")]: {
        padding: "16px 0 0 4px",
      },
    },
  }),
  {
    classNamePrefix: "AppraisalBiddersBox",
  },
);
