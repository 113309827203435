import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
// Icons
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";

import { formatDateTime } from "../../../lib";
import {
  // useDealers,
  userApi,
} from "../../../state";
// import { notifState } from "../../../state/notifs";

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: 16,
    },
    list: {
      "& td > a": {
        display: "block",
        margin: -16,
        padding: 16,
        height: "100%",
      },
    },
    summary: {
      display: "flex",
      flex: 1,
    },
    dealer: {
      flex: 1,
    },
    counts: {
      flex: 2,
    },
  }),
  {
    classNamePrefix: "UserNotificationsList",
  },
);

export const UserNotificationsTable = React.memo(
  /**
   *
   */
  function UserNotificationsList() {
    const classes = useStyles();
    // const dealers = useDealers();
    //TODO: console.log("use dealers for filters", dealers);

    const [notifications, setNotifications] = React.useState([]);

    React.useEffect(() => {
      const state = { mounted: true };
      (async function onMount() {
        const data = await userApi.getCurrentUserNotificationLogs();
        await userApi.updateLogsAsRead();
        // notifState.unreadCount = 0;
        if (!state.mounted) return;
        setNotifications(data);
      })();
      return () => {
        state.mounted = false;
      };
    }, []);

    return notifications.length ? (
      <div className={classes.root}>
        <Table
          className={classes.list}
          onMouseEnter={() => (document.body.style.cursor = "pointer")}
        >
          <TableBody>
            {notifications.map(notif => (
              <TableRow
                key={notif.id}
                selected={notif.web ? !!notif.webViewed : true}
                onClick={() => window.open(notif.link, "_blank", "noreferrer")}
                title={notif.link ?? undefined}
                style={{ cursor: "pointer" }}
              >
                <TableCell>
                  <Tooltip title="Sent as a Email" placement="top">
                    {notif.email ? <MailOutlineIcon /> : <></>}
                  </Tooltip>
                  <Tooltip title="Sent as a text" placement="top">
                    {notif.sms ? <SmsOutlinedIcon /> : <></>}
                  </Tooltip>
                  <Tooltip title="Sent as a web notification" placement="top">
                    {notif.web ? <LanguageOutlinedIcon /> : <></>}
                  </Tooltip>
                </TableCell>
                <TableCell>{notif.message}</TableCell>
                <TableCell>{formatDateTime(notif.createdAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    ) : (
      <>There are no notifications to view</>
    );
  },
);
