import React from "react";
// import {
//   Box,
//   // makeStyles,
// } from "@material-ui/core";
// Local
import { Navigation, useLocation } from "../../../lib";
import {
  appraisalActions,
  isArchivedPath,
  isLeaseReturnsPath,
  isLeaseReturnsMgmtPath,
  isRejectedPath,
  isServicePath,
  isShowroomPath,
  isDuplicatePath,
  isDealerStatsPath,
  useAction,
} from "../../../state";
import { FabLogo } from "../components/FabLogo";
import { AppraisalSearchbar } from "./AppraisalSearchbar";
import { AppraisalsList } from "./AppraisalsList";
import { AppraisalsListHeader } from "./AppraisalsListHeader";

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       minHeight: "100vh",
//     },
//   }),
//   {
//     classNamePrefix: "AppraisalsBoardPage",
//   },
// );

export const AppraisalsPage = React.memo(
  /**
   *
   */
  function AppraisalsPage() {
    // const classes = useStyles();

    // const dealer = useDealer();
    // const user = useSelector(authSelectors.user);
    const loadList = useAction(appraisalActions.loadList);
    const {
      query,
      query: { tab },
      params,
      pathname,
    } = useLocation();

    const isDuplicate = isDuplicatePath(pathname);
    const isDealerStats = isDealerStatsPath(pathname);
    const isArchived = isArchivedPath(pathname);
    const isLeaseReturns =
      isArchived || isDuplicate || isDealerStats
        ? false
        : isLeaseReturnsPath(pathname);
    const isLeaseReturnsMgmt = isLeaseReturnsMgmtPath(pathname);
    const isRejected =
      isArchived || isLeaseReturns ? false : isRejectedPath(pathname);
    const isShowroom = isShowroomPath(pathname);
    const isService = isServicePath(pathname);
    const isTradeins =
      !isArchived &&
      !isDuplicate &&
      !isDealerStats &&
      !isLeaseReturns &&
      !isRejected &&
      !isShowroom &&
      !isService;

    const onTabChanged = React.useCallback(
      (e, value) => {
        const newQuery = {
          ...query,
          tab: value,
        };
        Navigation.replace(pathname, {
          query: newQuery,
          // hash: value === "details" ? location.hash : "",
        });
      },
      [pathname, query],
    );

    const path = pathname.split("/");

    React.useEffect(() => {
      const { filter, sort, order } = query;
      const filterObj = filter
        ? JSON.parse(filter)
        : {
            filterId: isService || isShowroom ? 0 : 30,
          };
      if (isArchived) {
        filterObj.archived = 1;
      } else if (isDuplicate) {
        filterObj.duplicateVin = path[path.length - 1];
      } else if (isDealerStats) {
        const { appType, subType } = params || {};
        if (appType && subType) {
          filterObj.statsAppType = appType;
          filterObj.statsSubType = subType;
        }
      } else if (isService) {
        filterObj.service = true;
      } else if (isShowroom) {
        filterObj.showroom = true;
      } else {
        filterObj.appraisalTypeId = isLeaseReturns ? 2 : 1;
        if (isLeaseReturnsMgmt) {
          filterObj.leases = true;
        }
      }
      if (isRejected) {
        filterObj.appraisalStatusId = 3;
      } else {
        delete filterObj.appraisalStatusId;
      }
      loadList({
        filter: filterObj,
        sort,
        order,
      });
    }, [
      params,
      query,
      isArchived,
      isLeaseReturns,
      isLeaseReturnsMgmt,
      isRejected,
      isService,
      isShowroom,
      isDuplicate,
      isDealerStats,
      path,
      loadList,
    ]);

    return (
      <div>
        <AppraisalsListHeader
          isArchived={isArchived}
          isLeaseReturns={isLeaseReturns}
          isLeaseReturnsMgmt={isLeaseReturnsMgmt}
          isRejected={isRejected}
          isTradeins={isTradeins}
          isService={isService}
          isShowroom={isShowroom}
          isDuplicate={isDuplicate}
          isDealerStats={isDealerStats}
        />
        <AppraisalSearchbar
          isLeaseReturns={isLeaseReturns}
          isLeaseReturnsMgmt={isLeaseReturnsMgmt}
          isService={isService}
          isShowroom={isShowroom}
          hideFilters={isDuplicate || isDealerStats}
        />
        <AppraisalsList
          isArchived={isArchived}
          isLeaseReturns={isLeaseReturns}
          isLeaseReturnsMgmt={isLeaseReturnsMgmt}
          isService={isService}
          isShowroom={isShowroom}
          tab={tab}
          onTabChanged={onTabChanged}
          hideTabs={isDuplicate || isDealerStats}
        />
        <FabLogo isLeaseReturns={isLeaseReturns} />
      </div>
    );
  },
);
