import React from "react";
import {
  //
  Box,
  IconButton,
  Tabs,
  Tab,
  makeStyles,
  Button,
} from "@material-ui/core";
// Icons
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Local
import {
  //
  CopyableText,
  DollarField,
  Field,
  SelectField,
  TabPanel,
} from "../../../components";
import {
  formatAmountUSD,
  formatDecimal,
  Navigation,
  useFormikContext,
  useLocation,
  useOnMountAsync,
  useBoolean,
} from "../../../lib";
import {
  appraisalActions,
  prefActions,
  prefSelectors,
  useAction,
  useSelector,
} from "../../../state";
import { useMobile } from "../../../themes";
import { AppraisalValuationsHeader } from "./AppraisalValuationsHeader";
import { AppraisalBlackBookTab } from "./AppraisalBlackBookTab";
import { AppraisalGalvesTab } from "./AppraisalGalvesTab";
import { AppraisalJdpTab } from "./AppraisalJdpTab";
import { AppraisalMmrTab } from "./AppraisalMmrTab";

export const AppraisalWorksheetSidebar = React.memo(
  /**
   * @typedef {"carOfferProjectedValuation"|"auctionProjectedValuation"|"retailProjectedValuation"|"carOfferProjectedEquity"|"auctionProjectedEquity"|"retailProjectedEquity"} ValuationKeys
   * @typedef {object} AppraisalWorksheetSidebarProps
   * @property {Appraisal} record
   * @property {React.Dispatch<React.SetStateAction<string>} setFeeBox
   *
   * @param {AppraisalWorksheetSidebarProps} param0
   */
  function AppraisalWorksheetSidebar({ closed, record: row, setFeeBox }) {
    const [expandFees, _moreFees, _lessFees, toggleFees] = useBoolean(false);

    const useStyles = makeStyles(
      theme => ({
        root: {
          borderLeft: "1px solid #DADFF6",
          minWidth: "370px",
          [theme.breakpoints.down("xs")]: {
            borderLeft: "unset",
            borderTop: "1px solid #DADFF6",
            minWidth: "unset",
            margin: "24px -12px 0 -12px",
          },
        },
        accordionButton: {
          padding: 0,
        },
        separator: {
          backgroundColor: "#DADFF6",
          border: "none",
          height: 1,
          marginTop: 0,
          // marginLeft: -16,
          // marginRight: -16,
        },
        sidebarDollar: {
          marginRight: "-24px",
          [theme.breakpoints.down("xs")]: {
            marginRight: "unset",
          },
        },
        sidebarSubtotal: {
          color: "#141932",
          fontSize: 16,
          lineHeight: "25px",
        },
        button: {
          color: "#4E50FF",
        },
        expand: {
          display: expandFees ? "block" : "none",
        },
      }),
      {
        classNamePrefix: "AppraisalWorksheetSidebar",
      },
    );
    const classes = useStyles();
    const isMobile = useMobile();

    // #region Location based state
    const location = useLocation();
    const {
      // params: { id },
      query: { val: valTab = "mmr", fees: feesTab = "wholesale" },
    } = location;
    const isRetail = feesTab === "retail";
    const onChangeValTab = React.useCallback(
      (e, value) => {
        const query = {
          ...location.query,
          val: value,
        };
        Navigation.replace(location.pathname, {
          query,
        });
      },
      [location],
    );
    const onChangeFeesTab = React.useCallback(
      (e, value) => {
        const query = {
          ...location.query,
          fees: value,
        };
        Navigation.replace(location.pathname, {
          query,
        });
      },
      [location],
    );
    // #endregion

    const getDealTypes = useAction(appraisalActions.getDealTypes);

    const [dealTypes, setDealTypes] = React.useState(undefined);

    const toggleDetailsCollapsed = useAction(
      prefActions.toggleDetailsCollapsed,
    );
    /** @type {Record<"details"|"payoff"|"fees",boolean>} */
    const collapsed = useSelector(prefSelectors.detailsCollapsed);
    const toggleCollapsed = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      e => {
        const id = e.currentTarget.dataset.id;
        toggleDetailsCollapsed(id);
      },
      [toggleDetailsCollapsed],
    );

    const frm = useFormikContext();
    const { values } = frm;
    const totals = React.useMemo(() => {
      const auctionFee = parseFloat(values.auctionFee),
        pickupFee = parseFloat(values.pickupFee),
        reconditioningCost = parseFloat(values.reconditioningCost),
        wholesalePack = parseFloat(values.wholesalePack);
      return {
        wholesale: auctionFee + pickupFee + wholesalePack + reconditioningCost,
        wholesaleFees: auctionFee + pickupFee + wholesalePack,
        retail: pickupFee + reconditioningCost,
        retailFees: pickupFee,
      };
    }, [
      values.auctionFee,
      values.pickupFee,
      values.wholesalePack,
      values.reconditioningCost,
    ]);
    React.useEffect(()=>{
      const totalCosts =
        parseFloat(totals[`${feesTab}Fees`]) +
        parseFloat(values.reconditioningCost);
      setFeeBox(totalCosts);
    },[feesTab, setFeeBox, totals, values.reconditioningCost])

    useOnMountAsync(async state => {
      const items = await getDealTypes();
      if (!state.mounted) return;
      setDealTypes(items);
    });

    const DetailSection = isMobile ? SidebarSection : "div";
    const detailSectionProps = isMobile
      ? {
          classes,
          collapsed: collapsed.details,
          id: "details",
          title: "Details",
          toggleCollapsed,
        }
      : {};

    return (
      <div className={classes.root}>
        <DetailSection {...detailSectionProps}>
          <SidebarSection
            id="fees"
            classes={classes}
            collapsed={collapsed.fees}
            toggleCollapsed={toggleCollapsed}
            title="Fees and costs"
          >
            <Tabs
              variant="fullWidth"
              value={feesTab}
              onChange={onChangeFeesTab}
            >
              <Tab value="wholesale" label="Wholesale" />
              <Tab value="retail" label="Retail" />
            </Tabs>
            <hr className={classes.separator} />
            <Box p="24px">
              <Box display={"flex"}>
                <Box pb="24px" marginRight={2}>
                  <DollarField
                    name="reconBodyCost"
                    label="Body work"
                    variant="outlined"
                    margin="dense"
                    disabled={closed}
                  />
                </Box>
                <Box pb="32px" marginLeft={2}>
                  <DollarField
                    name="reconMechCost"
                    label="Mechanical work"
                    variant="outlined"
                    margin="dense"
                    disabled={closed}
                  />
                </Box>
              </Box>
              {!isRetail && (
                <Box pb="24px">
                  <DollarField
                    name="wholesalePack"
                    label="Wholesale pack"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    disabled={closed}
                  />
                </Box>
              )}
              {!isRetail && (
                <Box pb="24px" className={classes.expand}>
                  <DollarField
                    name="auctionFee"
                    label="Auction fee"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    disabled={closed}
                  />
                </Box>
              )}
              <Box pb="24px" className={classes.expand}>
                <DollarField
                  name="pickupFee"
                  label="Transport Fee"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  disabled={closed}
                />
              </Box>

              <Box pb="24px" className={classes.expand}>
                <DollarField
                  name="reconOtherCost"
                  label="Other work"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  disabled={closed}
                />
              </Box>
              <Box pb="24px">
                <Button
                  variant="text"
                  className={classes.button}
                  onClick={toggleFees}
                  disableFocusRipple
                  disableRipple
                >
                  {expandFees ? "Less" : "More"} fees
                </Button>
              </Box>
              <SidebarDollarAmount
                amount={totals[`${feesTab}Fees`]}
                classes={classes}
                title="Fees"
              />
              <SidebarDollarAmount
                amount={values.reconditioningCost}
                classes={classes}
                title="Reconditioning"
              />
              <Box
                alignItems="center"
                color="#141932"
                display="flex"
                fontSize="20px"
                fontWeight="600"
                pt="2px"
                pb="8px"
                justifyContent="space-between"
              >
                <div>Total</div>
                <CopyableText
                  className={classes.sidebarDollar}
                  text={formatDecimal(totals[feesTab])}
                >
                  {formatAmountUSD(totals[feesTab])}
                </CopyableText>
              </Box>
            </Box>
          </SidebarSection>
          <hr className={classes.separator} />
          <AppraisalValuationsHeader record={row} />
          <Tabs variant="fullWidth" value={valTab} onChange={onChangeValTab}>
            <Tab value="mmr" label="MMR" title="Manheim Market Report" />
            <Tab value="jdp" label="JDP" title="JD Power" />
            <Tab value="galves" label="Galves" title="Galves Market Data" />
            <Tab value="blackbook" label="Black" title="Black Book" />
          </Tabs>
          <hr className={classes.separator} />
          <TabPanel selected={valTab === "mmr"}>
            <AppraisalMmrTab record={row} closed={closed} />
          </TabPanel>
          <TabPanel selected={valTab === "jdp"}>
            <AppraisalJdpTab record={row} closed={closed} />
          </TabPanel>
          <TabPanel selected={valTab === "galves"}>
            <AppraisalGalvesTab record={row} closed={closed} />
          </TabPanel>
          <TabPanel selected={valTab === "blackbook"}>
            <AppraisalBlackBookTab record={row} closed={closed} />
          </TabPanel>
        </DetailSection>

        <hr className={classes.separator} />
        <SidebarSection
          id="dealDetails"
          classes={classes}
          collapsed={collapsed.dealDetails}
          toggleCollapsed={toggleCollapsed}
          title="Deal details"
        >
          <Box p="24px">
            <Box pb="32px">
              <Field
                name="dmsId"
                label="Deal number"
                fullWidth
                variant="outlined"
                margin="dense"
                // disabled={closed}
              />
            </Box>
            <Box pb="32px">
              <DollarField
                name="actualCost"
                label="Actual cost"
                fullWidth
                variant="outlined"
                margin="dense"
                // disabled={closed}
              />
            </Box>
            <Box pb="32px">
              <SelectField
                name="dealTypeId"
                label="Purchase type"
                emptyEnabled
                fullWidth
                variant="outlined"
                margin="dense"
                itemText="name"
                items={dealTypes}
                // disabled={closed}
              />
            </Box>
          </Box>
        </SidebarSection>
      </div>
    );
  },
);

const SidebarDollarAmount = React.memo(
  /**
   *
   */
  function SidebarDollarAmount({ amount, classes, title }) {
    return (
      <Box
        alignItems="center"
        color="#8D98C2"
        display="flex"
        fontSize="14px"
        fontWeight="500"
        justifyContent="space-between"
      >
        <div>{title}</div>
        <CopyableText
          className={`${classes.sidebarDollar} ${classes.sidebarSubtotal}`}
          text={formatDecimal(amount)}
        >
          {formatAmountUSD(amount)}
        </CopyableText>
      </Box>
    );
  },
);

const SidebarSection = React.memo(
  /**
   *
   */
  function SidebarSection({
    children,
    classes,
    collapsed,
    id,
    title,
    toggleCollapsed,
  }) {
    return (
      <>
        <Box
          alignItems="center"
          color="#1F2834"
          display="flex"
          fontSize="20px"
          fontWeight="bold"
          justifyContent="space-between"
          lineHeight="24px"
          p="24px 24px 12px 24px"
        >
          <div>{title}</div>
          <IconButton
            className={classes.accordionButton}
            data-id={id}
            onClick={toggleCollapsed}
          >
            {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>
        {collapsed ? null : children}
      </>
    );
  },
);
