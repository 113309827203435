import { createTheme, useMediaQuery } from "@material-ui/core";

/** Material-ui default theme, to copy from where necessary. */
const defaults = createTheme();

// #region Example to copy shadows from default theme.
/**
 * Override box shadows[3]. Must provide all 25 array elements...
 * - https://github.com/mui-org/material-ui/issues/8780
 * - https://gist.github.com/phacks/6c3c3a5f395f6e9660ae132c237250a3
 */
// const boxShadow = "0 0 4px -4px rgba(0,0,0,0.12), 0 0 8px 0 rgba(0,0,0,0.16)";
// const shadows = [...defaults.shadows];
// shadows[3] = boxShadow;
// #endregion

/**
 * Default theme for the app based on the
 * [Materiaul UI default theme](https://material-ui.com/customization/default-theme/)
 * with example overrides.
 */
export const defaultTheme = createTheme({
  appLayout: {
    topGutterHeight: 24,
  },
  palette: {
    type: "light",
    primary: { main: "#526B8A" },
    secondary: { main: "#4E50FF" },
    text: {
      primary: "#141932",
      secondary: "#8D98C2",
    },
    background: { default: "#ffffff" },
  },
  typography: {
    fontFamily: ["Poppins", `sans-serif`],
    h1: {
      fontSize: 32,
      fontWeight: 600,
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: "24px",
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
    },
    body2: {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  spacing: 8,
  overrides: {
    MuiAccordion: {
      root: {
        marginBottom: 1, // Always show the bottom border.
        "&.Mui-expanded:before": {
          opacity: "1 !important", // Always show the top border.
        },
        "&$expanded": {
          margin: 0, // Stop adding margin when expanded.
          marginBottom: 1, // Always show the bottom border.
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        backgroundColor: "#F0F0F0", // Make headers stand out more.
        "&$expanded": {
          minHeight: 48, // Stop adding height when expanded.
        },
      },
      content: {
        "&$expanded": {
          margin: 0, // Stop adding margin when expanded.
        },
      },
    },
    MuiBadge: {
      badge: {
        userSelect: "none",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        fontSize: 16,
      },
      contained: {
        backgroundColor: "#526B8A",
        borderRadius: 3,
        boxShadow: "none",
        color: "white",
        padding: "10px 26px",
        transition: "all 300ms linear",
        "&:hover": {
          backgroundColor: "#526B8A",
          boxShadow: "none",
          color: "white",
        },
      },
    },
    MuiDialogActions: {
      root: {
        paddingTop: 32,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#efefef",
      },
      inputHiddenLabel: {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    MuiFormControlLabel: {
      root: {
        // Don't let user select form label text. This helps particularly with
        // checkboxes, where the user clicks the label to toggle the checkbox.
        userSelect: "none",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#526B8A",
        lineHeight: "8px",
      },
    },
    MuiInputBase: {
      root: {
        color: "#526B8A",
      },
    },
    MuiInputLabel: {
      root: {
        userSelect: "none", // Same as MuiFormControlLabel...
        color: "#8D98C2",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 3,
      },
      notchedOutline: {
        borderWidth: 1,
        borderColor: "#BBC2E1",
      },
    },
    // MuiPaper: {
    //   elevation1: {
    //     boxShadow: "0px 2px 24px 0 #E5E7F5, 0px 2px 40px 0px #F5F6FE",
    //   },
    // },
    MuiTab: {
      root: {
        maxWidth: undefined,
        minWidth: 0,
        [defaults.breakpoints.up("sm")]: {
          minWidth: 0,
        },
        padding: "6px 0",
        margin: "0 12px",
      },
      wrapper: {
        flexDirection: "row",
      },
    },
    MuiTabs: {
      root: {
        "& .MuiTabScrollButton-root": {
          backgroundColor: "rgba(0, 0, 0, 0.12)",
          width: 16,
        },
        "& .Mui-selected": {
          color: "#1F2834",
        },
      },
      indicator: {
        background: "#1F2834",
      },
    },
    MuiLink: {
      root: {
        transition: "all 300ms linear",
      },
    },
    MuiAvatar: {
      root: {
        height: 32,
        width: 32,
        fontSize: 12,
        fontWeight: 500,
        "&:hover:not(.nohover)": {
          opacity: 0.5,
        },
      },
      colorDefault: {
        color: "#BBC2E1",
        backgroundColor: "#6876FF",
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: 4,
        marginRight: 4,
      },
      contained: {
        marginLeft: 4,
        marginRight: 4,
      },
      filled: {
        marginLeft: 4,
        marginRight: 4,
      },
    },
  },
  props: {
    MuiFormHelperText: {
      // Use a <div> instead of a <p> as the base for this component so we can
      // avoid React "validateDOMnesting" errors if we try to put a <div> inside
      // of a <p>...
      component: "div",
    },
    MuiTextField: {
      variant: "outlined",
    },
    MuiFilledInput: {
      disableUnderline: true,
    },
  },

  // #region Example global overrides for this theme:
  // overrides: {

  //   // Example to override "contained" variant of the Material-ui Button:
  //   // MuiButton: {
  //   //   contained: {
  //   //     boxShadow: "none",
  //   //     "&:hover": {
  //   //       boxShadow: "none",
  //   //     },
  //   //   },
  //   // },

  //   // Example to override the "contained" variant of Material-ui ButtonGroup:
  //   // MuiButtonGroup: {
  //   //   contained: {
  //   //     boxShadow: "none",
  //   //     border: "1px solid rgba(0,0,0,0.16)",
  //   //   },
  //   // },

  //   // Example to override the paper style of the Material-ui Menu:
  //   // MuiMenu: {
  //   //   paper: {
  //   //     boxShadow,
  //   //   },
  //   // },

  // },
  // #endregion
  // #region Example default props for any Material-ui components rendered with
  // this theme:
  // props: {
  //   MuiCard: {
  //     variant: "outlined",
  //   },
  // },
  // #endregion
  // Example to set custom shadows:
  // shadows,
});

/**
 * Returns `true` if the screen is sized for mobile. Depends on theme
 * breakpoints, otherwise this function would be defined in `lib/hooks.js`
 */
export function useMobile(size = "xs") {
  return useMediaQuery(defaultTheme.breakpoints.down(size));
}
