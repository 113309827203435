import { HomePage } from "./HomePage";
import { NewOpportunityPage } from "./NewOpportunityPage";
import { NotFoundPage } from "./NotFoundPage";

export const MainPages = {
  default: {
    path: "/",
    pathExact: true,
    view: HomePage,
  },
  new: {
    path: "/:dslug/new",
    pathExact: true,
    view: NewOpportunityPage,
  },
  notFound: {
    path: "*",
    title: "Not Found",
    view: NotFoundPage,
  },
};
