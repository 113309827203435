// import { createSelector } from "@reduxjs/toolkit";
// Local
import { AppState } from "../types";
// import { ShowingAppraisalState } from "./state";

export const appraisalSelectors = {
  dashboardLeaseReturns(state: AppState) {
    return state.appraisals.dashboardLeaseReturns;
  },
  dashboardTradeins(state: AppState) {
    return state.appraisals.dashboardTradeins;
  },
  //
  listItems(state: AppState) {
    return state.appraisals.list.items;
  },
  filter: (state: AppState) => state.appraisals.filter,
  showing: (state: AppState) => state.appraisals.showing,
  // stats: (state: AppState) => state.appraisals.stats,
};
