import React from "react";
// import { makeStyles } from "@material-ui/core";
// Local
import { carfax, useOnMountAsync } from "../../lib";

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       // margin: 0,
//     },
//   }),
//   {
//     classNamePrefix: "CarfaxSnap4P",
//   },
// );

export const CarfaxSnap4P = React.memo(
  /**
   * @param {{noLogo:boolean,vin:string} & React.HTMLProps<HTMLDivElement>} param0
   */
  function CarfaxSnap4P({ noLogo, vin, ...props }) {
    // const classes = useStyles();

    const [carfaxLoginRequired, setCarfaxLoginRequired] = React.useState(false);
    /** @type {[string, (state:string)=>void]} */
    const [cfxSnapshotKey, setCfxSnapshotKey] = React.useState(undefined);
    /** @type {[import("../../lib").CarfaxPillar[]]} */
    const [cfx4P, setCfx4P] = React.useState([]);

    const getCarfaxData = React.useCallback(
      async state => {
        const data = await carfax.getSnap4P({
          vin,
        });
        if (state && !state.mounted) return;
        if (data === false) {
          // carfax.getSnap4P returns false specifically if login is required.
          setCarfaxLoginRequired(true);
          return;
        }
        if (data) {
          setCfxSnapshotKey(data.snapshotKey);
          setCfx4P(data.fourPillars);
        }
        setTimeout(carfax.refreshDocumentSnapshot, 300);
      },
      [vin],
    );

    const maybeCheckCarfaxLogin = React.useCallback(() => {
      if (!carfaxLoginRequired) {
        // We're already logged in. Nothing to do.
        return;
      }
      if (carfax.auth.isConnected()) {
        // We just logged in. Update our status.
        setCarfaxLoginRequired(false);
        getCarfaxData();
      } else {
        setTimeout(maybeCheckCarfaxLogin, 3000);
      }
    }, [carfaxLoginRequired, getCarfaxData]);

    useOnMountAsync(state => {
      if (!carfax.auth.isConnected()) {
        setCarfaxLoginRequired(true);
        return;
      }
      getCarfaxData(state);
    });

    const Comp = noLogo ? "div" : "a";

    return (
      <Comp
        {...props}
        className="carfax-dealer-snapshot-hover"
        data-snapshotkey={cfxSnapshotKey}
        href={`/carfax/report/${vin}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={!carfaxLoginRequired ? undefined : maybeCheckCarfaxLogin}
      >
        {!noLogo && <img src="/images/carfax-logo.svg" alt="CARFAX" />}
        {carfaxLoginRequired && (
          <small>
            <em>{noLogo && "Carfax "}Login required</em>
          </small>
        )}
        {cfx4P.map(it => (
          <img
            key={it.name}
            src={it.iconUrl}
            alt={`Carfax ${it.name} icon.`}
            title={it.iconText}
            width="24"
          />
        ))}
      </Comp>
    );
  },
);
