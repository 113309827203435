import React from "react";
import { Button, Box, Typography } from "@material-ui/core";
import {
  useAction,
  useSelector,
  dealerApi,
  dealerSelectors,
} from "../../state";
import { FileInput } from "../inputs/FileInput";
import { SimpleDialog } from "../feedback/SimpleDialog";
import { CampaignEmailTable } from "../data/CampaignEmailTable";
export const FileUploadModal = React.memo(
  /**
   * A `react-dropzone` file input modal.
   * @property {number} [id] id needed for uploading file
   * @property {React.Dispatch<React.SetStateAction<string>>} [setSentEmail] pass back to parent component and display toast
   */
  function FileUploadModal(props) {
    const { id, setSentEmail } = props;
    const doc = useSelector(dealerSelectors.documents);
    const getDocument = useAction(dealerApi.getDocument);
    const uploadDocument = useAction(dealerApi.uploadDocument);
    const sendEmails = useAction(dealerApi.sendEmails);
    const clearDocument = useAction(dealerApi.clearDocument);

    const onFileChange = React.useCallback(
      /** @param {File[]} files */
      async (files, rej, e) => {
        if (!files || files.length < 0) {
          return;
        }
        try {
          const uploadDoc = await uploadDocument(id, files[0]);
          getDocument(uploadDoc.uid);
        } catch (e) {
          clearDocument();
          console.error("An error occurred while trying to get document ", e);
        }
      },
      [id, getDocument, uploadDocument, clearDocument],
    );
    const [open, setOpen] = React.useState(false);
    const closeDoc = React.useCallback(() => {
      clearDocument();
      setOpen(false);
    }, [clearDocument]);

    const onClick = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (e && e.preventDefault) e.preventDefault();
        setOpen(true);
      },
      [],
    );
    const onSendEmail = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        if (e && e.preventDefault) e.preventDefault();
        if (doc) {
          const sent = await sendEmails(doc.uid);
          setSentEmail(sent.response);
          setTimeout(() => {
            setSentEmail("");
          }, 5000);
        }
        setOpen(false);
      },
      [sendEmails, doc, setSentEmail],
    );
    return (
      <>
        <Button variant="outlined" color="secondary" onClick={onClick}>
          Upload
        </Button>

        <SimpleDialog
          open={open}
          onClose={closeDoc}
          title={"Upload your mailing list"}
        >
          <Box p={2}>
            {doc ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                height={"100px"}
              >
                <Typography>{doc.name}</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onSendEmail}
                >
                  Send emails
                </Button>
              </Box>
            ) : (
              <Box display={"flex"} flexDirection={"column"}>
                <CampaignEmailTable />
                <FileInput
                  label="Drag &amp; Drop a .csv or .xlsx file here"
                  onChange={onFileChange}
                  accept={".csv, application/vnd.ms-excel, text/csv, .xlsx"}
                  disabled={doc?.uid || false}
                />
              </Box>
            )}
          </Box>
        </SimpleDialog>
      </>
    );
  },
);
