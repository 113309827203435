import React from "react";
import {
  //
  Fab,
  makeStyles,
} from "@material-ui/core";
// Local
import { APPRAISAL_APP_URL } from "../../../config";
import { authSelectors, useDealer, useSelector } from "../../../state";

const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: "#4E50FF",
      borderRadius: "unset",
      bottom: 16,
      boxShadow:
        "0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12);",
      float: "right",
      height: 40,
      width: 40,
      right: 40,
      position: "fixed",
      zIndex: 10,

      [theme.breakpoints.down("xs")]: {
        bottom: 64,
      },
    },
  }),
  {
    classNamePrefix: "FabLogo",
  },
);

export const FabLogo = React.memo(
  /**
   * @param {import("@material-ui/core").FabProps & {isLeaseReturns:boolean}} param0
   */
  function FabLogo({ isLeaseReturns, ...fabProps }) {
    const classes = useStyles();

    const dealer = useDealer();
    const user = useSelector(authSelectors.user);

    const title = isLeaseReturns ? "New Lease return" : "New Appraisal";
    const href = `${APPRAISAL_APP_URL}/${
      isLeaseReturns ? "return" : "sell"
    }?dealer=${dealer.id}${user.uid ? "&duid=" + user.uid : ""}`;

    return (
      <Fab
        aria-label={title}
        color="secondary"
        className={classes.root}
        component="a"
        target="blank"
        rel="noopener noreferrer"
        title={title}
        href={href}
        {...fabProps}
      >
        <img src="/images/turnyn-icon-white.svg" alt="Turnyn logo" />
      </Fab>
    );
  },
);
