import React from "react";
import clsx from "clsx";
import { Avatar, Badge, makeStyles } from "@material-ui/core";
// Icons
import ShowroomIcon from "@material-ui/icons/Store";
import OutboundIcon from "@material-ui/icons/MailOutline";
import WebIcon from "@material-ui/icons/Language";
import DealerIcon from "@material-ui/icons/DriveEtaRounded";

export const useStyles = makeStyles(
  theme => ({
    avatar: {
      backgroundColor: "#FF3B6E",
      color: "#FFFFFF",
    },
    avatarSmall: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    badge: {
      "& .MuiBadge-badge": {
        fontSize: 16,
        backgroundColor: "#9BACFF",
        bottom: "4%",
        color: "#FFFFFF",
        padding: 0,
        border: "2px solid white",
      },
    },
  }),
  {
    classNamePrefix: "CampaignAvatar",
  },
);

/** @type {Record<string,string>} */
const abbrevs = {};

/** @type {import("@material-ui/core").BadgeProps["anchorOrigin"]} */
const badgeAlignment = {
  horizontal: "right",
  vertical: "bottom",
};

/** @type {Record<number,{title:string,icon:React.ReactNode}>} */
const typeById = {
  1: {
    title: "Showroom",
    icon: <ShowroomIcon fontSize="inherit" />,
  },
  2: {
    title: "Outbound marketing",
    icon: <OutboundIcon fontSize="inherit" />,
  },
  3: {
    title: "Web",
    icon: <WebIcon fontSize="inherit" />,
  },
  4: {
    title: "Dealer",
    icon: <DealerIcon fontSize="inherit" />,
  },
};

/** @param {string} name */
function campaignAbbrev(name) {
  let abbrev = abbrevs[name];
  if (abbrev) {
    return abbrev;
  }
  const parts = name.split(" ");
  const { length } = parts;
  if (length > 1 && length < 4) {
    abbrev = parts.map(p => p.substring(0, 1).toUpperCase()).join("");
  } else {
    abbrev = name.substring(0, 3);
  }
  abbrevs[name] = abbrev;
  return abbrev;
}

export const CampaignAvatar = React.memo(
  /**
   * @param {{
   * campaign?:Pick<DealerCampaign,"id"|"uid"|"name"|"typeId">;
   * record?:Appraisal
   * small?:boolean
   * }} param0
   */
  function CampaignAvatar({ campaign, record, small }) {
    const camp = campaign ?? record?.campaign;
    const classes = useStyles();
    if (!camp) {
      return <>&nbsp;</>;
    }
    const { name, typeId } = camp;
    let avatar = (
      <Avatar
        className={clsx(classes.avatar, small && classes.avatarSmall)}
        title={`Campaign: ${name}`}
      >
        {campaignAbbrev(name)}
      </Avatar>
    );
    const campType = typeById[typeId];
    if (campType) {
      avatar = (
        <Badge
          anchorOrigin={badgeAlignment}
          badgeContent={campType.icon}
          className={classes.badge}
          color="primary"
          overlap="circular"
          title={campType.title}
        >
          {avatar}
        </Badge>
      );
    }
    return avatar;
  },
);
