import React from "react";
import {
  Switch,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
// Icons
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
// Local
import { useDealer, dealerApi } from "../../../state";
import { TemplateEditor } from "./TemplateEditor";

const useStyles = makeStyles(
  theme => ({
    actions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    table: {
      "& td > a": {
        display: "block",
        margin: -16,
        padding: 16,
        height: "100%",
      },
    },
  }),
  {
    classNamePrefix: "EmailTemplatesList",
  },
);

export const EmailTemplatesList = React.memo(function EmailTemplatesList() {
  const classes = useStyles();
  const dealer = useDealer();

  /**
   * @typedef {{
   *   dealerId: number;
   *   enabled: number;
   *   externalId: string;
   *   id: number;
   *   info: string;
   *   name: string;
   *   options: string;
   * }} DealerEmailTemplate
   */

  /** @type {DealerEmailTemplate[]} */
  const [list, setList] = React.useState([]);
  /** @type {[DealerEmailTemplate | null]} */
  const [editing, setEditing] = React.useState(null);

  const onClickEdit = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLAnchorElement>} e */
    e => {
      if (e) {
        if (e.preventDefault) e.preventDefault();
        const id = parseInt(e.currentTarget.dataset.id);
        const editing = list.find(it => it.id === id);
        setEditing(editing);
      }
    },
    [list],
  );
  const onCloseEditor = React.useCallback(() => {
    setEditing(null);
  }, []);

  React.useEffect(() => {
    if (editing !== null) {
      // Only run at start or when `editing` changes back to null...
      return;
    }
    const state = { mounted: true };
    (async function onDealerChanged() {
      const list = await dealerApi.getTemplates();
      if (!state.mounted) return;
      setList(list);
    })();
    return () => {
      state.mounted = false;
    };
  }, [dealer, editing]);

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Custom</TableCell>
            <TableCell>Enabled</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map(it => {
            const linkProps = {
              "data-id": it.id,
              href: `/${dealer.slug}/settings/users/${it.id}`,
              underline: "none",
              onClick: onClickEdit,
            };
            return (
              <TableRow key={it.id}>
                <TableCell>
                  <Link {...linkProps}>{it.name}</Link>
                </TableCell>
                <TableCell>
                  <Link {...linkProps}>
                    {it.options ? (
                      <CheckCircleOutlineIcon color="primary" />
                    ) : (
                      ""
                    )}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link>
                    {it.options ? (
                      <Switch
                        checked={!!it.enabled}
                        onChange={async e =>
                          await dealerApi
                            .saveTemplate(
                              it?.id ?? 0,
                              JSON.parse(it.options),
                              true,
                              e.target.checked,
                            )
                            .then(() => {
                              setList(list =>
                                list.map(v =>
                                  v.id === it.id
                                    ? { ...v, enabled: v.enabled ? 0 : 1 }
                                    : v,
                                ),
                              );
                            })
                        }
                      />
                    ) : (
                      ""
                    )}
                    {/* {it.enabled && <Switch defaultChecked />} */}
                  </Link>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {editing && <TemplateEditor item={editing} onClose={onCloseEditor} />}
    </div>
  );
});
