import React from "react";
import clsx from "clsx";
import {
  //
  // Button,
  IconButton,
} from "@material-ui/core";
// Icons
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
// Local
import {
  //
  CopyableText,
  DollarField,
  IconToggleButton,
} from "../../../components";
import { formatAmountUSD, formatDateTime } from "../../../lib";
import { useMobile } from "../../../themes";
import { AppraisalBidEditButton } from "../components/AppraisalBidEditor";
import { useStyles } from "./AppraisalBiddersBox.styles";

let nextBidId = 0;
function getNextBidId() {
  return (nextBidId -= 1);
}

const formatAmountOpt = {
  decimalCount: 0,
  // decimalIfNotWhole: true,
};

function formatDollar(value) {
  return formatAmountUSD(value, formatAmountOpt);
}

export const AppraisalBiddersBox = React.memo(
  /**
   * @typedef {object} AppraisalBiddersBoxProps
   * @property {string} auctionProposal
   * @property {string} auctionFee
   * @property {AppraisalBid[]} bids
   * @property {boolean} closed
   * @property {string} pickupFee
   * @property {string} reconditioningCost
   * @property {string} wholesalePack
   * @property {Appraisal} row
   * @property {import("formik").FormikHelpers["setFieldValue"]} setFieldValue
   *
   * @param {AppraisalBiddersBoxProps} props
   */
  function AppraisalBiddersBox(props) {
    const classes = useStyles();
    const isMobile = useMobile();

    const {
      //
      bids,
      closed,
      row,
      setFieldValue,
    } = props;

    const profitCalculator = React.useCallback(() => {
      //
      // WARNING: WHEN CHANGING THIS LOGIC, ALSO UPDATE WORKSHEET TAB SUBMIT!!!
      //
      const auctionProposal = parseFloat(props.auctionProposal || "0"),
        auctionFee = parseFloat(props.auctionFee || "0"),
        pickupFee = parseFloat(props.pickupFee || "0"),
        wholesalePack = parseFloat(props.wholesalePack || "0"),
        reconditioningCost = parseFloat(props.reconditioningCost || "0");
      const fees = auctionFee + pickupFee + wholesalePack + reconditioningCost;
      return amount => {
        // console.log("CALC PROFIT", amount, auctionProposal, fees);
        return amount - auctionProposal - fees;
      };
    }, [
      props.auctionProposal,
      props.auctionFee,
      props.pickupFee,
      props.wholesalePack,
      props.reconditioningCost,
    ]);
    const calcProfit = profitCalculator();

    // TODO: Use appraisalActions.toggleDetailsCollapsed and selector here...
    const [collapsed, setCollapsed] = React.useState(false);
    const toggleCollapsed = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        setCollapsed(value => !value);
      },
      [],
    );

    const onChangePreferred = React.useCallback(
      /**
       * @param {React.SyntheticEvent<HTMLButtonElement>} e
       * @param {boolean} value
       */
      (e, value) => {
        const id = parseInt(e.currentTarget.dataset.id);
        setFieldValue(
          "bids",
          bids.map(it =>
            // Set isPreferred on the item with the matching id.
            // Unset it on any other items where isPreferred is true.
            it.id === id
              ? Object.assign({}, it, { isPreferred: value })
              : it.isPreferred
              ? Object.assign({}, it, { isPreferred: false })
              : it,
          ),
        );
      },
      [bids, setFieldValue],
    );

    const onChangeBid = React.useCallback(
      /**
       * @param {React.SyntheticEvent<HTMLButtonElement>} e
       * @param {{amount:number,bidderName:string}} value
       */
      ({ id, ...values }) => {
        // console.log("SAVING", values);
        setFieldValue(
          "bids",
          id
            ? // Update an item.
              bids.map(it => (it.id === id ? { id, ...values } : it))
            : // Add an item.
              [
                ...bids,
                {
                  id: getNextBidId(),
                  ...values,
                },
              ],
        );
      },
      [bids, setFieldValue],
    );

    const onClickDelete = React.useCallback(
      /**
       * @param {React.SyntheticEvent<HTMLButtonElement>} e
       */
      e => {
        const id = parseInt(e.currentTarget.dataset.id);
        const bid = bids.find(it => it.id === id);
        if (
          !window.confirm(
            `Delete ${formatDollar(bid.amount)} bid from ${bid.bidderName}?`,
          )
        ) {
          return;
        }
        setFieldValue(
          "bids",
          bids.filter(it => it.id !== id),
        );
      },
      [bids, setFieldValue],
    );

    return (
      <div className={classes.root}>
        <div className={classes.titlebar}>
          <div className={classes.title}>
            Bidders
            {bids.length > 0 ? <small> ({bids.length}) </small> : ""}
            {/* <InfoIcon /> */}
          </div>
          <div className={classes.toggler}>
            <IconButton
              color="inherit"
              title="Collapse"
              onClick={toggleCollapsed}
            >
              {collapsed ? (
                <ExpandMoreIcon fontSize="inherit" />
              ) : (
                <ExpandLessIcon fontSize="inherit" />
              )}
            </IconButton>
          </div>
        </div>
        {!collapsed && (
          <>
            {bids.length > 0 && (
              <table className={classes.list} cellPadding="0">
                <thead>
                  <tr>
                    <td>{/* Star */}</td>
                    <td>{/* Bidder */}</td>
                    <td>Bid</td>
                    {!isMobile && <td>{/* Eq. sign */}</td>}
                    <td
                      className={classes.colhead}
                      title={
                        "Put your mouse over each profit amount below \n" +
                        "to see the calculations."
                      }
                    >
                      Profit {!isMobile && <InfoIcon fontSize="small" />}
                    </td>
                    {!isMobile && <td>Date</td>}
                    <td>{/* Delete button */}</td>
                  </tr>
                </thead>
                <tbody>
                  {bids
                    .sort((a, b) => b.profit - a.profit)
                    .map((it, idx) => {
                      const amountFmt = formatDollar(it.amount);
                      const profitFmt = formatDollar(calcProfit(it.amount));
                      const profitFormula =
                        `Profit = Bid - Offer - Fees ` +
                        `(Auction + Transport + Pack + Reconditioning)`;
                      const profitCalculation =
                        `${amountFmt} - ` +
                        `$${props.auctionProposal} - (` +
                        `$${props.auctionFee} + $${props.pickupFee} + ` +
                        `$${props.wholesalePack} + $${props.reconditioningCost})`;
                      return (
                        <tr key={it.id}>
                          <td
                            className={clsx(
                              classes.pref,
                              it.isPreferred && classes.prefSelected,
                            )}
                          >
                            <IconToggleButton
                              title="Set preferred bid"
                              color="inherit"
                              // hoverIcon={<StarIcon />}
                              onChange={onChangePreferred}
                              toggleIcon={<StarIcon />}
                              value={it.isPreferred}
                              data-id={it.id}
                              disabled={closed}
                              size="small"
                            >
                              <StarOutlineIcon />
                            </IconToggleButton>
                          </td>
                          <td className={classes.bidder}>
                            <AppraisalBidEditButton
                              appraisalId={row.id}
                              color="inherit"
                              item={it}
                              onChange={onChangeBid}
                              disabled={closed}
                              fullWidth
                            >
                              {it.bidderName}
                            </AppraisalBidEditButton>
                          </td>
                          <td className={classes.amount}>
                            {isMobile ? (
                              <AppraisalBidEditButton
                                appraisalId={row.id}
                                color="inherit"
                                item={it}
                                onChange={onChangeBid}
                                disabled={closed}
                                fullWidth
                              >
                                {amountFmt}
                              </AppraisalBidEditButton>
                            ) : (
                              <DollarField
                                fullWidth
                                margin="dense"
                                name={`bids.${idx}.amount`}
                                disabled={closed}
                              />
                            )}
                          </td>
                          {!isMobile && <td className={classes.eq}> = </td>}
                          <td className={classes.profit}>
                            {isMobile ? (
                              <AppraisalBidEditButton
                                appraisalId={row.id}
                                color="inherit"
                                item={it}
                                onChange={onChangeBid}
                                disabled={closed}
                                fullWidth
                              >
                                {profitFmt}
                              </AppraisalBidEditButton>
                            ) : (
                              <CopyableText
                                text={`${it.bidderName} bid: ${amountFmt} profit: ${profitFmt}`}
                                title={profitFormula + "\n" + profitCalculation}
                              >
                                {profitFmt}
                              </CopyableText>
                            )}
                          </td>
                          {!isMobile && (
                            <td className={classes.date}>
                              {formatDateTime(it.updatedAt)}
                            </td>
                          )}
                          <td className={classes.del}>
                            <IconButton
                              title="Remove bid"
                              // hoverIcon={<DeleteIcon />}
                              color="inherit"
                              onClick={onClickDelete}
                              data-id={it.id}
                              disabled={closed}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
            <div className={classes.add}>
              <AppraisalBidEditButton
                appraisalId={row.id}
                color="inherit"
                startIcon={<AddCircleOutlineIcon />}
                onChange={onChangeBid}
                disabled={closed}
              >
                Add new bid
              </AppraisalBidEditButton>
            </div>
          </>
        )}
      </div>
    );
  },
);
