import React from "react";
import {
  //
  Box,
  makeStyles,
} from "@material-ui/core";
// Local
import { formatAmountUSD } from "../../../lib";

const useStyles = makeStyles(
  theme => ({
    root: {
      color: "#1F2834",
    },
  }),
  {
    classNamePrefix: "AppraisalValuationsHeader",
  },
);

export const AppraisalValuationsHeader = React.memo(
  /**
   * @param {Appraisal} record
   */
  function AppraisalValuationsHeader({ record: row }) {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Box display="flex" justifyContent="space-between" p="24px 24px 0 24px">
          <img src="/images/mmr-logo.jpg" alt="MMR" height="24px" />
          <Box fontSize="16px" fontWeight="500">
            {formatAmountUSD(row.mmrVal)}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" p="24px 24px 0 24px">
          <img src="/images/jdp-logo.png" alt="JDP" height="24px" />
          <Box fontSize="16px" fontWeight="500">
            {formatAmountUSD(row.jdpVal)}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" p="24px 24px 0 24px">
          <img src="/images/galves-logo.svg" alt="Galves" height="24px" />
          <Box fontSize="16px" fontWeight="500">
            {formatAmountUSD(row.galvesVal)}
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p="24px 24px 12px 48px"
        >
          <img
            src="/images/blackbook-logo.svg"
            alt="Black Book"
            height="24px"
          />
          <Box fontSize="16px" fontWeight="500">
            {formatAmountUSD(row.blackbookVal)}
          </Box>
        </Box>
      </div>
    );
  },
);
