import React from "react";
// import { makeStyles } from "@material-ui/core";
// Local
import { Navigation, useOnMount } from "../../lib";
import { authSelectors, useDefaultDealer, useSelector } from "../../state";
import { Pages } from "../";

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       // margin: 0,
//     },
//   }),
//   {
//     classNamePrefix: "HomePage",
//   },
// );

export const HomePage = React.memo(
  /**
   *
   */
  function HomePage() {
    // const classes = useStyles();

    const isAppraiser = useSelector(authSelectors.isAppraiser);
    const isReception = useSelector(authSelectors.isReception);
    // const isInSales = useSelector(authSelectors.isInSales);

    const dealer = useDefaultDealer();

    useOnMount(() => {
      if (isReception) {
        Navigation.replace(`/${dealer.slug}/new`);
      } else if (isAppraiser) {
        Navigation.replace(Pages.appraisals.list.url(dealer));
      } else {
        // For now, just send everyone to the new opportunity page...
        Navigation.replace(`${dealer.slug}/new`);
      }
    });

    return null;
  },
);
