import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { appraisalActions, useAction } from "../../../state";

const styles = {
  /** @type {React.CSSProperties} */
  select: {
    color: "#141932",
  },
};

export const DealTypeInput = React.memo(
  /**
   * @param {{record:Appraisal,items?:AppraisalDealType[]} & import("@material-ui/core").SelectProps} param0
   */
  function DealTypeInput({ record: row, items, ...props }) {
    const [value, setValue] = React.useState(row.dealTypeId ?? "");
    const save = useAction(appraisalActions.saveDealType);
    const onChange = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLSelectElement>} e */
      async e => {
        const dealTypeId = e.target.value || null;
        await save(row.id, dealTypeId);
        setValue(dealTypeId ?? "");
      },
      [row, save],
    );

    return (
      <Select
        disableUnderline
        fullWidth
        value={!items ? "" : value}
        onChange={onChange}
        style={styles.select}
        displayEmpty
        {...props}
      >
        <MenuItem value="">&nbsp;</MenuItem>
        {items &&
          items.map(it => (
            <MenuItem key={it.id} value={it.id}>
              {it.name}
            </MenuItem>
          ))}
      </Select>
    );
  },
);
