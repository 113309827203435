import React from "react";
import { formatDistanceToNowStrict } from "date-fns";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  //TablePagination,
  Avatar,
  Button,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { Link } from "react-router-dom";
//import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions.js";
// Icons
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import ArchiveIcon from "@material-ui/icons/Archive";
// Local
import { useMobile } from "../../themes/defaultTheme.js";
import { AppraisalOfferDialog } from "../../pages/appraisals/show/AppraisalOfferDialog.jsx";
import { useWorksheet } from "../../pages/appraisals/show/useWorksheet.jsx";
import { useAsyncEffect } from "../../lib";
import {
  appraisalActions,
  authSelectors,
  dealerApi,
  useAction,
  useDealer,
  useSelector,
} from "../../state";
import {
  SectionDetails,
  isCollapse,
  toggleCollapse,
  useDragState,
} from "./DraggableState";
import { useStyles } from "./Opportunity.styles.js";
interface Props {
  item: SectionDetails;
}

const getters = {
  mp: dealerApi.getMissingPayoffs,
  ai: dealerApi.getAwaitingInspections,
  ro: dealerApi.getResendOffers,
  he: dealerApi.getHighEquityOffers,
};

export default function OpportunitiesContent({ item }: Props) {
  const classes = useStyles() as any;
  const isMobile = useMobile();
  const isTablet = useMobile("md");
  const [items, setItems] = React.useState<{ data: any[]; count: number }>({
    data: [],
    count: 0,
  });

  const collapse = isCollapse(item.id);
  const [displayRow, setDisplayRow] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [range, setRange] = React.useState<number[]>([0, 4]);
  const [offering, setOffering] = React.useState(0);
  const avatarInfo = useSelector(authSelectors.avatarInfo);

  const handleChangePage = (_event, newPage) => {
    if (newPage === 1) {
      setRange([0, 4]);
    } else {
      const min = range[1];
      setRange([min + 1, min + 5 >= items.count ? items.count : min + 5]);
    }
    setPage(newPage);
  };

  const [actionsMenuAnchor, setActionsMenuAnchor] = React.useState(null);
  const closeActionsMenu = React.useCallback(() => {
    setActionsMenuAnchor(null);
  }, []);
  const moveArea = useAction(appraisalActions.moveArea);
  const actionsMenuOpen = Boolean(actionsMenuAnchor);
  const archiveItem = useAction(appraisalActions.archiveItem);
  const onClickMoveArea = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    (e, id) => {
      e.stopPropagation();
      const toArea = e.currentTarget?.attributes?.getNamedItem("value")?.value;
      const label =
        toArea === "service"
          ? "Service"
          : toArea === "showroom"
          ? "the Showroom"
          : toArea === "buying"
          ? "the Buying center"
          : "Lease returns";
      setTimeout(async () => {
        if (!window.confirm(`Move this record to ${label}?`)) {
          return;
        }
        await moveArea(id, toArea);
      }, 250);
    },
    [moveArea],
  );
  const onClickArchive = React.useCallback(
    id => {
      if (!window.confirm("Archive this record?")) {
        return;
      }
      archiveItem(id);
    },
    [archiveItem],
  );
  const onClickActionMenu = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    e => {
      e.stopPropagation();
      setActionsMenuAnchor(e.currentTarget);
    },
    [],
  );

  const onClickOffer = React.useCallback(
    id => {
      if (offering !== id) {
        setOffering(id);
      }
    },
    [offering],
  );

  const { collapse: dragCollapse } = useDragState();

  const toggle = () => {
    localStorage.setItem(
      "dragCollapse",
      JSON.stringify(toggleCollapse(item.id) ?? dragCollapse),
    );
  };

  const dealer = useDealer();

  useAsyncEffect(
    async state => {
      const items = await getters[item.internal_name](range);
      if (!state.mounted) return;
      setItems(items);
    },
    [page, item.internal_name, range, dealer],
  );

  const isMp = item.internal_name === "mp";

  const allowWrite = useSelector(authSelectors.allowWriteAppraisal);
  const allowBids = useSelector(authSelectors.isAdminOrAcqManager);
  const { row, frm, profits, proposals, valuations } = useWorksheet({
    allowBids,
    allowWrite,
    id: offering,
  });
  const { values } = frm;
  return (
    <Box
      display={"flex"}
      flexDirection={"row-reverse"}
      className={classes.contentContainer}
    >
      <Table className={classes.section}>
        <TableHead>
          <TableRow>
            <TableCell style={{ border: "none" }}>
              <IconButton onClick={toggle} size={"small"}>
                <ArrowDropDownIcon
                  style={{ rotate: collapse ? "0deg" : "180deg" }}
                />
              </IconButton>
            </TableCell>
            <TableCell size="small" style={{ border: "none" }}>
              <Typography style={{ fontSize: "16px", fontWeight: 600 }}>
                {item.title}
                <Typography component={"span"} color={"primary"}>
                  {" "}
                  ({items.count})
                </Typography>
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {items.count && !collapse
            ? items.data.map(i => {
                return (
                  <TableRow
                    className={classes.row}
                    onMouseEnter={() => setDisplayRow(i.id)}
                    onMouseLeave={() => setDisplayRow(null)}
                    style={{
                      border:
                        items.data.length === 1 ? "1px solid #DADFF6" : "",
                      borderRadius: items.data.length === 1 ? "4px" : "",
                    }}
                    key={i.id}
                  >
                    <TableCell>
                      <Link
                        to={`/${dealer.slug}/appraisals/${i.id}`}
                        className={classes.aTag}
                      >
                        <Box
                          color={"black"}
                          display={"flex"}
                          flexDirection={"column"}
                          width={"100%"}
                        >
                          <Typography
                            className={classes.detailText}
                          >{`Buying center / ${
                            item.internal_name === "mp"
                              ? " Finalize payoff and make offer"
                              : item.title
                          }`}</Typography>
                          <ul className={classes.list}>
                            <li className={classes.listItem}>
                              {`${i.year} ${i.make} ${i.model}`}
                            </li>
                            {i.trim ? (
                              <li className={classes.listItem}>
                                {" "}
                                {`${i.trim}`}
                              </li>
                            ) : null}
                            {i.vin ? (
                              <li className={classes.listItem}>
                                {" "}
                                {`${i.vin}`}
                              </li>
                            ) : null}
                          </ul>
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell className={classes.detailText}>
                      {displayRow !== i.id ? (
                        <Typography>
                          {item.internal_name === "ro"
                            ? `Offer made ${formatDistanceToNowStrict(
                                new Date(i.updatedAt),
                              )} ago`
                            : null}
                        </Typography>
                      ) : (
                        <Box
                          display={"flex"}
                          justifyContent={isMp ? "flex-end" : "flex-start"}
                          width={isMp ? "50%" : "100%"}
                        >
                          {!isMp && (
                            <Button
                              variant="text"
                              color="secondary"
                              onClick={() => {
                                onClickOffer(i.id);
                              }}
                            >
                              {item.internal_name === "ro" ? "Resend" : "Make"}{" "}
                              offer
                            </Button>
                          )}
                          {offering && row && row.id === offering ? (
                            <AppraisalOfferDialog
                              // TODO: make offerType a dealer setting
                              offerType={
                                i.sell_strategy_status_id === 2
                                  ? "auction"
                                  : "retail"
                              }
                              onClose={() => setOffering(null)}
                              profits={profits}
                              proposals={proposals}
                              record={row}
                              valuations={valuations}
                              worksheetValues={values}
                            />
                          ) : null}{" "}
                          <IconButton
                            onClick={onClickActionMenu}
                            size="small"
                            title="Menu"
                          >
                            {/* TODO: get a new icon  */}
                            <ArchiveIcon style={{ rotate: "-90deg" }} />
                          </IconButton>
                          <Menu
                            anchorEl={actionsMenuAnchor}
                            open={actionsMenuOpen}
                            onClose={closeActionsMenu}
                            key="moveArea"
                          >
                            <MenuItem
                              value="buying"
                              onClick={e => onClickMoveArea(e, i.id)}
                            >
                              Buying center
                            </MenuItem>
                            <MenuItem
                              value="lease"
                              onClick={e => onClickMoveArea(e, i.id)}
                            >
                              Lease return
                            </MenuItem>
                            <MenuItem
                              value="showroom"
                              onClick={e => onClickMoveArea(e, i.id)}
                            >
                              Showroom
                            </MenuItem>
                            <MenuItem
                              value="service"
                              onClick={e => onClickMoveArea(e, i.id)}
                            >
                              Service
                            </MenuItem>
                          </Menu>
                          <IconButton
                            onClick={e => {
                              e.stopPropagation();
                              onClickArchive(i.id);
                            }}
                            size="small"
                            title="Archive"
                          >
                            <ArchiveIcon />
                          </IconButton>
                        </Box>
                      )}
                      {!isMobile && !isTablet && (
                        <Avatar
                          title={avatarInfo.fullName}
                          style={{ color: "white" }}
                        >
                          {avatarInfo.text}
                        </Avatar>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            : null}
        </TableBody>
        {items.count && !collapse ? (
          <TableFooter className={classes.footer}>
            <TableRow>
              <TableCell>
                {/* <TablePagination
              //https://stackoverflow.com/a/53184993
              //if we add the ability to select more rows per page then the range calculations should be adjusted as well
              rowsPerPageOptions={[]}
              count={items.count}
              rowsPerPage={5}
              page={page}
              labelDisplayedRows={({ from, to }) => `${from}-${to}`}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            /> */}
                <Pagination
                  count={Math.ceil(items.count / 5)}
                  onChange={handleChangePage}
                  page={page}
                  size="small"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  color="secondary"
                  variant="text"
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        ) : null}
      </Table>
    </Box>
  );
}
