import React from "react";
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
// Icons
import AddIcon from "@material-ui/icons/Add";
// Local
import { formatDateTime } from "../../../lib";
import { dealerApi, useDealer } from "../../../state";
import { useMobile } from "../../../themes";
import { DealerAffiliateEditor } from "./DealerAffiliateEditor";

const useStyles = makeStyles(
  theme => ({
    actions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    list: {
      padding: 16,
      "& td > a": {
        display: "block",
        margin: -16,
        padding: 16,
        height: "100%",
      },
    },
  }),
  {
    classNamePrefix: "DealerAffiliatesList",
  },
);

export const DealerAffiliatesList = React.memo(
  /**
   *
   */
  function DealerAffiliatesList() {
    const classes = useStyles();
    const dealer = useDealer();
    const isMobile = useMobile();

    /** @type {[DealerAffiliate[]]} */
    const [list, setList] = React.useState([]);
    /** @type {[DealerAffiliate | null]} */
    const [editing, setEditing] = React.useState(null);

    const onClickAdd = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      e => {
        if (e.preventDefault) e.preventDefault();
        setEditing({ id: 0 });
      },
      [],
    );

    const onClickEdit = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLAnchorElement>} e */
      e => {
        if (e) {
          if (e.preventDefault) e.preventDefault();
          const id = parseInt(e.currentTarget.dataset.id);
          const editing = list.find(it => it.id === id);
          setEditing(editing);
        }
      },
      [list],
    );
    const onCloseEditor = React.useCallback(() => {
      setEditing(null);
    }, []);

    React.useEffect(() => {
      if (editing !== null) {
        // Only run at start or when `editing` changes back to null...
        return;
      }
      const state = { mounted: true };
      (async function onDealerChanged() {
        const list = await dealerApi.getAffiliates();
        if (!state.mounted) return;
        setList(list);
      })();
      return () => {
        state.mounted = false;
      };
    }, [dealer, editing]);

    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Button
            onClick={onClickAdd}
            startIcon={<AddIcon />}
            variant="contained"
            color="secondary"
            size="small"
          >
            Add Affiliate
          </Button>
        </div>
        <Table className={classes.list}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {!isMobile && (
                <>
                  <TableCell>Updated</TableCell>
                  <TableCell>Created</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(it => {
              const linkProps = {
                "data-id": it.id,
                href: `/${dealer.slug}/settings/affiliates/${it.id}`,
                underline: "none",
                onClick: onClickEdit,
              };
              return (
                <TableRow key={it.id}>
                  <TableCell>
                    <Link {...linkProps}>{it.name}</Link>
                  </TableCell>
                  {!isMobile && (
                    <>
                      <TableCell>
                        <Link {...linkProps}>
                          {formatDateTime(it.updatedAt)}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link {...linkProps}>
                          {formatDateTime(it.createdAt)}
                        </Link>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {editing && (
          <DealerAffiliateEditor item={editing} onClose={onCloseEditor} />
        )}
      </div>
    );
  },
);
