import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    contentContainer: {
      width: "100%",
      "&:hover": {
        "&>table , &>table>tbody": {
          backgroundColor: "#ECEFF5",
          "&>tbody>tr:not(:hover)   ": {
            backgroundColor: "#FFF",
          },
        },
      },
    },

    section: {
      width: "100%",
      borderRadius: "4px",
      borderCollapse: "collapse",
      "& > thead": {
        display: "flex",
      },

      boxShadow: "0 2px 12px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
      backgroundColor: "transparent",

      "& > tbody > tr": {
        borderTop: "1px solid #DADFF6",
        marginLeft: "12px",
        marginRight: "12px",
      },
      "& > tbody>tr:last-child": {
        marginBottom: "12px",
      },
      "& > tbody>tr:first-child": {
        marginTop: "12px",
      },
      "&:hover > tbody>tr:first-child": {
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
      },
      "&:hover> tbody>tr:last-child": {
        borderBottom: "1px solid #DADFF6",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px",
      },
    },
    aTag: {
      textDecoration: "none",
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },

      "& > td": {
        border: "none",
      },
      "& > td:last-child": {
        display: "flex",
        width: "300px",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        cursor: "default",
        "&> td:first-child": {
          cursor: "pointer",
        },
      },
      [theme.breakpoints.down("xs")]: {
        boxShadow: "unset",
      },
    },

    list: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
      flexDirection: "row",
      width: "100%",
      paddingLeft: 0,
      margin: 2,
    },

    listItem: {
      "&:first-child": {
        listStyle: "none",
        fontWeight: 600,
      },
      "&:not(:first-child)": {
        paddingLeft: "12px",
        listStylePosition: "inside",
      },

      fontSize: "14px",
    },

    footer: {
      "&>tr>td": {
        backgroundColor: "transparent",
        padding: "12px",
      },
    },

    detailText: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: "400",
    },
    textCenter: {
      textAlign: "center",
    },

    pr2: {
      paddingRight: 2,
    },
    pl4: {
      paddingLeft: 4,
    },
  }),
  {
    classNamePrefix: "Draggable",
  },
);
