import React from "react";
import {
  //
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
// Local
import { TabPanel } from "../../../components";
import { Navigation, useLocation } from "../../../lib";
import { authSelectors, useDealer, useSelector } from "../../../state";
import { useMobile } from "../../../themes";
import { DealerProfileForm } from "../profile/DealerProfileForm";
import { DealerAffiliatesList } from "../affiliates/DealerAffiliatesList";
import { DealerBanksList } from "../banks/DealerBanksList";
import { DealerCampaignsList } from "../campaigns/DealerCampaignsList";
import { DealerSettingsList } from "../defaults/DealerSettingsList";
import { DealerUsersList } from "../users/DealerUsersList";
import { EmailTemplatesList } from "../emails/EmailTemplatesList";

const useStyles = makeStyles(
  theme => ({
    root: {
      // margin: 0,
    },
    container: {
      borderTop: "1px solid rgb(0, 0, 0, 0.2)",
      display: "flex",
      margin: "20px -40px 0 -40px",
      padding: "0 40px",
      [theme.breakpoints.down("xs")]: {
        display: "block",
        margin: "8px -16px 0 -16px",
        padding: "0 16px",
      },
    },
    contents: {
      borderLeft: "1px solid rgb(0, 0, 0, 0.2)",
      flex: 1,
      paddingLeft: 16,
      paddingTop: 16,
      minHeight: 800,
      [theme.breakpoints.down("xs")]: {
        borderLeft: "unset",
        paddingLeft: 0,
      },
    },
  }),
  {
    classNamePrefix: "DealerSettingsPage",
  },
);
/** Settings area definitions. NOTE: Must also add page path for each one! */
const settingsAreaByName = {
  profile: {
    adminOnly: true,
    name: "profile",
    label: "Profile",
    children: <DealerProfileForm />,
  },
  defaults: {
    adminOnly: true,
    name: "defaults",
    label: "Defaults",
    children: <DealerSettingsList />,
  },
  affiliates: {
    adminOnly: true,
    name: "affiliates",
    label: "Affiliates",
    children: <DealerAffiliatesList />,
  },
  banks: {
    adminOnly: true,
    name: "banks",
    label: "Banks",
    children: <DealerBanksList />,
  },
  campaigns: {
    adminOnly: false,
    name: "campaigns",
    label: "Campaigns",
    children: <DealerCampaignsList />,
  },
  users: {
    adminOnly: true,
    name: "users",
    label: "Users",
    children: <DealerUsersList />,
  },
  emails: {
    adminOnly: true,
    name: "emails",
    label: "Emails",
    children: <EmailTemplatesList />,
  },
};
const settingsAreas = Object.values(settingsAreaByName);

const disableTransition = {
  // TODO: Figure out why Tabs animation (disabled here) is broken on mobile.
  style: { transition: "none" },
};

export const DealerSettingsPage = React.memo(function DealerSettingsPage() {
  const classes = useStyles();
  const location = useLocation();
  const isMobile = useMobile();

  /** @type {typeof settingsAreas[number]} */
  const currentArea = React.useMemo(() => {
    // e.g. /dealer/settings/AREA === 0/1/2/3
    const name = location.pathname.split("/")[3];
    console.log("NAME", name);
    return settingsAreaByName[name];
  }, [location]);
  const dealer = useDealer();
  const isAdmin = useSelector(authSelectors.isAdmin);

  const onChangeSettingsTab = React.useCallback(
    (e, value) => {
      Navigation.replace(`/${dealer.slug}/settings/${value}`);
    },
    [dealer],
  );

  return (
    <div className={classes.root}>
      <Typography variant="h2" noWrap>
        {dealer.name} &bull; {currentArea.title ?? currentArea.label}
      </Typography>
      <div className={classes.container}>
        <Tabs
          value={currentArea.name}
          onChange={onChangeSettingsTab}
          orientation={isMobile ? "horizontal" : "vertical"}
          variant={isMobile ? "scrollable" : undefined}
          TabIndicatorProps={disableTransition}
        >
          {settingsAreas.map(
            area =>
              (!area.adminOnly || isAdmin) && (
                <Tab key={area.name} value={area.name} label={area.label} />
              ),
          )}
        </Tabs>
        <div className={classes.contents}>
          {settingsAreas.map(
            area =>
              (!area.adminOnly || isAdmin) && (
                <TabPanel
                  key={area.name}
                  selected={currentArea.name === area.name}
                >
                  {area.children}
                </TabPanel>
              ),
          )}
        </div>
      </div>
    </div>
  );
});
