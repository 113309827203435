import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
// Local
import { useField } from "../../lib";

export const CheckboxField = React.memo(
  /**
   * A Material-UI `FormControl` and `Checkbox` bound to a `formik` field.
   *
   * @typedef {import("@material-ui/core").FormControlLabelProps} FormControlLabelProps
   * @typedef {object} CheckboxFieldProps
   * @property {Record<string,string>} [classFrom] Object to get `className`
   * from, for the given field `name`.
   * @property {boolean} [error] True if error.
   * @property {boolean} [fullWidth]
   * @property {string} [helperText] Message to show beneath the control.
   *
   * @param {CheckboxFieldProps & FormControlLabelProps} props
   */
  function CheckboxField(props) {
    let {
      name,
      classFrom,
      className,
      error,
      fullWidth,
      helperText,
      value: _value,
      autoFocus,
      ...rest
    } = props;
    const [fld, meta, helpers] = useField(name);
    error = (meta.touched && Boolean(meta.error)) || error;
    helperText = meta.touched ? meta.error : helperText;
    const onChange = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLInputElement>} e */
      e => {
        helpers.setValue(e.target.checked);
      },
      [helpers],
    );
    return (
      <FormControl component="fieldset" fullWidth={fullWidth}>
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              checked={fld.value ?? false}
              className={className || classFrom?.[name]}
              onChange={onChange}
              autoFocus={autoFocus}
            />
          }
          {...rest}
        />
        <FormHelperText id={`${name}-helper-text`} error={error}>
          {helperText}
        </FormHelperText>
      </FormControl>
    );
  },
);
