import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
// Local
import {
  //
  carfax,
  useLocation,
  useOnMount,
} from "../../../lib";

const useStyles = makeStyles(
  theme => ({
    root: {
      // margin: 0,
      padding: 20,
    },
  }),
  {
    classNamePrefix: "CarfaxConnectedPage",
  },
);

export const CarfaxConnectedPage = React.memo(
  /**
   *
   */
  function CarfaxConnectedPage() {
    const classes = useStyles();
    const { query } = useLocation();

    useOnMount(() => {
      // Pass the query string's `code` and `state` params to carfax.auth so it
      // can navigate back to the original page in this application that was
      // trying to do something with the Carfax API...
      carfax.auth.connectAndNavigate(query);
    });

    return (
      <div className={classes.root}>
        <Typography>Loading Carfax...</Typography>
      </div>
    );
  },
);
