import axios from "axios";
// Local
// import { timeoutAsync } from "../utils";

const {
  /**
   * A callback where the browser will be redirected after the user logs in.
   * It must exactly match one of the callback URLs you entered in the app
   * settings in Partner Portal. Callback URLs should use HTTPS. Although you
   * can append dynamic query parameters, beware the user can tamper with them;
   * instead, it is recommended to use state as a key to retrieve application
   * context.
   * @default "${window.location.origin}/carfax/connected"
   */
  REACT_APP_CARFAX_CBPATH: redirect_uri = window.location.origin +
    "/carfax/connected",
  REACT_APP_CARFAX_CLIENTID: client_id = "YOUR_CLIENT_ID",
} = process.env;

const baseUrl = "https://auth.carfax.com";

/**
 * Authorization state from Carfax stored in localStorage `cfxa`.
 */
interface CarfaxAuthState {
  /** e.g. "eyJ0eXAiOiJKV1QiLCJhb..." */
  access_token: string;
  /** e.g. "MjBlZDA0OGViZjEyZjg0ZmEzNGE3ZjEwNWU0ZTU0M2UK..." */
  refresh_token: string;
  /** e.g. "offline_access" */
  scope: string;
  /** Expiration timestamp, added by our code - not returned from server. */
  expires_at: number;
  /** Expiration time in seconds, e.g. `86400` */
  expires_in: number;
  /** e.g. "Bearer" */
  token_type: string;
}
/** Params sent from Carfax to our callback, to complete the connection. */
export interface CarfaxConnectParams {
  code: string;
  state: string;
}
/**
 * Stuff we need after we're connected. The next URL to go to, etc...
 */
interface CarfaxConnectedState {
  gotoUrl?: string;
}
/**
 * Lookup CarfaxConnectedState by the authorization state key nonce we sent
 * to Carfax, stored in localStorage `cfxc`.
 */
interface CarfaxConnectState {
  [stateKey: string]: CarfaxConnectedState | unknown;
}

const urlsafeBase64Char = {
  "+": "-",
  "/": "_",
  "=": ".",
};

function generateNonce() {
  const array = new Uint32Array(4);
  crypto.getRandomValues(array);
  return btoa(array.join("")).replace(/[+/=]/g, c => urlsafeBase64Char[c]);
}

interface GetAccessTokenParams {
  code: string;
  state: string;
}

async function getAccessToken({
  // state,
  code,
}: GetAccessTokenParams) {
  const url = `${baseUrl}/oauth/token`;

  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("code", code);
  params.append("redirect_uri", redirect_uri);
  params.append("client_id", client_id);
  // params.append("client_secret", state);

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const res = await axios.post<CarfaxAuthState>(url, params, { headers });
  const { data } = res;
  if (data && typeof data.expires_in === "number") {
    data.expires_at = Date.now() + data.expires_in * 1000;
  }
  return res.data;
}

async function refreshToken({
  refresh_token,
}: Record<string, any> & { refresh_token: string }) {
  const url = `${baseUrl}/oauth/token`;

  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", refresh_token);
  params.append("client_id", client_id);
  // params.append("client_secret", ???);

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const res = await axios.post<CarfaxAuthState>(url, params, { headers });
  const { data } = res;
  if (data && typeof data.expires_in === "number") {
    data.expires_at = Date.now() + data.expires_in * 1000;
  }
  return res.data;
}

export interface StartCarfaxAuthParams {
  /**
   * Pass true to replace the current spot in the window history when we
   * navigate the browser to the carfax authorization page.
   * @default false
   */
  replace?: boolean;
  /**
   * A random string that is only used once, called a nonce. It is required to
   * prevent CSRF vulnerabilities and account hijacking. Use a
   * cryptographically secure random number generator to generate an array of at
   * least 128 bits (16 bytes) and then convert it to a string using URL-safe
   * base-64 encoding. Store the nonce in a server side session or client side
   * cookie so you can verify it later. You can also use the nonce as a key to
   * store additional application context needed to seamlessly complete a user
   * initiated action after login.
   * @default "NONCE" */
  state?: string;
}

function startCarfaxAuth({
  replace = true,
  state = "NONCE",
}: StartCarfaxAuthParams) {
  //
  // CONSIDER: We could also open a popup to do all this auth...

  const url =
    `${baseUrl}/authorize` +
    `?client_id=${client_id}` +
    `&redirect_uri=${redirect_uri}` +
    `&state=${state}` +
    `&response_type=code` +
    `&audience=https://connect.carfax.com` +
    `&scope=offline_access`;
  if (replace) {
    window.location.replace(url);
  } else {
    window.location.assign(url);
  }
}

export interface CarfaxAuthorizeParams {
  vin: string;
}

const local = {
  get auth(): CarfaxAuthState | undefined {
    const json = localStorage.getItem("cfxa");
    if (json) {
      return JSON.parse(json);
    }
    return undefined;
  },
  set auth(value: CarfaxAuthState | undefined) {
    if (!value) {
      localStorage.removeItem("cfxa");
    } else {
      localStorage.setItem("cfxa", JSON.stringify(value));
    }
  },
  /** Cache of the last auth token seen in check to see if we're connected... */
  authToken: "",
  get connect(): CarfaxConnectState | undefined {
    const json = localStorage.getItem("cfxc");
    if (json) {
      return JSON.parse(json);
    }
    return undefined;
  },
  set connect(value: CarfaxConnectState | undefined) {
    if (!value) {
      localStorage.removeItem("cfxc");
    } else {
      localStorage.setItem("cfxc", JSON.stringify(value));
    }
  },
  isConnected() {
    const auth = local.auth;
    if (auth) {
      local.authToken = auth.access_token;
      return true;
    }
    local.authToken = "";
    return false;
  },
};

/**
 * Finishes the authorization and returns the `CarfaxConnectedState`.
 * @param param0 params
 * @returns The `CarfaxConnectedState` stored before attempting to connect.
 */
async function onConnected({ code, state }: CarfaxConnectParams) {
  const connected = local.connect;
  if (!connected) {
    throw new Error("Missing carfax connect state.");
  }
  local.connect = undefined;
  local.auth = await getAccessToken({
    code,
    state,
  });
  local.authToken = local.auth.access_token;
  return connected[state] as CarfaxConnectedState;
}

export const carfaxAuth = {
  gotoConnectRequired() {
    if (local.isConnected()) {
      // Already authorized. Go to Carfax connect is not required.
      return false;
    }
    // The state string we're sending to Carfax is used to lookup the
    // the state object we need after we connect to Carfax...
    const state = generateNonce();
    const connect: CarfaxConnectState = {
      [state]: {
        gotoUrl: window.location.href,
      },
    };
    local.connect = connect;
    startCarfaxAuth({ state });
    // NOT authorized. We are going to the required Carfax connect page now.
    return true;
  },
  async connectAndNavigate(query) {
    // Give the developer a chance to open DevTools if necessary...
    // await timeoutAsync(5000);

    const stateData = await onConnected(query);
    const url = stateData?.gotoUrl ?? "/";
    window.location.replace(url);
    return true;
  },
  getAccessToken() {
    return local.authToken;
  },
  isConnected() {
    return local.isConnected();
  },
  async logout() {
    local.auth = undefined;
    local.authToken = "";
    local.connect = undefined;
    // CONSIDER: Send a logout request to carfax.
  },
  async refreshToken() {
    const { auth } = local;
    if (!auth) {
      return false;
    }
    try {
      local.auth = await refreshToken(auth);
      local.authToken = local.auth.access_token;
    } catch {
      carfaxAuth.logout();
      return false;
    }
    return true;
  },
};
