import React from "react";
import {
  // Box,
  // Button,
  DialogContent,
  // Link,
  // Typography,
  makeStyles,
} from "@material-ui/core";
// Local
import {
  CancelSaveDialogActions,
  CheckboxField,
  Field,
  NumberField,
  // DollarField,
  SimpleDialog,
  // useBasicDialog,
} from "../../../components";
import {
  //
  Form,
  formatDateTime,
  // formatDecimal,
  mapFormDefaults,
  useFormik,
  yup,
} from "../../../lib";
import { dealerApi } from "../../../state";

const useStyles = makeStyles(
  theme => ({
    // root: {
    //   "& .MuiDialog-paper": {
    //     width: 400,
    //     minHeight: 321,
    //   },
    //   "& .MuiDialogContent-root": {
    //     paddingTop: 24,
    //     paddingRight: 24,
    //     paddingLeft: 24,
    //     paddingBottom: 8,
    //   },
    //   "& .MuiFormControl-root": {
    //     marginTop: 32,
    //   },
    //   "& .MuiDialogActions-root": {
    //     paddingBottom: 24,
    //     paddingLeft: 24,
    //     paddingRight: 24,
    //   },
    // },
    changed: {
      paddingTop: 12,
      [theme.breakpoints.down("xs")]: {
        // Added vertical space for mobile keyboard.
        paddingBottom: 36,
      },
    },
  }),
  {
    classNamePrefix: "DealerSettingEditor",
  },
);

const dealerSettingIds = {
  /** Default value for appraisals wholesalePack. */
  wholesalePack: 40107,
  /** Code for the default valuation type: "MMR" | "JDP" | "Galves" */
  defaultWholesaleValuation: 40108,
  /** True if the Affiliates feature-set is enabled. */
  affiliatesEnabled: 40109,
  /** True if the Affiliate field is required when submitting appraisals. */
  appraisalAffiliateRequired: 40110,
  /** Comma separated list of hours to send reminders after event. */
  reminderEmailTimesIncInsp: 40111,
  /** Comma separated list of hours to send reminders after event. */
  reminderSmsTimesIncInsp: 40112,
  /** Comma separated list of hours to send reminders after event. */
  reminderEmailTimesOffers: 40113,
  /** Default range for MMR valuations. */
  defaultMmrRange: 40114,
  /** Default source field from JDP valuations. */
  defaultJdpRetailType: 40115,
  /** True to allow alternate inspector. */
  allowAlternateInspector: 40116,
  highEquity: 40117,
  // valuations that you consider HighEquity
  opportunityIntervals: 40118,
  // How far back to display new opportunities , In months
};

const defaultValues = {
  [dealerSettingIds.wholesalePack]: {
    value: "200.00",
  },
  [dealerSettingIds.defaultWholesaleValuation]: {
    value: "MMR",
  },
  [dealerSettingIds.affiliatesEnabled]: {
    value: false,
  },
  [dealerSettingIds.appraisalAffiliateRequired]: {
    value: false,
  },
  [dealerSettingIds.reminderEmailTimesIncInsp]: {
    value: "",
  },
  [dealerSettingIds.reminderSmsTimesIncInsp]: {
    value: "",
  },
  [dealerSettingIds.reminderEmailTimesOffers]: {
    value: "",
  },
  [dealerSettingIds.defaultMmrRange]: {
    value: "Average",
  },
  [dealerSettingIds.defaultJdpRetailType]: {
    value: "Trade",
  },
  [dealerSettingIds.allowAlternateInspector]: {
    value: true,
  },
  [dealerSettingIds.highEquity]: {
    value: "5000.00",
  },
  [dealerSettingIds.opportunityIntervals]: {
    value: "3",
  },
};

const wholesaleValuations = ["MMR", "JDP", "Galves"];
const mmrRanges = ["Average", "Above", "Below"];
const jdpTypes = ["Trade", "Loan", "Retail"];

const valueRequiredText = "Value is required.";

const validationSchemas = {
  [dealerSettingIds.wholesalePack]: yup.object({
    value: yup.number().required(valueRequiredText),
  }),
  [dealerSettingIds.defaultWholesaleValuation]: yup.object({
    value: yup
      .string()
      .oneOf(
        wholesaleValuations,
        `Enter one of: ${wholesaleValuations.join(", ")}`,
      )
      .required(valueRequiredText),
  }),
  [dealerSettingIds.defaultMmrRange]: yup.object({
    value: yup
      .string()
      .oneOf(mmrRanges, `Enter one of: ${mmrRanges.join(", ")}`)
      .required(valueRequiredText),
  }),
  [dealerSettingIds.defaultJdpRetailType]: yup.object({
    value: yup
      .string()
      .oneOf(jdpTypes, `Enter one of: ${jdpTypes.join(", ")}`)
      .required(valueRequiredText),
  }),
  [dealerSettingIds.highEquity]: yup.object({
    value: yup.number().required(valueRequiredText),
  }),
};

export const DealerSettingEditor = React.memo(
  /**
   * @param {{item:DealerSetting}} param0
   */
  function DealerSettingEditor({ item, onClose }) {
    const classes = useStyles();
    // const dealer = useDealer();

    /** @type {[DealerSetting]} */
    const [initialValues, setInitialValues] = React.useState(
      defaultValues[item.id],
    );

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      // validateOnBlur: false,
      validateOnChange: false,
      validationSchema: validationSchemas[item.id],
      async onSubmit(values, helpers) {
        let { value } = values;
        // if (item.id === dealerSettingIds.defaultWholesaleValuation) {
        //   if (!wholesaleValuations.includes(value)) {
        //     helpers.setFieldError(
        //       "value",
        //       "Must be one of: MMR, JDP or Galves",
        //     );
        //     return;
        //   }
        // }
        // if (item.type === "number") {
        //   value = parseFloat(value);
        // }
        await dealerApi.saveSetting({ id: item.id, value });
        setInitialValues(values);
        onClose();
      },
    });

    React.useEffect(() => {
      if (!item || item.id === 0) {
        return;
      }
      const state = { mounted: true };
      (async function onDealerChanged() {
        setInitialValues(defaultValues[item.id]);
        const values = await dealerApi.getSetting(item.id);
        if (!state.mounted) return;
        setInitialValues(
          mapFormDefaults(defaultValues[item.id], {
            value:
              item.type === "boolean"
                ? values.value === "true"
                : item.type === "number"
                ? parseFloat(values.value ?? "0")
                : values.value ?? "",
          }),
        );
      })();
      return () => {
        state.mounted = false;
      };
    }, [item]);

    const isHighEquityModal = item.id === dealerSettingIds.highEquity;
    return (
      <SimpleDialog
        className={classes.root}
        onClose={onClose}
        open={true}
        title={item?.name ?? ""}
      >
        <Form form={frm}>
          <DialogContent>
            {item.type === "boolean" ? (
              <CheckboxField
                name="value"
                label={item.description}
                autoFocus
                fullWidth
                // helperText={item.description}
              />
            ) : item.type === "number" ? (
              <NumberField
                name="value"
                label={!isHighEquityModal ? item.description : "Setting value"}
                autoFocus
                fullWidth
                decimals={2}
                helperText={isHighEquityModal ? item.description : ""}
              />
            ) : (
              <Field
                name="value"
                label="Setting value"
                autoFocus
                fullWidth
                helperText={item.description}
              />
            )}
            {item?.updatedAt && (
              <div className={classes.changed}>
                Last change: {formatDateTime(item.updatedAt)}
              </div>
            )}
          </DialogContent>
          <CancelSaveDialogActions
            disabled={frm.isSubmitting}
            saveDisabled={!frm.dirty}
            onCancel={onClose}
          />
        </Form>
      </SimpleDialog>
    );
  },
);
