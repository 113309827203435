import React from "react";
// Local
import {
  //
  // formatAmountUSD,
  // formatDateTime,
  formatDecimal,
  // isEmpty,
  useAsyncEffect,
  useFormik,
} from "../../../lib";
import {
  // AppraisalStatusId,
  appraisalActions,
  // authSelectors,
  useAction,
  // appraisalSelectors,
  // useSelector,
} from "../../../state";

const defaultValues = {
  profitObjective: "0.00",
  pickupFee: "0.00",
  reconBodyCost: "0.00",
  reconMechCost: "0.00",
  reconOtherCost: "0.00",
  reconditioningCost: "0.00",
  adjustedPayoffAmount: "0.00",

  carOfferAmount: "0.00",
  auctionValuation: "0.00",
  retailValuation: "0.00",

  wholesalePack: "0.00",
  carOfferFee: "0.00",
  auctionFee: "0.00",

  carOfferProposal: "0.00",
  auctionProposal: "0.00",
  retailProposal: "0.00",

  dmsId: "",
  dealTypeId: "",
  actualCost: "0.00",

  packageNotes: "",

  /** @type {AppraisalBid[]} */
  bids: [],
};
/**
 * @param {{allowBids?:boolean;allowWrite?:boolean;id?:number;record?:Appraisal}} param0
 */
export function useWorksheet({ allowBids, allowWrite, id, record }) {
  // Actions
  const saveWorksheet = useAction(appraisalActions.saveWorksheet);
  const getData = useAction(appraisalActions.getById);

  /** Row that is gotten when id is provided; for NewOpportunityPage ONLY... */
  const [row, setRow] = React.useState(record ?? {});
  useAsyncEffect(
    async state => {
      if (id) {
        const data = await getData(id);
        if (!state.mounted) return;
        setRow(data);
      } else if (record) {
        setRow(record);
      }
    },
    [id, getData, record],
  );

  // Selectors
  // const allowWrite = useSelector(authSelectors.allowWriteAppraisal);
  // const allowBids = useSelector(authSelectors.isAdminOrAcqManager);
  // State
  const closed =
    !allowWrite ||
    // 1: Buying, 2: Grounding
    (row.appraisalStatusId > 0 && row.appraisalStatusId < 3);
  const isLeaseReturn = row.appraisalTypeId === 2;

  const [initialValues, setInitialValues] = React.useState(defaultValues);

  const [valuations, setValuations] = React.useState({
    carOfferProjectedValuation: 0,
    auctionProjectedValuation: 0,
    retailProjectedValuation: 0,

    carOfferProjectedEquity: 0,
    auctionProjectedEquity: 0,
    retailProjectedEquity: 0,
  });

  const [proposals, setProposals] = React.useState({
    carOffer: 0,
    auction: 0,
    retail: 0,
  });

  const [profits, setProfits] = React.useState({
    carOfferTotalProfit: row.carOfferTotalProfit,
    auctionTotalProfit: row.auctionTotalProfit,
    retailTotalProfit: row.retailTotalProfit,

    carOfferCustomerProfit: 0,
    auctionCustomerProfit: 0,
    retailCustomerProfit: 0,

    carOfferProfitStatus: "",
    auctionProfitStatus: "",
    retailProfitStatus: "",
  });

  React.useEffect(() => {
    const values = {
      ...defaultValues,
      profitObjective: formatDecimal(row.profitObjective ?? 0),
      pickupFee: formatDecimal(row.pickupFee ?? 0),
      reconBodyCost: formatDecimal(row.reconBodyCost ?? 0),
      reconMechCost: formatDecimal(row.reconMechCost ?? 0),
      reconOtherCost: formatDecimal(row.reconOtherCost ?? 0),
      reconditioningCost: formatDecimal(row.reconditioningCost ?? 0),
      adjustedPayoffAmount: formatDecimal(row.adjustedPayoffAmount ?? 0),

      carOfferAmount: formatDecimal(row.carOfferAmount ?? 0),
      auctionValuation: formatDecimal(row.auctionValuation ?? 0),
      retailValuation: formatDecimal(row.retailValuation ?? 0),

      wholesalePack: formatDecimal(row.wholesalePack ?? 0),
      carOfferFee: formatDecimal(row.carOfferFee ?? 0),
      auctionFee: formatDecimal(row.auctionFee ?? 0),

      carOfferProposal: formatDecimal(row.carOfferProposal ?? 0),
      auctionProposal: formatDecimal(row.auctionProposal ?? 0),
      retailProposal: formatDecimal(row.retailProposal ?? 0),

      dmsId: row.dmsId ?? "",
      dealTypeId: row.dealTypeId ?? "",
      actualCost: formatDecimal(row.actualCost ?? 0),

      packageNotes: row.packageNotes ?? "",
    };
    if (allowBids && row.bids) {
      values.bids = row.bids.map(it => ({
        ...it,
        amount: formatDecimal(it.amount),
        isPreferred: !!it.isPreferred,
      }));
    }
    setInitialValues(values);
  }, [row, allowBids]);

  const frm = useFormik({
    enableReinitialize: true,
    initialValues,
    // validationSchema, // They want to let it submit no matter what.
    validateOnBlur: false,
    validateOnChange: false,
    async onSubmit(values, helpers) {
      const data = {
        // Spread all values so we don't miss any??
        ...profits,
        ...values,
        ...valuations,

        // Profits

        carOfferTotalProfit: profits.carOfferTotalProfit || 0,
        auctionTotalProfit: profits.auctionTotalProfit || 0,
        retailTotalProfit: profits.retailTotalProfit || 0,

        carOfferCustomerProfit: profits.carOfferCustomerProfit || 0,
        auctionCustomerProfit: profits.auctionCustomerProfit || 0,
        retailCustomerProfit: profits.retailCustomerProfit || 0,

        carOfferProfitStatus: profits.carOfferProfitStatus || "",
        auctionProfitStatus: profits.auctionProfitStatus || "",
        retailProfitStatus: profits.retailProfitStatus || "",

        // Values

        profitObjective: parseFloat(values.profitObjective || "0"),
        pickupFee: parseFloat(values.pickupFee || "0"),
        reconBodyCost: parseFloat(values.reconBodyCost || "0"),
        reconMechCost: parseFloat(values.reconMechCost || "0"),
        reconOtherCost: parseFloat(values.reconOtherCost || "0"),
        reconditioningCost: parseFloat(values.reconditioningCost || "0"),
        adjustedPayoffAmount: parseFloat(values.adjustedPayoffAmount || "0"),

        carOfferAmount: parseFloat(values.carOfferAmount || "0"),
        auctionValuation: parseFloat(values.auctionValuation || "0"),
        retailValuation: parseFloat(values.retailValuation || "0"),

        wholesalePack: parseFloat(values.wholesalePack || "0"),
        carOfferFee: parseFloat(values.carOfferFee || "0"),
        auctionFee: parseFloat(values.auctionFee || "0"),

        carOfferProposal: parseFloat(values.carOfferProposal || "0"),
        auctionProposal: parseFloat(values.auctionProposal || "0"),
        retailProposal: parseFloat(values.retailProposal || "0"),

        actualCost: parseFloat(values.actualCost || "0"),

        // Valuations

        carOfferProjectedValuation: valuations.carOfferProjectedValuation || 0,
        auctionProjectedValuation: valuations.auctionProjectedValuation || 0,
        retailProjectedValuation: valuations.retailProjectedValuation || 0,

        carOfferProjectedEquity: valuations.carOfferProjectedEquity || 0,
        auctionProjectedEquity: valuations.auctionProjectedEquity || 0,
        retailProjectedEquity: valuations.retailProjectedEquity || 0,
      };
      if (allowBids) {
        const fees =
          data.auctionFee +
          data.pickupFee +
          data.wholesalePack +
          data.reconditioningCost;
        data.bids = values.bids.map(it => {
          const amount = parseFloat(it.amount || "0");
          return {
            ...it,
            id: it.id > 0 ? it.id : undefined,
            amount,
            profit: amount - data.auctionProposal - fees,
          };
        });
      }
      await saveWorksheet(row.id, data).catch(err => {
        window.alert(
          "There was an error. Any pending emails have not been sent.",
        );
        return Promise.reject(err);
      });
    },
  });

  const {
    dirty,
    resetForm,
    // setErrors,
    setFieldValue,
    // setTouched,
    // submitForm,
    // validateForm,
    values,
  } = frm;

  /** Ref of frm fields for hooks without requiring frm as dependency. */
  const frmRef = React.useRef({
    //
    dirty,
    resetForm,
    setFieldValue,
    values,
  });
  frmRef.current = {
    //
    dirty,
    resetForm,
    setFieldValue,
    values,
  };

  /** Function to set new values without altering `frm.dirty`. */
  const resetFieldValues = React.useCallback(newValues => {
    const { dirty, values, resetForm, setFieldValue } = frmRef.current;
    if (!dirty) {
      resetForm({ ...values, ...newValues });
    } else {
      for (const key in newValues) {
        setFieldValue(key, newValues[key]);
      }
    }
  }, []);
  const defaultFees = React.useMemo(() => {
    const auctionFee = parseFloat(values.auctionFee),
      pickupFee = parseFloat(values.pickupFee),
      wholesalePack = parseFloat(values.wholesalePack);
    return {
      wholesaleFees: auctionFee + pickupFee + wholesalePack,
      retailFees: pickupFee,
    };
  }, [values.auctionFee, values.pickupFee, values.wholesalePack]);
  // subject to change based on default fee setting
  const defaultEquity = React.useMemo(() => {
    return (
      row.auctionValuation -
      parseFloat(values.adjustedPayoffAmount) -
      defaultFees.wholesaleFees
    );
  }, [
    row.auctionValuation,
    values.adjustedPayoffAmount,
    defaultFees.wholesaleFees,
  ]);

  React.useEffect(() => {
    setFieldValue(
      "reconditioningCost",
      formatDecimal(
        parseFloat(values.reconBodyCost) +
          parseFloat(values.reconMechCost) +
          parseFloat(values.reconOtherCost),
      ),
    );
  }, [
    values.reconBodyCost,
    values.reconMechCost,
    values.reconOtherCost,
    setFieldValue,
  ]);

  React.useEffect(() => {
    const carOfferAmount = parseFloat(values.carOfferAmount);

    const carOfferFee = parseFloat(values.carOfferFee);
    const pickupFee = parseFloat(values.pickupFee);
    const reconditioningCost = parseFloat(values.reconditioningCost);
    const auctionValuation = parseFloat(values.auctionValuation);
    const auctionFee = parseFloat(values.auctionFee);
    const retailValuation = parseFloat(values.retailValuation);
    const adjustedPayoffAmount = parseFloat(values.adjustedPayoffAmount);

    const valuations = {
      carOfferProjectedValuation:
        carOfferAmount -
        carOfferFee -
        // carOfferDeliveryFee -
        pickupFee -
        reconditioningCost,
      auctionProjectedValuation:
        auctionValuation - auctionFee - pickupFee - reconditioningCost,
      retailProjectedValuation:
        retailValuation -
        // retailFee -
        // retailDeliveryFee -
        pickupFee -
        reconditioningCost,
    };
    Object.assign(valuations, {
      carOfferProjectedEquity:
        valuations.carOfferProjectedValuation - adjustedPayoffAmount,
      auctionProjectedEquity:
        valuations.auctionProjectedValuation - adjustedPayoffAmount,
      retailProjectedEquity:
        valuations.retailProjectedValuation - adjustedPayoffAmount,
    });
    setValuations(valuations);
  }, [
    values.carOfferAmount,
    values.carOfferFee,
    values.pickupFee,
    values.reconditioningCost,
    values.auctionValuation,
    values.auctionFee,
    values.retailValuation,
    values.adjustedPayoffAmount,
  ]);

  React.useEffect(() => {
    const profitObjective = parseFloat(values.profitObjective);
    const wholesalePack = parseFloat(values.wholesalePack);
    const proposals = {
      carOffer:
        valuations.carOfferProjectedValuation - profitObjective - wholesalePack,
      auction:
        valuations.auctionProjectedValuation - profitObjective - wholesalePack,
      retail: valuations.retailProjectedValuation - profitObjective,
    };
    setProposals(proposals);
    // Use `resetFieldValues` instead of `setFieldValue` to leave dirty as-is.
    let newValues;
    if (!row.carOfferProposal) {
      newValues = {
        ...newValues,
        carOfferProposal: formatDecimal(proposals.carOffer),
      };
    }
    if (!row.auctionProposal) {
      newValues = {
        ...newValues,
        auctionProposal: formatDecimal(proposals.auction),
      };
    }
    if (!row.retailProposal) {
      newValues = {
        ...newValues,
        retailProposal: formatDecimal(proposals.retail),
      };
    }
    if (newValues) {
      resetFieldValues(newValues);
    }
  }, [
    row,
    valuations,
    values.profitObjective,
    values.wholesalePack,
    resetFieldValues,
  ]);

  React.useEffect(() => {
    const profitObjective = parseFloat(values.profitObjective);
    const carOfferProposal = parseFloat(values.carOfferProposal);
    const auctionProposal = parseFloat(values.auctionProposal);
    const retailProposal = parseFloat(values.retailProposal);

    const profits = {
      carOfferTotalProfit:
        valuations.carOfferProjectedValuation - carOfferProposal,
      auctionTotalProfit:
        valuations.auctionProjectedValuation - auctionProposal,
      retailTotalProfit: valuations.retailProjectedValuation - retailProposal,
    };

    profits.carOfferCustomerProfit =
      valuations.carOfferProjectedEquity - profits.carOfferTotalProfit;
    profits.auctionCustomerProfit =
      valuations.auctionProjectedEquity - profits.auctionTotalProfit;
    profits.retailCustomerProfit =
      valuations.retailProjectedEquity - profits.retailTotalProfit;

    profits.carOfferProfitStatus = getProfitStatus(
      profits.carOfferTotalProfit,
      profitObjective,
    );
    profits.auctionProfitStatus = getProfitStatus(
      profits.auctionTotalProfit,
      profitObjective,
    );
    profits.retailProfitStatus = getProfitStatus(
      profits.retailTotalProfit,
      profitObjective,
    );

    setProfits(profits);
  }, [
    values.profitObjective,
    values.carOfferProposal,
    values.auctionProposal,
    values.retailProposal,
    valuations,
  ]);

  return {
    closed,
    defaultEquity,
    defaultFees,
    frm,
    // initialValues,
    isLeaseReturn,
    profits,
    proposals,
    // resetFieldValues,
    // saveWorksheet,
    valuations,
    row,
  };
}

export function getProfitStatus(value, objective) {
  return value < objective ? "less" : value > objective ? "more" : "equal";
}
export function getProfitStatusRange(value, min, max) {
  return value < min ? "less" : value > max ? "more" : "equal";
}
