import { ROLES } from "../../config";
import { Navigation } from "../../lib";
import { AppraisalsBoardPage } from "./board/AppraisalsBoardPage";
import { AppraisalsDashboardPage } from "./dashboard/AppraisalsDashboardPage";
import { AppraisalsPage } from "./list/AppraisalsPage";
// import { ShowAppraisalPage } from "./show/ShowAppraisalPage";
import { ShowAppraisalPage } from "./show/ShowAppraisalPage";

const roles = [ROLES.DEALER];

export const AppraisalPages = {
  boardLeaseReturns: {
    path: "/:dslug/appraisals/board/returns",
    pathExact: true,
    roles: [ROLES.DEALER_ADMIN, ROLES.FINANCE_MANAGER],
    title: "Lease returns",
    view: AppraisalsBoardPage,
    /** @param {Dealer} dealer */
    url(dealer) {
      return Navigation.url(AppraisalPages.boardLeaseReturns, {
        params: {
          dslug: dealer.slug,
        },
      });
    },
  },
  boardTradeins: {
    path: "/:dslug/appraisals/board",
    pathExact: true,
    roles: [ROLES.DEALER_ADMIN, ROLES.FINANCE_MANAGER],
    title: "Buying center",
    view: AppraisalsBoardPage,
    /** @param {Dealer} dealer */
    url(dealer) {
      return Navigation.url(AppraisalPages.boardTradeins, {
        params: {
          dslug: dealer.slug,
        },
      });
    },
  },
  dashboardLeaseReturns: {
    path: "/:dslug/appraisals/dashboard/returns",
    pathExact: true,
    roles: [ROLES.DEALER_ADMIN, ROLES.FINANCE_MANAGER, ROLES.SALES_MANAGER],
    title: "Lease returns",
    view: AppraisalsDashboardPage,
    /** @param {Dealer} dealer */
    url(dealer) {
      return Navigation.url(AppraisalPages.dashboardLeaseReturns, {
        params: {
          dslug: dealer.slug,
        },
      });
    },
  },
  dashboardTradeins: {
    path: "/:dslug/appraisals/dashboard",
    pathExact: true,
    roles: [ROLES.DEALER_ADMIN, ROLES.FINANCE_MANAGER, ROLES.SALES_MANAGER],
    title: "Buying center",
    view: AppraisalsDashboardPage,
    /** @param {Dealer} dealer */
    url(dealer) {
      return Navigation.url(AppraisalPages.dashboardTradeins, {
        params: {
          dslug: dealer.slug,
        },
      });
    },
  },
  duplicate: {
    path: "/:dslug/appraisals/duplicate/:appVin",
    pathExact: true,
    roles,
    title: "Duplicate submissions",
    view: AppraisalsPage,
    /**
     * @param {Dealer} dealer
     * @param {Appraisal} app
     */
    url(dealer, app) {
      return Navigation.url(AppraisalPages.duplicate, {
        params: {
          dslug: dealer.slug,
          appVin: app.vin,
        },
      });
    },
  },
  groupAdminStats: {
    path: "/:dslug/:appType/:subType/dealer-stats",
    pathExact: false,
    roles: [...roles, ROLES.GROUP_ADMIN],
    title: "Dealer Submission",
    view: AppraisalsPage,
    /**
     * @param {Dealer} dealer
     * @param {"returns" | "trade"} appraisalType
     * @param {"submitted" | "inspected" | "offers-sent" | "offers-accepted" | "buying" | "grounding"} submissionType
     */
    url(dealer, appraisalType, submissionType) {
      return Navigation.url(AppraisalPages.groupAdminStats, {
        params: {
          dslug: dealer.slug,
          appType: appraisalType,
          subType: submissionType,
        },
      });
    },
  },
  list: {
    path: "/:dslug/appraisals",
    pathExact: true,
    roles,
    title: "Buying center",
    view: AppraisalsPage,
    /** @param {Dealer} dealer */
    url(dealer) {
      return Navigation.url(AppraisalPages.list, {
        params: {
          dslug: dealer.slug,
        },
      });
    },
  },
  archived: {
    path: "/:dslug/appraisals/archived",
    pathExact: true,
    roles,
    title: "Archived",
    view: AppraisalsPage,
    /** @param {Dealer} dealer */
    url(dealer) {
      return Navigation.url(AppraisalPages.archived, {
        params: {
          dslug: dealer.slug,
        },
      });
    },
  },
  rejected: {
    path: "/:dslug/appraisals/rejected",
    pathExact: true,
    roles,
    title: "Rejected",
    view: AppraisalsPage,
    /** @param {Dealer} dealer */
    url(dealer) {
      return Navigation.url(AppraisalPages.rejected, {
        params: {
          dslug: dealer.slug,
        },
      });
    },
  },
  returns: {
    path: "/:dslug/appraisals/returns",
    pathExact: true,
    roles,
    title: "Lease returns",
    view: AppraisalsPage,
    /** @param {Dealer} dealer */
    url(dealer) {
      return Navigation.url(AppraisalPages.returns, {
        params: {
          dslug: dealer.slug,
        },
      });
    },
  },
  returnsMgmt: {
    path: "/:dslug/appraisals/manage/returns",
    pathExact: true,
    roles,
    title: "Lease returns management",
    view: AppraisalsPage,
    /** @param {Dealer} dealer */
    url(dealer) {
      return Navigation.url(AppraisalPages.returns, {
        params: {
          dslug: dealer.slug,
        },
      });
    },
  },
  service: {
    path: "/:dslug/appraisals/service",
    pathExact: true,
    roles,
    title: "Service",
    view: AppraisalsPage,
    /** @param {Dealer} dealer */
    url(dealer) {
      return Navigation.url(AppraisalPages.service, {
        params: {
          dslug: dealer.slug,
        },
      });
    },
  },
  showroom: {
    path: "/:dslug/appraisals/showroom",
    pathExact: true,
    roles,
    title: "Showroom",
    view: AppraisalsPage,
    /** @param {Dealer} dealer */
    url(dealer) {
      return Navigation.url(AppraisalPages.showroom, {
        params: {
          dslug: dealer.slug,
        },
      });
    },
  },
  show: {
    path: "/:dslug/appraisals/:id",
    pathExact: true,
    roles,
    title: "Show Appraisal",
    // view: ShowAppraisalPage,
    view: ShowAppraisalPage,
    /**
     * @param {number} id
     * @param {{tab:"details"|"offers"|"messages"}} [query]
     */
    url(dealer, id, query = undefined) {
      return Navigation.url(AppraisalPages.show, {
        params: {
          dslug: dealer,
          id,
        },
        query,
      });
    },
  },
};
