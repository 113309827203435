import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  // Link,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  // TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
// Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Local
// import { formatDateTime } from "../../../lib";
// import { useMobile } from "../../../themes";
import { Notifications } from "../../../services";
import {
  //
  useDealers,
  userApi,
} from "../../../state";
// import { notifState } from "../../../state/notifs";

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: 16,
    },
    list: {
      "& td > a": {
        display: "block",
        margin: -16,
        padding: 16,
        height: "100%",
      },
    },
    summary: {
      display: "flex",
      flex: 1,
    },
    dealer: {
      flex: 1,
    },
    counts: {
      flex: 2,
    },
  }),
  {
    classNamePrefix: "UserNotificationsList",
  },
);
// function findWebEnabled(obj) {
//   for (let dealer in obj) {
//     for (let setting in obj[dealer]) {
//       if (obj[dealer][setting].web === true) {
//         return true;
//       }
//     }
//   }
//   return false;
// }

export const UserNotificationsList = React.memo(
  /**
   *
   */
  function UserNotificationsList() {
    // const { notifState } = useNotifState();
    const classes = useStyles();
    const dealers = useDealers();
    // const isMobile = useMobile();
    const isSummaryReport = settingName => {
      const words = settingName.split(" ");
      return words[1] === "Summary";
    };
    const [busy, setBusy] = React.useState(true);

    /** @type {[{id:string,name:string}[]]} */
    const [notifications, setNotifications] = React.useState([]);
    /**
     * Settings by dealer and notification id...
     * @type {[Record<number,Record<string,DealerUserNotificationSetting>>]}
     */
    const [settings, setSettings] = React.useState([]);
    const onChange = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLInputElement>} e */
      e => {
        const el = e.currentTarget;
        const dealerId = parseInt(el.dataset.did);
        const notification = el.dataset.nid;
        const control = el.dataset.control;
        const optout =
          control === "optout" ? !e.target.checked : e.target.checked;
        if (
          notification === "ALL_FOR_DEALER" ||
          notification === "ALL_FOR_DEALER_SMS" ||
          notification === "ALL_FOR_DEALER_WEB"
        ) {
          const changes = notifications.map(notif => ({
            ...settings[dealerId]?.[notif.id],
            dealerId,
            notification: notif.id,
            [control]:
              notification === "ALL_FOR_DEALER_SMS" ||
              notification === "ALL_FOR_DEALER_WEB"
                ? !!optout && !isSummaryReport(notif.name)
                : !!optout,
          }));

          userApi.saveCurrentUserNotificationSettings(changes).then(withIds => {
            setSettings({
              ...settings,
              // Reduce array of returned records to a map by key....
              [dealerId]: withIds.reduce((byKey, it) => {
                byKey[it.notification] = it;
                return byKey;
              }, {}),
            });
            // if (!notification === "ALL_FOR_DEALER_WEB") {
            //   notifState.enabled = findWebEnabled(settings);
            // } else notifState.enabled = true;
          });
        } else {
          const newSetting = {
            ...settings[dealerId]?.[notification],
            dealerId,
            notification,
            // ...notificationsOption,
            [control]: !!optout,
          };
          const newSettings = {
            ...settings,
            [dealerId]: {
              ...settings[dealerId],
              [notification]: newSetting,
            },
          };
          setSettings(newSettings);
          userApi
            .saveCurrentUserNotificationSetting(newSetting)
            .then(withId => {
              setSettings({
                ...settings,
                [dealerId]: {
                  ...settings[dealerId],
                  [notification]: withId,
                },
              });
              // notifState.enabled = findWebEnabled(newSettings);
            });
        }
      },
      [settings, notifications],
    );

    const onClickSendTestNotif = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        Notifications.sendTest().catch(err => {
          console.log("ERROR", err);
          window.alert("Error sending test notification");
        });
      },
      [],
    );

    React.useEffect(() => {
      const state = { mounted: true };
      (async function onMount() {
        const data = await userApi.getCurrentUserNotificationSettings();
        if (!state.mounted) return;
        // Reduce settings ARRAY to multi-level map by dealerid/notification.

        const settings = data.settings.reduce((byDealer, it) => {
          byDealer[it.dealerId] = {
            ...byDealer[it.dealerId],
            [it.notification]: it,
          };
          return byDealer;
        }, {});
        setSettings(settings);
        setNotifications(
          data.notifications.sort((a, b) =>
            a.name.localeCompare(b.name, "en", { sensitivity: "base" }),
          ),
        );
        setBusy(false);
      })();
      return () => {
        state.mounted = false;
      };
    }, []);
    /** @type {Record<number,{subscribed:number,text:React.ReactNode,total:number}>} */
    const counts = React.useMemo(() => {
      const total = notifications.length;
      const byDealer = dealers.reduce((countsByDealer, dlr) => {
        // const subscribed = notifications.reduce((count, notif) => {
        //   if (
        //     settings[dlr.id]?.[notif.id]?.optout !== true ||
        //     settings[dlr.id]?.[notif.id]?.sms === true ||
        //     settings[dlr.id]?.[notif.id]?.web === true
        //   ) {
        //     count += 1;
        //   }
        //   return count;
        // }, 0);
        const optout = notifications.reduce((count, notif) => {
          if (settings[dlr.id]?.[notif.id]?.optout !== true) {
            count += 1;
          }
          return count;
        }, 0);
        const sms = notifications.reduce((count, notif) => {
          if (settings[dlr.id]?.[notif.id]?.sms === true) {
            count += 1;
          }
          return count;
        }, 0);
        const web = notifications.reduce((count, notif) => {
          if (settings[dlr.id]?.[notif.id]?.web === true) {
            count += 1;
          }
          return count;
        }, 0);
        countsByDealer[dlr.id] = {
          // subscribed,
          text: (
            <>
              <strong>Email</strong>{" "}
              <span>
                {optout} of {total}
              </span>{" "}
              <strong>Text</strong> <span>{sms} of 6</span> <strong>Web</strong>{" "}
              <span>{web} of 6</span>
            </>
          ),
          total,
          optout,
          sms,
          web,
        };
        return countsByDealer;
      }, {});
      return byDealer;
    }, [dealers, notifications, settings]);

    return (
      <div className={classes.root}>
        {dealers.map(dlr => (
          <Accordion key={dlr.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.summary}>
                <Typography variant="h4" className={classes.dealer}>
                  {dlr.name}
                </Typography>
                <div className={classes.counts}>{counts[dlr.id].text}</div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Table className={classes.list}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <>
                        <em> {dlr.name}</em>
                      </>
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            inputProps={{
                              "data-did": dlr.id,
                              "data-nid": "ALL_FOR_DEALER",
                              "data-control": "optout",
                            }}
                            checked={
                              counts[dlr.id].optout === counts[dlr.id].total
                            }
                            onChange={onChange}
                            disabled={busy}
                          />
                        }
                        label={
                          <>
                            <em>All email </em>
                          </>
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            inputProps={{
                              "data-did": dlr.id,
                              "data-nid": "ALL_FOR_DEALER_SMS",
                              "data-control": "sms",
                            }}
                            checked={
                              counts[dlr.id].sms === counts[dlr.id].total - 6
                            }
                            onChange={onChange}
                            disabled={busy}
                          />
                        }
                        label={
                          <>
                            <em>All text</em>
                          </>
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            inputProps={{
                              "data-did": dlr.id,
                              "data-nid": "ALL_FOR_DEALER_WEB",
                              "data-control": "web",
                            }}
                            checked={
                              counts[dlr.id].web === counts[dlr.id].total - 6
                            }
                            onChange={onChange}
                            disabled={busy}
                          />
                        }
                        label={
                          <>
                            <em>All web</em>
                          </>
                        }
                      />
                    </TableCell>
                  </TableRow>

                  {notifications.map(notif => (
                    // INFO: this was originally built to handle just email
                    // notifications with the var to optout. We now added sms
                    // and web notifications. Instead of opting out, you're
                    // opting in so they follow a different pattern than optout
                    // where true is false and false is true
                    <TableRow key={notif.id}>
                      <TableCell>{notif.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              inputProps={{
                                "data-did": dlr.id,
                                "data-nid": notif.id,
                                "data-control": "optout",
                              }}
                              checked={
                                settings[dlr.id]?.[notif.id]?.optout !== true
                              }
                              onChange={onChange}
                            />
                          }
                          label="Email"
                          disabled={busy}
                        />
                      </TableCell>
                      <TableCell>
                        {isSummaryReport(notif.name) ? (
                          <Tooltip title="Text notifications unavailable for this setting">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  inputProps={{
                                    "data-did": dlr.id,
                                    "data-nid": notif.id,
                                    "data-control": "sms",
                                  }}
                                  checked={
                                    settings[dlr.id]?.[notif.id]?.sms === true
                                  }
                                  onChange={onChange}
                                />
                              }
                              label="Text"
                              disabled={true}
                            />
                          </Tooltip>
                        ) : (
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                inputProps={{
                                  "data-did": dlr.id,
                                  "data-nid": notif.id,
                                  "data-control": "sms",
                                }}
                                checked={
                                  settings[dlr.id]?.[notif.id]?.sms === true
                                }
                                onChange={onChange}
                              />
                            }
                            label="Text"
                            disabled={busy}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {isSummaryReport(notif.name) ? (
                          <Tooltip title="Web notifications unavailable for this setting">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  inputProps={{
                                    "data-did": dlr.id,
                                    "data-nid": notif.id,
                                    "data-control": "web",
                                  }}
                                  checked={
                                    settings[dlr.id]?.[notif.id]?.web === true
                                  }
                                  onChange={onChange}
                                />
                              }
                              label="Web"
                              disabled={true}
                            />
                          </Tooltip>
                        ) : (
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                inputProps={{
                                  "data-did": dlr.id,
                                  "data-nid": notif.id,
                                  "data-control": "web",
                                }}
                                checked={
                                  settings[dlr.id]?.[notif.id]?.web === true
                                }
                                onChange={onChange}
                              />
                            }
                            label="Web"
                            disabled={busy}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ))}
        <Box pt="16px" textAlign="right">
          <Button variant="outlined" onClick={onClickSendTestNotif}>
            Send test web notification
          </Button>
        </Box>
      </div>
    );
  },
);
