import React from "react";
import clsx from "clsx";
import {
  AppBar,
  Avatar,
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  // Badge,
} from "@material-ui/core";
// Icons
import MenuIcon from "@material-ui/icons/Apps";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
// Local
import { GlobalSearch } from "./GlobalSearch";
import { PageNavLink } from "../../components";
import { TurnynLogo } from "../../components/img/TurnynLogo";
import {
  Navigation,
  // useLocation,
} from "../../lib";
import { Pages } from "../../pages";
import {
  authSelectors,
  // matchAppraisalListPath,
  useDealerOrDefault,
  useDealers,
  useSelector,
} from "../../state";
// import { useNotifCheck } from "../../state/notifs";

/** @type {import("@material-ui/core").MenuProps} */
const userMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  /** Makes vertical position work https://stackoverflow.com/a/52551100 */
  getContentAnchorEl: null,
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

const drawerWidth = "100%";

const useStyles = makeStyles(
  theme => ({
    root: {
      height: 56,
    },
    appBar: {
      backgroundColor: theme.palette.secondary.main,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(0, 3),
      maxWidth: "100vw",
      left: 0,
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: drawerWidth,
      transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    mainTools: {
      display: "flex",
      alignItems: "center",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.secondary.main,
      color: "#ffffff",
      paddingLeft: theme.spacing(1),
    },
    drawerHeader: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    TurnynLogo: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    closeIcon: {
      color: "#ffffff",
      fontSize: 24,
    },
    menu: {
      width: "100%",
      maxWidth: 220,
      "& .MuiTypography-body1": {
        cursor: "pointer",
        fontSize: 16,
        fontWeight: 500,
      },
      "& a": {
        fontSize: 16,
        fontWeight: 500,
        color: "#B5C1FF",
        marginBottom: theme.spacing(1),
        "&:hover": {
          color: "#ffffff",
        },
      },
      "& $menuButton": {
        fontSize: 14,
        color: theme.palette.text.primary,
        backgroundColor: "#B5C1FF",
        padding: theme.spacing(1),
        borderRadius: 3,
      },
    },
    nestedMenu: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: 12,
      borderLeft: "1px solid rgba(255,255,255,0.38)",
    },
    secondList: { marginTop: theme.spacing(3) },
    dealerName: {
      color: "unset",
      paddingLeft: 16,
      marginRight: 16,
    },
  }),
  {
    classNamePrefix: "MobileToolbar",
  },
);

export const MobileToolbar = React.memo(
  /**
   *
   */
  function MobileToolbar({ toggleMainMenu }) {
    // #region Hooks
    const classes = useStyles();
    const avatarInfo = useSelector(authSelectors.avatarInfo);
    const isAdmin = useSelector(authSelectors.isAdmin);
    const isGroupAdmin = useSelector(authSelectors.isGroupAdmin);
    const allowService = useSelector(authSelectors.allowService);
    const onlyService = useSelector(authSelectors.onlyService);
    // const { notif } = useNotifCheck();

    // const location = useLocation();
    // /** Used to keep the same list search when switching lists... */
    // const search = matchAppraisalListPath(location.pathname)
    //   ? location.search
    //   : "";
    const search = "";
    const dealer = useDealerOrDefault();
    const dealers = useDealers();
    const [open, setOpen] = React.useState(false);
    const [listOpen, setListOpen] = React.useState(false);
    const [searchOpen, setSearchOpen] = React.useState(false);

    const handleDrawerOpen = React.useCallback(() => {
      setOpen(true);
    }, []);

    const handleDrawerClose = React.useCallback(() => {
      setOpen(false);
    }, []);

    const handleClick = React.useCallback(() => {
      setListOpen(!listOpen);
    }, [listOpen]);

    const [userMenuAnchor, setUserMenuAnchor] = React.useState(null);
    const userMenuOpen = Boolean(userMenuAnchor);
    const closeUserMenu = React.useCallback(() => {
      setUserMenuAnchor(null);
    }, []);
    const openUserMenu = React.useCallback(e => {
      setUserMenuAnchor(e.currentTarget);
    }, []);

    const onChangeDealer = React.useCallback(
      /** @param {React.FormEvent<HTMLSelectElement>} e */
      e => {
        const id = e.target.value;
        const dealer = dealers.find(it => it.id === id);
        closeUserMenu();
        // Keep the same path that we're on, just change the slug...
        let pathname = `/${dealer.slug}/new`;
        // console.log("GOING TO", pathname);
        Navigation.go(pathname);
      },
      [dealers, closeUserMenu],
    );

    const onClickLogout = React.useCallback(() => {
      closeUserMenu();
      Navigation.go(Pages.auth.logout);
    }, [closeUserMenu]);

    const onClickDealerSettings = React.useCallback(() => {
      closeUserMenu();
      Navigation.go(Pages.dealer.profile, {
        params: {
          dslug: dealer.slug,
        },
      });
    }, [dealer, closeUserMenu]);

    const onClickUserSettings = React.useCallback(() => {
      closeUserMenu();
      Navigation.go(Pages.user.profile);
    }, [closeUserMenu]);

    const onClickNotification = React.useCallback(() => {
      closeUserMenu();
      Navigation.go(Pages.user.notificationsTable);
    }, [closeUserMenu]);

    // #endregion

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar
            disableGutters={true}
            style={{ justifyContent: searchOpen ? "center" : "space-between" }}
          >
            {!searchOpen ? (
              <Box className={classes.mainTools}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <Box pt="6px">
                  <TurnynLogo height="24px" width="87px" />
                </Box>
              </Box>
            ) : null}
            <Box className={classes.mainTools}>
              <GlobalSearch isMobile={true} setSearchOpen={setSearchOpen} />
              {/* <Badge badgeContent={notif.unreadCount} color="error"> */}
              {!searchOpen ? (
                <Avatar
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={openUserMenu}
                  title={avatarInfo.fullName}
                >
                  {avatarInfo.text}
                </Avatar>
              ) : null}
              {/* </Badge> */}
              <Menu
                id="menu-appbar"
                anchorEl={userMenuAnchor}
                open={userMenuOpen}
                onClose={closeUserMenu}
                classes={{ paper: classes.userMenu }}
                {...userMenuProps}
              >
                {/* <MenuItem onClick={closeUserMenu}>Profile</MenuItem>
                <MenuItem onClick={closeUserMenu}>My account</MenuItem>
                <Divider />
                NOTE: The following MenuItem exists to prevent the first item
                being selected by default. See
                https://github.com/mui/material-ui/issues/5186#issuecomment-337278330
                */}
                <MenuItem style={{ display: "none" }}>&nbsp;</MenuItem>
                <Select
                  disableUnderline
                  className={classes.dealerName}
                  value={dealer.id}
                  onChange={onChangeDealer}
                >
                  {dealers.map(it => (
                    <MenuItem key={it.id} value={it.id}>
                      {it.name}
                    </MenuItem>
                  ))}
                </Select>
                <Divider />
                {isAdmin && [
                  <MenuItem key="ds" onClick={onClickDealerSettings}>
                    Dealer settings
                  </MenuItem>,
                  <Divider key="d1" />,
                ]}
                <MenuItem onClick={onClickUserSettings}>Your settings</MenuItem>
                <Divider />
                <MenuItem onClick={onClickNotification}>
                  Notifications
                  {/* <Box sx={{ pr: 2 }}>Notifications</Box>
                  <Badge badgeContent={notif.unreadCount} color="error" /> */}
                </MenuItem>
                <Divider />
                <MenuItem onClick={onClickLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
            <Box pt="6px" className={classes.TurnynLogo}>
              <TurnynLogo height="24px" width="87px" />
            </Box>
          </div>
          <List component="nav" className={classes.menu}>
            {!onlyService && (
              <ListItem
                onClick={handleClick}
                className={classes.nestedMenuLink}
              >
                <ListItemText primary="Opportunities" />
                {listOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            {!onlyService && (
              <Collapse in={listOpen} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  disablePadding
                  className={classes.nestedMenu}
                >
                  <ListItem>
                    <PageNavLink
                      to={`/${dealer.slug}/appraisals${search}`}
                      noWrap
                      color="inherit"
                      onClick={handleDrawerClose}
                    >
                      Buying center
                    </PageNavLink>
                  </ListItem>
                  <ListItem>
                    <PageNavLink
                      to={`/${dealer.slug}/appraisals/returns${search}`}
                      noWrap
                      color="inherit"
                      onClick={handleDrawerClose}
                    >
                      Lease returns
                    </PageNavLink>
                  </ListItem>
                </List>
              </Collapse>
            )}
            {!onlyService && (
              <ListItem>
                <PageNavLink
                  to={`/${dealer.slug}/appraisals/dashboard${search}`}
                  noWrap
                  color="inherit"
                  className={classes.headerLink}
                  onClick={handleDrawerClose}
                >
                  Dashboard
                </PageNavLink>
              </ListItem>
            )}
            {!onlyService && (
              <ListItem>
                <PageNavLink
                  to={`/${dealer.slug}/appraisals/showroom`}
                  noWrap
                  color="inherit"
                  onClick={handleDrawerClose}
                >
                  Showroom
                </PageNavLink>
              </ListItem>
            )}
            {allowService && (
              <ListItem>
                <PageNavLink
                  to={`/${dealer.slug}/appraisals/service`}
                  noWrap
                  color="inherit"
                  onClick={handleDrawerClose}
                >
                  Service
                </PageNavLink>
              </ListItem>
            )}
            {isGroupAdmin && (
              <ListItem>
                <PageNavLink
                  to={`/my/stats`}
                  noWrap
                  color="inherit"
                  onClick={handleDrawerClose}
                >
                  Dealer Stats
                </PageNavLink>
              </ListItem>
            )}
            <List>
              {!onlyService && (
                <ListItem>
                  <PageNavLink
                    to={`/${dealer.slug}/appraisals/rejected${search}`}
                    noWrap
                    color="inherit"
                    className={classes.secondList}
                    onClick={handleDrawerClose}
                  >
                    Rejected
                  </PageNavLink>
                </ListItem>
              )}
              {!onlyService && (
                <ListItem>
                  <PageNavLink
                    to={`/${dealer.slug}/appraisals/archived${search}`}
                    noWrap
                    color="inherit"
                    onClick={handleDrawerClose}
                  >
                    Archived
                  </PageNavLink>
                </ListItem>
              )}
              <ListItem>
                <PageNavLink
                  to={`/${dealer.slug}/new${search}`}
                  noWrap
                  color="inherit"
                  className={classes.menuButton}
                  onClick={handleDrawerClose}
                >
                  New {onlyService ? "Inspection" : "Opportunity"}
                </PageNavLink>
              </ListItem>
            </List>
          </List>
        </Drawer>
      </div>
    );
  },
);
