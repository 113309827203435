export const InputPropsFor = {
  /** @type {import("@material-ui/core").InputProps} */
  disableUnderline: {
    disableUnderline: true,
  },
  /** @type {import("@material-ui/core").InputProps} */
  readOnly: {
    readOnly: true,
  },
};
