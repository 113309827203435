// import type { AppThunk } from "../types";
import { FileUploader } from "../../lib";
import { authClient } from "../auth/actions";
import { AppThunk } from "../types";
import { dealer } from "./state";
const { actions } = dealer;

const campaignTypeById = {
  1: { id: 1, name: "Showroom" },
  2: { id: 2, name: "Outbound marketing" },
  3: { id: 3, name: "Web" },
  4: { id: 4, name: "Dealer" },
};
const campaignTypes = Object.values(campaignTypeById);

export const dealerApi = {
  // Affiliates

  async getAffiliate(id: number) {
    const { data } = await authClient.get<DealerAffiliate>(
      `/dealer_affiliates/${id}`,
    );
    return data;
  },
  async getAffiliates() {
    const { data } = await authClient.get<DealerAffiliate[]>(
      `/dealer_affiliates`,
    );
    return data;
  },
  async removeAffiliate(id: number) {
    await authClient.delete(`/dealer_affiliates/${id}`);
  },
  async saveAffiliate(values: Partial<DealerAffiliate> & { id: number }) {
    const { id, ...rest } = values;
    if (id > 0) {
      const { data } = await authClient.put<DealerAffiliate>(
        `/dealer_affiliates/${id}`,
        rest,
      );
      return data;
    }
    const { data } = await authClient.post<DealerAffiliate>(
      `/dealer_affiliates`,
      rest,
    );
    return data;
  },

  // Banks

  async getBank(id: number) {
    const { data } = await authClient.get<DealerBank>(`/dealer_banks/${id}`);
    return data;
  },
  async getBanks() {
    const { data } = await authClient.get<DealerBank[]>(`/dealer_banks`);
    return data;
  },
  async removeBank(id: number) {
    await authClient.delete(`/dealer_banks/${id}`);
  },
  async saveBank(values: Partial<DealerBank> & { id: number }) {
    const { id, ...rest } = values;
    if (id > 0) {
      const { data } = await authClient.put<DealerBank>(
        `/dealer_banks/${id}`,
        rest,
      );
      return data;
    }
    const { data } = await authClient.post<DealerBank>(`/dealer_banks`, rest);
    return data;
  },

  // Campaigns

  campaignTypeById,
  campaignTypes,

  async getCampaign(id: number) {
    const { data } = await authClient.get<DealerCampaign>(
      `/dealer_campaigns/${id}`,
    );
    return data;
  },
  async getCampaigns() {
    const { data } = await authClient.get<DealerCampaign[]>(
      `/dealer_campaigns`,
    );
    return data;
  },
  async removeCampaign(id: number) {
    await authClient.delete(`/dealer_campaigns/${id}`);
  },
  async saveCampaign(values: Partial<DealerCampaign> & { id: number }) {
    const { id, ...rest } = values;
    if (id > 0) {
      const { data } = await authClient.put<DealerCampaign>(
        `/dealer_campaigns/${id}`,
        rest,
      );
      return data;
    }
    const { data } = await authClient.post<DealerCampaign>(
      `/dealer_campaigns`,
      rest,
    );
    return data;
  },

  // Dealer

  async getDealer(id: number) {
    const { data } = await authClient.get<Dealer>(`/dealers/${id}`);
    return data;
  },
  async getDealers() {
    const { data } = await authClient.get<Dealer[]>(`/dealers`);
    return data;
  },
  async saveDealer(values: Partial<Dealer> & { id: number }) {
    const { id } = values;
    const { data } = await authClient.put<Dealer>(`/dealers/${id}`, values);
    return data;
  },

  // Settings

  async getSetting(id: number) {
    const { data } = await authClient.get<DealerSetting>(
      `/dealer_settings/${id}`,
    );
    return data;
  },
  async getSettings() {
    const { data } = await authClient.get<DealerSetting[]>(`/dealer_settings`);
    return data;
  },
  async saveSetting(values: Partial<DealerSetting> & { id: number }) {
    const { id, ...rest } = values;
    const { data } = await authClient.put<DealerSetting>(
      `/dealer_settings/${id}`,
      rest,
    );
    return data;
  },

  // email templates

  //get all email templates for display purposes
  async getTemplates() {
    const { data } = await authClient.get(`/dealer_email_templates`);
    return data;
  },

  //get a email template by id to view and edit
  async getTemplate(id: number) {
    const { data } = await authClient.get(`/dealer_email_templates/${id}`);
    return data;
  },

  //saving the template as a post or a put depending if it exists on dealer_email_templates already
  async saveTemplate(
    id: number,
    values: object,
    isCustom: boolean,
    enabled = false,
  ) {
    if (isCustom) {
      console.log(4444, values);
      const { data } = await authClient.put(`/dealer_email_templates/${id}`, {
        enabled,
        values,
      });
      return data;
    }
    const { data } = await authClient.post(`/dealer_email_templates`, {
      id,
      values,
      enabled,
    });
    return data;
  },
  async previewTemplate(templateId: number, body: any) {
    await authClient.post(`/dealer_email_templates/preview`, {
      templateId,
      body,
    });
  },
  async removeTemplate(id: number) {
    await authClient.delete(`/dealer_email_templates/${id}`);
  },
  createDocument(id: number, file: File): AppThunk<Promise<any>> {
    return async dispatch => {
      const { data } = await authClient.post<any>(
        `/dealer_campaigns/${id}/documents`,
        {
          name: file.name,
          size: file.size,
          type: file.type,
        },
      );
      return data;
    };
  },
  uploadDocument(
    id: number,
    file: File,
  ): AppThunk<Promise<CreateDocumentData>> {
    return async dispatch => {
      const doc = await dispatch(dealerApi.createDocument(id, file));
      await FileUploader.upload(file, doc.uploadUrl);
      return doc;
    };
  },
  getDocument(uid: string): AppThunk {
    return async dispatch => {
      const { data } = await authClient.get(
        `/dealer_campaigns/document/${uid}`,
      );
      dispatch(actions.setDocument(data));
    };
  },
  clearDocument(): AppThunk {
    return async dispatch => {
      dispatch(actions.setDocument(undefined));
    };
  },
  sendEmails(uid: string): AppThunk<Promise<{ response: string }>> {
    return async dispatch => {
      try {
        const { data } = await authClient.get(
          `/dealer_campaigns/document/${uid}/sendEmail`,
        );
        return data;
      } catch (e) {
        if (e.response) {
          return e.response.data;
        }
      } finally {
        dispatch(dealerApi.clearDocument());
      }
    };
  },

  // #region oppurtunity dash
  async getMissingPayoffs(range: number[]) {
    const { data, headers } = await authClient.get(
      `/missing_payoff_appraisals`,
      {
        params: { range },
      },
    );
    let total = headers["x-total-count"];

    if (!total) {
      const { data: innerData } = data;
      total = innerData.length;
    }
    return data;
  },
  async getAwaitingInspections(range: number[]) {
    const { data, headers } = await authClient.get(
      `/awaiting_inspection_appraisals`,
      {
        params: { range },
      },
    );
    let total = headers["x-total-count"];
    if (!total) {
      const { data: innerData } = data;
      total = innerData.length;
    }
    return data;
  },
  async getResendOffers(range: number[]) {
    const { data, headers } = await authClient.get(`/resend_offer_appraisals`, {
      params: { range },
    });

    let total = headers["x-total-count"];
    if (!total) {
      const { data: innerData } = data;
      total = innerData.length;
    }
    return data;
  },
  async getHighEquityOffers(range: number[]) {
    const { data, headers } = await authClient.get(`/high_equity_appraisals`, {
      params: { range },
    });
    let total = headers["x-total-count"];
    if (!total) {
      const { data: innerData } = data;
      total = innerData.length;
    }
    return data;
  },
  // #endregion
};
