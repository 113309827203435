import React from "react";
import { Button, makeStyles } from "@material-ui/core";
// Local
import { Field, PhoneField, SelectStateField } from "../../../components";
import { Form, mapFormDefaults, useFormik } from "../../../lib";
import { dealerApi, useDealer } from "../../../state";

const useStyles = makeStyles(
  theme => ({
    root: {
      // margin: 0,
    },
    formItem: {
      padding: 16,
    },
    formActions: {
      display: "flex",
      padding: 16,
      "& > .MuiButton-root": {
        marginRight: 16,
        minWidth: 120,
      },
    },
  }),
  {
    classNamePrefix: "DealerProfileForm",
  },
);

const defaultValues = {
  // name: "",
  // publicName: "",
  // logoUrl: "",
  phoneNumber: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
};

export const DealerProfileForm = React.memo(
  /**
   *
   */
  function DealerProfileForm({ children }) {
    const classes = useStyles();
    const dealer = useDealer();

    /** @type {[Dealer]} */
    const [initialValues, setInitialValues] = React.useState(defaultValues);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      validateOnBlur: false,
      validateOnChange: false,
      async onSubmit(values, helpers) {
        await dealerApi.saveDealer({ id: dealer.id, ...values });
        setInitialValues(values);
      },
    });

    React.useEffect(() => {
      const state = { mounted: true };
      (async function onDealerChanged() {
        setInitialValues(defaultValues);
        const values = await dealerApi.getDealer(dealer.id);
        if (!state.mounted) return;
        setInitialValues(mapFormDefaults(defaultValues, values));
      })();
      return () => {
        state.mounted = false;
      };
    }, [dealer]);

    return (
      <div className={classes.root}>
        <Form form={frm}>
          {/* <div className={classes.formItem}>
            <Field
              name="name"
              label="Name"
              helperText="Enter the default common name of the dealership. Example: Brooklyn Chrysler"
              fullWidth
            />
          </div>
          <div className={classes.formItem}>
            <Field
              name="publicName"
              label="Public name"
              helperText="Enter the full public name of the dealership. Example: Brooklyn Chrysler Jeep Dodge Ram"
              fullWidth
            />
          </div> */}
          <div className={classes.formItem}>
            <Field
              name="address1"
              label="Public dealership address."
              fullWidth
            />
          </div>
          <div className={classes.formItem}>
            <Field name="address2" label="Address 2" fullWidth />
          </div>
          <div className={classes.formItem}>
            <Field name="city" label="City" />
          </div>
          <div className={classes.formItem}>
            <SelectStateField name="state" label="State" />
          </div>
          <div className={classes.formItem}>
            <Field name="zip" label="zip" />
          </div>
          <div className={classes.formItem}>
            <PhoneField name="phoneNumber" label="Public phone number" />
          </div>
          {/* <div className={classes.formItem}>
            <Field
              name="logoUrl"
              label="Logo"
              helperText="Enter the URL of the dealership logo. It MUST start with https://"
              fullWidth
            />
          </div> */}
          <div className={classes.formActions}>
            <Button
              type="reset"
              variant="outlined"
              size="large"
              disabled={!frm.dirty || frm.isSubmitting}
            >
              Reset
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={!frm.dirty || frm.isSubmitting}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    );
  },
);
