import React from "react";
import {
  Box,
  // Button,
  DialogContent,
  // Link,
  // Typography,
  makeStyles,
} from "@material-ui/core";
// Local
import {
  AutocompleteField,
  CancelSaveDialogActions,
  // ComboField,
  Field,
  // DollarField,
  PhoneField,
  QRCodeImage,
  SimpleDialog,
  ToggleBlock,
  useBasicDialog,
} from "../../../components";
import {
  //
  Form,
  formatDateTime,
  // formatDecimal,
  mapFormDefaults,
  useFormik,
  yup,
} from "../../../lib";
import {
  getDealerServiceAppraisalUrl,
  userApi,
  useDealer,
} from "../../../state";
import { useMobile } from "../../../themes";

const useStyles = makeStyles(
  theme => ({
    root: {
      [theme.breakpoints.up("sm")]: {
        // "& .MuiDialog-paper": {
        //   width: 540,
        //   minHeight: 321,
        // },
        // "& .MuiDialogContent-root": {
        //   paddingTop: 24,
        //   paddingRight: 24,
        //   paddingLeft: 24,
        //   paddingBottom: 8,
        // },
        "& .MuiFormControl-root": {
          marginTop: 32,
        },
        // "& .MuiDialogActions-root": {
        //   paddingBottom: 24,
        //   paddingLeft: 24,
        //   paddingRight: 24,
        // },
      },
    },
    formItem: {
      padding: 8,
    },
    infoTable: {
      borderCollapse: "collapse",
      fontWeight: 200,
      "& .info-title": {
        fontWeight: 400,
      },
      "& tr": {
        borderBottom: "1px solid lightgrey",
      },
    },
    tb: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    changed: {
      paddingTop: 12,
      [theme.breakpoints.down("xs")]: {
        // Added vertical space for mobile keyboard.
        paddingBottom: 36,
      },
    },
  }),
  {
    classNamePrefix: "DealerUserEditor",
  },
);

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  roles: [],
};

const validationSchema = yup.object({
  firstName: yup.string().min(3).required("First name is required"),
  lastName: yup.string().min(3).required("First name is required"),
  email: yup.string().min(3).required("Email name is required"),
  phoneNumber: yup.string(),
});

export const DealerUserEditor = React.memo(
  /**
   *
   */
  function DealerUserEditor({ item, onClose }) {
    const classes = useStyles();
    const dealer = useDealer();
    const isMobile = useMobile();
    const { basicDialog, confirmAsync } = useBasicDialog();

    /** @type {[DealerUser]} */
    const [initialValues, setInitialValues] = React.useState(defaultValues);
    const [roles, setRoles] = React.useState([]);
    const [info, setInfo] = React.useState({
      allowService: false,
      uid: "",
    });

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      // validateOnBlur: false,
      validateOnChange: false,
      validationSchema,
      async onSubmit(values, helpers) {
        // console.log("SAVING", {
        //   item,
        //   values,
        // });
        await userApi.saveUser(item?.id ?? 0, values);
        setInitialValues(values);
        onClose();
      },
    });

    const onClickDelete = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      async e => {
        // if (!window.confirm("Are you sure?")) {
        //   return;
        // }
        if (!(await confirmAsync("Are you sure?"))) {
          return;
        }

        await userApi.removeUser(item.id);
        onClose();
      },
      [item, confirmAsync, onClose],
    );

    React.useEffect(() => {
      const state = { mounted: true };
      (async function onDealerChanged() {
        setInitialValues(defaultValues);
        const roles = await userApi.getUserRoles();
        const values = item?.id ? await userApi.getUser(item.id) : undefined;
        if (!state.mounted) return;
        setRoles(roles);
        if (values) {
          setInitialValues(mapFormDefaults(defaultValues, values));
          setInfo({
            allowService: values.roles.some(it => [3, 12, 13].includes(it.id)),
            uid: values.uid,
          });
        }
      })();

      return () => {
        state.mounted = false;
      };
    }, [item]);

    return (
      <SimpleDialog
        maxWidth="sm"
        className={classes.root}
        onClose={onClose}
        open={true}
        title={`${!item?.id ? "Add" : "Edit"} user`}
        fullScreen={isMobile}
      >
        <Form form={frm}>
          <DialogContent>
            <div className={classes.formItem}>
              <Field name="firstName" label="First name" autoFocus fullWidth />
            </div>
            <div className={classes.formItem}>
              <Field name="lastName" label="Last name" fullWidth />
            </div>
            <div className={classes.formItem}>
              <Field name="email" label="Email" fullWidth />
            </div>
            <div className={classes.formItem}>
              <PhoneField name="phoneNumber" label="Phone number" fullWidth />
            </div>
            <div className={classes.formItem}>
              <AutocompleteField
                name="roles"
                label="Role"
                // options={roles}
                itemsList={roles}
                // getOptions={userApi.getUserRoles}
                multiple
                fullWidth
                autoHighlight={false}
                autoSelect={false}
                // disableCloseOnSelect
                // renderOption={renderRoleOption}
              />
              <ToggleBlock
                infoIcon
                title="Show role permissions..."
                tip="Click here to show permissions for each role."
              >
                <table className={classes.infoTable}>
                  <tbody>
                    {roles.map(it => (
                      <tr key={it.id}>
                        <td className="info-title">{it.name}</td>
                        <td>{it.summary}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ToggleBlock>
            </div>
            {info.allowService && info.uid && (
              <ToggleBlock
                title="Show QR code for Service appraisals."
                tip="Click here to show the QR code for Service appraisals..."
                className={classes.tb}
              >
                <Box color="rgba(0,0,0,0.5)">
                  <em>
                    <small>
                      Right click to open image in new tab and print.
                    </small>
                  </em>
                </Box>
                <Box textAlign="center">
                  <QRCodeImage
                    text={getDealerServiceAppraisalUrl(dealer, info)}
                    title={`QR Code for service appraisals`}
                    width={120}
                  />
                </Box>
              </ToggleBlock>
            )}

            {item?.updatedAt && (
              <div className={classes.changed}>
                Last change: {formatDateTime(item.updatedAt)}
              </div>
            )}
          </DialogContent>
          <CancelSaveDialogActions
            disabled={frm.isSubmitting}
            saveDisabled={!frm.dirty}
            onCancel={onClose}
            onDelete={onClickDelete}
            deleteLabel="Deactivate"
          />
        </Form>
        {basicDialog}
      </SimpleDialog>
    );
  },
);

// const styles = {
//   /** @type {React.CSSProperties} */
//   roleName: {
//     display: "inline-block",
//     width: 220,
//   },
// };

// /** @param {{id:number,name:string}} option */
// function renderRoleOption(option) {
//   return (
//     <React.Fragment>
//       <div style={styles.roleName}>{option.name}</div>
//       &nbsp;&nbsp;
//       <small>TODO: Describe role #{option.id}...</small>
//     </React.Fragment>
//   );
// }
