import React from "react";
import {
  //
  Box,
  // Typography,
  makeStyles,
} from "@material-ui/core";
// Local
// import sendImg from "../../assets/images/send_customer_form.png";
import startImg from "../../assets/images/start_customer_form.png";
import {
  //
  NewAppraisalButton,
} from "../../dialogs/NewAppraisalDialogAnon";
// import { getMainSlug } from "../../lib/routing/Routing";
import { useMobile } from "../../themes";

const useStyles = makeStyles(
  theme => ({
    root: {},
    subtitle: {
      color: theme.palette.text.primary,
      fontSize: 16,
      marginTop: theme.spacing(1),
      fontWeight: 400,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 40,
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    card: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "40px 0",
      height: 270,
      width: 270,
      border: "1px solid #BBC2E1",
      borderRadius: 3,
      // marginRight: 40,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginBottom: theme.spacing(3),
      },
    },
    button: {
      height: 48,
      minWidth: 212,
      borderRadius: 3,
      backgroundColor: theme.palette.secondary.main,
      color: "#FFFFFF",
      fontSize: 16,
      fontWeight: 500,

      "&:hover": {
        backgroundColor: "#B5C1FF",
        color: theme.palette.text.primary,
      },
    },
    bgImg: {
      position: "fixed",
      bottom: 0,
      right: 0,
      zIndex: -1,
    },
  }),
  {
    classNamePrefix: "NewServiceAppraisalPage",
  },
);

// function getFormHref({ isLeaseReturn, dealer, user }) {
//   return `${APPRAISAL_APP_URL}/${isLeaseReturn ? "return" : "sell"}?dealer=${
//     dealer.id
//   }${user.uid ? "&duid=" + user.uid : ""}`;
// }

export const NewServiceAppraisalPage = React.memo(
  /**
   *
   */
  function NewServiceAppraisalPage() {
    const classes = useStyles();
    const isMobile = useMobile();
    // const dealerSlug = React.useMemo(() => getMainSlug(), []);

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Box fontSize="32px" py="64px" textAlign="center">
            Thank you!
          </Box>
          <div className={classes.card}>
            <img
              height="105"
              width="89"
              src={startImg}
              alt="Start a new service appraisal"
            />
            <NewAppraisalButton
              className={classes.button}
              isService
              defaultOpen
              isAnon
            >
              Start another service appraisal
            </NewAppraisalButton>
          </div>
        </div>
        {isMobile ? (
          ""
        ) : (
          <Box className={classes.bgImg}>
            <img
              alt="Appraisal dashboard"
              height="380"
              src="/images/NewOpportunityBg.png"
              width="700"
            />
          </Box>
        )}
      </div>
    );
  },
);
