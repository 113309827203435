import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
// Local
import { useField } from "../../lib";

export const useStyles = makeStyles(
  theme => ({
    group: props => ({
      paddingTop: 16,
      ...(props.compact || props.horizontal
        ? undefined
        : {
            "& .MuiFormControlLabel-root:not(:first-child)": {
              paddingTop: 16,
            },
          }),
    }),
    label: {
      alignItems: "start",
      "& .MuiRadio-root": {
        paddingTop: 0,
      },
    },
  }),
  {
    classNamePrefix: "RadioButtonsField",
  },
);

export const RadioButtonsField = React.memo(
  /**
   * A Material-UI `FormControl` and `Checkbox` bound to a `formik` field.
   *
   * @typedef {import("@material-ui/core").FormControlProps} FormControlProps
   * @typedef {object} RadioButtonsFieldProps
   * @property {Record<string,string>} [classFrom] Object to get `className`
   * from, for the given field `name`.
   * @property {boolean} [compact] True to lessen padding between items.
   * @property {boolean} [error] True if error.
   * @property {string} [helperText] Message to show beneath the control.
   * @property {{title:string,id:string|number}[]} items
   * @property {string} name
   * @property {string} [label]
   * @property {boolean} [horizontal] True to orient checkboxes horizontally.
   * @property {(value:any)=>any} [format]
   *
   * @param {RadioButtonsFieldProps & FormControlProps} props
   */
  function RadioButtonsField(props) {
    let {
      name,
      classFrom,
      className,
      compact: _compact,
      error,
      format,
      helperText,
      itemKey = "id",
      itemText = "title",
      itemValue = itemKey,
      items,
      label,
      horizontal,
      value: _value,
      ...rest
    } = props;
    const classes = useStyles(props);
    const [fld, meta, helpers] = useField(name);
    error = Boolean(meta.error) || error;
    helperText = meta.error ? meta.error : helperText;

    const onChange = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLInputElement>} e */
      e => {
        // console.log("VALUE", e.target.value);
        if (format) {
          helpers.setValue(format(e.target.value));
        } else {
          helpers.setValue(e.target.value);
        }
      },
      [helpers, format],
    );
    return (
      <FormControl
        component="fieldset"
        error={error}
        className={className || classFrom?.[name]}
        {...rest}
      >
        <FormLabel component="legend" focused={false}>
          {label}
        </FormLabel>
        <FormHelperText id={`${name}-helper-text`} error={error}>
          {helperText}
        </FormHelperText>
        <RadioGroup
          name={name}
          row={horizontal}
          className={classes.group}
          value={"" + fld.value}
          onChange={onChange}
        >
          {items.map((it, i) => (
            <FormControlLabel
              key={it[itemKey]}
              className={classes.label}
              value={"" + it[itemValue]}
              control={<Radio />}
              label={it[itemText]}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  },
);
