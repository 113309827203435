import { createSelector } from "@reduxjs/toolkit";
// Local
import { ROLES } from "../../config";
import { stringToHslPastel } from "../../lib";
import { AppState } from "../types";

const _state = (state: AppState) => state.auth;
const _roles = (state: AppState) => state.auth.roles ?? [];
const _isAdmin = createSelector(_roles, roles =>
  roles.includes(ROLES.DEALER_ADMIN),
);
const _isGroupAdmin = createSelector(_roles, roles =>
  roles.includes(ROLES.GROUP_ADMIN),
);
const _isAppraiser = createSelector(_roles, roles =>
  roles.includes(ROLES.APPRAISER),
);
const _isReception = createSelector(_roles, roles =>
  roles.includes(ROLES.RECEPTION),
);
const _isLoggedIn = createSelector(_state, state => !!state.userId);
const _userId = createSelector(_state, state => state.userId ?? 0);
const _userName = createSelector(_state, state => state.userName ?? "");

export const authSelectors = {
  allowReadVals: createSelector(_roles, roles => {
    return roles.some(role => ROLES.ALLOW_READ_VALUATION.includes(role));
  }),
  allowWriteAppraisal: createSelector(_roles, roles => {
    return roles.some(role => ROLES.ALLOW_WRITE_APPRAISAL.includes(role));
  }),
  avatarInfo: createSelector(_state, state => {
    const { userName = "", user } = state;
    let initials: string;
    let fullName: string;
    if (user) {
      const firstName = user.firstName ?? "";
      const lastName = user.lastName ?? "";
      initials = (firstName.substr(0, 1) + lastName.substr(0, 1)).toUpperCase();
      fullName = firstName + " " + lastName;
    } else {
      initials = (userName.substr(0, 1) || "NO").toUpperCase();
      fullName = userName;
    }
    return {
      bgColor: stringToHslPastel(fullName),
      fullName,
      text: initials,
      textColor: "#fff",
    };
  }),
  isAdmin: _isAdmin,
  isAdminOrAcqManager: createSelector(
    _roles,
    roles =>
      roles.includes(ROLES.DEALER_ADMIN) || roles.includes(ROLES.ACQ_MANAGER),
  ),
  allowTransfers: createSelector(
    _roles,
    roles =>
      roles.includes(ROLES.DEALER_ADMIN) ||
      roles.includes(ROLES.ACQ_MANAGER) ||
      roles.includes(ROLES.FINANCE_MANAGER) ||
      roles.includes(ROLES.APPRAISER) ||
      roles.includes(ROLES.SERVICE_MANAGER),
  ),
  isAdminOrFinanceManager: createSelector(
    _roles,
    roles =>
      roles.includes(ROLES.DEALER_ADMIN) ||
      roles.includes(ROLES.FINANCE_MANAGER),
  ),
  isAdminOrSalesAcqManager: createSelector(
    _roles,
    roles =>
      roles.includes(ROLES.DEALER_ADMIN) ||
      roles.includes(ROLES.SALES_MANAGER) ||
      roles.includes(ROLES.ACQ_MANAGER),
  ),
  isAppraiser: _isAppraiser,
  isGroupAdmin: _isGroupAdmin,
  isInSales: createSelector(
    _roles,
    roles =>
      roles.includes(ROLES.DEALER_ADMIN) ||
      roles.includes(ROLES.SALES) ||
      roles.includes(ROLES.SALES_MANAGER) ||
      roles.includes(ROLES.FINANCE_MANAGER),
  ),
  allowService: createSelector(
    _roles,
    roles =>
      roles.includes(ROLES.DEALER_ADMIN) ||
      roles.includes(ROLES.SERVICE_TECH) ||
      roles.includes(ROLES.SERVICE_MANAGER),
  ),
  onlyService: createSelector(_roles, roles => {
    const tech = roles.includes(ROLES.SERVICE_TECH);
    const mgr = roles.includes(ROLES.SERVICE_MANAGER);
    const len = roles.length;
    return (
      // Count the DEALER role + SERVICE_TECH &| SERVICE_MANAGER
      (tech && mgr && len === 3) || (tech && len === 2) || (mgr && len === 2)
    );
  }),
  isLoggedIn: _isLoggedIn,
  isReception: _isReception,
  roles: _roles,
  /** Selector used for routing */
  route: createSelector(_isLoggedIn, _roles, (isLoggedIn, roles) => ({
    isLoggedIn,
    roles,
  })),
  /** Selector used for getting the entire auth state. */
  state: createSelector(
    _isAdmin,
    _isLoggedIn,
    _roles,
    _userId,
    _userName,
    (isAdmin, isLoggedIn, roles, userId, userName) => ({
      isAdmin,
      isLoggedIn,
      roles,
      userId,
      userName,
    }),
  ),
  userId: _userId,
  userName: _userName,
  user: createSelector(_state, state => {
    const { user } = state;
    return user;
  }),
};
