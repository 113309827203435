import React from "react";
// Local
import { useStyles } from "./AuthLayout.styles";
import { useMobile } from "../../themes";

export const AuthLayout = React.memo(
  /**
   *
   */
  function AuthLayout({ children }) {
    const classes = useStyles();
    const isMobile = useMobile();

    return (
      <div className={classes.container}>
        <div className={classes.panel}>
          <div>
            {isMobile ? (
              <img src="/images/turnyn-logo-violetDark.png" alt="Turnyn" />
            ) : (
              <img src="/images/turnyn-logo-violet.png" alt="Turnyn" />
            )}
          </div>
          {isMobile ? (
            ""
          ) : (
            <div className={classes.icon}>
              <img
                src="/images/turnyn-icon-violet.svg"
                alt="Turnyn logo"
                width="342px"
              />
            </div>
          )}
          {/* placeholder div to space icon in middle of page with flex column direction justify space between */}
          <div />
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    );
  },
);
