import React from "react";
import {
  //
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Box,
  // Typography,
  // makeStyles,
} from "@material-ui/core";
// Local
import { formatDateTime, useOnMount } from "../../../lib";
import {
  appraisalActions,
  appraisalSelectors,
  useAction,
  useSelector,
} from "../../../state";
import { useMobile } from "../../../themes";

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       // margin: 0,
//     },
//   }),
//   {
//     classNamePrefix: "AppraisalActivityTab",
//   },
// );

export const AppraisalActivityTab = React.memo(
  /**
   * @param {{ record: Appraisal }} param0
   */
  function AppraisalActivityTab({ record }) {
    // const classes = useStyles();
    const isMobile = useMobile();

    const { activity } = useSelector(appraisalSelectors.showing);

    const getActivity = useAction(appraisalActions.getActivity);

    useOnMount(() => {
      getActivity(record.id);
    });

    if (!activity) {
      return (
        <Box padding="60px" textAlign="center">
          Loading...
        </Box>
      );
    }

    return (
      <Box
        padding={isMobile ? undefined : "20px"}
        mx={isMobile ? "-16px" : undefined}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Activity</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activity.map((it, i) => (
              <TableRow key={i}>
                <TableCell>{it.act}</TableCell>
                <TableCell>{it.det}</TableCell>
                <TableCell>{formatDateTime(it.ts)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  },
);
