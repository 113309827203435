import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
// Local
import {
  Field,
  PhoneField,
  QRCodeImage,
  ToggleBlock,
  useBasicDialog,
} from "../../../components";
import {
  Form,
  Navigation,
  // formatDateTime,
  mapFormDefaults,
  useFormik,
  yup,
} from "../../../lib";
import {
  //
  getDealerServiceAppraisalUrl,
  userApi,
  useDealers,
  authSelectors,
  useSelector,
} from "../../../state";
import Pages from "../../../pages";

const useStyles = makeStyles(
  theme => ({
    root: {
      // margin: 0,
    },
    formItem: {
      padding: 16,
    },
    formActions: {
      display: "flex",
      padding: 16,
      "& > .MuiButton-root": {
        marginRight: 16,
        minWidth: 120,
      },
    },
  }),
  {
    classNamePrefix: "UserProfileForm",
  },
);

const defaultValues = {
  firstName: "",
  lastName: "",
  // email: "",
  phoneNumber: "",
};

const validationSchema = yup.object({
  firstName: yup.string().min(3).required("First name is required"),
  lastName: yup.string().min(3).required("First name is required"),
  // email: yup.string().min(3).required("Email name is required"),
  phoneNumber: yup.string(),
});

export const UserProfileForm = React.memo(
  /**
   *
   */
  function UserProfileForm({ children }) {
    const classes = useStyles();
    const dealers = useDealers();
    const user = useSelector(authSelectors.user);
    const allowService = useSelector(authSelectors.allowService);

    const { alertAsync, basicDialog } = useBasicDialog();

    /** @type {[Dealer]} */
    const [initialValues, setInitialValues] = React.useState(defaultValues);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema,
      async onSubmit(values, helpers) {
        userApi
          .saveCurrentUser(values)
          .catch(err => {
            return alertAsync("There was an error, please contact support.");
          })
          .then(() => {
            return alertAsync(
              "We need to log you out for changes to take effect.",
            ).then(() => {
              Navigation.go(Pages.auth.logout);
            });
          });
      },
    });

    React.useEffect(() => {
      const state = { mounted: true };
      (async function onMount() {
        setInitialValues(defaultValues);
        const values = await userApi.getCurrentUser();
        if (!state.mounted) return;
        setInitialValues(mapFormDefaults(defaultValues, values));
      })();
      return () => {
        state.mounted = false;
      };
    }, []);

    return (
      <div className={classes.root}>
        <Form form={frm}>
          <div className={classes.formItem}>
            <Field name="firstName" label="First name" autoFocus fullWidth />
          </div>
          <div className={classes.formItem}>
            <Field name="lastName" label="Last name" fullWidth />
          </div>
          {/* <div className={classes.formItem}>
            <Field name="email" label="Email" fullWidth disabled />
          </div> */}
          <div className={classes.formItem}>
            <PhoneField name="phoneNumber" label="Phone number" fullWidth />
          </div>
          <div className={classes.formActions}>
            <Button
              type="reset"
              variant="outlined"
              size="large"
              disabled={!frm.dirty || frm.isSubmitting}
            >
              Reset
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={!frm.dirty || frm.isSubmitting}
            >
              Save
            </Button>
          </div>
        </Form>
        {allowService &&
          dealers.map(dealer => (
            <div className={classes.formItem} key={dealer.id}>
              <ToggleBlock
                title={
                  <Button variant="outlined" style={{ width: "100%" }}>
                    Show service appraisal QR code for: <u>{dealer.name}</u>
                  </Button>
                }
              >
                <Box color="rgba(0,0,0,0.5)" textAlign="center">
                  <em>
                    <small>
                      Right click to open image in new tab and print.
                    </small>
                  </em>
                </Box>
                <Box textAlign="center">
                  <QRCodeImage
                    text={getDealerServiceAppraisalUrl(dealer, user)}
                    title={`QR Code for service appraisals`}
                    width={120}
                  />
                </Box>
              </ToggleBlock>
            </div>
          ))}
        {basicDialog}
      </div>
    );
  },
);
