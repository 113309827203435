import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    lrtnColHead: {
      color: "#8D98C2",
      fontSize: 14,
      fontWeight: 600,
      paddingBottom: 16,
      paddingLeft: 16,
    },
    statusGroupRow: {
      boxShadow: "0 2px 12px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
      "& td": {
        verticalAlign: "top",
        // Set height to a positive number to enable `height: 100%` children.
        height: 1,
      },
      "& td > a": {
        display: "block",
        padding: "16px 16px 16px 16px",
        height: "100%",
      },
      "& td > .cell-body": {
        display: "block",
        padding: "16px 2px 16px 2px",
        height: "100%",
      },
      "& td > .bullet": {
        display: "block",
        padding: "16px 0 0 10px",
        height: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        boxShadow: "unset",
      },
    },
    nolink: {
      padding: "16px 16px 16px 16px",
    },
    lrtnContact: {
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "space-between",
      maxWidth: 270,
      padding: "16px 16px 16px 16px",
    },
    lrtnInfo: {
      flexDirection: "column",
      flex: 1,
    },
    lrtnAddr: {
      display: "inline-block",
      maxWidth: 200,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    lrtnGreen: {
      color: "#00D289",
    },
    lrtnRed: {
      color: "#FF3B6E",
    },
    lrtnOther: {
      color: "#8D98C2",
      // display: "inline-block",
      maxWidth: 200,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    lrtnStat: {
      color: "#8D98C2",
      paddingTop: 8,
      maxWidth: 240,
      overflow: "hidden",
      wordWrap: "break-word",
      // fontSize: "14px",
      // lineHeight: "1rem",
    },
    // first column
    photoBox: {
      height: 56,
      width: 88,
      boxSizing: "content-box",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: 11,
      "& svg": {
        position: "absolute",
        top: 4,
        left: 0,
        height: 20,
        width: 20,
        color: "white",
        backgroundColor: "#6876FF",
      },
      "& .thumbnail": {
        height: 56,
        width: 88,
        borderRadius: 3,
        objectFit: "cover",
      },
    },
    // second column
    launcher: {
      "& button": {
        borderRadius: 3,
        color: "#526B8A",
      },
    },
    lrtnVehicle: {
      width: 460,
      maxWidth: 460,
    },
    // third column
    // assignedIcon: {
    //   backgroundColor: "#4e50ff",
    //   color: "#fff",
    // },
    // fourth column
    users: {
      "& .MuiAvatarGroup-avatar": {
        width: "32px",
        height: "32px",
        fontSize: "12px",
      },
    },
    amountHeader: {
      color: "#8D98C2",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "21px",
      paddingBottom: theme.spacing(1),
    },
    amountBox: {
      color: theme.palette.text.primary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "21px",
      paddingTop: 4,
      paddingBottom: 20,
      "&.green": {
        color: "#3A8722",
      },
      "&.orange": {
        color: "#BB761F",
      },
      "&.blue": {
        color: "#2455AD",
      },
      "&.red": {
        color: "#F62527",
      },
    },
    carDetailText: {
      color: theme.palette.text.primary,
      fontWeight: 400,
      margin: "8px 0",
    },
    transfer: {
      backgroundColor: "#e4f7eb",
      borderRadius: 3,
      padding: "0 6px",
    },
    transferred: {
      backgroundColor: "#f7bced",
      color: "#141932",
      padding: "0 6px",
    },
    deleted: {
      backgroundColor: "#e91257",
      borderRadius: 3,
      color: "#ffffff",
      padding: "0 6px",
    },
    detailText: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: "400",
    },
    textCenter: {
      textAlign: "center",
    },

    pr2: {
      paddingRight: 2,
    },
    pl4: {
      paddingLeft: 4,
    },
    rowgap: {
      height: 8,
      [theme.breakpoints.down("xs")]: {
        height: 1,
        borderTop: "1px solid #DADFF6",
      },
    },
    noRows: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "60vh",

      "& > tr > td:nth-child(1)": {
        textAlign: "center",
        fontSize: "16px",
      },
      "& > tr > td > p:nth-child(3)": {
        textAlign: "center",
        fontSize: "16px",
        color: "#8D98C2",
      },
    },
  }),
  {
    classNamePrefix: "AppraisalsListGroup",
  },
);
