import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthDealer {
  id: number;
  name: string;
}

export interface AuthUser {
  email: string;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  uid: string;
}

export interface AuthState {
  dealers?: Dealer[];
  roles?: string[];
  userId?: number;
  userName?: string;
  user?: AuthUser;
}

const initialState: AuthState = {};

export const auth = {
  ...createSlice({
    name: "auth",
    initialState,
    reducers: {
      setAuthState(state, { payload }: PayloadAction<AuthState | undefined>) {
        if (!payload) {
          return initialState;
        }
        Object.assign(state, payload);
      },
    },
  }),
  persist: true,
};
