import React from "react";
import {
  //
  Avatar,
  makeStyles,
} from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
// Icons
//import AddIcon from "@material-ui/icons/Add";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const useStyles = makeStyles(
  theme => ({
    root: {
      flexWrap: "wrap",
      // maxWidth: 140,
      marginTop: -4,
    },
    user: {
      backgroundColor: "#B5C1FF",
      color: "#fff",
      height: 40,
      width: 40,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "19px",
      "&:first-child": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    originator: {
      backgroundColor: theme.palette.secondary.main,
    },
    add: {
      height: 40,
      width: 40,
      lineHeight: "19px",
      cursor: "pointer",
      backgroundColor: "white",
      border: "1px dashed #BBC2E1",
      "& .MuiSvgIcon-root": {
        fontSize: 20,
      },
    },
  }),
  {
    classNamePrefix: "AssignedUsers",
  },
);

export const AssignedUsers = React.memo(
  /**
   * @typedef {object} AssignedUsersProps
   * @property {number} [max] Maximum number of users to show
   * @property {Pick<Appraisal, "id" | "users" | "originator">} record
   *
   * @param {AssignedUsersProps} param0
   */
  function AssignedUsers({
    disabled,
    edit,
    // max = 4,
    record,
  }) {
    const classes = useStyles();

    const { id, users, originator: orig } = record;

    const onClickAdd = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (disabled) {
          return;
        }
        edit(id);
      },
      [edit, id, disabled],
    );

    return (
      <AvatarGroup
        className={classes.root}
        // max={max} // Do not use: It cuts off the orig and edit Avatars.
      >
        {orig && (
          <Avatar
            key={orig.id}
            className={`${classes.user} ${classes.originator}`}
            title={`${orig.firstName} ${orig.lastName} (Originator)`}
          >
            {orig.initials}
          </Avatar>
        )}
        {users &&
          users.map(u => (
            <Avatar
              key={u.id}
              className={classes.user}
              title={`${u.firstName} ${u.lastName}`}
            >
              {u.initials}
            </Avatar>
          ))}
        {edit && (
          <Avatar
            className={classes.add}
            onClick={onClickAdd}
            title="Assign users"
          >
            <PersonAddIcon />
          </Avatar>
        )}
      </AvatarGroup>
    );
  },
);
