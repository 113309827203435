import React from "react";
import {
  //
  AppBar,
  Box,
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  // Badge,
  // Typography,
} from "@material-ui/core";
// Icons
// import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Apps";
// Local
import { PageNavLink } from "../../components";
import { GlobalSearch } from "./GlobalSearch";
import { TurnynLogo } from "../../components/img/TurnynLogo";
import { Navigation } from "../../lib";
import { Pages } from "../../pages";
import {
  authSelectors,
  // matchAppraisalListPath,
  useDealerOrDefault,
  useDealers,
  useSelector,
} from "../../state";
import { useStyles } from "./MainToolbar.styles.js";
// import { useNotifCheck } from "../../state/notifs";

/** @type {import("@material-ui/core").MenuProps} */
const userMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  /** Makes vertical position work https://stackoverflow.com/a/52551100 */
  getContentAnchorEl: null,
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export const MainToolbar = React.memo(
  /**
   *
   */
  function MainToolbar({ toggleMainMenu }) {
    const classes = useStyles();
    const avatarInfo = useSelector(authSelectors.avatarInfo);
    const isAdmin = useSelector(authSelectors.isAdmin);
    const isGroupAdmin = useSelector(authSelectors.isGroupAdmin);
    const allowService = useSelector(authSelectors.allowService);
    const onlyService = useSelector(authSelectors.onlyService);

    // /** Used to keep the same list search when switching lists... */
    // const search = matchAppraisalListPath(location.pathname)
    //   ? location.search
    //   : "";
    const search = "";
    const dealer = useDealerOrDefault();
    const dealers = useDealers();
    const onChangeDealer = React.useCallback(
      /** @param {React.FormEvent<HTMLSelectElement>} e */
      e => {
        const id = e.target.value;
        const dealer = dealers.find(it => it.id === id);
        let pathname = `/${dealer.slug}/new`;
        Navigation.go(pathname);
      },
      [dealers],
    );
    // const { notif } = useNotifCheck();
    const [userMenuAnchor, setUserMenuAnchor] = React.useState(null);

    const userMenuOpen = Boolean(userMenuAnchor);
    const closeUserMenu = React.useCallback(() => {
      setUserMenuAnchor(null);
    }, []);
    const openUserMenu = React.useCallback(e => {
      setUserMenuAnchor(e.currentTarget);
    }, []);

    const onClickLogout = React.useCallback(() => {
      closeUserMenu();
      Navigation.go(Pages.auth.logout);
    }, [closeUserMenu]);

    const onClickDealerSettings = React.useCallback(() => {
      closeUserMenu();
      Navigation.go(Pages.dealer.profile, {
        params: {
          dslug: dealer.slug,
        },
      });
    }, [dealer, closeUserMenu]);

    const onClickUserSettings = React.useCallback(() => {
      closeUserMenu();
      Navigation.go(Pages.user.profile);
    }, [closeUserMenu]);

    const onClickNotification = React.useCallback(() => {
      closeUserMenu();
      Navigation.go(Pages.user.notificationsTable);
    }, [closeUserMenu]);

    const onClickDealerStats = React.useCallback(() => {
      closeUserMenu();
      Navigation.go(Pages.user.dealerStatsTable);
    }, [closeUserMenu]);

    return (
      <AppBar
        position="static"
        color="secondary"
        className={classes.root}
        elevation={0}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <div className={classes.mainTools}>
            <IconButton
              color="inherit"
              aria-label="Open main menu"
              onClick={toggleMainMenu}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <PageNavLink to="/" noWrap color="inherit">
              <Box pt="6px">
                <TurnynLogo height="24px" width="87px" />
              </Box>
            </PageNavLink>
            <PageNavLink
              to={`/${dealer.slug}/new`}
              noWrap
              color="inherit"
              className={classes.headerLink}
            >
              New {onlyService ? "inspection" : "opportunity"}
            </PageNavLink>
            {!onlyService && (
              <>
                <PageNavLink
                  to={`/${dealer.slug}/appraisals${search}`}
                  noWrap
                  color="inherit"
                  className={classes.headerLink}
                >
                  Buying center
                </PageNavLink>
                <PageNavLink
                  to={`/${dealer.slug}/appraisals/returns${search}`}
                  noWrap
                  color="inherit"
                  className={classes.headerLink}
                >
                  Lease returns
                </PageNavLink>
                <PageNavLink
                  to={`/${dealer.slug}/appraisals/showroom`}
                  noWrap
                  color="inherit"
                  className={classes.headerLink}
                >
                  Showroom
                </PageNavLink>
              </>
            )}
            {allowService && (
              <PageNavLink
                to={`/${dealer.slug}/appraisals/service`}
                noWrap
                color="inherit"
                className={classes.headerLink}
              >
                Service
              </PageNavLink>
            )}
          </div>
          <div className={classes.sideTools}>
            {/* <div className={classes.dealerName} title={dealer.publicName}>
              {dealer.name}
            </div> */}
            <Select
              disableUnderline
              className={classes.dealerName}
              value={dealer.id}
              onChange={onChangeDealer}
            >
              {dealers.map(it => (
                <MenuItem key={it.id} value={it.id}>
                  {it.name}
                </MenuItem>
              ))}
            </Select>
            <GlobalSearch />
            {/* <Badge badgeContent={notif.unreadCount} color="error"> */}
            <Avatar
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={openUserMenu}
              title={avatarInfo.fullName}
            >
              {avatarInfo.text}
            </Avatar>
            {/* </Badge> */}
            <Menu
              id="menu-appbar"
              anchorEl={userMenuAnchor}
              open={userMenuOpen}
              onClose={closeUserMenu}
              classes={{ paper: classes.userMenu }}
              {...userMenuProps}
            >
              {isAdmin && (
                <MenuItem onClick={onClickDealerSettings}>
                  Dealer settings
                </MenuItem>
              )}
              {isGroupAdmin && (
                <MenuItem onClick={onClickDealerStats}>Dealer stats</MenuItem>
              )}
              <Divider />
              <MenuItem onClick={onClickUserSettings}>Your settings</MenuItem>
              <Divider />

              <MenuItem onClick={onClickNotification}>
                Notifications
                {/* <Box sx={{ pr: 2 }}>Notifications</Box>
                <Badge badgeContent={notif.unreadCount} color="error" /> */}
              </MenuItem>
              <Divider />
              <MenuItem onClick={onClickLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    );
  },
);
