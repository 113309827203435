/**
 * @file From NPM package "material-ui-nested-menu-item", updated to:
 *
 * - Allow a left-aligned icon.
 *
 * Original source:
 * - https://github.com/azmenak/material-ui-nested-menu-item/blob/ba9e14374f18dafb1def39f1f3bd636a9b61bcad/src/index.tsx
 */
import React, {
  useCallback,
  useState,
  useRef,
  useImperativeHandle,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import clsx from "clsx";

export interface NestedMenuItemProps extends Omit<MenuItemProps, "button"> {
  /**
   * @default <ArrowRight />
   */
  align?: "left" | "right";
  /**
   * Open state of parent `<Menu />`, used to close decendent menus when the
   * root menu is closed.
   */
  parentMenuOpen: boolean;
  /**
   * Component for the container element.
   * @default 'div'
   */
  component?: React.ElementType;
  /**
   * Effectively becomes the `children` prop passed to the `<MenuItem/>`
   * element.
   */
  label?: React.ReactNode;
  /**
   * @default <ArrowRight />
   */
  menuIcon?: React.ReactNode;
  /**
   * Props passed to container element.
   */
  ContainerProps?: React.HTMLAttributes<HTMLElement> &
    React.RefAttributes<HTMLElement | null>;
  /**
   * Props passed to sub `<Menu/>` element
   */
  MenuProps?: Omit<MenuProps, "children">;
  /**
   * @see https://material-ui.com/api/list-item/
   */
  button?: true | undefined;
}

const TRANSPARENT = "rgba(0,0,0,0)";
const useMenuItemStyles = makeStyles(theme => ({
  root: (props: { align: "left" | "right"; open: boolean }) => {
    const { align, open } = props;
    const alignRight = align === "right";
    const css: React.CSSProperties = {};
    if (alignRight) {
      css.justifyContent = "space-between";
    } else {
      css.flexDirection = "row-reverse";
      css.justifyContent = "flex-end";
      css["& .MuiSvgIcon-root"] = {
        marginLeft: -24,
      };
    }
    return {
      backgroundColor: open ? theme.palette.action.hover : TRANSPARENT,
      display: "flex",
      ...css,
    };
  },
}));

const defaultContainerProps: React.HTMLAttributes<HTMLElement> &
  React.RefAttributes<HTMLElement> = {};

/**
 * Use as a drop-in replacement for `<MenuItem>` when you need to add cascading
 * menu elements as children to this component.
 */
export const NestedMenuItem = React.forwardRef<
  HTMLLIElement | null,
  NestedMenuItemProps
>(function NestedMenuItem(props, ref) {
  const {
    align = "right",
    parentMenuOpen,
    // component = "div",
    component: _ItemComponent,
    label,
    menuIcon = align === "right" ? <ArrowRight /> : <ArrowLeft />,
    children,
    className,
    tabIndex: tabIndexProp,
    // MenuProps = {},
    MenuProps: _MenuProps,
    ContainerProps: ContainerPropsProp = defaultContainerProps,
    ...menuItemProps
  } = props;

  const alignRight = align === "right";

  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

  const menuItemRef = useRef<HTMLLIElement>(null);
  useImperativeHandle(ref, () => menuItemRef.current);

  const containerRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(containerRefProp, () => containerRef.current);

  const menuContainerRef = useRef<HTMLDivElement>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setIsSubMenuOpen(true);

      if (ContainerProps?.onMouseEnter) {
        ContainerProps.onMouseEnter(event);
      }
    },
    [ContainerProps],
  );
  const handleMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setIsSubMenuOpen(false);

      if (ContainerProps?.onMouseLeave) {
        ContainerProps.onMouseLeave(event);
      }
    },
    [ContainerProps],
  );

  // Check if any immediate children are active
  const isSubmenuFocused = useCallback(() => {
    const active = containerRef.current?.ownerDocument?.activeElement;
    const children = menuContainerRef.current?.children ?? [];
    for (const idx in children) {
      const child = children[idx];
      if (child === active) {
        return true;
      }
    }
    return false;
  }, []);

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLElement>) => {
      if (event.target === containerRef.current) {
        setIsSubMenuOpen(true);
      }

      if (ContainerProps?.onFocus) {
        ContainerProps.onFocus(event);
      }
    },
    [ContainerProps],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      /** Key to focus on parent element. */
      const upKey = alignRight ? "ArrowLeft" : "ArrowRight";
      /** Key to focus on sub menu item 0. */
      const inKey = alignRight ? "ArrowRight" : "ArrowLeft";
      if (event.key === "Escape") {
        return;
      }

      if (isSubmenuFocused()) {
        event.stopPropagation();
      }

      const active = containerRef.current?.ownerDocument?.activeElement;

      if (event.key === upKey && isSubmenuFocused()) {
        containerRef.current?.focus();
      }

      if (
        event.key === inKey &&
        event.target === containerRef.current &&
        event.target === active
      ) {
        const firstChild = menuContainerRef.current?.children[0] as
          | HTMLElement
          | undefined;
        firstChild?.focus();
      }
    },
    [alignRight, isSubmenuFocused],
  );

  const open = isSubMenuOpen && parentMenuOpen;
  const menuItemClasses = useMenuItemStyles({ align, open });

  // Root element must have a `tabIndex` attribute for keyboard navigation
  let tabIndex: number;
  if (!props.disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
  }

  return (
    <div
      {...ContainerProps}
      ref={containerRef}
      onFocus={handleFocus}
      tabIndex={tabIndex}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
    >
      <MenuItem
        {...menuItemProps}
        className={clsx(menuItemClasses.root, className)}
        ref={menuItemRef}
      >
        {label}
        {menuIcon}
      </MenuItem>
      {!menuItemProps.disabled && (
        <Menu
          // Set pointer events to 'none' to prevent the invisible Popover div
          // from capturing events for clicks and hovers
          style={{ pointerEvents: "none" }}
          anchorEl={menuItemRef.current}
          anchorOrigin={{
            vertical: "top",
            horizontal: align,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: alignRight ? "left" : "right",
          }}
          open={open}
          autoFocus={false}
          disableAutoFocus
          disableEnforceFocus
          onClose={() => {
            setIsSubMenuOpen(false);
          }}
        >
          <div ref={menuContainerRef} style={{ pointerEvents: "auto" }}>
            {children}
          </div>
        </Menu>
      )}
    </div>
  );
});
