/**
 * @file This file is used to normalize environment variables and provide
 * intellisense/autocomplete for them. Import your settings from this file
 * instead of directly from `process.env`.
 *
 * Also export other (semi-) static config values here.
 */

export const ROLES = {
  ACQ_MANAGER: "ACQ_MANAGER",
  APPRAISER: "APPRAISER",
  /** The base role. Everybody has it. */
  DEALER: "DEALER",
  DEALER_ADMIN: "DEALER_ADMIN",
  FINANCE_MANAGER: "FINANCE_MANAGER",
  RECEPTION: "RECEPTION",
  SALES: "SALES",
  SALES_MANAGER: "SALES_MANAGER",
  SERVICE_TECH: "SERVICE_TECH",
  SERVICE_MANAGER: "SERVICE_MANAGER",
  GROUP_ADMIN: "GROUP_ADMIN",

  ALL_SALES_ROLES: [
    "DEALER_ADMIN",
    "SALES",
    "SALES_MANAGER",
    "FINANCE_MANAGER",
  ],

  ALLOW_READ_VALUATION: [
    "ACQ_MANAGER",
    "APPRAISER",
    "DEALER_ADMIN",
    "FINANCE_MANAGER",
    "SALES_MANAGER",
    // "SERVICE_MANAGER",
  ],

  ALLOW_WRITE_APPRAISAL: [
    "ACQ_MANAGER",
    "APPRAISER",
    "DEALER_ADMIN",
    "SALES_MANAGER",
    "SERVICE_MANAGER",
  ],
};

/**
 * The runtime environment. Prefer to use `process.env.NODE_ENV` if you can,
 * but this variable offers more flexibility since create-react-app only puts
 * the values "development" or "production" in NODE_ENV. When you use
 * `process.env.NODE_ENV`, the webpack compiler will trim out non-dev code...
 */
const REACT_APP_ENV = process.env.REACT_APP_ENV;
let APPRAISAL_APP_URL = "https://www.turnyn.com";

if (process.env.NODE_ENV === "development") {
  APPRAISAL_APP_URL = "http://localhost:7774";
} else {
  APPRAISAL_APP_URL =
    REACT_APP_ENV === "staging"
      ? "https://www.autoturnin.com"
      : "https://www.turnyn.com";
}

/** Base URL of the API. */
let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
/** URL to the company website. */
const REACT_APP_COMPANY_SITE_URL = process.env.REACT_APP_COMPANY_SITE_URL;
/** DSN URL for the error telemetry API. */
const REACT_APP_ERRORS_DSN = process.env.REACT_APP_ERRORS_DSN;
/** True to report errors during development. */
const REACT_APP_ERRORS_DEV = process.env.REACT_APP_ERRORS_DEV === "true";
/** Key to store redux state under localStorage. */
const REACT_APP_PERSIST_KEY = process.env.REACT_APP_PERSIST_KEY;
/** Sentry error reporting string */
const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
/** Site title string. */
const REACT_APP_SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
/** Package version string from the package.json file. */
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

if (process.env.NODE_ENV === "development") {
  // We cannot use the localhost API server to test on mobile devices.
  if (window.location.hostname !== "localhost") {
    REACT_APP_API_URL = REACT_APP_API_URL.replace(
      "http://localhost",
      `http://${window.location.hostname}`,
    );
  }
}

export {
  // Environment
  REACT_APP_ENV,
  APPRAISAL_APP_URL,
  // Application
  REACT_APP_API_URL,
  REACT_APP_COMPANY_SITE_URL,
  REACT_APP_ERRORS_DSN,
  REACT_APP_ERRORS_DEV,
  REACT_APP_PERSIST_KEY,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SITE_TITLE,
  REACT_APP_VERSION,
};
/**
 * Browser type information.
 */
export const browserType = {
  /** True if the browser has been detected as IE11. */
  ie11: !!window.MSInputMethodContext && !!document.documentMode,
  /**
   * True if iOS (iPhone, iPad, iPod) has been detected.
   * - See [Material-UI SwipeableDrawer](https://material-ui.com/components/drawers/#swipeable-temporary-drawer)
   */
  iOS: process?.browser && /iPad|iPhone|iPod/.test(navigator.userAgent),
};
