import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
//icons
import DescriptionIcon from "@material-ui/icons/Description";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SendIcon from "@material-ui/icons/Send";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LoopIcon from "@material-ui/icons/Loop";
// Local
import { useStyles } from "./Stats.styles";
import { Dot, SelectField } from "../../../components";
import { Link } from "react-router-dom";
import {
  //
  Form,
  useFormik,
  formatAmountUSD,
  isEmpty,
  shallowEqualsObj,
  useDebouncedEffect,
  useAsyncEffect,
  useLocation,
  formatPercent,
  Navigation,
  formatThousands,
} from "../../../lib";
import { appraisalActions, useAction } from "../../../state";

interface StatProps {
  icon: any;
  link:
    | "submitted"
    | "inspected"
    | "offers-accepted"
    | "offers-sent"
    | "buying"
    | "grounding";
  value: string;
  description: string;
  helperText?: string;
}
const formatPct = (p: number) => formatPercent(p * 100);
const formatDlrOnAppType = (p: DealerStats, getAvg = false) => {
  const field = `totalExpectedProfit${getAvg ? "Avg" : ""}${
    p.appraisalType === "trade_in" ? "TradeIn" : "LeaseReturn"
  }`;
  return formatAmountUSD(p[field]);
};
const dateRanges = [
  { id: 0, title: "Today" },
  { id: -2, title: "This week" },
  { id: -3, title: "This month" },
  { id: 7, title: "Past 7 days" },
  { id: 30, title: "Past 30 days" },
  { id: 60, title: "Past 60 days" },
  { id: 90, title: "Past 90 days" },
];

const defaultValues = {
  filterId: 30,
};

export const DealerStatsTable = React.memo(function AppraisalsDashboardPage({
  children,
}) {
  const classes = useStyles();
  const getGroupAdmin = useAction(appraisalActions.getDealerStats);

  const {
    //
    query,
    pathname,
  } = useLocation() || {};

  const [loading, setLoading] = React.useState(true);
  const [initialValues, setInitialValues] = React.useState(defaultValues);
  const [dashData, setDashData] = React.useState([]);

  useAsyncEffect(
    async state => {
      setLoading(true);
      const filter =
        !isEmpty(query) && query?.filter
          ? JSON.parse(query?.filter)
          : defaultValues;

      setInitialValues({ ...filter } as typeof defaultValues);

      const data = await getGroupAdmin({ ...filter } as Record<string, any>);
      if (!state.mounted) return;
      setDashData(data);
      setLoading(false);
    },
    [query],
  );

  /**
   * When we submit the form, change the location query. and the data will reload
   */
  const frm = useFormik({
    enableReinitialize: true,
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    async onSubmit(values, helpers) {
      Navigation.replace(Navigation.history.location.pathname, {
        query: { filter: JSON.stringify(values) },
      });
    },
  });

  useDebouncedEffect(() => {
    if (shallowEqualsObj(frm.initialValues, frm.values)) {
      return;
    }
    frm.submitForm();
  }, [frm]);

  const formatedType = (d: DealerStats) =>
    d.appraisalTypeId === 1 ? "trade ins" : "lease returns";
  const appTypeIdToType = (d: DealerStats) =>
    d.appraisalTypeId === 1 ? "trade" : "returns";

  const statCardsRow: (data: DealerStats) => StatProps[] = data => {
    const appType = appTypeIdToType(data);
    if (appType === "trade") {
      return [
        {
          icon: DescriptionIcon,
          value: `${formatThousands(data?.totalSubmitted) ?? 0}`,
          description: "Appraisals submitted",
          helperText: "Submitted",
          link: "submitted",
        },
        {
          icon: SearchIcon,
          value: `${formatThousands(data?.totalInspected) ?? 0}/${
            formatThousands(data?.totalSubmitted) ?? 0
          }`,
          description: `Inspections complete (${formatPct(
            data?.totalInspectedPct ?? 0,
          )})`,
          helperText: "Inspected / Submitted",
          link: "inspected",
        },
        {
          icon: SendIcon,
          value: `${formatThousands(data?.totalOfferSent) ?? 0}/${
            formatThousands(data?.totalSubmitted) ?? 0
          }`,
          description: `Offers sent (${formatPct(
            data?.totalOfferSentPct ?? 0,
          )})`,
          helperText: "Offers sent / Submitted",
          link: "offers-sent",
        },
        {
          icon: CheckCircleIcon,
          value: `${formatThousands(data?.totalOfferAccepted) ?? 0}/${
            data?.totalOfferSent ?? 0
          }`,
          description: `Offers accepted(${formatPct(
            data?.totalOfferAcceptedPct ?? 0,
          )})`,
          helperText: "Offers accepted / Offers sent",
          link: "offers-accepted",
        },
        {
          icon: LoopIcon,
          value: `${formatThousands(data?.totalBuying) ?? 0}/${
            formatThousands(data?.totalSubmitted) ?? 0
          }`,
          description: `Total converted (${formatPct(
            data?.totalConvertedPct ?? 0,
          )})`,
          helperText: "Buying / Submitted",
          link: "buying",
        },
      ];
    } else {
      return [
        {
          icon: DescriptionIcon,
          value: `${formatThousands(data?.totalSubmitted) ?? 0}`,
          description: "Appraisals submitted",
          helperText: "Submitted",
          link: "submitted",
        },
        {
          icon: SearchIcon,
          value: `${formatThousands(data?.totalInspected) ?? 0}/${
            formatThousands(data?.totalSubmitted) ?? 0
          }`,
          description: `Inspections complete (${formatPct(
            data?.totalInspectedPct ?? 0,
          )})`,
          helperText: "Inspected / Submitted",
          link: "inspected",
        },
        {
          icon: CheckCircleIcon,
          value: `${formatThousands(data?.totalBuying) ?? 0}/${
            formatThousands(data?.totalInspected) ?? 0
          }`,
          description: `Buying (${formatPct(data?.totalConvertedPct ?? 0)})`,
          helperText: "Buying / Inspected",
          link: "buying",
        },
        {
          icon: HighlightOffIcon,
          value: `${formatThousands(data?.totalGrounding) ?? 0}/${
            formatThousands(data?.totalInspected) ?? 0
          }`,
          description: `Total grounded`,
          helperText: "Grounded / Inspected",
          link: "grounding",
        },
        {
          icon: LoopIcon,
          value: `${formatThousands(data?.totalBuying) ?? 0}/${
            formatThousands(data?.totalSubmitted) ?? 0
          }`,
          description: `Total converted (${formatPct(
            data?.totalConvertedPct ?? 0,
          )})`,
          helperText: "Buying / Submitted",
          link: "buying",
        },
      ];
    }
  };
  const statCardsColumn: (
    data: DealerStats,
  ) => Omit<StatProps, "link">[] = data => [
    {
      icon: <Dot size={12} color="#00D289" />,
      value: `${data && formatDlrOnAppType(data)}`,
      description: "Total Expected profit of accepted offers",
    },
    {
      icon: <Dot size={12} color="#00C2FF" />,
      value: `${data && formatDlrOnAppType(data, true)}`,
      description: "Average expected profit of accepted offers",
    },
  ];

  return (
    <div>
      <Box className={classes.filterContainer}>
        <Form form={frm} promptOnExit={false} pathname={pathname}>
          <Box display="flex" pt="4px">
            <Box color="#8D98C2" fontSize="14px" fontWeight="500" pt="2px">
              Date range:{" "}
            </Box>
            <div>&nbsp;</div>
            <Box>
              <SelectField
                // emptyEnabled
                name="filterId"
                items={dateRanges}
                size="small"
                variant="standard"
                disableUnderline
                disabled={loading}
                className={classes.filter}
              />
            </Box>
          </Box>
        </Form>
      </Box>
      <Box className={classes.dataContainer}>
        {dashData.length ? (
          dashData.map(it => (
            <div className={classes.pageContainer} key={it.id}>
              <Box className={classes.title}>
                <Typography variant="h2">{`${it.name}'s   ${formatedType(
                  it,
                )}`}</Typography>
              </Box>
              <Grid container className={classes.statCardContainerHorizontal}>
                {statCardsRow(it).length &&
                  statCardsRow(it).map((sc: StatProps, idx) => {
                    return (
                      <Grid
                        item
                        className={classes.statCard}
                        key={idx}
                        title={sc.helperText ?? ""}
                      >
                        <Link
                          className={classes.link}
                          to={{
                            pathname: `/${it.dealerSlug}/${appTypeIdToType(
                              it,
                            )}/${sc.link}/dealer-stats`,
                            search: "?" + new URLSearchParams(query).toString(),
                          }}
                        >
                          <div className={classes.icon}>
                            {<sc.icon color="inherit" fontSize="medium" />}
                          </div>
                          <Typography className={classes.cardValue}>
                            {sc.value}
                          </Typography>
                          <Typography className={classes.cardDescription}>
                            {sc.description}
                          </Typography>
                        </Link>
                      </Grid>
                    );
                  })}
                <Grid item className={classes.statCardContainerVertical}>
                  <div className={classes.verticalColumn}>
                    {dashData ? (
                      <>
                        {statCardsColumn(it).length &&
                          statCardsColumn(it).map((sc: StatProps, idx) => {
                            return (
                              <Grid
                                item
                                className={classes.verticalStatCard}
                                key={idx}
                                title={sc.helperText ?? ""}
                              >
                                <Box alignItems="center" display="flex">
                                  <Box display="flex" margin={3}>
                                    {sc.icon}
                                  </Box>
                                  <Box
                                    display="flex"
                                    justifyContent={"space-around"}
                                  >
                                    <Typography className={classes.cardValue}>
                                      {sc.value}
                                    </Typography>
                                    <Typography
                                      className={
                                        classes.verticalCardDescription
                                      }
                                    >
                                      {sc.description}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                      </>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </div>
          ))
        ) : (
          <Box className={classes.noRows}>
            <img
              src="/images/break-time.svg"
              alt="break-time"
              style={{ width: "145px" }}
            />
            <p>Time for a break</p>
            <p>No submissions where found</p>
          </Box>
        )}
      </Box>
    </div>
  );
});
